const { isEmpty, omit, } = require('lodash');

const { cfAccountItemCategories, } = require('../config');

const { entries } = Object;

const presetConsolidationJournalTypes = entries({
  investAndCapital: { label: '投資と資本の消去', },
  minorityInterest: { label: '非支配株主持分への按分', },
  dividend: { label: '配当金の相殺', },
  goodwill: { label: 'のれん償却', },
  debtAndCredit: { label: '債権債務消去', },
  profitAndCost: { label: '損益取引消去', },
  unrealizedProfit: { label: '未実現利益の消去', },
  allowance: { label: '貸倒引当金の調整', },
  taxEffect: { label: '連結税効果', },
  equityMethod: { label: '持分法', },
  net: { label: '純額処理', },
  other: { label: 'その他', },
}).map(([k, v], i) => ({ id: k, name: v.label, index: -100 + i, }));

const fields = ({ otherItems = [], }) => {
  const otherItemNames = otherItems.map(_ => _.name);
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        unique: v => !otherItemNames.includes(v),
      },
    },
  };
};

exports.fields = fields;
exports.presetConsolidationJournalTypes = presetConsolidationJournalTypes;
