import React, { Component, Fragment, useEffect, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { uniqBy, isEmpty, get, orderBy, sumBy, keyBy, sortBy, omit, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import numeral from 'numeral';

import { batch } from './../shared/firebase';
import { accountItemCategoryNames, } from '../shared/config';
import { floatFormat } from './../util';
import firebase, { functions } from './../firebase';
import { generateRows, rowsForExport, computeAlerts, } from '../shared/lib/individualPkg';
import { withConsolidationAccountItem, } from './../shared/models/trial';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import usePkgItems from './hooks/usePkgItems';
import ModelFormModal from './modals/ModelFormModal';
import ProgressButton from './ProgressButton';
import AddButton from './AddButton';
import EditButton from './EditButton';
import ExportButton from './ExportButton';
import DeleteButton from './DeleteButton';
import AmountWithExchange from './AmountWithExchange';
import Alerts from './Alerts';

const { keys, entries } = Object;
const db = firebase.firestore();

export default function IndividualChangesTableSection (props) {
  const { collectionName, section, title, fields, changeFieldNames, company, period, yearMonth, filteredYearMonths, relatedCompanies, accountItems, consolidationAccountItems, itemTrials, trials, subsidiaryId, filterTrials, relatedCompany, prevCr, cr, isLockedMonth, } = props;
  const itemsRef = company.ref.collection(collectionName);
  const { items } = usePkgItems(company, relatedCompany, subsidiaryId, accountItems, collectionName, [], 'closing', changeFieldNames, filteredYearMonths);
  const accountItemsByName = keyBy(accountItems, 'name');
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const trialBalances = trials.flatMap(_ => _.balances);
  const rows = generateRows(relatedCompany, items || [], accountItems, changeFieldNames, prevCr, cr, trials);
  const onClickImportFromFreee = async () => {
    if(!window.confirm('試算表から取り込みます。よろしいですか？')) return;

    const existingItems = items;
    const filteredBalances = uniqBy([...itemTrials.map(_ => ({ ..._, type: 'itemTrial' })), ...trialBalances.map(_ => ({ ..._, type: 'trial' }))], 'account_item_name').map(_ => withConsolidationAccountItem(_, accountItemsByName, consolidationAccountItemsById)).filter(filterTrials);
    await batch(db, filteredBalances, (batch, balance) => {
      const accountItemId = accountItemsByName[balance.account_item_name]?.id;
      if(accountItemId == null || existingItems.some(_ => _.accountItemId === accountItemId)) return;

      const data = {
        accountItemId,
        opening: balance.opening_balance,
        ...entries(omit(fields(), ['accountItemId', 'opening'])).reduce((x, [field, { label, }]) => {
          const filterItems = field === 'other' ? (_ => !Object.values(omit(fields(), 'other')).map(_ => _.label).includes(_.name)) : (_ => _.name === label);
          const amount = (balance.type === 'trial' && field === 'other') ? (balance.closing_balance - balance.opening_balance) : sumBy(balance.items?.filter(filterItems), _ => _.closing_balance - _.opening_balance);
          return {
            ...x,
            [field]: amount,
          };
        }, {}),
        subsidiaryId,
        period,
        yearMonth,
        createdAt: new Date(),
      };
      if(keys(omit(fields(), 'accountItemId')).every(_ => data[_] === 0)) return;

      batch.set(itemsRef.doc(), data);
    });
    toast.success('試算表から取り込みました。');
  };
  const alerts = computeAlerts(relatedCompany, items || [], accountItems, changeFieldNames, prevCr, cr, trials);

  return (
    <div>
      <h5>{title}</h5>
      <Alerts alerts={alerts} />
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ProgressButton label="試算表から取込み" process={onClickImportFromFreee} disabled={isLockedMonth} />
        <ExportButton fileName={`${section}_${title}.csv`} rows={rowsForExport(relatedCompany, items, accountItems, changeFieldNames, prevCr, cr, trials)} />
        <AddButton itemRef={itemsRef.doc()} processValues={_ => ({ ..._, subsidiaryId, period, yearMonth, })} FormModal={ModelFormModal} formProps={{ title, documentName: 'generalPkgItem', fields: fields({ accountItems, otherItems: rows, }) }} disabled={isLockedMonth} />
      </div>
      <div className="overflow-auto" style={{ maxHeight: 'min(800px, 80vh)', }}>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              {
                entries(fields()).map(([fieldName, { label }], i) => {
                  return (
                    <th style={{ minWidth: 150 }} key={fieldName} className={i === 0 && 'sticky'}>
                      {label}
                    </th>
                  );
                })
              }
              <th style={{ minWidth: 150 }}>
                為替差額
              </th>
              <th style={{ minWidth: 150 }}>
                期末
              </th>
              <th style={{ minWidth: 150 }}>
                試算表残高
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="thead-light">
            {
              rows.map((item) => {
                const { id, ref, accountItem, exchangedAmounts, closing, exchangeDiff, trialAmount, } = item;

                return (
                  <tr key={id}>
                    <th className="sticky">
                      {accountItem && accountItem.name}
                    </th>
                    {
                      entries(omit(fields(), ['accountItemId'])).map(([fieldName, { label }]) => {
                        const isOpening = fieldName === 'opening';
                        const amount = item[fieldName] || 0;
                        const exchangedAmount = isOpening ? amount * prevCr : exchangedAmounts[fieldName];
                        return (
                          <td key={fieldName} className="text-right">
                            <AmountWithExchange currency={relatedCompany.currency} amounts={[amount, exchangedAmount]} />
                          </td>
                        );
                      })
                    }
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[0, exchangeDiff]} />
                    </td>
                    <td className={classnames('text-right', { 'table-danger': floatFormat(closing) !== floatFormat(trialAmount) })}>
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[closing, closing * cr]} />
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[trialAmount, trialAmount * cr]} />
                    </td>
                    <td className="text-nowrap text-right">
                      <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title, documentName: 'generalPkgItem', fields: fields({ accountItems, otherItems: rows.filter(_ => _.id !== id), }), }} disabled={isLockedMonth} />
                      <DeleteButton item={item} itemRef={ref} className="ml-2" disabled={isLockedMonth} />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
