const { pick, groupBy, keyBy, sortBy, omit, } = require('lodash');

const { accountItemCategories } = require('../config');
const { getCategory, } = require('../models/accountItem');
const { parseSections, } = require('../models/section');
const { generalSegment, } = require('../models/consolidationSegment');
const { inheritTypes, } = require('../models/individualAdjustmentJournal');

const generateRows = (individualAdjustmentJournals, sections, accountItems, consolidationAccountItems) => {
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const { tipSections } = parseSections(sections);
  const sectionsById = keyBy(tipSections, 'id');
  return sortBy(individualAdjustmentJournals, 'index').map((individualAdjustmentJournal) => {
    const { id, items, description, } = individualAdjustmentJournal;
    return {
      ...individualAdjustmentJournal,
      mappedItems: items.map((item, i) => {
        const { debitItemId, debitSectionId, debitAmount, creditItemId, creditSectionId, creditAmount, } = item;
        return {
          ...item,
          debitAccountItem: consolidationAccountItemsById[debitItemId],
          debitSection: sectionsById[debitSectionId],
          creditAccountItem: consolidationAccountItemsById[creditItemId],
          creditSection: sectionsById[creditSectionId],
        };
      }),
    };
  });
};

const rowsForExport = (relatedCompany, individualAdjustmentJournals, sections, accountItems, consolidationAccountItems) => () => {
  return generateRows(individualAdjustmentJournals, sections, accountItems, consolidationAccountItems).flatMap((row) => {
    const { id, inheritType = 'none', mappedItems, description, } = row;
    return mappedItems.map((item, i) => {
      const { debitAccountItem, debitSection, debitAmount, creditAccountItem, creditSection, creditAmount, } = item;
      return {
        delimiter: i === 0 ? '*' : null,
        inheritType: i === 0 ? inheritTypes[inheritType]?.label : null,
        ...(relatedCompany.externalType === 'bugyo' && { debitAccountItemCode: debitAccountItem?.shortcut_num }),
        debitAccountItemName: debitAccountItem?.name,
        debitSectionName: debitSection?.name,
        debitAmount,
        ...(relatedCompany.externalType === 'bugyo' && { creditAccountItemCode: creditAccountItem?.shortcut_num }),
        creditAccountItemName: creditAccountItem?.name,
        creditSectionName: creditSection?.name,
        creditAmount,
        description: i === 0 ? description : null,
      };
    });
  });
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
