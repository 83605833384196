export function initialize () {
  if(import.meta.env.VITE_ENV !== 'production') return;

  (function() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
    }
    var ch = function() {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function(args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  })();
};

export function boot ({ user, companies }) {
  if(import.meta.env.VITE_ENV !== 'production') return;

  window.ChannelIO('boot', {
    pluginKey: '554b0f0c-bd0f-4628-9fd8-ee0f25fa4503',
    profile: {
      memberId: user.id,
      userId: user.id,
      email: user.email,
      name: user.displayName,
      ...(
        companies.reduce((x, company, i) => {
          return {
            ...x,
            [`company_${i+1}_id`]: company.id,
            [`company_${i+1}_name`]: company.display_name,
          };
        }, {})
      ),
    }
  });
}
