const { keyBy, sortBy, omit, } = require('lodash');

const { floatFormat } = require('../util');
const { currencies } = require('../config');
const { getCategory } = require('../models/accountItem');

const generateRows = (prevPeriodAmounts, allConsolidationAccountItems) => {
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  return prevPeriodAmounts.map((prevPeriodAmount) => {
    const { id, ref, consolidationAccountItemId, amount, } = prevPeriodAmount;
    const consolidationAccountItem = consolidationAccountItemsById[consolidationAccountItemId]
    const accountItemCategory = getCategory(consolidationAccountItem);
    return {
      ...prevPeriodAmount,
      consolidationAccountItem,
      accountItemCategory,
    };
  });
};

const rowsForExport = (company, ...args) => () => {
  return generateRows(...args).map((row) =>{
    const { accountItemCategory, consolidationAccountItem, amount } = row;
    return {
      ...(company.externalType === 'bugyo' && { consolidationAccountItemCode: consolidationAccountItem?.shortcut_num }),
      consolidationAccountItemName: consolidationAccountItem?.name,
      amount,
    };
  });
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
