import React, { Fragment, Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { get, mapValues, omit, } from 'lodash';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import numeral from 'numeral';

import useFormState from '../hooks/useFormState';
import { floatFormat } from '../../util';
import Field from '../Field';
import firebase, { functions } from '../../firebase';
import { currencies, } from '../../shared/config';
import { fields, } from '../../shared/models/exchangeRate';
import { generateRows, rowsForExport, } from '../../shared/lib/exchangeRates';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import AddButton from '../AddButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import QuerySelector from '../QuerySelector';

const { entries } = Object;

export default CompanyPage(function CompanyExchangeRates (props) {
  const { company, yearMonth, history, location, isLockedMonth, } = props;
  const exchangeRateRef = company.ref.collection('exchangeRates').doc(yearMonth);
  const exchangeRate = useDocumentSubscription(exchangeRateRef, [yearMonth]);
  const rows = generateRows(exchangeRate);
  const arName = company.usesMonthlyAr ? 'mar' : 'ar';
  const fields = entries(omit(currencies, 'jpy')).map(([currency, { label }]) => {
    return [arName, 'cr'].map(_ => ({
      name: [currency, _].join('-'),
      type: 'float',
      initialValue: 0,
      validations: {
        greaterThanOrEqualTo0: v => v == null || v >= 0,
      },
    }));
  }).flat().reduce((x, y) => ({ ...x, [y.name]: y }), {});
  const processRow = (batch, row, i) => {
    const { currency, cr, ar, mar } = row;
    if(!currency || [cr, ar, mar].every(_ => !(numeral(_).value() > 0))) return;

    batch.set(exchangeRateRef, {
      [`${currency}-cr`]: numeral(cr).value(),
      [`${currency}-ar`]: numeral(ar).value(),
      [`${currency}-mar`]: numeral(mar).value(),
    }, { merge: true });
  };

  return props.translate(
    <div className="company-consolidation-account-item container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="為替レートを設定する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>為替レート</h4>
        </div>
        <div className="d-flex justify-content-end mb-3 gap-1">
          <ExportButton fileName="為替レート.csv" rows={rowsForExport(exchangeRate)} />
          <ImportButton processRow={processRow} documentName="exchangeRate" fields={fields} onFailed={_ => toast.error(_.message)} importKey={`exchangeRates__${yearMonth}`} />
          <EditButton company={company} exchangeRate={exchangeRate} exchangeRateRef={exchangeRateRef} disabled={isLockedMonth} fields={fields} arName={arName} />
        </div>
        <div>
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr>
                <th>通貨</th>
                {!company.usesMonthlyAr ? <th>AR</th> : <th>単月AR</th>}
                <th>CR</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map(({ currency, label, ar, mar, cr, }) => {
                  return (
                    <tr key={currency}>
                      <th>{label}</th>
                      <td className="text-right">
                        {floatFormat(company.usesMonthlyAr ? mar : ar)}
                      </td>
                      <td className="text-right">
                        {floatFormat(cr)}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});


function EditButton(props) {
  const { company, exchangeRate, exchangeRateRef, fields, arName, ...extraProps } = props;
  const [showsModal, toggleModal] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const statedFields = useFormState(exchangeRate, fields, showsModal);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = async () => {
    toggleSubmitting(true);
    await exchangeRateRef.set(mapValues(statedFields, 'value'), { merge: true });
    toggleSubmitting(false);
    toggleModal(false);
  };

  return (
    <Fragment>
      <Button onClick={toggleModal.bind(null, true)} {...extraProps} data-operation-type="write">
        <span className="fas fa-edit mr-1" />
        編集
      </Button>
      {
        showsModal && (
          <Modal isOpen>
            <ModalHeader>
              換算レート
            </ModalHeader>
            <Form onSubmit={onSubmit}>
              <ModalBody>
                <table className="table table-bordered sticky-table">
                  <thead className="thead-light text-center">
                    <tr>
                      <th>通貨</th>
                      {!company.usesMonthlyAr ? <th>AR</th> : <th>単月AR</th>}
                      <th>CR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      entries(omit(currencies, 'jpy')).map(([currency, { label }]) => {
                        return (
                          <tr key={currency}>
                            <th>{label}</th>
                            <td>
                              <Field
                                name={[currency, arName].join('-')}
                                {...statedFields[[currency, arName].join('-')]}
                              />
                            </td>
                            <td>
                              <Field
                                name={[currency, 'cr'].join('-')}
                                {...statedFields[[currency, 'cr'].join('-')]}
                              />
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </ModalBody>
              <ModalFooter>
                <Button className="cancel" color="secondary" onClick={toggleModal.bind(null, false)}>閉じる</Button>
                <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>保存</Button>
              </ModalFooter>
            </Form>
          </Modal>
        )
      }
    </Fragment>
  );
};
