import React from 'react';

export default function WithLoading(props) {
  const { loading = false, children, } = props;

  return loading ? (
    <div className="d-flex justify-content-center align-items-center">
      <span className="fas fa-spin fa-spinner mr-1" />
      読込中...
    </div>
  ) : children;
};
