import React, { Component, useEffect, useState, } from 'react';
import { useToggle, useAsync, useSessionStorage, } from 'react-use';
import { pick, findKey, keyBy, omit, last, } from 'lodash';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { format as formatDate, startOfMonth, endOfMonth, } from 'date-fns';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { pcaCompanyFields, } from '../../shared/models/company';
import { batch, getCollectionData, } from '../../shared/firebase';
import { pcaDataCenters, externalTypes, } from '../../shared/config';
import { fieldsToAddFromExternal, fieldsToAdd, } from '../../shared/models/subsidiary';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import ModalButton from '../ModalButton';
import ModelFormModal from '../modals/ModelFormModal';
import NonFreeeCompanyFormModal from '../modals/NonFreeeCompanyFormModal';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import useLocale from '../hooks/useLocale';

const { entries } = Object;
const getCompanyFreeeCompanies = functions.httpsCallable('getCompanyFreeeCompanies');
const getCompanyBugyoCompanies = functions.httpsCallable('getCompanyBugyoCompanies');
const getBugyoAuthUrl = functions.httpsCallable('getBugyoAuthUrl');
const getMfAuthUrl = functions.httpsCallable('getMfAuthUrl');
const getPcaAuthUrl = functions.httpsCallable('getPcaAuthUrl');
const db = firebase.firestore();

export default CompanyPage(function CompanyRelatedCompanies (props) {
  const { history, company, user, match: { params: { companyId } }, yearMonth, } = props;
  const { translate } = useLocale();
  const queryParams = useQueryParams();
  const [bugyoCompanies, setBugyoCompanies] = useState(null);
  const [pcaDataAreas, setPcaDataAreas] = useState(null);
  const { role, enabledRelatedCompanyIds, } = company?.users?.[user.id] || {};
  const [isDragging, toggleDragging] = useToggle(false);
  const [bugyoIntegrationPath, setBugyoIntegrationPath] = useSessionStorage('bugyoIntegrationPath');
  const [mfIntegrationPath, setMfIntegrationPath] = useSessionStorage('mfIntegrationPath');
  const [pcaIntegrationPath, setPcaIntegrationPath] = useSessionStorage('pcaIntegrationPath');
  const [pcaDataCenterCode, setPcaDataCenterCode] = useSessionStorage('pcaDataCenterCode');
  const location = useLocation();
  const subsidiariesRef = company.ref.collection('subsidiaries');
  const subsidiaries = useCollectionSubscription(subsidiariesRef.orderBy('index'), [company]);
  const companies = [{ ...company, isParent: true }, ...subsidiaries];
  const { value: freeeCompanies = [] } = useAsync(async () => {
    if(user.freee == null) return;

    const { data: { freeeCompanies = [] } } = await getCompanyFreeeCompanies({ companyId: company.id, ownerId: findKey(company.users, { role: 'owner' }), includesExisting: true });
    return freeeCompanies.filter(_ => _.id !== company.sourceId);
  }, [user.freee]);
  const integrateWithBugyo = async () => {
    setBugyoIntegrationPath(location.pathname);
    const { data: url } = await getBugyoAuthUrl();
    window.location.href = url;
  }
  const integrateWithMf = async () => {
    setMfIntegrationPath(location.pathname);
    const { data: url } = await getMfAuthUrl();
    window.location.href = url;
  }
  const integrateWithPca = async (values) => {
    setPcaIntegrationPath(location.pathname);
    setPcaDataCenterCode(values.dataCenterCode);
    const { data: url } = await getPcaAuthUrl({ ...values, });
    window.location.href = url;
  }
  const onDrop = async ({ addedIndex, removedIndex }) => {
    // NOTE: 親会社の分だけ-1する
    const newIds = arrayMoveImmutable(subsidiaries, removedIndex - 1, addedIndex - 1).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('subsidiaries').doc(id), { index });
    });
  };
  useEffect(() => {
    (async () => {
      if(queryParams.mf === '1') {
        const { office, accountingPeriods } = queryParams;
        const subsidiaries = await getCollectionData(subsidiariesRef.orderBy('index'));
        await subsidiariesRef.doc().set({
          display_name: office.office_name,
          createdAt: new Date(),
          externalType: 'mf',
          index: (last(subsidiaries)?.index + 1) || 0,
          fiscalYears: accountingPeriods.map(_ => omit(_, 'office_name')),
          mf: {
            ...office,
            ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
          },
        });
        toast.success('追加しました');
        history.replace(location.pathname);
      }
      if(queryParams.bugyo === '1') {
        const { bugyoCompanies, } = queryParams;
        setBugyoCompanies(bugyoCompanies);
      }
      if(queryParams.pca === '1') {
        const { dataAreas, } = queryParams;
        setPcaDataAreas(dataAreas);
      } 
    })();
  }, []);

  return props.translate(
    <div className="company-subsidiaries container-fluid">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="個社別の連結PKGを作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>グループ会社</h4>
        </div>
        {
          subsidiaries.length >= (company.subsidiariesLimit ?? 5) ? (
            <div className="d-flex mb-3 alert alert-warning">
              子会社数の上限に達しています。
            </div>
          ) : (
            <div className="d-flex justify-content-end mb-3 gap-1">
              <AddButton
                label="freeeから連結子会社を追加"
                itemRef={subsidiariesRef.doc()}
                processValues={(values) => {
                  const freeeCompany = freeeCompanies.find(_ => _.id === values.sourceId);
                  return ({
                    ...values,
                    ...omit(freeeCompany, ['id']),
                    index: (last(subsidiaries)?.index + 1) || 0,
                    createdAt: new Date(),
                    externalType: 'freee',
                  });
                }}
                FormModal={ModelFormModal}
                formProps={{ title: '連結子会社 追加', fields: fieldsToAddFromExternal({ externalCompanies: freeeCompanies, }), }}
              />
              {
                /*
                <AddButton
                  label="勘定奉行クラウドから連結子会社を追加"
                  itemRef={subsidiariesRef.doc()}
                  processValues={(values) => {
                    const bugyoCompany = bugyoCompanies.find(_ => _.id === values.sourceId);
                    return ({
                      ...values,
                      ...omit(bugyoCompany, ['id']),
                      display_name: bugyoCompany.name,
                      index: (last(subsidiaries)?.index + 1) || 0,
                      createdAt: new Date(),
                      externalType: 'bugyo',
                      fiscalYears: values.fiscalYears.map(_ => ({ ..._, start_date: formatDate(startOfMonth(_.start_date), 'yyyy-MM-dd'), end_date: formatDate(endOfMonth(_.end_date), 'yyyy-MM-dd'), })),
                    });
                  }}
                  FormModal={NonFreeeCompanyFormModal}
                  formProps={{ fields: fieldsToAddFromExternal({ externalCompanies: bugyoCompanies, }), }}
                />
                */
              }
              <ProgressButton color="primary" process={integrateWithBugyo}>
                <span className="fas mr-1 fa-plus" />
                勘定奉行クラウドから連結子会社を追加
              </ProgressButton>
              {
                /*
                <ProgressButton color="primary" process={integrateWithMf}>
                  <span className="fas mr-1 fa-plus" />
                  [admin] MFクラウドから連結子会社を追加
                </ProgressButton>
                */
              }
              <ModalButton
                color="primary"
                Modal={ModelFormModal}
                modalProps={{
                  title: 'PCAクラウドから連結子会社を追加',
                  fields: {
                    dataCenterCode: {
                      label: 'データセンター',
                      type: 'select',
                      options: entries(pcaDataCenters).map(([k, v]) => ({ label: v.label, value: k })),
                      validations: { required: _ => _ != null },
                    },
                  },
                  submitLabel: '次へ',
                  onSubmit: integrateWithPca,
                }}
              >
                <span className={classnames('fas mr-1 fa-plus')} />
                PCAクラウドから連結子会社を追加
              </ModalButton>
              <AddButton
                label="連結子会社を追加"
                processValues={(_) => ({
                  ..._,
                  externalType: null,
                  index: (last(subsidiaries)?.index + 1) || 0,
                })}
                itemRef={subsidiariesRef.doc()}
                FormModal={ModelFormModal}
                formProps={{ title: '連結子会社 追加', fields: fieldsToAdd, }}
              />
            </div>
          )
        }
        <div>
          {
            companies.length > 0 ? (
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th rowSpan={2}></th>
                    <th rowSpan={2}>会社名</th>
                    <th colSpan={companies.length}>関係会社マッピング</th>
                  </tr>
                  <tr>
                    {
                      companies.map((company) => {
                        return (
                          <th key={company.id}>
                            {company.display_name}
                          </th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <Container
                  dragHandleSelector=".drag-handle"
                  onDrop={onDrop}
                  onDragStart={_ => toggleDragging(true)}
                  onDragEnd={_ => toggleDragging(false)}
                  dropPlaceholder={{ style: { background: 'eee', } }}
                  render={(ref) => (
                    <tbody ref={ref}>
                      {
                        companies.map((relatedCompany, i) => {
                          const { id, ref, isParent, pkgLocks, } = relatedCompany;
                          const isPkgLocked = !!pkgLocks?.[yearMonth];
                          const canAccess = (user?.admin || ['owner', 'admin'].includes(role)) || enabledRelatedCompanyIds?.includes(id);
                          const canLock = (user?.admin || ['owner', 'admin'].includes(role));
                          const onClickLock = async () => {
                            if(!window.confirm(`本当にロック${isPkgLocked ? '解除' : ''}しますか？`)) return;

                            await ref.set({
                              pkgLocks: {
                                [yearMonth]: !isPkgLocked,
                              },
                            }, { merge: true, });
                            toast.success(`ロック${isPkgLocked ? '解除' : ''}しました`);
                          };

                          return (
                            <Draggable
                              key={id}
                              render={() => {
                                return (
                                  <tr key={id} style={{ display: !isDragging && 'table-row', }}>
                                    <td style={{ width: company.usesApprovement ? 120 : 30 }}>
                                      <div className="d-flex align-items-center justify-content-between gap-1">
                                        {
                                          i > 0 ? (
                                            <div className="drag-handle text-muted cursor-pointer">
                                              <span className="fas fa-grip-vertical" />
                                            </div>
                                          ) : <div />
                                        }
                                        {
                                          company.usesApprovement && canLock && (
                                            <ProgressButton className="text-nowrap" size="sm" process={onClickLock} color={isPkgLocked ? 'warning' : 'success'}>
                                              ロック{isPkgLocked && '解除'}
                                            </ProgressButton>
                                          )
                                        }
                                      </div>
                                    </td>
                                    <td style={{ minWidth: 250 }}>
                                      {
                                        canAccess ? (
                                          <Link to={`/companies/${companyId}/relatedCompanies/${relatedCompany.id}${location.search}`}>
                                            {relatedCompany.display_name}{isParent && ' (親会社)'}
                                          </Link>
                                        ) : (
                                          <span>{relatedCompany.display_name}{isParent && ' (親会社)'}</span>
                                        )
                                      }
                                      {relatedCompany.externalType != null && <span className="badge badge-info ml-1">{externalTypes[relatedCompany.externalType]?.label}連携</span>}
                                    </td>
                                    {
                                      companies.map((baseCompany) => {
                                        return (
                                          <td key={baseCompany.id} style={{ minWidth: 200 }}>
                                            {baseCompany.relatedCompanyPartnerSettings?.[relatedCompany.id]?.[baseCompany.externalType === 'freee' ? 'freeePartner' : 'externalPartner']?.name}
                                          </td>
                                        );
                                      })
                                    }
                                  </tr>
                                );
                              }}
                            />
                          );
                        })
                      }
                    </tbody>
                  )}
                />
              </table>
            ) : (
              <div>
                連結子会社は未登録です
              </div>
            )
          }
        </div>
      </div>
      {
        bugyoCompanies && (
          <NonFreeeCompanyFormModal
            isOpen
            onSubmit={async (values) => {
              const bugyoCompany = bugyoCompanies.find(_ => _.id === values.sourceId);
              await subsidiariesRef.doc().set({
                ...values,
                ...omit(bugyoCompany, ['id']),
                display_name: bugyoCompany.name,
                index: (last(subsidiaries)?.index + 1) || 0,
                createdAt: new Date(),
                externalType: 'bugyo',
                fiscalYears: values.fiscalYears.map(_ => ({ ..._, start_date: formatDate(startOfMonth(_.start_date), 'yyyy-MM-dd'), end_date: formatDate(endOfMonth(_.end_date), 'yyyy-MM-dd'), })),
                bugyo: {
                  ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
                },
              });
              toast.success('追加しました');
              history.replace(location.pathname);
              setBugyoCompanies(null);
            }}
            fields={fieldsToAddFromExternal({ externalCompanies: bugyoCompanies, })}
            onClickClose={_ => {
              history.replace(location.pathname);
              setBugyoCompanies(null);
            }}
          />
        )
      }
      {
        pcaDataAreas && (
          <ModelFormModal
            title="子会社 追加"
            isOpen
            onSubmit={async (values) => {
              const pcaDataAreasByName = keyBy(pcaDataAreas, 'Name');
              await subsidiariesRef.doc().set({
                display_name: values.display_name,
                createdAt: new Date(),
                index: (last(subsidiaries)?.index + 1) || 0,
                externalType: 'pca',
                fiscalYears: values.dataAreaIds.map(_ => pcaDataAreasByName[_]).map(_ => ({ ..._, start_date: [_.CompanyTermStart.slice(0, 4), _.CompanyTermStart.slice(4, 6), _.CompanyTermStart.slice(6)].join('-'), end_date: [_.CompanyTermEnd.slice(0, 4), _.CompanyTermEnd.slice(4, 6), _.CompanyTermEnd.slice(6)].join('-'), })),
                pca: {
                  ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
                  dataCenterCode: pcaDataCenterCode,
                },
              });
              toast.success('追加しました');
              history.replace(location.pathname);
              setPcaDataAreas(null);
              setPcaIntegrationPath('');
              setPcaDataCenterCode('');
            }}
            fields={pcaCompanyFields({ dataAreas: pcaDataAreas, })}
            onClickClose={_ => {
              history.replace(location.pathname);
              setPcaDataAreas(null);
            }}
          />
        )
      }
    </div>
  );
});
