const { isEmpty, } = require('lodash');

const { entries } = Object;

const fields = ({ otherItems = [], } = {}) => {
  const otherItemNames = otherItems.map(_ => _.name);
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        unique: v => !otherItemNames.includes(v),
      },
    },
    disuses: {
      label: '使用しない',
      type: 'boolean',
      initialValue: false,
    },
  };
};

exports.fields = fields;
