const { keyBy, sortBy, omit, } = require('lodash');

const { accountItemCategories } = require('../config');
const { parseSections, } = require('../models/section');
const { generalSegment, } = require('../models/consolidationSegment');

const generateRows = (sections, consolidationSegments) => {
  const sortedConsolidationSegments = sortBy(consolidationSegments, 'index');
  const allConsolidationSegments = [...sortedConsolidationSegments, generalSegment];
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const { tipSections } = parseSections(sections);
  return sortBy(tipSections, 'index').map((section) => {
    return {
      ...section,
      consolidationSegment: consolidationSegmentsById[section.consolidationSegmentId],
    };
  });
};

const rowsForExport = (sections, consolidationSegments) => () => {
  return generateRows(sections, consolidationSegments).map((section) => {
    const { id, name, consolidationSegment, } = section;
    return {
      id,
      name,
      consolidationSegmentName: consolidationSegment?.name,
    };
  });
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
