import { useContext, useMemo, } from 'react';
import replaceDOMString from 'replace-dom-string';
import { Redirect } from 'react-router-dom';
import { orderBy, isArray, isFunction, isBoolean, isString, toPairs, fromPairs, zip } from 'lodash';

import LocaleContext from '../contexts/locale';

export default () => {
  const locale = useContext(LocaleContext);
  const generateDict = (locale) => {
    const items = orderBy(locale?.content?.split('\n').filter(_ => _).map((v) => v.split?.(':')) || [], _ => _[0].length, 'desc');
    return { items, dict: fromPairs(items) };
  };
  const { items, dict } = useMemo(() => {
    return generateDict(locale);
  }, [locale]);
  const translate = (x, _locale = null) => {
    const __locale = _locale || locale;
    if(__locale?.id === 'ja') return x;

    const { items: _items, dict: _dict } = _locale ? generateDict(_locale) : { items, dict };
    const _translate = (x) => {
      return x?.type?.disabledTranslation ? (
        x
      ) : isArray(x) ? (
        x.map(_translate)
      ) : isString(x) ? (
        _dict[x] ?? (() => {
          const item = _items.find(_ => x.includes(_[0]));
          return item && x.replace(new RegExp(item[0], 'g'), item[1]);
        })() ?? x
      ) : x?.props != null ? (
        {
          ...x,
          props: {
            ...x.props,
            ...(
              x.props.children != null && {
                children: (
                  isString(x.props.children) ? (
                    _translate(x.props.children)
                  ): isFunction(x.props.children) ? (
                    (...args) => _translate(x.props.children(...args))
                  ) : [x.props.children].flat().map(_translate)
                ),
              }
            ),
            ...(
              x.props.render != null && {
                render: (...args) => {
                  return _translate(x.props.render(...args));
                },
              }
            ),
          },
          ...(
            ![Redirect].includes(x.type) && x.props.children == null && x.props.render == null && isFunction(x.type) && x.type.prototype?.render == null && {
              type: (...args) => {
                return _translate(x.type(...args));
              },
            }
          ),
        }
      ) : x;
    };
    return _translate(x);
  };

  const Translate = function Translate(props) {
    return translate(
      props.children
    );
  };

  const applyLocale = (_locale = null, selector) => {
    const __locale = _locale || locale;
    if(__locale?.id === 'ja') return;

    const targetNode = document.querySelector(selector);
    if(targetNode == null) return;

    const _items = _locale ? generateDict(_locale).items : items;
    replaceDOMString(...zip(..._items), targetNode, { attributes: false });
  };

  return {
    locale,
    dict,
    translate,
    Translate,
    applyLocale,
  };
};
