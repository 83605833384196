import React, { Fragment, Component, useMemo, } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, pick, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';

import firebase, { functions } from '../firebase';
import { integerFormat } from '../util';
import { getCategory, } from '../shared/models/accountItem';
import { generateRowGroups, rowsForExport, computeAlerts, } from '../shared/lib/pkg';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useCompanySelector from './hooks/useCompanySelector';
import useQueryParams from './hooks/useQueryParams';
import usePkgItemGroups from './hooks/usePkgItemGroups';
import PkgPage from './hocs/PkgPage';
import ModelFormModal from './modals/ModelFormModal';
import CompanySyncButton from './CompanySyncButton';
import TrialsSyncButton from './TrialsSyncButton';
import AddButton from './AddButton';
import EditButton from './EditButton';
import ExportButton from './ExportButton';
import DeleteButton from './DeleteButton';
import QuerySelector from './QuerySelector';
import Alerts from './Alerts';

const { keys, entries, } = Object;

export default function PkgChangesTableSection(props) {
  const { path, collectionName, section, title, fields, changeFieldNames, company, exchangeRate, prevExchangeRate, period, yearMonth, filteredYearMonths, prevYearMonths, relatedCompanies, relatedCompaniesById, accountItems, accountItemsById, consolidationAccountItems, allConsolidationJournalTypes, location, suffixColumns = _ => [], prevFiscalYear, prevEndYearMonth, companiesAmountItems, } = props;
  const prevPeriod = prevFiscalYear?.start_date.replace(/-/g, '').slice(0, 6);
  const { itemGroups } = usePkgItemGroups(company, relatedCompanies, accountItems, collectionName, [], 'closing', changeFieldNames, filteredYearMonths);
  const { itemGroups: prevYearItemGroups } = usePkgItemGroups(company, relatedCompanies, accountItems, collectionName, [], 'closing', changeFieldNames, prevYearMonths);
  const rowGroups = useMemo(_ => itemGroups && prevYearItemGroups && companiesAmountItems && generateRowGroups(relatedCompanies, itemGroups, prevYearItemGroups, companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames), [itemGroups, prevYearItemGroups, companiesAmountItems]);
  const alerts = useMemo(_ => itemGroups && prevYearItemGroups && companiesAmountItems && computeAlerts(relatedCompanies, itemGroups, prevYearItemGroups, companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames), [itemGroups, prevYearItemGroups, companiesAmountItems]);

  return (
    <div>
      <h5>{title}</h5>
      <Alerts alerts={alerts} />
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ExportButton fileName={`全社_${section}_${title}.csv`} rows={rowsForExport(relatedCompanies, itemGroups, prevYearItemGroups, companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames, fields)} />
      </div>
      <div className="overflow-auto" style={{ maxHeight: 'min(800px, 80vh)', }}>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }} className="sticky">勘定科目</th>
              <th colSpan={2} style={{ minWidth: 200 }}>会社</th>
              <th style={{ minWidth: 150 }}>期首</th>
              {
                entries(pick(fields(), changeFieldNames)).map(([fieldName, { label }]) => {
                  return (
                    <th style={{ minWidth: 150 }} key={fieldName}>
                      {label}
                    </th>
                  );
                })
              }
              <th style={{ minWidth: 150 }}>為替差額</th>
              <th style={{ minWidth: 150 }}>期末</th>
              {
                suffixColumns().map((_, i) => <th key={i} style={{ minWidth: 150 }}>{_.header.value}</th>)
              }
            </tr>
          </thead>
          <tbody className="thead-light">
            {
              rowGroups?.map(({ consolidationAccountItem, relatedCompanyRowGroups, pkgConclusiveAmount, diff, companiesAmountItem, }, consolidationAccountItemRowGroupIndex) => {
                const Amount = _ => <div>{integerFormat(_.amount)}</div>;
                const rowsInRelatedCompanyRowGroup = relatedCompanyRowGroups.flatMap(_ => _.rows);

                return (
                  <Fragment key={consolidationAccountItem.id}>
                    {
                      relatedCompanyRowGroups.map(({ relatedCompany, prevCr, cr, rows }, relatedCompanyRowGroupIndex) => {
                        return (
                          <Fragment key={relatedCompany.id}>
                            {
                              rows.map((row, rowIndex) => {
                                const { id, ref, rowType, rowLabel, exchangedOpening, exchangeDiff, exchangedClosing, exchangedAmounts, consolidationJournalType, } = row;
                                const url = ({
                                  pkgItem: `/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/${path}${location.search}`,
                                  prevYearIndividualAdjustmentJournal: `/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/individualAdjustments?${qs.stringify({ period: prevPeriod, yearMonth: prevEndYearMonth })}`,
                                  individualAdjustmentJournal: `/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/individualAdjustments${location.search}`,
                                  prevYearConsolidationJournal: `/companies/${company.id}/consolidationJournals?${qs.stringify({ period: prevPeriod, yearMonth: prevEndYearMonth, consolidationAccountItemIds: [consolidationAccountItem.id], types: [consolidationJournalType?.id], })}`,
                                  consolidationJournal: `/companies/${company.id}/consolidationJournals?${qs.stringify({ period, yearMonth, consolidationAccountItemIds: [consolidationAccountItem.id], types: [consolidationJournalType?.id], })}`,
                                })[rowType];
                                const Amount = _ => <Link to={url}>{integerFormat(_.amount)}</Link>;

                                return (
                                  <tr key={id || rowType}>
                                    {
                                      relatedCompanyRowGroupIndex === 0 && rowIndex === 0 && (
                                        <th rowSpan={sumBy(relatedCompanyRowGroups, 'rows.length') + 2} className="sticky">
                                          {consolidationAccountItem.name}
                                        </th>
                                      )
                                    }
                                    {
                                      rowIndex === 0 && (
                                        <td rowSpan={rows.length} style={{ borderRight: 0, }}>
                                          {relatedCompany.display_name}
                                        </td>
                                      )
                                    }
                                    <td style={{ minWidth: 100, borderLeft: 0, }}>
                                      {rowLabel}
                                    </td>
                                    <td className="text-right">
                                      <Amount amount={exchangedOpening} />
                                    </td>
                                    {
                                      entries(pick(fields(), changeFieldNames)).map(([fieldName, { label }]) => {
                                        const exchangedAmount = exchangedAmounts[fieldName];
                                        return (
                                          <td key={fieldName} className="text-right">
                                            <Amount amount={exchangedAmount} />
                                          </td>
                                        );
                                      })
                                    }
                                    <td className="text-right">
                                      <Amount amount={exchangeDiff} />
                                    </td>
                                    <td className="text-right">
                                      <Amount amount={exchangedClosing} />
                                    </td>
                                    {
                                      suffixColumns(row, { Amount }).map((column, i) => {
                                        const { body: { value, className } } = column;
                                        return (
                                          <td key={i} className={className}>
                                            {value}
                                          </td>
                                        );
                                      })
                                    }
                                  </tr>
                                );
                              })
                            }
                          </Fragment>
                        );
                      })
                    }
                    <tr className="font-weight-bold">
                      <td className="text-right" colSpan={2}>
                        合計
                      </td>
                      <td className="text-right">
                        <Amount amount={sumBy(relatedCompanyRowGroups, 'exchangedOpening')} />
                      </td>
                      {
                        entries(pick(fields(), changeFieldNames)).map(([fieldName, { label }]) => {
                          return (
                            <td key={fieldName} className="text-right">
                              <Amount amount={sumBy(rowsInRelatedCompanyRowGroup, _ => _.exchangedAmounts[fieldName])} />
                            </td>
                          );
                        })
                      }
                      <td className="text-right">
                        <Amount amount={sumBy(rowsInRelatedCompanyRowGroup, 'exchangeDiff')} />
                      </td>
                      <td className={classnames('text-right', { 'table-danger': Math.abs(diff) > 0, })}>
                        <Amount amount={pkgConclusiveAmount} />
                      </td>
                    </tr>
                    <tr className="font-weight-bold">
                      <td className="text-right" colSpan={2}>
                        連結精算表
                      </td>
                      <td className="text-right" colSpan={changeFieldNames.length + 3}>
                        <Amount amount={companiesAmountItem?.conclusiveAmount} />
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
