const validations = {
  general: {
    required: '必須項目です',
    greaterThanOrEqualTo0: '0以上で指定してください。',
    samePassword: 'パスワードと一致しません',
    format: '形式が正しくありません',
    inRange: '範囲内で指定してください',
    greaterThanStart: '開始時刻以降で指定してください',
    exclusive: '他と重なっています',
    minLength: '長さが不足しています',
    exists: '存在しません',
    finite: '数値を入力してください',
    requiredInSomeCase: '入力してください',
    unique: '他に存在します',
  },
  cfMappingItem: {
    pkgCategory: { required: '値が正しくありません', },
    columnKey: { required: '値が正しくありません', },
    cfChangeTypeId: { required: '値が正しくありません', },
    cfAccountItemId: { required: '値が正しくありません', },
  },
  relatedCompanyBalance: {
    unique: '相手会社、個別勘定科目、部門が同一のものが他に存在します',
  },
  generalPkgItem: {
    unique: '勘定科目が同一のものが他に存在します',
  },
};

exports.validations = validations;
