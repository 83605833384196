const { isEmpty, zip, keyBy, sortBy, groupBy, sumBy, get, sum, omit, } = require('lodash');
const { format: formatDate, addMonths, } = require('date-fns');

const { floatFormat } = require('../util');
const { getCollectionData, getDocumentData, } = require('../firebase');
const { accountItemCategories, accountItemCategoryNames } = require('../config');
const { computeSingleAmounts, } = require('../models/consolidationAccountItem');

const { keys, entries, } = Object;
const { abs, round } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRowGroups = (company, relatedCompanies, period, items, prevItems, joinItems, exceptItems, cfChangeTypes, cfChange, showsAll = true, yearMonth) => {
  if(isEmpty(items)) return [];

  const categoryItems = items.filter(_ => _.itemType === 'category');
  const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
  const itemsGroupedByCategory = groupBy(items, 'accountItemCategory.name');
  const prevCategoryItems = prevItems.filter(_ => _.itemType === 'category');
  const prevCategoryItemsByCategory = keyBy(prevCategoryItems, 'accountItemCategory.name');
  const prevItemsGroupedByCategory = groupBy(prevItems, 'accountItemCategory.name');
  const joinCategoryItems = joinItems.filter(_ => _.itemType === 'category');
  const joinCategoryItemsByCategory = keyBy(joinCategoryItems, 'accountItemCategory.name');
  const joinItemsGroupedByCategory = groupBy(joinItems, 'accountItemCategory.name');
  const exceptCategoryItems = exceptItems.filter(_ => _.itemType === 'category');
  const exceptCategoryItemsByCategory = keyBy(exceptCategoryItems, 'accountItemCategory.name');
  const exceptItemsGroupedByCategory = groupBy(exceptItems, 'accountItemCategory.name');
  return accountItemCategories.map((category) => {
    const sign = ({ debit: 1, credit: -1 })[category.direction];
    const zippedDirectItems = zip(...[prevItemsGroupedByCategory, itemsGroupedByCategory, joinItemsGroupedByCategory, exceptItemsGroupedByCategory].map(_ => _[category.name] || []))
      .filter(_ => _[0].itemType === 'consolidationAccountItem');
    const profitBeforeTaxZippedItem = zippedDirectItems.find(_ => _[1].key === '税引前当期純利益');
    const computeRow = (prevItem, item, joinItem, exceptItem) => {
      const joinColumns = (joinItem?.relatedCompanyColumns || []).map(_ => category.name === '現金・預金' ? ({ ..._, exchangedAmount: 0 }) : _);
      const exceptColumns = (exceptItem?.relatedCompanyColumns || []).map(_ => category.name === '現金・預金' ? ({ ..._, exchangedAmount: 0, }) : _);
      const conclusiveChange = get(item, 'conclusiveAmount', 0) - get(prevItem, 'conclusiveAmount', 0) - sumBy(joinColumns, 'exchangedAmount') + sumBy(exceptColumns, 'exchangedAmount');
      const exchangeDiffColumns = relatedCompanies.filter(_ => (_.currency || 'jpy') !== 'jpy').map((relatedCompany) => {
        const index = relatedCompanies.indexOf(relatedCompany);
        // NOTE: ここでjoinColumnsを使わないのは、ここでは現金・預金の金額も使いたいため
        const startItem = relatedCompany.joinPeriod === period && yearMonth >= relatedCompany.joinYearMonth ? (joinItem?.relatedCompanyColumns || []).find(_ => _.relatedCompany.id === relatedCompany.id) : prevItem?.companyColumns[index];
        const endItem = relatedCompany.exceptPeriod === period && yearMonth >= relatedCompany.exceptYearMonth ? (exceptItem?.relatedCompanyColumns || []).find(_ => _.relatedCompany.id === relatedCompany.id) : item?.companyColumns[index];
        const exchangedChange = endItem.exchangedAmount - startItem.exchangedAmount;
        const arExchangedChange = company.usesMonthlyAr ? (
          get(endItem, 'totalIssuedExchangedAmount', 0)
        ) : (
          (endItem?.adjustedAmount - startItem?.adjustedAmount) * (endItem?.monthGroup.exchangeRate?.[`${relatedCompany.currency}-ar`] || 0)
        );
        return {
          relatedCompany,
          exchangedChange,
          exchangeDiff: round(exchangedChange - arExchangedChange),
        };
      });
      const totalExchangeDiff = -sumBy(exchangeDiffColumns, 'exchangeDiff');
      const diffWithoutExchange = conclusiveChange + totalExchangeDiff;
      const cfChangeColumns = cfChangeTypes.map((cfChangeType) => {
        const key = [item.key, cfChangeType.id].join('__');
        return {
          cfChangeType,
          amount: get(cfChange, ['values', key, 'amount'], 0),
          cfAccountItemId: get(cfChange, ['values', key, 'cfAccountItemId'], 0),
        };
      });
      return {
        conclusiveAmount: item.conclusiveAmount,
        prevConclusiveAmount: prevItem.conclusiveAmount,
        conclusiveChange,
        exchangeDiffColumns,
        joinColumns,
        exceptColumns,
        exceptColumns,
        totalExchangeDiff,
        diffWithoutExchange,
        cfChangeColumns,
        sign,
      };
    };
    const rows = [
      ...zippedDirectItems.filter(_ => _ !== profitBeforeTaxZippedItem).map((zippedItem) => {
        const [_, item] = zippedItem;
        return {
          ...computeRow(...zippedItem),
          itemType: 'consolidationAccountItem',
          itemKey: item.key,
          itemName: item.item.name,
          item: item.item,
        };
      }),
      ...(
        profitBeforeTaxZippedItem != null ? [
          (() => {
            const [_, item] = profitBeforeTaxZippedItem;
            const categoryItem = categoryItemsByCategory[category.name];
            const prevCategoryItem = prevCategoryItemsByCategory[category.name];
            const joinCategoryItem = joinCategoryItemsByCategory[category.name];
            const exceptCategoryItem = exceptCategoryItemsByCategory[category.name];
            return {
              ...computeRow(prevCategoryItem, categoryItem, joinCategoryItem, exceptCategoryItem),
              itemType: 'consolidationAccountItem',
              itemKey: item.key,
              itemName: item.item.name,
              item: item.item,
            };
           })(),
        ] : []
      ),
      ...(
        category.name === '当期純損益金額' ? [
          (() => {
            const categoryItem = categoryItemsByCategory[category.name];
            const prevCategoryItem = prevCategoryItemsByCategory[category.name];
            const joinCategoryItem = joinCategoryItemsByCategory[category.name];
            const exceptCategoryItem = exceptCategoryItemsByCategory[category.name];
            return {
              ...computeRow(prevCategoryItem, { ...categoryItem, key: category.key() }, joinCategoryItem, exceptCategoryItem),
              itemType: 'category',
              itemKey: category.key(),
              itemName: category.name,
              item: categoryItem.item,
            };
           })(),
        ] : []
      ),
    ].filter(r => showsAll || r.itemType === 'category' || [...['conclusiveAmount', 'prevConclusiveAmount', 'conclusiveChange'].map(_ => r[_]), ...r.exchangeDiffColumns.map(_ => _.exchangeDiff), ...r.cfChangeColumns.map(_ => _.amount)].some(_ => Math.abs(_) > 0));
    return { category, rows };
  });
};

const rowsForExport = (company, relatedCompanies, period, items, prevItems, joinItems, exceptItems, sortedCfChangeTypes, cfChange, sortedCfAccountItems, showsAll, yearMonth) => () => {
  const cfAccountItemsById = keyBy(sortedCfAccountItems, 'id');
  return generateRowGroups(company, relatedCompanies, period, items, prevItems, joinItems, exceptItems, sortedCfChangeTypes, cfChange, showsAll, yearMonth).filter(_ => _.category.type === 'bs').flatMap(_ => _.rows).map(({ itemType, itemKey, itemName, conclusiveAmount, prevConclusiveAmount, conclusiveChange, exchangedChange, joinColumns, exceptColumns, exchangeDiffColumns, diffWithoutExchange, cfChangeColumns, sign, }) => {
    return {
      category: itemType === 'category' ? itemName : null,
      itemName: itemType === 'consolidationAccountItem' ? itemName : null,
      prevConclusiveAmount: prevConclusiveAmount * sign,
      ...joinColumns.reduce((x, _) => ({
        ...x,
        [`連結対象になった時点_${_.relatedCompany.display_name}`]: _.exchangedAmount * sign,
      }), {}),
      ...exceptColumns.reduce((x, _) => ({
        ...x,
        [`連結除外になった時点_${_.relatedCompany.display_name}`]: _.exchangedAmount * sign,
      }), {}),
      conclusiveAmount: conclusiveAmount * sign,
      conclusiveChange: conclusiveChange * sign,
      ...(
        exchangeDiffColumns.reduce((x, exchangeDiffColumn) => {
          const { relatedCompany, exchangeDiff } = exchangeDiffColumn;

          return {
            ...x,
            [`換算差額_${relatedCompany?.display_name}`]: -exchangeDiff * sign,
          };
        }, {})
      ),
      diffWithoutExchange: diffWithoutExchange * sign,
      ...(
        cfChangeColumns.reduce((x, cfChangeColumn) => {
          const { cfChangeType, amount, cfAccountItemId, } = cfChangeColumn;
          const cfAccountItem = cfAccountItemsById[cfAccountItemId];

          return {
            ...x,
            [`${cfChangeType.name}_金額`]: amount,
            [`${cfChangeType.name}_CF科目`]: cfAccountItem?.name,
          };
        }, {})
      ),
      total: diffWithoutExchange * sign + sumBy(cfChangeColumns, _ => _.amount || 0) + sumBy(joinColumns, _ => _.exchangedAmount * sign) - sumBy(exceptColumns, _ => _.exchangedAmount * sign),
    };
  });
};

const computeAlerts = (items, cfChangeTypes, cfChange) => {
  const itemsByKey = keyBy(items, 'key');
  const cfChangeTypeIds = cfChangeTypes.map(_ => _.id);
  const noCfAccountItemValues = Object.values(cfChange?.values || {}).filter(_ => _ && Math.abs(_.amount) > 0 && _.cfAccountItemId == null && cfChangeTypeIds.includes(_.cfChangeTypeId));
  return [
    noCfAccountItemValues.length > 0 && `CF科目が選択されていないものがあります(${noCfAccountItemValues.map(_ => itemsByKey[_.itemKey]?.item.name).join(', ')})`,
  ].filter(_ => _);
};

const computeJoinOrExceptRelatedCompaniesAmounts = async (company, relatedCompanies, yearMonthType, startType, allConsolidationAccountItems, accountItems) => {
  const accountItemsGroupedByCompanyId = groupBy(accountItems, _ => _.subsidiaryId || company.id);
  const relatedCompanyItems = await Promise.all(relatedCompanies.map(async (relatedCompany) => {
    const subsidiaryId = relatedCompany === company ? null : relatedCompany.id;
    const isStart = !!relatedCompany[startType];
    const yearMonth = relatedCompany[yearMonthType];
    const yearMonths = [isStart ? formatDate(addMonths(new Date([yearMonth.slice(0, 4), yearMonth.slice(4), '01'].join('/')), -1), 'yyyyMM') : yearMonth];
    const getData = async (yearMonth) => {
      const trials = await getCollectionData(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth));
      const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth));
      const exchangedItemRef = company.ref.collection('exchangedItems').doc([relatedCompany.id, yearMonth].join('__'));
      const exchangedItem = await getDocumentData(exchangedItemRef);
      const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
      return { yearMonth, trials, individualAdjustmentJournals, exchangedItem, exchangeRate };
    };
    const monthGroups = await Promise.all(yearMonths.map(getData));
    const prevMonthGroup = null;
    return computeSingleAmounts(company, relatedCompany, allConsolidationAccountItems, accountItemsGroupedByCompanyId[relatedCompany.id], relatedCompany.currency, monthGroups, prevMonthGroup)
      .map(_ => ({ ..._, relatedCompany }));
  }));
  return zip(...relatedCompanyItems).map((relatedCompanyColumns) => {
    const [{ accountItemCategory, item, itemType, key, }] = relatedCompanyColumns;
    return {
      accountItemCategory,
      item,
      itemType,
      key,
      relatedCompanyColumns,
    };
  });
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
exports.computeJoinOrExceptRelatedCompaniesAmounts = computeJoinOrExceptRelatedCompaniesAmounts;
