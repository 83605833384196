const { omit, isEmpty, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const changeFieldNames = [
  'acquisition',
  'retirement',
  'sale',
  'impairment',
  'depreciation',
  'suspenceTransfer',
  'other',
];

const fields = ({ accountItems = [], otherItems, } = {}) => {
  const unique = (v, s) => otherItems?.every(o => ['accountItemId'].some(_ => o[_] !== s[_]));
  return {
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
        unique,
      },
    },
    opening: {
      label: '期首',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    acquisition: {
      label: '取得',
      type: 'float',
    },
    retirement: {
      label: '除却',
      type: 'float',
    },
    sale: {
      label: '売却',
      type: 'float',
    },
    impairment: {
      label: '減損',
      type: 'float',
    },
    depreciation: {
      label: '減価償却',
      type: 'float',
    },
    suspenceTransfer: {
      label: '建仮振替',
      type: 'float',
    },
    other: {
      label: 'その他',
      type: 'float',
    },
  };
};

exports.fields = fields;
exports.changeFieldNames = changeFieldNames;
