import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { orderBy, get, sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { cfAccountItemCategories, } from '../../shared/config';
import { generateRowGroups, rowsForExport, computeAlerts, } from '../../shared/lib/cfWs2';
import { presetConsolidationAccountItems, computeCompaniesAmounts, } from '../../shared/models/consolidationAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { accountItemCategoryNames, } from '../../shared/config';
import { presetCfAccontItems, } from '../../shared/models/cfAccountItem';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useCompaniesAmounts from '../hooks/useCompaniesAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';
import WithLoading from '../WithLoading';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const { abs } = Math;
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);

export default CompanyPage(function CompanyCfWs2 (props) {
  const { company, history, location, prevEndYearMonth, isLockedMonth, filteredYearMonths, } = props;
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [subsidiaries]);
  const queryParams = useQueryParams();
  const { period, yearMonth, } = queryParams;
  const disclosureSettingRef = company.ref.collection('disclosureSettings').doc(yearMonth);
  const disclosureSetting = useDocumentSubscription(disclosureSettingRef, [yearMonth]);
  const generalAmount = useDocumentSubscription(company.ref.collection('amounts').doc(period), [company, period]);
  const cfChangeTypes = useCollectionSubscription(company.ref.collection('cfChangeTypes'), [company]);
  const cfAccountItems = useCollectionSubscription(company.ref.collection('cfAccountItems').orderBy('name'), [company]);
  const allCfAccountItems = useMemo(_ => [...cfAccountItems, ...presetCfAccontItems], [cfAccountItems]);
  const sortedCfAccountItems = useMemo(_ => orderBy(allCfAccountItems, [_ => cfAccountItemCategoryKeys.indexOf(_.cfAccountItemCategoryKey), 'index'], ['asc', 'asc']), [allCfAccountItems]);
  const cfAccountItemsById = keyBy(sortedCfAccountItems, 'id');
  const cfChangeRef = company.ref.collection('cfChanges').doc(yearMonth);
  const cfChange = useDocumentSubscription(cfChangeRef, [yearMonth]);
  const filteredCfChangeTypes = useMemo(_ => cfChangeTypes.filter(_ => (cfChange?.cfChangeTypeIds || []).includes(_.id) || !_.disuses), [cfChange, cfChangeTypes]);
  const sortedCfChangeTypes = useMemo(_ => sortBy(filteredCfChangeTypes, 'index'), [filteredCfChangeTypes]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);

  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const sortedConsolidationAccountItems = useMemo(_ => sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
  const allConsolidationAccountItems = useMemo(_ => [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems], [sortedConsolidationAccountItems]);
  const { items: companiesAmountItems, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true);

  const rowGroups = useMemo(_ => companiesAmountItems && generateRowGroups(cfChange, sortedCfAccountItems, sortedCfChangeTypes, companiesAmountItems), [cfChange, sortedCfAccountItems, sortedCfChangeTypes, companiesAmountItems]);
  const alerts = useMemo(_ => companiesAmountItems && computeAlerts(cfChange, sortedCfAccountItems, sortedCfChangeTypes, companiesAmountItems), [cfChange, sortedCfAccountItems, sortedCfChangeTypes, companiesAmountItems]);
  useEffect(() => {
    if(disclosureSetting === null) {
      disclosureSettingRef.set({ createdAt: new Date(), }, { merge: true });
    }
  }, [disclosureSetting]);

  return props.translate(
    <div className="company-account-item-mappings container-fluid">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結CFWS2を確認する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結CFWS2</h4>
        </div>
        <WithLoading loading={!rowGroups}>
          <Alerts alerts={alerts} />
          <div className="mb-2 d-flex justify-content-end">
            <ExportButton fileName={queryParams.filename || '連結CFWS2.csv'} rows={rowsForExport(cfChange, sortedCfAccountItems, sortedCfChangeTypes, disclosureSetting, generalAmount)} encoding={queryParams.encoding} />
          </div>
          <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
            <table className="table table-bordered sticky-table mb-0">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 180 }} className="sticky">CF科目</th>
                  {
                    sortedCfChangeTypes.map(_ => (
                      <th key={_.id} style={{ minWidth: 150 }}>
                        {_.name}
                      </th>
                    ))
                  }
                  <th style={{ minWidth: 150}}>合計</th>
                  <th style={{ minWidth: 150}}>その他</th>
                </tr>
              </thead>
              <tbody>
                {
                  rowGroups?.map(({ cfAccountItemCategory, rows }) => {
                    return (
                      <Fragment key={cfAccountItemCategory.key}>
                        {
                          rows.map(({ cfAccountItem, cfChangeColumns, totalAmount, }, i) => {
                            const disclosesAsOther = disclosureSetting?.otherCfAccountItems?.includes(cfAccountItem.id);

                            return (
                              <tr key={cfAccountItem.id}>
                                <td className="sticky bg-white">
                                  {cfAccountItem.name}
                                </td>
                                {
                                  cfChangeColumns.map((cfChangeColumn) => {
                                    const { cfChangeType, amount, } = cfChangeColumn;
                                    return (
                                      <td key={cfChangeType.id} className="text-right">
                                        {integerFormat(amount)}
                                      </td>
                                    );
                                  })
                                }
                                <td className="text-right">
                                  {integerFormat(totalAmount)}
                                </td>
                                <td>
                                  <FormGroup className="m-0">
                                    <FormGroup check>
                                      <Label check>
                                        <Input type="checkbox" checked={disclosesAsOther === true} onChange={_ => disclosureSetting?.ref.update({ otherCfAccountItems: firebase.firestore.FieldValue[_.target.checked ? 'arrayUnion' : 'arrayRemove'](cfAccountItem.id) })} disabled={isLockedMonth} />
                                        その他
                                      </Label>
                                    </FormGroup>
                                  </FormGroup>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </Fragment>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </WithLoading>
      </div>
    </div>
  );
});
