import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { round, sumBy, pickBy, pick, get, keyBy, uniq, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';
import classnames from 'classnames';

import { integerFormat } from '../../util';
import useFormState from '../hooks/useFormState';
import useCompany from '../hooks/useCompany';
import Field from '../Field';
import { fields, itemFields, } from '../../shared/models/linkingRule';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function LinkingRuleFormModal(props) {
  const { isOpen, values, relatedCompanies, consolidationAccountItems, consolidationSegments, sectionsGroupedByRelatedCompanyId, onClickClose, } = props;
  console.log(3, sectionsGroupedByRelatedCompanyId);
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const statedFields = useFormState(values, fields({ relatedCompanies, consolidationAccountItems, consolidationSegments, }), isOpen);
  const isNew = !values || !values.id;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values?.items || [{}]);
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    (items.length === 0) ||
    (items.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 1350 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            自動マッチングルール{isNew ? '追加' : '編集'}
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(pick(statedFields, ['name', 'relatedCompanyId1', 'relatedCompanyId2'])).map(([fieldName, fieldSetting]) => {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  {...fieldSetting}
                />
              );
            })
          }
          <div className="my-3">
            <ListForm
              addButtonLabel="ルール追加"
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      index={itemIndex}
                      values={item}
                      consolidationAccountItems={consolidationAccountItems}
                      consolidationSegments={consolidationSegments}
                      relatedCompaniesByNumber={{ 1: relatedCompaniesById[statedFields.relatedCompanyId1.value], 2: relatedCompaniesById[statedFields.relatedCompanyId2.value], }}
                      sectionsGroupedByRelatedCompanyId={sectionsGroupedByRelatedCompanyId}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={1}
            />
          </div>
          {
            entries(omit(statedFields, ['name', 'relatedCompanyId1', 'relatedCompanyId2'])).map(([fieldName, fieldSetting]) => {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  {...fieldSetting}
                />
              );
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, consolidationAccountItems, consolidationSegments, relatedCompaniesByNumber, sectionsGroupedByRelatedCompanyId, onChange, } = props;
  const company = useCompany();
  const statedFields = useFormState(values, itemFields({ company, consolidationAccountItems, consolidationSegments, relatedCompaniesByNumber, sectionsGroupedByRelatedCompanyId, }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex gap-2">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => {
            return (
              <div key={fieldName} style={{ width: fieldSetting.type === 'boolean' ? 100 : 250 }}>
                <Field
                  name={fieldName}
                  values={mapValues(statedFields, 'value')}
                  {...fieldSetting}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
