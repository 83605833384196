const { isEmpty, } = require('lodash');

const { entries } = Object;

const generalSegment = { id: 'general', name: '調整額(全社)' };

const fields = () => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

exports.fields = fields;
exports.generalSegment = generalSegment;
