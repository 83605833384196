const { zip, keyBy, sortBy, groupBy, } = require('lodash');

const { integerFormat, floatFormat } = require('../util');
const { accountItemCategories, accountItemCategoryNames } = require('../config');

const { keys } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRowGroups = (items) => {
  const itemsByCategory = keyBy(items, 'accountItemCategory.name');
  return accountItemCategories
    .filter(_ => _.type === 'bs')
    .filter(_ => !_.closes)
    .filter(_ => _.name === _.disclosureName())
    .map((category) => {
      const { directItems, categoryItem } = itemsByCategory[category.name];
      const { closes = false, closesItems = false, } = category;
      const rows = [...directItems, categoryItem]
        .filter(_ => abs(_.amount) > 0)
        .filter(_ => _.itemType === 'category' || !closesItems);
      return {
        category,
        rows,
      };
    });
};

const rowsForExport = (items) => () => {
  return generateRowGroups(items).flatMap(({ category, rows }) => {
    return rows.map((row) => {
      const { item, itemType, amount } = row;
      return {
        category: itemType === 'category' ? item.name : '',
        groupName: itemType !== 'category' ? (item.group_name || item.name) : '',
        amount,
      };
    });
  });
};

const computeAlerts = (items) => {
  return [
    (() => {
      const debitAmount = items.find(_ => _.categoryItem.item.name === '資産').categoryItem.amount;
      const creditAmount = items.find(_ => _.categoryItem.item.name === '負債及び純資産').categoryItem.amount;
      const diff = debitAmount - creditAmount;
      return diff !== 0 && `貸借不一致です (${integerFormat(Math.abs(diff))})`;
    })(),
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
