const { memoize, invoke, sum, sumBy, uniq, keyBy, } = require('lodash');

const accountItemCategories = [
  ...[
    ...[
      { name: '現金・預金', parent: '流動資産', closes: true, },
      { name: '売上債権', parent: '流動資産', closes: true, },
      { name: '棚卸資産', parent: '流動資産', closes: true, },
      { name: '他流動資産', parent: '流動資産', closes: true, },
      { name: '有価証券', parent: '流動資産', },
      { name: '流動資産', parent: '資産', },
      { name: '有形固定資産', parent: '固定資産', },
      { name: '無形固定資産', parent: '固定資産', },
      { name: '投資その他の資産', parent: '固定資産', },
      { name: '固定資産', parent: '資産', },
      { name: '繰延資産', parent: '資産', },
      { name: '諸口', parent: '資産', },
      { name: '資産', },
    ].map(_ => ({ ..._, direction: 'debit', })),
    ...[
      { name: '仕入債務', parent: '流動負債', closes: true, },
      { name: '他流動負債', parent: '流動負債', closes: true, },
      { name: '流動負債', parent: '負債', },
      { name: '固定負債', parent: '負債', },
      { name: '負債', parent: '負債及び純資産', },
      { name: '資本金', roma: 'shihon', parent: '株主資本', closesItems: true, },
      { name: '新株式申込証拠金', roma: 'shinkabushiki', parent: '株主資本', closesItems: true, },
      { name: '資本準備金', roma: 'shihonjunbi', parent: '資本剰余金', closes: true, closesItems: true, },
      { name: 'その他資本剰余金', roma: 'sonotashihonjouyo', parent: '資本剰余金', closes: true, closesItems: true, },
      { name: '資本剰余金', roma: 'shihonjouyo', parent: '株主資本', closesItems: true, compute: (_, isSum) => computeCategoryAmount('資本準備金', _, isSum) + computeCategoryAmount('その他資本剰余金', _, isSum) },
      { name: '利益準備金', roma: 'riekijunbi', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '任意積立金', roma: 'ninitsumitate', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '繰越利益剰余金', roma: 'kurikoshijouyo', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '当期純損益金額', roma: 'toukijunsoneki', parent: '利益剰余金', closes: true, closesItems: true, compute: (_, isSum) => computeCategoryAmount('親会社株主に帰属する当期純利益', _, isSum), },
      { name: 'その他利益剰余金', roma: 'sonotariekijouyo', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '利益剰余金', roma: 'riekijouyo', parent: '株主資本', closesItems: true, compute: (_, isSum) => computeCategoryAmount('利益準備金', _, isSum) + computeCategoryAmount('任意積立金', _, isSum) + computeCategoryAmount('繰越利益剰余金', _, isSum) + computeCategoryAmount('当期純損益金額', _, isSum) + computeCategoryAmount('その他利益剰余金', _, isSum) },
      { name: '自己株式', roma: 'jikokabushiki', parent: '株主資本', closesItems: true, },
      { name: '自己株式申込証拠金', roma: 'jikokabushikishouko', parent: '株主資本', closesItems: true, },
      { name: '株主資本', roma: 'kabunushishihon', parent: '純資産', compute: (_, isSum) => computeCategoryAmount('資本金', _, isSum) + computeCategoryAmount('新株式申込証拠金', _, isSum) + computeCategoryAmount('資本剰余金', _, isSum) + computeCategoryAmount('利益剰余金', _, isSum) + computeCategoryAmount('自己株式', _, isSum) + computeCategoryAmount('自己株式申込証拠金', _, isSum) },
      { name: '他有価証券評価差額金', roma: 'yuukashoukensagaku', parent: '評価・換算差額等', closesItems: true, },
      { name: '繰延ヘッジ損益', roma: 'kurinobehejji', parent: '評価・換算差額等', closesItems: true, },
      { name: '土地再評価差額金', roma: 'tochisaihyoukasagaku', parent: '評価・換算差額等', closesItems: true, },
      { name: '為替換算調整勘定', roma: 'kawasekansan', parent: '評価・換算差額等', closesItems: true, },
      { name: '退職給付に係る調整累計額', ciName: '退職給付に係る調整額', roma: 'taishokuchousei', closesItems: true, parent: '評価・換算差額等', },
      { name: '評価・換算差額等', roma: 'hyoukakansan', parent: '純資産', compute: (_, isSum) => sumBy(['他有価証券評価差額金', '繰延ヘッジ損益', '土地再評価差額金', '為替換算調整勘定', '退職給付に係る調整累計額',], name => computeCategoryAmount(name, _, isSum)) },
      { name: '新株予約権', roma: 'shinkabuyoyaku', parent: '純資産', closesItems: true, },
      { name: '非支配株主持分', roma: 'hishihaimochibun', parent: '純資産', closesItems: true, },
      { name: '純資産', roma: 'junshisan', parent: '負債及び純資産', compute: (_, isSum) => computeCategoryAmount('株主資本', _, isSum) + computeCategoryAmount('評価・換算差額等', _, isSum) + computeCategoryAmount('非支配株主持分', _, isSum) + computeCategoryAmount('新株予約権', _, isSum) },
      { name: '負債及び純資産', compute: (_, isSum) => computeCategoryAmount('負債', _, isSum) + computeCategoryAmount('純資産', _, isSum) },
    ].map(_ => ({ ..._, direction: 'credit', })),
  ].map(_ => ({ ..._, type: 'bs', denominatorCategoryName: '資産', })),
  ...[
    { name: '売上高', direction: 'credit', closesItems: true,  denominatorCategoryName: '売上高', },

    // NOTE: 以下、売上原価が親だが、一旦parentをなくす。売上原価は試算表の単独の行を使う。
    { name: '期首商品棚卸', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '当期商品仕入', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '他勘定振替高(商)', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '期末商品棚卸', direction: 'credit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '商品売上原価', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('期首商品棚卸', _, isSum) + computeCategoryAmount('当期商品仕入', _, isSum) + computeCategoryAmount('他勘定振替高(商)', _, isSum) - computeCategoryAmount('期末商品棚卸', _, isSum), sum: ['期首商品棚卸', '当期商品仕入', '他勘定振替高(商)', '期末商品棚卸'], },
    { name: '期首製品棚卸', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '当期製品製造原価', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('製造原価', _, isSum), },
    { name: '他勘定振替高(製)', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '期末製品棚卸', direction: 'credit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '製品売上原価', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('期首製品棚卸', _, isSum) + computeCategoryAmount('当期製品製造原価', _, isSum) + computeCategoryAmount('他勘定振替高(製)', _, isSum) - computeCategoryAmount('期末製品棚卸', _, isSum), sum: ['期首製品棚卸', '当期製品製造原価', '他勘定振替高(製)', '期末製品棚卸'], },
    { name: '売上原価', direction: 'debit', closesItems: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('商品売上原価', _, isSum) + computeCategoryAmount('製品売上原価', _, isSum), },
    { name: '振替', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '売上総利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('売上高', _, isSum) - computeCategoryAmount('売上原価', _, isSum), sum: ['売上高', '売上原価'], },
    { name: '販売管理費', direction: 'debit', denominatorCategoryName: '販売管理費', },
    { name: '営業利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('売上総利益', _, isSum) - computeCategoryAmount('販売管理費', _, isSum), sum: ['売上総利益', '販売管理費'], },
    { name: '営業外収益', direction: 'credit', denominatorCategoryName: '営業外収益', },
    { name: '営業外費用', direction: 'debit', denominatorCategoryName: '営業外費用', },
    { name: '経常利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('営業利益', _, isSum) + computeCategoryAmount('営業外収益', _, isSum) - computeCategoryAmount('営業外費用', _, isSum), sum: ['営業利益', '営業外収益', '営業外費用'], },
    { name: '特別利益', direction: 'credit', denominatorCategoryName: '特別利益', },
    { name: '特別損失', direction: 'debit', denominatorCategoryName: '特別損失', },
    { name: '税引前当期純利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('経常利益', _, isSum) + computeCategoryAmount('特別利益', _, isSum) - computeCategoryAmount('特別損失', _, isSum), sum: ['経常利益', '特別利益', '特別損失'], },
    { name: '法人税等', direction: 'debit', },
    { name: '法人税等調整額', direction: 'debit', },
    { name: '当期純利益', roma: 'toukijunrieki', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('税引前当期純利益', _, isSum) - computeCategoryAmount('法人税等', _, isSum) - computeCategoryAmount('法人税等調整額', _, isSum), sum: ['税引前当期純利益', '法人税等', '法人税等調整額'], },
    { name: '非支配株主持分損益', direction: 'debit', },
    { name: '親会社株主に帰属する当期純利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('当期純利益', _, isSum) - computeCategoryAmount('非支配株主持分損益', _, isSum), sum: ['当期純利益', '非支配株主持分損益'], },
  ].map(_ => ({ ..._, type: 'pl', })),
  ...[
    { name: '期首原材料棚卸', direction: 'debit', closes: true, },
    { name: '当期原材料仕入高', direction: 'debit', closes: true, },
    { name: '期末原材料棚卸', direction: 'credit', closes: true, },
    { name: '材料費', direction: 'debit', closes: true, computable: true, compute: (_, isSum) => computeCategoryAmount('期首原材料棚卸', _, isSum) + computeCategoryAmount('当期原材料仕入高', _, isSum) - computeCategoryAmount('期末原材料棚卸', _, isSum), sum: ['期首原材料棚卸', '当期原材料仕入高', '期末原材料棚卸'], },
    { name: '労務費', direction: 'debit', closes: true, },
    { name: '製造経費', direction: 'debit', closes: true, },
    { name: '期首仕掛品棚卸', direction: 'debit', closes: true, },
    { name: '総製造費用', direction: 'debit', closes: true, computable: true, compute: (_, isSum) => computeCategoryAmount('材料費', _, isSum) + computeCategoryAmount('労務費', _, isSum) + computeCategoryAmount('製造経費', _, isSum), sum: ['材料費', '労務費', '製造経費'], },
    { name: '期末仕掛品棚卸', direction: 'credit', closes: true, },
    { name: '製造原価', direction: 'debit', closes: true, computable: true, compute: (_, isSum) => computeCategoryAmount('期首仕掛品棚卸', _, isSum) + computeCategoryAmount('総製造費用', _, isSum) - computeCategoryAmount('期末仕掛品棚卸', _, isSum), sum: ['期首仕掛品棚卸', '総製造費用', '期末仕掛品棚卸'], },
  ].map(_ => ({ ..._, type: 'cr', })),
].map((_category) => {
  const category = {
    ..._category,
    id: _category.name,
    key: _ => [_category.type, _category.name].join('--'),
    directionValue: ({ debit: 1, credit: -1 })[_category.direction],
    parents: memoize(_ => categoryParents(category)),
    indent: _ => category.parents().length,
    descendants: memoize(_ => accountItemCategories.filter(_ => _.parents().includes(category.name))),
    selfAndDescendants: _ => [category, ...category.descendants()],
    descendantsAndSelf: _ => [...category.descendants(), category],
    selfAndParents: _ => [category, ...category.parents()],
    children: memoize(_ => accountItemCategories.filter(_ => _.parent === category.name)),
    compute: (amountsByCategory, isSum) => {
      return isSum ? (
        category.sum(amountsByCategory, isSum)
      ) : (
        _category.compute ? (
          _category.compute(amountsByCategory, isSum)
        ) : (amountsByCategory[category.name] || 0)
      );
    },
    sum: _category.sum ? ((amountsByCategory, isSum) => sumBy(_category.sum, _ => accountItemCategoriesByName[_].compute(amountsByCategory, true))) : _category.compute || (amountsByCategory => amountsByCategory[category.name]),
    disclosureName: _ => _category.closes ? invoke(accountItemCategoriesByName, [_category.parent, 'disclosureName']) : _category.name,
    ratio: (item, categoryItemsByCategory) => item.conclusiveAmount / category.denominator(item, categoryItemsByCategory),
    denominator: (item, categoryItemsByCategory) => categoryItemsByCategory[category.denominatorCategoryName]?.conclusiveAmount,
  };
  return category;
});
const parentCategoryNames = uniq(accountItemCategories.map(_ => _.parent));
const accountItemCategoryNames = accountItemCategories.map(_ => _.name);
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoryParentNames = uniq(accountItemCategories.map(_ => _.parent));
const categoryParents = (category) => {
  return category.parent ?
    [category.parent, ...categoryParents(accountItemCategoriesByName[category.parent])]
  : [];
};
const accountItemCategoryChildren = accountItemCategories.filter(_ => !accountItemCategoryParentNames.includes(_.name))
const computeCategoryAmount = (categoryName, amountsGroupedByCategory, isSum) => {
  return accountItemCategoriesByName[categoryName][isSum ? 'sum' : 'compute'](amountsGroupedByCategory, isSum);
};
const otherHrAccountItemNamePatterns = [
  /関係会社株式/,
  /子会社株式/,
  /関連会社株式/,
];

const cfAccountItemCategories = {
  sales: {
    label: '営業活動',
  },
  salesBottom: {
    label: '営業活動（小計欄以下）',
  },
  investment: {
    label: '投資活動',
  },
  finance: {
    label: '財務活動',
  },
  cashExchangeDiff: {
    label: '現金及び現金同等物に係る換算差額',
  },
  openingCash: {
    label: '現金及び現金同等物の期首残高',
  },
};

const documentTypes = {
  bs: { label: '貸借対照表', },
  pl: { label: '損益計算書', },
  cr: { label: '製造原価報告書', },
};

const screens = {
  relatedCompanies: { label: '個社連結PKG', showsInHeader: true, },
  exchangeRates: { label: '為替レート', showsInHeader: true, },
  relatedCompanies: { label: '個社連結PKG', showsInHeader: true, },
  exchangeRates: { label: '為替レート', showsInHeader: true, },
  prevPeriodAmounts: { label: '前期数値', showsInHeader: true, },
  consolidationAccountItems: { label: '連結科目', showsInHeader: true, },
  consolidationJournalTypes: { label: '仕訳種別', showsInHeader: true, },
  pkg: { label: '全社連結PKG', showsInHeader: true, },
  consolidationJournals: { label: '開始・連結仕訳', showsInHeader: true, },
  consolidationCalculation: { label: '連結精算表', showsInHeader: true, },
  bs: { label: '連結BS', showsInHeader: true, },
  pl: { label: '連結PL', showsInHeader: true, },
  ss: { label: '連結SS', showsInHeader: true, },
  ci: { label: '連結CI', showsInHeader: true, },
  cfChangeTypes: { label: 'CF増減種別', showsInHeader: true, },
  cfAccountItems: { label: 'CF科目', showsInHeader: true, },
  cfMappings: { label: 'CFマッピング', showsInHeader: true, },
  cfWs: { label: '連結CFWS', showsInHeader: true, },
  cfWs2: { label: '連結CFWS2', showsInHeader: true, },
  cf: { label: '連結CF', showsInHeader: true, },
  consolidationSegments: { label: '連結セグメント', showsInHeader: true, },
  segmentsInfo: { label: '連結セグメント情報', showsInHeader: true, },
  alerts: { label: '全体チェック', showsInHeader: true, },
  monthlyTrends: { label: '月次推移', showsInHeader: true, },
  integration: { label: '開示連携', showsInHeader: true, },
  settings: { label: '設定', showsInHeader: true, },
};
const pkgScreens = {
  relatedCompanyBalances: { label: '債権債務取引高', path: '/relatedCompanyBalances', isActive: _ => _.pathname.match(/relatedCompanyBalances/) },
  cashes: { label: '現金及び現金同等物', path: '/cashes', isActive: _ => _.pathname.match(/cashes/) },
  fixedAssets: { label: '固定資産', path: '/fixedAssets', isActive: _ => _.pathname.match(/fixedAssets/) },
  investments: { label: '投資関連', path: '/investments', isActive: _ => _.pathname.match(/investments/) },
  finances: { label: '財務関連', path: '/finances', isActive: _ => _.pathname.match(/finances/) },
  miscellaneousAccounts: { label: '雑勘定', path: '/miscellaneousAccounts', isActive: _ => _.pathname.match(/miscellaneousAccounts/) },
  allowances: { label: '引当金', path: '/allowances', isActive: _ => _.pathname.match(/allowances/) },
};

const externalTypes = {
  freee: { label: 'freee', },
  bugyo: { label: '勘定奉行クラウド', },
  // mf: { label: 'MFクラウド', },
  pca: { label: 'PCAクラウド', },
};

const bugyoCategoryMaps = {
  現金: '現金・預金',
  預金: '現金・預金',
  当座資産: '売上債権',
  その他の流動資産: '他流動資産',
  流動負債: '他流動負債',
  その他有価証券評価差額金: '他有価証券評価差額金',
  純売上高: '売上高',
  売上原価: '当期商品仕入',
  外注加工費: '製造経費',
  棚卸調整: '製造経費',
  販売費及び一般管理費: '販売管理費',
  材料費: '当期原材料仕入高',
};

const pcaCategoryMaps = {
  '現金預金': '現金・預金',
  '当座資産': '現金・預金',
  'その他流動資産': '他流動資産',
  '本支店': '諸口',
  'その他流動負債': '他流動負債',
  '引当金': '固定負債',
  '他有価証券評価差額金': '',
  '自己株式払込金': '自己株式',
  'その他剰余金': 'その他利益剰余金',
  '利益剰余金': '任意積立金',
  '未処分利益': '繰越利益剰余金',
  '商品売上原価': '当期商品仕入',
  '製造原価（材料費）': '材料費',
  '製造原価（労務費）': '労務費',
  '製造原価（外注費）': '製造経費',
  '製造原価（経費）': '製造経費',
  '販売費及び一般管理費': '販売管理費',
};

const pcaDataCenters = {
  east02: { label: '関東DC', },
  west01: { label: '関西DC', },
  east04: { label: '新関東DC', },
  west03: { label: '新関西DC', },
  aws: { label: 'AWS東京リージョン', },
};

const mfCategoryMaps = {
  現金及び預金: '現金・預金',
  その他流動資産: '他流動資産',
  その他流動負債: '他流動負債',
  積立金等: '任意積立金',
  期首棚卸高: '期首商品棚卸',
  当期仕入高: '当期商品仕入',
  期末棚卸高: '期末商品棚卸',
  販売費及び一般管理費: '販売管理費',
  他勘定振替高: '他勘定振替高(商)',
  期首材料棚卸高: '期首原材料棚卸',
  当期材料仕入高: '当期原材料仕入高',
  期末材料棚卸高: '期末原材料棚卸',
  期首仕掛品棚卸高: '期首仕掛品棚卸',
  期末仕掛品棚卸高: '期末仕掛品棚卸',
};

const colors = [
  '#66c2a5',
  '#ffd92f',
  '#fc8d62',
  '#8da0cb',
  '#e78ac3',
  '#a6d854',
  '#e5c494',
  '#b3b3b3',
];

const currencies = {
  aed: { label: 'AED', },
  afn: { label: 'AFN', },
  all: { label: 'ALL', },
  amd: { label: 'AMD', },
  ang: { label: 'ANG', },
  aoa: { label: 'AOA', },
  ars: { label: 'ARS', },
  aud: { label: 'AUD', },
  awg: { label: 'AWG', },
  azn: { label: 'AZN', },
  bam: { label: 'BAM', },
  bbd: { label: 'BBD', },
  bdt: { label: 'BDT', },
  bgn: { label: 'BGN', },
  bhd: { label: 'BHD', },
  bif: { label: 'BIF', },
  bmd: { label: 'BMD', },
  bnd: { label: 'BND', },
  bob: { label: 'BOB', },
  bov: { label: 'BOV', },
  brl: { label: 'BRL', },
  bsd: { label: 'BSD', },
  btn: { label: 'BTN', },
  bwp: { label: 'BWP', },
  byn: { label: 'BYN', },
  bzd: { label: 'BZD', },
  cad: { label: 'CAD', },
  cdf: { label: 'CDF', },
  che: { label: 'CHE', },
  chf: { label: 'CHF', },
  chw: { label: 'CHW', },
  clf: { label: 'CLF', },
  clp: { label: 'CLP', },
  cnh: { label: 'CNH', },
  cnt: { label: 'CNT', },
  cny: { label: 'CNY', },
  cop: { label: 'COP', },
  cou: { label: 'COU', },
  crc: { label: 'CRC', },
  cuc: { label: 'CUC', },
  cup: { label: 'CUP', },
  cve: { label: 'CVE', },
  czk: { label: 'CZK', },
  djf: { label: 'DJF', },
  dkk: { label: 'DKK', },
  dop: { label: 'DOP', },
  dzd: { label: 'DZD', },
  egp: { label: 'EGP', },
  ern: { label: 'ERN', },
  etb: { label: 'ETB', },
  eur: { label: 'EUR', },
  fjd: { label: 'FJD', },
  fkp: { label: 'FKP', },
  gbp: { label: 'GBP', },
  gel: { label: 'GEL', },
  ggp: { label: 'GGP', },
  ghs: { label: 'GHS', },
  gip: { label: 'GIP', },
  gmd: { label: 'GMD', },
  gnf: { label: 'GNF', },
  gtq: { label: 'GTQ', },
  gyd: { label: 'GYD', },
  hkd: { label: 'HKD', },
  hnl: { label: 'HNL', },
  hrk: { label: 'HRK', },
  htg: { label: 'HTG', },
  huf: { label: 'HUF', },
  idr: { label: 'IDR', },
  ils: { label: 'ILS', },
  imp: { label: 'IMP', },
  inr: { label: 'INR', },
  iqd: { label: 'IQD', },
  irr: { label: 'IRR', },
  isk: { label: 'ISK', },
  jep: { label: 'JEP', },
  jmd: { label: 'JMD', },
  jod: { label: 'JOD', },
  jpy: { label: 'JPY', },
  kes: { label: 'KES', },
  kgs: { label: 'KGS', },
  khr: { label: 'KHR', },
  kid: { label: 'KID', },
  kmf: { label: 'KMF', },
  kpw: { label: 'KPW', },
  krw: { label: 'KRW', },
  kwd: { label: 'KWD', },
  kyd: { label: 'KYD', },
  kzt: { label: 'KZT', },
  lak: { label: 'LAK', },
  lbp: { label: 'LBP', },
  lkr: { label: 'LKR', },
  lrd: { label: 'LRD', },
  lsl: { label: 'LSL', },
  lyd: { label: 'LYD', },
  mad: { label: 'MAD', },
  mdl: { label: 'MDL', },
  mga: { label: 'MGA', },
  mkd: { label: 'MKD', },
  mmk: { label: 'MMK', },
  mnt: { label: 'MNT', },
  mop: { label: 'MOP', },
  mru: { label: 'MRU', },
  mur: { label: 'MUR', },
  mvr: { label: 'MVR', },
  mwk: { label: 'MWK', },
  mxn: { label: 'MXN', },
  mxv: { label: 'MXV', },
  myr: { label: 'MYR', },
  mzn: { label: 'MZN', },
  nad: { label: 'NAD', },
  ngn: { label: 'NGN', },
  nio: { label: 'NIO', },
  nis: { label: 'NIS', },
  nok: { label: 'NOK', },
  npr: { label: 'NPR', },
  ntd: { label: 'NTD', },
  nzd: { label: 'NZD', },
  omr: { label: 'OMR', },
  pab: { label: 'PAB', },
  pen: { label: 'PEN', },
  pgk: { label: 'PGK', },
  php: { label: 'PHP', },
  pkr: { label: 'PKR', },
  pln: { label: 'PLN', },
  prb: { label: 'PRB', },
  pyg: { label: 'PYG', },
  qar: { label: 'QAR', },
  rmb: { label: 'RMB', },
  ron: { label: 'RON', },
  rsd: { label: 'RSD', },
  rub: { label: 'RUB', },
  rwf: { label: 'RWF', },
  sar: { label: 'SAR', },
  sbd: { label: 'SBD', },
  scr: { label: 'SCR', },
  sdg: { label: 'SDG', },
  sek: { label: 'SEK', },
  sgd: { label: 'SGD', },
  shp: { label: 'SHP', },
  sll: { label: 'SLL', },
  sls: { label: 'SLS', },
  sos: { label: 'SOS', },
  srd: { label: 'SRD', },
  ssp: { label: 'SSP', },
  stn: { label: 'STN', },
  svc: { label: 'SVC', },
  syp: { label: 'SYP', },
  szl: { label: 'SZL', },
  thb: { label: 'THB', },
  tjs: { label: 'TJS', },
  tmt: { label: 'TMT', },
  tnd: { label: 'TND', },
  top: { label: 'TOP', },
  try: { label: 'TRY', },
  ttd: { label: 'TTD', },
  tvd: { label: 'TVD', },
  twd: { label: 'TWD', },
  tzs: { label: 'TZS', },
  uah: { label: 'UAH', },
  ugx: { label: 'UGX', },
  usd: { label: 'USD', },
  usn: { label: 'USN', },
  uyi: { label: 'UYI', },
  uyu: { label: 'UYU', },
  uyw: { label: 'UYW', },
  uzs: { label: 'UZS', },
  ves: { label: 'VES', },
  vnd: { label: 'VND', },
  vuv: { label: 'VUV', },
  wst: { label: 'WST', },
  xaf: { label: 'XAF', },
  xag: { label: 'XAG', },
  xau: { label: 'XAU', },
  xba: { label: 'XBA', },
  xbb: { label: 'XBB', },
  xbc: { label: 'XBC', },
  xbd: { label: 'XBD', },
  xcd: { label: 'XCD', },
  xdr: { label: 'XDR', },
  xof: { label: 'XOF', },
  xpd: { label: 'XPD', },
  xpf: { label: 'XPF', },
  xpt: { label: 'XPT', },
  xsu: { label: 'XSU', },
  xts: { label: 'XTS', },
  xua: { label: 'XUA', },
  xxx: { label: 'XXX', },
  yer: { label: 'YER', },
  zar: { label: 'ZAR', },
  zmw: { label: 'ZMW', },
  zwb: { label: 'ZWB', },
  zwl: { label: 'ZWL', },
};

const presetConsolidationAccountItems = [
  {
    id: '為替換算調整勘定',
    consolidationAccountItemId: '為替換算調整勘定',
    account_category: '為替換算調整勘定',
    name: '為替換算調整勘定',
    group_name: '為替換算調整勘定',
    shortcut_num: 'kawasekansan',
  },
  {
    id: '退職給付に係る調整累計額',
    consolidationAccountItemId: '退職給付に係る調整累計額',
    account_category: '退職給付に係る調整累計額',
    name: '退職給付に係る調整累計額',
    group_name: '退職給付に係る調整累計額',
    shortcut_num: 'taishokuchousei',
  },
  {
    id: '非支配株主持分',
    consolidationAccountItemId: '非支配株主持分',
    account_category: '非支配株主持分',
    name: '非支配株主持分',
    group_name: '非支配株主持分',
    shortcut_num: 'hishihaimochibun',
  },
  {
    id: '非支配株主持分損益',
    consolidationAccountItemId: '非支配株主持分損益',
    account_category: '非支配株主持分損益',
    name: '非支配株主持分損益',
    group_name: '非支配株主持分損益',
    shortcut_num: 'hishihaimochibunsoneki',
  },
];

exports.mfCategoryMaps = mfCategoryMaps;
exports.currencies = currencies;
exports.colors = colors;
exports.accountItemCategories = accountItemCategories;
exports.accountItemCategoryNames = accountItemCategoryNames;
exports.accountItemCategoriesByName = accountItemCategoriesByName;
exports.accountItemCategoryChildren = accountItemCategoryChildren;
exports.cfAccountItemCategories = cfAccountItemCategories;
exports.documentTypes = documentTypes;
exports.otherHrAccountItemNamePatterns = otherHrAccountItemNamePatterns;
exports.screens = screens;
exports.pkgScreens = pkgScreens;
exports.externalTypes = externalTypes;
exports.bugyoCategoryMaps = bugyoCategoryMaps;
exports.pcaCategoryMaps = pcaCategoryMaps;
exports.pcaDataCenters = pcaDataCenters;
exports.presetConsolidationAccountItems = presetConsolidationAccountItems;
