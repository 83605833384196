const { isEmpty, get, mapValues, sumBy, groupBy, keyBy, sortBy, omit, } = require('lodash');
const { accountItemCategories } = require('../config');

const { integerFormat, floatFormat } = require('../util');

const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRowGroups = (singleAmountItems) => {
  const categoryItems = singleAmountItems.filter(_ => _.itemType === 'category');
  const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
  const itemsGroupedByCategory = groupBy(singleAmountItems, 'accountItemCategory.name');
  return ['bs', 'pl', 'cr'].map((type) => {
    const accountItemCategories = accountItemCategoriesGroupedByType[type];
    return {
      type,
      rowGroups: accountItemCategories.map((category) => {
        const directItems = (itemsGroupedByCategory[category.name] || [])
          .filter(_ => _.itemType === 'consolidationAccountItem')
          .filter(_ => ['closingBalance', 'debitAmount', 'creditAmount', 'adjustedAmount', 'exchangedAmount'].some(k => Math.abs(_[k]) > 0));
        const categoryItem = categoryItemsByCategory[category.name];
        const rows = [...directItems, categoryItem].filter(_ => _);
        return {
          category,
          rows,
        };
      }),
    };
  });
};

const rowsForExport = (singleAmountItems) => () => {
  return generateRowGroups(singleAmountItems).flatMap(_ => _.rowGroups.flatMap(_ => _.rows)).map(({ item, itemType, closingBalance, debitAmount, creditAmount, adjustedAmount, exchangedAmount }) => {
    return {
      category: itemType === 'category' ? item.name : null,
      itemName: itemType === 'consolidationAccountItem' ? item.name : null,
      closingBalance,
      debitAmount,
      creditAmount,
      adjustedAmount,
      exchangedAmount,
    };
  });
};

const computeAlerts = (relatedCompany, items, prevYearItems) => {
  if([items, prevYearItems].some(_ => isEmpty(_))) return;

  return [
    (() => {
      const debitAmount = items.find(_ => _.item.name === '資産').exchangedAmount;
      const creditAmount  = items.find(_ => _.item.name === '負債及び純資産').exchangedAmount;
      const diff = debitAmount - creditAmount;
      return diff !== 0 && `貸借不一致です (${integerFormat(Math.abs(diff))})`;
    })(),
    (() => {
      const prevYearItemsByName = keyBy(prevYearItems?.filter(_ => _.itemType === 'category'), 'item.name');
      const diffs = ['adjustedAmount', 'exchangedAmount'].map(amountName => items.find(_ => _.itemType === 'category' && _.item.name === '繰越利益剰余金')?.[amountName] - (prevYearItemsByName['繰越利益剰余金']?.[amountName] + prevYearItemsByName['当期純損益金額']?.[amountName]));
      const diff = diffs.find(_ => Math.abs(Math.round(_ * 1000)) > 0); // NOTE: JSの都合で極小の差額が出ることがあるので対策
      return diff != null && `前期末の「繰越利益剰余金＋当期純損益金額」と当期末の繰越利益剰余金に差額があります (${integerFormat(Math.abs(diff))})`;
    })(),
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
