const { isEmpty, sumBy, pick, lowerFirst, mapKeys, keyBy, sortBy, groupBy, } = require('lodash');

const { floatFormat, disassembleJournalItems, } = require('../util');
const { accountItemCategoryNames } = require('../config');

const { keys } = Object;

const generateRowGroups = (itemGroups, individualAdjustmentJournals, consolidationJournals, company, accountItems, consolidationAccountItems, exchangeRate) => {
  const relatedCompanies = itemGroups.map(_ => _.relatedCompany);
  const [individualAdjustmentJournalItemsGroupedByItemId, consolidationJournalItemsGroupedByItemId] = [individualAdjustmentJournals, consolidationJournals].map(journals => (
    groupBy(journals.flatMap(disassembleJournalItems).filter(_ => _.changeType === 'cash'), 'itemId')
  ));
  const accountItemsById = keyBy(accountItems, 'id');
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const rows = itemGroups.flatMap((itemGroup) => {
    const { relatedCompany, pkgItems } = itemGroup;
    return pkgItems.map((cash) => {
      const { id, ref, accountItemId, amount, note, subsidiaryId, } = cash;
      const accountItem = accountItemsById[accountItemId];
      const consolidationAccountItem = consolidationAccountItemsById[accountItem && accountItem.consolidationAccountItemId]
      const currency = relatedCompany?.currency || 'jpy';
      const rate = currency === 'jpy' ? 1 : exchangeRate?.[[currency, 'cr'].join('-')];
      const exchangedAmount = amount * rate;
      return {
        rowType: 'accountItem',
        ...cash,
        relatedCompany,
        accountItem,
        consolidationAccountItem,
        currency,
        rate,
        exchangedAmount,
      };
    });
  });
  const rowsGroupedByConsolidationAccountItemId = groupBy(rows, 'consolidationAccountItem.id');
  const sortedItems = consolidationAccountItems.filter(_ => keys(rowsGroupedByConsolidationAccountItemId).includes(_.id));
  return sortedItems.map((consolidationAccountItem) => {
    const rows = rowsGroupedByConsolidationAccountItemId[consolidationAccountItem.id];
    const rowsGroupedByRelatedCompanyId = groupBy(rows, 'relatedCompany.id');
    const individualAdjustmentJournalItems = individualAdjustmentJournalItemsGroupedByItemId[consolidationAccountItem.id] || [];
    const individualAdjustmentJournalItemsGroupedByRelatedCompanyId = groupBy(individualAdjustmentJournalItems, _ => _.journal.subsidiaryId || company.id);
    const consolidationJournalItems = consolidationJournalItemsGroupedByItemId[consolidationAccountItem.id] || [];
    const consolidationJournalItemsGroupedByRelatedCompanyId = groupBy(consolidationJournalItems, _ => _.companyId);
    const relatedCompanyRowGroups = relatedCompanies.map((relatedCompany) => {
      const currency = relatedCompany?.currency || 'jpy';
      const rate = currency === 'jpy' ? 1 : exchangeRate?.[[currency, 'cr'].join('-')];
      const individualAdjustmentJournalItems = individualAdjustmentJournalItemsGroupedByRelatedCompanyId[relatedCompany.id] || [];
      const individualAdjustmentJournalsAmount = sumBy(individualAdjustmentJournalItems, _ => _.amount * ({ debit: 1, credit: -1 })[_.direction] * rate);
      const consolidationJournalItems = consolidationJournalItemsGroupedByRelatedCompanyId[relatedCompany.id] || [];
      const consolidationJournalsAmount = sumBy(consolidationJournalItems, _ => _.amount * ({ debit: 1, credit: -1 })[_.direction]);
      const rows = [
        ...(rowsGroupedByRelatedCompanyId[relatedCompany.id] || []),
        ...(isEmpty(individualAdjustmentJournalItems) ? [] : [
          {
            rowType: 'individualAdjustment',
            id: 'individualAdjustment',
            accountItem: { name: '個別修正', },
            exchangedAmounts: { amount: individualAdjustmentJournalsAmount, },
            relatedCompany,
            exchangedAmount: individualAdjustmentJournalsAmount,
          }
        ]),
        ...(isEmpty(consolidationJournalItems) ? [] : [
          {
            rowType: 'consolidation',
            id: 'consolidation',
            accountItem: { name: '連結仕訳', },
            exchangedAmounts: { amount: consolidationJournalsAmount, },
            relatedCompany,
            exchangedAmount: consolidationJournalsAmount,
          }
        ]),
      ];
      return {
        relatedCompany,
        rows,
        individualAdjustmentJournalItems,
      };
    });
    return {
      consolidationAccountItem,
      relatedCompanyRowGroups,
    }
  });
};

const rowsForExport = (...args) => () => {
  return generateRowGroups(...args).flatMap(({ consolidationAccountItem, relatedCompanyRowGroups, }) => {
    return relatedCompanyRowGroups.flatMap(({ relatedCompany, rows }) => {
      return rows.map((row) => {
        const { id, accountItem, currency, amount, rate, exchangedAmount, note, } = row;
        return {
          id,
          consolidationAccountItemName: consolidationAccountItem?.name,
          relatedCompanyName: relatedCompany?.display_name,
          accountItemName: accountItem?.name,
          exchangedAmount,
          note,
        };
      });
    });
  });
};

const computeAlerts = (...args) => {
  return [
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.computeAlerts = computeAlerts;
exports.rowsForExport = rowsForExport;
