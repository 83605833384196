import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { get, sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { fiscalYearOfPeriod, } from '../../utils';
import { computeAlerts, generateRows, rowsForExport, headers, } from '../../shared/lib/ss';
import { isTrialNetIncome0, } from '../../shared/models/trial';
import { presetConsolidationAccountItems, computeDisclosureAmounts, } from '../../shared/models/consolidationAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { accountItemCategories, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useDisclosureAmounts from '../hooks/useDisclosureAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import SsItemFormModal from '../modals/SsItemFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';
import WithLoading from '../WithLoading';
import Alerts from '../Alerts';
import Field from '../Field';

const { entries } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');

export default CompanyPage(function CompanySs (props) {
  const { company, role, period, yearMonth, history, location, prevEndYearMonth, prevYearMonths, filteredYearMonths, isLockedMonth, } = props;
  const periodSettingRef = company.ref.collection('periodSettings').doc(period);
  const periodSetting = useDocumentSubscription(periodSettingRef, [period]);
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [subsidiaries]);
  const prevPeriodAmounts = useCollectionSubscription(company.ref.collection('prevPeriodAmounts').where('period', '==', period), [period]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const allConsolidationAccountItems = useMemo(() => [...consolidationAccountItems, ...presetConsolidationAccountItems], [consolidationAccountItems]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const ssItemRef = company.ref.collection('ssItems').doc(yearMonth);
  const ssItem = useDocumentSubscription(ssItemRef, [yearMonth]);
  const prevYearDisclosureSetting = useDocumentSubscription(prevEndYearMonth && company.ref.collection('disclosureSettings').doc(prevEndYearMonth), [prevEndYearMonth]);
  const disclosureSetting = useDocumentSubscription(company.ref.collection('disclosureSettings').doc(yearMonth), [yearMonth]);
  const { items: prevYearItems, } = useDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevYearMonths, true, prevPeriodAmounts, periodSetting?.usesPrevPeriodAmounts, prevYearDisclosureSetting);
  const { items, } = useDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true, null, false, disclosureSetting);

  const rows = useMemo(_ => items && prevYearItems && ssItem && generateRows(items, prevYearItems, ssItem), [items, prevYearItems, ssItem]);
  const alerts = useMemo(_ => items && prevYearItems && computeAlerts(items, prevYearItems, ssItem), [items, prevYearItems, ssItem]);
  useEffect(() => {
    if(ssItem === null) {
      ssItemRef.set({ createdAt: new Date(), });
    }
  }, [ssItem]);

  return props.translate(
    <div className="company-ss container-fluid">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結財務諸表（連結BS、PL、SS、CI）を確認する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結SS</h4>
        </div>
        <WithLoading loading={rows == null}>
          <Alerts alerts={alerts} />
          <div className="mb-3 d-flex justify-content-start align-items-end gap-1">
            <ExportButton fileName="連結SS.csv" rows={rowsForExport(items, prevYearItems, ssItem)} />
            <EditButton itemRef={ssItemRef} FormModal={SsItemFormModal} disabled={isLockedMonth} />
            {periodSetting !== undefined && <Field type="boolean" label="前期数値を使用する" value={periodSetting?.usesPrevPeriodAmounts || false} setValue={_ => periodSettingRef.set({ usesPrevPeriodAmounts: _ }, { merge: true })} formGroupProps={{ className: 'm-0', }} readOnly={_ => isLockedMonth || ['reader'].includes(role)} />}
          </div>
          <div className="d-flex overflow-auto">
            <table className="table flex-fill table-bordered">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 200 }}></th>
                  <th colSpan={headers.filter(_ => _.category.parents().includes('株主資本')).length + 1}>株主資本</th>
                  <th colSpan={headers.filter(_ => _.category.parents().includes('評価・換算差額等')).length + 1}>評価・換算差額等</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th style={{ minWidth: 200 }}></th>
                  {
                    headers.map((header, i) => {
                      return (
                        <th key={i} style={{ minWidth: 100 }}>{header.label}{header.children.length > 0 && ' 計'}</th>
                      );
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  rows?.map((row, i) => {
                    const { level, label, columns, } = row;
                    return (
                      <tr key={i}>
                        <th style={{ textIndent: (level - 1) * 18 }}>{label}</th>
                        {
                          columns.map((column, i) => {
                            const { value, invalid } = column;
                            return (
                              <td key={i} className={classnames('text-right', { 'table-danger': invalid })}>
                                {value === '' ? '' : integerFormat(value)}
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </WithLoading>
      </div>
    </div>
  );
});
