import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { pickBy, pick, get, keyBy, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields, itemFields, } from '../../shared/models/miscellaneousAccount';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function MiscellaneousAccountFormModal (props) {
  const { isOpen, values, accountItems, onClickClose, } = props;
  const statedFields = useFormState(values, fields({ accountItems }), isOpen);
  const isNew = !values || !values.id;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values?.items || []);
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    (items.length < 1) ||
    (items.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            雑勘定{isNew ? '追加' : '編集'}
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div style={{ width: 300 }}>
            <Field
              name="accountItemId"
              {...statedFields.accountItemId}
            />
          </div>
          <div className="mt-3">
            <ListForm
              addButtonLabel="内訳追加"
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      index={itemIndex}
                      values={item}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={1}
            />
          </div>
          <div className="mt-3">
            <Field
              name="note"
              {...statedFields.note}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, onChange, } = props;
  const statedFields = useFormState(values, itemFields(), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div key={fieldName} className="mr-2" style={{ minWidth: 150 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
