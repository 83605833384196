import React, { useState, } from 'react';
import { mapValues } from 'lodash';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import { isFunction } from 'lodash';
import classnames from 'classnames';
import { Button, } from 'reactstrap';

import useLocale from './hooks/useLocale';
import { encode } from '../util';

export default function ExportButton ({ fileName, label = 'エクスポート', rows, encoding = 'SJIS', ...extraProps }) {
  const [isExporting, setIsExporting] = useState(false);
  const { translate } = useLocale();

  const onClickExport = async () => {
    setIsExporting(true);
    const _rows = isFunction(rows) ? (await rows()) : rows;
    const text = unparseCsv(_rows.map(_ => mapValues(_, v => translate(v))));
    const fileContent = encode(text, encoding);
    fileDownload(fileContent, translate(fileName));
    setIsExporting(false);
  };

  return (
    <Button color="secondary" onClick={onClickExport} disabled={isExporting} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      {label}
    </Button>
  );
};
