const { keyBy, sortBy, sumBy, } = require('lodash');

const { uniqAccountName, floatFormat } = require('../util');
const { accountItemCategoryNames } = require('../config');
const { getCategory, } = require('../models/accountItem');

const generateRows = (relatedCompany, items, accountItems, changeFieldNames, prevCr, cr, trials) => {
  const trialBalances = trials.flatMap(_ => _.balances);
  const accountItemsById = keyBy(accountItems, 'id');
  return sortBy(items.map((item) => {
    const { accountItemId, } = item;
    const accountItem = accountItemsById[accountItemId];
    const opening = item.opening || 0;
    const change = sumBy(changeFieldNames, _ => item[_] || 0);
    const exchangedChange = sumBy(changeFieldNames, _ => item.exchangedAmounts[_] || 0);
    const closing = opening + change;
    const exchangeDiff = closing * cr - opening * prevCr - exchangedChange;
    const trialAmount = trialBalances.find(_ => uniqAccountName(relatedCompany, _.account_item_name, _.key) === uniqAccountName(relatedCompany, accountItem?.name, accountItem?.shortcut_num))?.closing_balance;
    return {
      ...item,
      accountItem,
      opening,
      change,
      exchangedChange,
      closing,
      exchangeDiff,
      trialAmount,
    };
  }), _ => accountItemCategoryNames.indexOf(_.accountItem?.account_category), 'accountItem.index');
};

const rowsForExport = (relatedCompany, items, accountItems, changeFieldNames, prevCr, cr, trials) => () => {
  return generateRows(relatedCompany, items, accountItems, changeFieldNames, prevCr, cr, trials).map((row) => {
    const { id, accountItem, opening, closing, } = row;
    return {
      id,
      accountItemName: accountItem?.name,
      opening,
      ...(
        changeFieldNames.reduce((x, fieldName) => {
          return {
            ...x,
            [fieldName]: row[fieldName] || 0,
          };
        }, {})
      ),
      exchangeDiff: 0,
      closing,
    };
  });
};

const computeAlerts = (...args) => {
  return [
    generateRows(...args).some(_ => floatFormat(_.closing) !== floatFormat(_.trialAmount)) && `差額が発生しています`,
  ].filter(_ => _);
};

const generateSimpleRows = (items, accountItems, prevCr, cr, trials) => {
  const trialBalances = trials.flatMap(_ => _.balances);
  const accountItemsById = keyBy(accountItems, 'id');
  return sortBy(items.map((item) => {
    const { accountItemId, opening = 0, closing = 0, _change: change, exchangedAmounts, } = item;
    const accountItem = accountItemsById[accountItemId];
    const category = getCategory(accountItem);
    const exchangedChange = exchangedAmounts._change;
    const exchangeDiff = exchangedAmounts.closing - opening * prevCr - exchangedChange;
    const trialAmount = trialBalances.find(_ => _.account_item_name === accountItem?.name)?.closing_balance;
    return {
      ...item,
      accountItem,
      category,
      change,
      closing,
      exchangedChange,
      exchangeDiff,
      trialAmount,
    };
  }), _ => accountItemCategoryNames.indexOf(_.accountItem?.account_category), 'accountItem.index');
};

const simpleRowsForExport = (...args) => () => {
  return generateSimpleRows(...args).map((row) => {
    const { id, accountItem, opening = 0, change = 0, closing = 0, note, } = row;
    return {
      id,
      accountItemName: accountItem?.name,
      opening,
      change,
      closing,
      note,
    };
  });
};

const computeSimpleAlerts = (...args) => {
  return [
    generateSimpleRows(...args).some(_ => floatFormat(_.closing) !== floatFormat(_.trialAmount)) && `差額が発生しています`,
  ].filter(_ => _);
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
exports.generateSimpleRows = generateSimpleRows;
exports.simpleRowsForExport = simpleRowsForExport;
exports.computeSimpleAlerts = computeSimpleAlerts;
