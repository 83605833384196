import React, { useState, useEffect, useMemo, } from 'react';
import { pickBy, findKey, sortBy, keyBy, range, inRange, get, pick, last, } from 'lodash';
import { useHistory, useParams, useLocation, } from 'react-router';
import { Nav, NavItem, } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import qs from 'qs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { getRates, } from '../../shared/models/exchangeRate';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { presetConsolidationAccountItems, } from '../../shared/models/consolidationAccountItem';
import { accountItemCategoryNames, pkgScreens, } from '../../shared/config';
import firebase from '../../firebase';
import CompanyPage from './CompanyPage';
import HelpLink from '../HelpLink';
import CompanySyncButton from '../CompanySyncButton';
import useCompanySelector from '../hooks/useCompanySelector';
import useCompaniesAmounts from '../hooks/useCompaniesAmounts';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import QuerySelector from '../QuerySelector';

const { entries, keys } = Object;
const auth = firebase.auth();
const db = firebase.firestore();
const companiesRef = db.collection('companies');


export default function PkgPageHOC(WrappedComponent) {
  return CompanyPage(function PkgPage (props) {
    const { prevEndYearMonth, filteredYearMonths, yearMonth, company, user, location, history, match: { params: { companyId, } }, } = props;
    const { role, enabledPkgScreens, } = company?.users?.[user.id] || {};
    const activeScreen = findKey(pkgScreens, _ => _.isActive(location));
    const canAccess = (user?.admin || ['owner', 'admin'].includes(role)) || enabledPkgScreens?.includes(activeScreen);
    const accessableScreens = (user?.admin || ['owner', 'admin'].includes(role)) ? pkgScreens : pickBy(pkgScreens, (v, k) => enabledPkgScreens?.includes(k)); // NOTE: pickでなくpickByなのは、順番制御のため
    const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'), [company]);
    const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [company, subsidiaries]);
    const relatedCompaniesById = keyBy(relatedCompanies, 'id');
    const exchangeRate = useDocumentSubscription(company.ref.collection('exchangeRates').doc(yearMonth), [yearMonth]);
    const prevExchangeRate = useDocumentSubscription(prevEndYearMonth && company.ref.collection('exchangeRates').doc(prevEndYearMonth), [prevEndYearMonth]);
    const accountItems = useCollectionSubscription(company.ref.collection('accountItems'), [company]);
    const accountItemsById = keyBy(accountItems, 'id');
    const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
    const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
    const sortedConsolidationAccountItems = useMemo(_ => sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
    const allConsolidationAccountItems = useMemo(_ => [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems], [sortedConsolidationAccountItems]);
    const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
    const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
    const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
    const relatedCompany = _ => relatedCompaniesById[_.subsidiaryId || company.id];
    const accountItem = _ => accountItemsById[_.accountItemId];
    const consolidationAccountItem = _ => consolidationAccountItemsById[get(accountItem(_), 'consolidationAccountItemId')];
    const currency = _ => get(relatedCompany(_), 'currency', 'jpy');
    const { items: companiesAmountItems, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true);
    useEffect(() => {
      activeScreen === 'relatedCompanyBalances' && !canAccess && history.replace(`/companies/${company.id}/pkg/${keys(accessableScreens)[0]}${location.search}`);
    }, []);

    return canAccess ? (
      <div className="pkg-page h-100 container-fluid">
        <div className="p-4 bg-white">
          <props.Translate>
            <div className="d-flex justify-content-end mb-3">
              <HelpLink text="グループ全社の連結PKGを確認する" />
            </div>
            <Nav tabs className="mt-4 mb-5">
              {
                entries(accessableScreens).map(([name, { label, isActive, path, }]) => {
                  return (
                    <NavItem key={name}>
                      <Link className={classnames('nav-link cursor-pointer', { active: isActive(location) })} to={`/companies/${companyId}/pkg${path}${location.search}`}>
                        {label}
                      </Link>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </props.Translate>
          <WrappedComponent {...props} {...{ subsidiaries, relatedCompanies, relatedCompaniesById, exchangeRate, prevExchangeRate, accountItems, accountItemsById, consolidationAccountItems: sortedConsolidationAccountItems, consolidationAccountItemsById, relatedCompany, accountItem, consolidationAccountItem, currency, allConsolidationJournalTypes, companiesAmountItems, }} />
        </div>
      </div>
    ): (
      <div className="container">
        <div className="alert alert-danger mt-3">権限がありません</div>
      </div>
    );
  });
};
