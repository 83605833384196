import React, { Fragment, Component, useEffect, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { get, mapValues, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { format as formatDate, addMonths, } from 'date-fns';

import { numberFormat, integerFormat, } from '../../util';
import firebase, { functions } from '../../firebase';
import { isTrialNetIncome0, } from '../../shared/models/trial';
import { accountItemCategories, currencies, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useSingleAmounts from '../hooks/useSingleAmounts';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import ExchangedItemFormModal from '../modals/ExchangedItemFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';

const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const bsAccountItemCategories = accountItemCategories.filter(_ => _.type === 'bs');

export default RelatedCompanyPage(function CompanyExchangeDiffs (props) {
  const { relatedCompany, company, subsidiaryId, period, yearMonth, isParent, yearMonths, prevYearMonths, ar, } = props;
  const isCurrentPeriodJoin = relatedCompany.joinPeriod === period && yearMonth >= relatedCompany.joinYearMonth;
  const isCurrentPeriodExcept = relatedCompany.exceptPeriod === period && yearMonth >= relatedCompany.exceptYearMonth;
  const joinYearMonth = relatedCompany.isStartJoin ? (parseInt(relatedCompany.joinYearMonth, 10) - 1).toString() : relatedCompany.joinYearMonth;
  const startYearMonths = useMemo(_ => (
    isCurrentPeriodJoin ? (
      relatedCompany.isStartJoin && period === relatedCompany.joinYearMonth ? (
        prevYearMonths
      ) : (
        yearMonths.filter(_ => formatDate(_, 'yyyyMM') <= joinYearMonth)
      )
    ) : prevYearMonths
  ), [relatedCompany, isCurrentPeriodJoin && yearMonths, !isCurrentPeriodJoin && prevYearMonths]);
  const endYearMonths = useMemo(_ => yearMonths.filter(_ => formatDate(_, 'yyyyMM') <= (isCurrentPeriodExcept ? relatedCompany.exceptYearMonth : yearMonth)), [isCurrentPeriodExcept, yearMonths, yearMonth]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const consolidationAccountItemsGroupedByCategory = groupBy(consolidationAccountItems, 'account_category');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const { items: startItems } = useSingleAmounts(company, relatedCompany, subsidiaryId, consolidationAccountItems, accountItems, startYearMonths);
  const { items: endItems } = useSingleAmounts(company, relatedCompany, subsidiaryId, consolidationAccountItems, accountItems, endYearMonths, true);
  const { consolidationAccountItem: startConsolidationItemItems = [], category: startCategoryItems = [] } = groupBy(startItems, 'itemType');
  const startItemsByItemName = keyBy(startConsolidationItemItems, 'item.name');
  const startCategoryItemsByCategory = keyBy(startCategoryItems, 'accountItemCategory.name');
  const { consolidationAccountItem: endConsolidationItemItems = [], category: endCategoryItems = [] } = groupBy(endItems, 'itemType');
  const endItemsByItemName = keyBy(endConsolidationItemItems, 'item.name');
  const endCategoryItemsByCategory = keyBy(endCategoryItems, 'accountItemCategory.name');
  const currencyLabel = get(currencies, [relatedCompany.currency, 'label'], '');

  return props.translate(
    <div className="company-exchange-diffs">
      <div>
        <table className="table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th rowSpan={2} style={{ minWidth: 180 }}>連結科目</th>
              <th colSpan={2}>{isCurrentPeriodJoin ? '連結対象になった時点' : '前期末'}</th>
              <th colSpan={2}>{isCurrentPeriodExcept ? '連結除外になった時点' : '当期末'}</th>
              <th colSpan={2}>増減</th>
              <th colSpan={2}>円貨の増減の内訳</th>
              <th rowSpan={2}>CF精算表調整額</th>
            </tr>
            <tr>
              <th>{currencyLabel}</th>
              <th>円</th>
              <th>{currencyLabel}</th>
              <th>円</th>
              <th>{currencyLabel}</th>
              <th>円</th>
              <th>ARによる換算</th>
              <th>Rateの変動による影響</th>
            </tr>
          </thead>
          <tbody>
            {
              bsAccountItemCategories.map((category) => {
                const consolidationAccountItems = consolidationAccountItemsGroupedByCategory[category.name] || [];
                const sign = ({ debit: 1, credit: -1 })[category.direction];
                const rows = [...consolidationAccountItems.map(_ => ({ ..._, type: 'consolidationAccountItem' })), { ...category, type: 'category' }].map((subject) => {
                  const startItem = ({ consolidationAccountItem: startItemsByItemName, category: startCategoryItemsByCategory })[subject.type][subject.name];
                  const item = ({ consolidationAccountItem: endItemsByItemName, category: endCategoryItemsByCategory })[subject.type][subject.name];
                  return {
                    subject,
                    startItem,
                    item,
                    change: get(item, 'adjustedAmount', 0) - get(startItem, 'adjustedAmount', 0),
                    exchangedChange: get(item, 'exchangedAmount', 0) - get(startItem, 'exchangedAmount', 0),
                    arExchangedChange: company.usesMonthlyAr ? (
                      get(item, 'totalIssuedExchangedAmount', 0)
                    ) : (
                      (item?.adjustedAmount - startItem?.adjustedAmount) * (item?.monthGroup.exchangeRate?.[`${relatedCompany.currency}-ar`] || 0)
                    ),
                  };
                })
                  .filter(_ => abs(get(_.startItem, 'adjustedAmount')) > 0 || abs(get(_.item, 'adjustedAmount')) > 0);

                return (
                  <Fragment key={category.name}>
                    {
                      rows.map(({ subject, startItem, item, change, exchangedChange, arExchangedChange, }, i) => {

                        return (
                          <tr key={i} className={subject.type === 'category' && 'font-weight-bold'}>
                            <td style={{ textIndent: (category.indent() + (subject.type === 'category' ? 0 : 1)) * 18 }}>
                              {subject.name}
                            </td>
                            <td className="text-right">
                              {numberFormat(relatedCompany.currency, get(startItem, 'adjustedAmount'))}
                            </td>
                            <td className="text-right">
                              {integerFormat(get(startItem, 'exchangedAmount'))}
                            </td>
                            <td className="text-right">
                              {numberFormat(relatedCompany.currency, get(item, 'adjustedAmount'))}
                            </td>
                            <td className="text-right">
                              {integerFormat(get(item, 'exchangedAmount'))}
                            </td>
                            <td className="text-right">
                              {numberFormat(relatedCompany.currency, change)}
                            </td>
                            <td className="text-right">
                              {integerFormat(exchangedChange)}
                            </td>
                            <td className="text-right">
                              {integerFormat(arExchangedChange)}
                            </td>
                            <td className="text-right">
                              {integerFormat(exchangedChange - arExchangedChange)}
                            </td>
                            <td className="text-right">
                              {integerFormat(-(exchangedChange - arExchangedChange) * sign)}
                            </td>
                          </tr>
                        );
                      })
                    }
                 </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

