const { keyBy, sortBy, orderBy, sumBy, groupBy, isEqual, uniq, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategories, } = require('../config');
const { inheritTypes, } = require('../models/consolidationJournal');

const generateRowGroups = (sortedConsolidationJournals, relatedCompanies, multicombinations) => {
  return sortedConsolidationJournals;
};

const rowsForExport = (company, consolidationJournals, relatedCompanies, multicombinations, allConsolidationSegments, allConsolidationAccountItems, consolidationJournalTypesById) => () => {
  const companiesById = keyBy(relatedCompanies, 'id');
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  return generateRowGroups(consolidationJournals, relatedCompanies, multicombinations).flatMap((consolidationJournal) => {
    const { id, items, type, isOpening = false, inheritType = 'none', description, } = consolidationJournal;
    return (items || []).map((item, i) => {
      const { debitCompanyId, debitSegmentId, debitItemId, debitAmount, debitIsIntersegment = false, creditCompanyId, creditSegmentId, creditItemId, creditAmount, creditIsIntersegment = false, } = item;
      return {
        delimiter: i === 0 ? '*' : null,
        type: i === 0 ? consolidationJournalTypesById[type]?.name : null,
        isOpening: i === 0 ? isOpening : null,
        inheritType: i === 0 ? inheritTypes[inheritType]?.label : null,
        debitCompanyName: companiesById[debitCompanyId]?.display_name,
        debitSegmentName: consolidationSegmentsById[debitSegmentId]?.name,
        ...(company.externalType === 'bugyo' && { debitAccountItemCode: consolidationAccountItemsById[debitItemId]?.shortcut_num }),
        debitAccountItemName: consolidationAccountItemsById[debitItemId]?.name,
        debitAmount,
        debitIsIntersegment,
        creditCompanyName: companiesById[creditCompanyId]?.display_name,
        creditSegmentName: consolidationSegmentsById[creditSegmentId]?.name,
        ...(company.externalType === 'bugyo' && { creditAccountItemCode: consolidationAccountItemsById[creditItemId]?.shortcut_num }),
        creditAccountItemName: consolidationAccountItemsById[creditItemId]?.name,
        creditAmount,
        creditIsIntersegment,
        description: i === 0 ? description : null,
      };
    });
  });
};

const computeAlerts = (company, consolidationJournals) => {
  return [
    company.usesApprovement && consolidationJournals.some(_ => _.approvedAt == null) && `未承認の仕訳があります`,
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
