const { isEmpty, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const itemFields = () => {
  return {
    content: {
      label: '内訳',
      type: 'string',
      validations: {
        required: v => v != null,
      },
    },
    amount: {
      label: '金額',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
  };
};

const fields = ({ accountItems = [] } = {}) => {
  return {
    accountItemId: {
      label: '個別勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
    note: {
      label: '備考',
      type: 'text',
    }
  };
};

exports.fields = fields;
exports.itemFields = itemFields;
