import React, { useState, useEffect, useMemo, } from 'react';
import { sortBy, keyBy, range, inRange, get, pick, last, } from 'lodash';
import { useHistory, useParams, useLocation, } from 'react-router';
import { Nav, NavItem, } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import qs from 'qs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { getRates, } from '../../shared/models/exchangeRate';
import { accountItemCategoryNames, } from '../../shared/config';
import firebase from '../../firebase';
import CompanyPage from './CompanyPage';
import HelpLink from '../HelpLink';
import CompanySyncButton from '../CompanySyncButton';
import useCompanySelector from '../hooks/useCompanySelector';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import QuerySelector from '../QuerySelector';

const auth = firebase.auth();
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const tabs = [
  { name: 'general', label: '一般', path: '', isActive: _ => _.pathname.endsWith('settings') },
  { name: 'integration', label: '連携', path: '/integration', isActive: _ => _.pathname.match(/integration/), filter: c => c.usesWizLabo || c.usesPronexus, },
  { name: 'members', label: 'メンバー', path: '/members', isActive: _ => _.pathname.match(/members/) },
  { name: 'monthLocks', label: '月次ロック', path: '/monthLocks', isActive: _ => _.pathname.match(/monthLocks/) },
];

export default function SettingsPageHOC(WrappedComponent) {
  return CompanyPage(function SettingsPage (props) {
    const { Translate, user, company, location, history, match: { params: { companyId, } }, } = props;

    return (user.admin || ['owner', 'admin'].includes(company.users?.[user.id].role)) && (
      <div className="settings-page h-100 container-fluid">
        <div className="p-4 bg-white">
          <Translate>
            <Nav tabs className="mt-4 mb-4">
              {
                tabs.filter(_ => _.filter ? _.filter(company) : true).map(({ label, name, isActive, path, }) => {
                  return (
                    <NavItem key={name}>
                      <Link className={classnames('nav-link cursor-pointer', { active: isActive(location) })} to={`/companies/${companyId}/settings${path}${location.search}`}>
                        {label}
                      </Link>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </Translate>
          <WrappedComponent {...props}  />
        </div>
      </div>
    );
  });
};
