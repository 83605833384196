import React, { useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { isEmail } from 'validator';
import copy from 'copy-to-clipboard';
import { sortBy, pick, omit, get, } from 'lodash';
import Select from 'react-select';
import { useAsync } from 'react-use';

import firebase, { functions } from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import SettingsPage from '../hocs/SettingsPage';
import HelpLink from '../HelpLink';
import { roles } from '../../shared/models/user';

const db = firebase.firestore();
const { entries } = Object;

export default SettingsPage(function CompanyMonthLocks (props) {
  const { period, company, monthOptions, periodSetting, } = props;
  const periodSettingRef = company.ref.collection('periodSettings').doc(period);

  return props.translate(
    <div className="company-month-locks">
      <div className="d-flex justify-content-end mb-3">
        {/*<HelpLink text="メンバー管理" />*/}
      </div>
      <div className="d-flex flex-column gap-3">
        {
          monthOptions.map((monthOption) => {
            const { label, value } = monthOption;
            const isLocked = periodSetting?.locks?.[value];
            const onClickLockButton = async (locks) => {
              const label = locks ? 'ロック' : 'ロック解除';
              if(!window.confirm(`${label}しますか？`)) return;

              await periodSettingRef.set({
                locks: {
                  [value]: locks,
                },
              }, { merge: true });
              toast.success(`${label}しました`);
            };

            return (
              <div className="d-flex align-items-center gap-3" key={value}>
                <div>{label}</div>
                <div>
                  {
                    isLocked ? (
                      <div>
                        <Button color="warning" onClick={onClickLockButton.bind(null, false)}>
                          ロック解除する
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button color="primary" onClick={onClickLockButton.bind(null, true)}>
                          ロックする
                        </Button>
                      </div>
                    )
                  }
                </div>
                <div style={{ minWidth: 50 }}>
                  {
                    isLocked && (
                      <div className="text-success">
                        ロック済み
                      </div>
                    )
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
});
