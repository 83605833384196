import React, { Fragment, Component, useMemo, } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { getCategory, } from '../../shared/models/accountItem';
import { changeFieldNames, fields, } from '../../shared/models/fixedAsset';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import PkgChangesTableSection from '../PkgChangesTableSection';
import PkgSimpleTableSection from '../PkgSimpleTableSection';

const { keys } = Object;

export default PkgPage(function CompanyPkgFixedAssets (props) {
  const { company, exchangeRate, period, yearMonth, relatedCompanies, relatedCompaniesById, accountItemsById, consolidationAccountItemsById, location, } = props;

  return props.translate(
    <div className="company-pkg-fixed-assets">
      <PkgChangesTableSection
        section="固定資産"
        title="増減"
        collectionName="fixedAssets"
        path="fixedAssets"
        fields={fields}
        changeFieldNames={changeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgSimpleTableSection
        section="固定資産"
        title="取得または売却にかかる債権債務・売却損益"
        collectionName="fixedAssetRelatedItems"
        path="fixedAssets"
        {...props}
      />
    </div>
  );
});
