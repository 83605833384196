const { keyBy, sortBy, omit, } = require('lodash');

const { floatFormat } = require('../util');
const { currencies } = require('../config');

const generateRows = (exchangeRate) => {
  return Object.entries(omit(currencies, 'jpy')).map(([currency, { label }]) => {
    const [ar, mar, cr] = ['ar', 'mar', 'cr'].map(_ => exchangeRate?.[[currency, _].join('-')]);
    return {
      currency,
      label,
      ar,
      mar,
      cr,
    };
  }).filter(_ => [_.ar, _.mar, _.cr].some(_ => _ > 0));
};

const rowsForExport = (...args) => () => {
  return generateRows(...args);
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
