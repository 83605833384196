import React, { Component, useEffect, useMemo, Fragment, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { zipWith, uniq, orderBy, get, sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, pickBy, countBy, } from 'lodash';
import { Input, Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';
import numeral from 'numeral';
import moji from 'moji';
import dedent from 'dedent';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip'

import { integerFormat } from '../../util';
import firebase, { functions } from '../../firebase';
import { getCollectionData, getDocumentData, } from '../../shared/firebase';
import { generateRowGroups, rowsForExport, computeAlerts, computeJoinOrExceptRelatedCompaniesAmounts, } from '../../shared/lib/cfWs';
import { computeCfMapping, } from '../../shared/lib/cfMapping';
import { cfMappingPresetConsolidationAccountItems, computeCompaniesAmounts, } from '../../shared/models/consolidationAccountItem';
import { getCategory, } from '../../shared/models/accountItem';
import { presetCfAccontItems, } from '../../shared/models/cfAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { isTrialNetIncome0, } from '../../shared/models/trial';
import { joinAndExceptMappingFields, } from '../../shared/models/cfChange';
import { accountItemCategories, accountItemCategoryNames, cfAccountItemCategories, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useCompaniesAmounts from '../hooks/useCompaniesAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import CompanySyncButton from '../CompanySyncButton';
import ProgressButton from '../ProgressButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import Field from '../Field';
import ExportButton from '../ExportButton';
import ContentEditable from '../ContentEditable';
import QueryBoolean from '../QueryBoolean';
import WithLoading from '../WithLoading';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const { abs, round } = Math;
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');

export default CompanyPage(function CompanyCfWs (props) {
  const { company, role, history, location, prevEndYearMonth, isLockedMonth, filteredYearMonths, prevYearMonths, } = props;
  const { period, yearMonth, showsAll = '0', } = useQueryParams();
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'), [company]);
  const relatedCompanies = useMemo(() => [company, ...subsidiaries], [company, subsidiaries]);
  const abroadRelatedCompanies = useMemo(_ => relatedCompanies.filter(_ => (_.currency || 'jpy') !== 'jpy'), [relatedCompanies]);
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const periodSettingRef = company.ref.collection('periodSettings').doc(period);
  const periodSetting = useDocumentSubscription(periodSettingRef, [period]);
  const prevPeriodAmounts = useCollectionSubscription(company.ref.collection('prevPeriodAmounts').where('period', '==', period), [period]);
  const cfChangeTypes = useCollectionSubscription(company.ref.collection('cfChangeTypes'), [company]);
  const cfAccountItems = useCollectionSubscription(company.ref.collection('cfAccountItems').orderBy('name'), [company]);
  const allCfAccountItems = useMemo(_ => [...cfAccountItems, ...presetCfAccontItems], [cfAccountItems]);
  const sortedCfAccountItems = useMemo(_ => orderBy(allCfAccountItems, [_ => cfAccountItemCategoryKeys.indexOf(_.cfAccountItemCategoryKey), 'index'], ['asc', 'asc']), [cfAccountItems]);
  const cfAccountItemsById = useMemo(_ => keyBy(sortedCfAccountItems, 'id'), [sortedCfAccountItems]);
  const cfAccountItemOptions = useMemo(_ => sortedCfAccountItems.map(_ => ({ label: _.name, value: _.id })), [sortedCfAccountItems]);
  const cfChangeRef = company.ref.collection('cfChanges').doc(yearMonth);
  const cfChange = useDocumentSubscription(cfChangeRef, [yearMonth]);
  const filteredCfChangeTypes = useMemo(_ => cfChangeTypes.filter(_ => (cfChange?.cfChangeTypeIds || []).includes(_.id) || !_.disuses), [cfChange, cfChangeTypes]);
  const sortedCfChangeTypes = useMemo(_ => sortBy(filteredCfChangeTypes, 'index'), [filteredCfChangeTypes]);
  const presetConsolidationAccountItemSettings = useCollectionSubscription(company.ref.collection('presetConsolidationAccountItemSettings'), [company], { initialItems: null });
  const presetConsolidationAccountItemSettingsById = keyBy(presetConsolidationAccountItemSettings, 'id');
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const sortedConsolidationAccountItems = useMemo(_ => sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
  const allConsolidationAccountItems = useMemo(_ =>  [...sortedConsolidationAccountItems, ...cfMappingPresetConsolidationAccountItems.map(_ => ({ ..._, ...presetConsolidationAccountItemSettingsById[_.id], }))], [sortedConsolidationAccountItems]);
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const consolidationAccountItemsGroupedByCategory = groupBy(allConsolidationAccountItems, 'account_category');
  const { value: accountItems, loading: isLoadingAccountItems } = useAsync(async () => await getCollectionData(company.ref.collection('accountItems')), [company]);
  const accountItemsById = keyBy(accountItems, 'id');
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const consolidationJournalTypesById = keyBy(allConsolidationJournalTypes, 'id');

  const prevExchangeRate = useDocumentSubscription(prevEndYearMonth && company.ref.collection('exchangeRates').doc(prevEndYearMonth), [prevEndYearMonth]);
  const exchangeRate = useDocumentSubscription(company.ref.collection('exchangeRates').doc(yearMonth), [yearMonth]);

  const { items: prevItems, isLoading: isLoadingPrevItems, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevYearMonths, true, prevPeriodAmounts, periodSetting?.usesPrevPeriodAmounts);
  const { items, isLoading: isLoadingItems, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true);
  const joinedRelatedCompanies = useMemo(_ => relatedCompanies.filter(_ => _.joinPeriod === period && yearMonth >= _.joinYearMonth), [relatedCompanies, period, yearMonth])
  const exceptedRelatedCompanies = useMemo(_ => relatedCompanies.filter(_ => _.exceptPeriod === period && yearMonth >= _.exceptYearMonth), [relatedCompanies, period, yearMonth]);
  const { value: joinItems, loading: isLoadingJoinItems, error } = useAsync(async () => {
    if(isLoadingAccountItems) return [];

    return await computeJoinOrExceptRelatedCompaniesAmounts(company, joinedRelatedCompanies, 'joinYearMonth', 'isStartJoin', allConsolidationAccountItems, accountItems);
  }, [joinedRelatedCompanies, isLoadingAccountItems]);
  const { value: exceptItems, loading: isLoadingExceptItems } = useAsync(async () => {
    if(isLoadingAccountItems) return [];

    return await computeJoinOrExceptRelatedCompaniesAmounts(company, exceptedRelatedCompanies, 'exceptYearMonth', 'isStartExcept', allConsolidationAccountItems, accountItems);
  }, [exceptedRelatedCompanies, isLoadingAccountItems]);

  const rowGroups = useMemo(() => {
    if(isLoadingPrevItems || isLoadingItems || isLoadingAccountItems || !joinItems || isLoadingJoinItems || !exceptItems || isLoadingExceptItems) return;
    
    return generateRowGroups(company, relatedCompanies, period, items, prevItems, joinItems, exceptItems, sortedCfChangeTypes, cfChange, showsAll === '1', yearMonth);
  }, [isLoadingPrevItems, isLoadingItems, isLoadingAccountItems, joinItems, isLoadingJoinItems, exceptItems, isLoadingExceptItems, cfChange, showsAll, periodSetting]);
  const allRows = rowGroups?.flatMap(rowGroup => rowGroup.rows.map(_ => ({ ..._, category: rowGroup.category })));
  const bsRows = allRows?.filter(_ => _.category.type === 'bs');
  const alerts = computeAlerts(items, sortedCfChangeTypes, cfChange);
  const onClickCfMapping = async () => {
    if(!window.confirm('CFマッピング計算します。よろしいですか？')) return;

    const data = (
      await computeCfMapping(allConsolidationAccountItems, allConsolidationJournalTypes, company, relatedCompanies, filteredYearMonths, prevYearMonths, accountItems, cfAccountItemsById, prevExchangeRate, exchangeRate, allRows)
    ).filter(_ => isFinite(_.amount) && Math.abs(_.amount) > 0);

    await cfChange.ref.update({
      values: {
        ...cfChange.values,
        ...data.filter(_ => Math.abs(cfChange.values[_.key]?.amount ?? 0) === 0).reduce((x, y) => ({ ...x, [y.key]: omit(y, ['key', 'consolidationAccountItemId']), }), {}),
      },
    });
    toast.success('CFマッピング計算しました');
    const conflictedItems = keys(pickBy(countBy(data, 'key'), _ => _ > 1)).map(key => data.filter(_ => _.key  === key));
    if(conflictedItems.length > 0) {
      toast.warn(dedent`
        同一のセル内に複数のマッピングがありました。

        [該当の連結科目]
        ${uniq(conflictedItems.flatMap(_ => _.map(_ => consolidationAccountItemsById[_.consolidationAccountItemId]?.name))).filter(_ => _).join('\n')}
      `);
    }
  };
  const onClickCfUnmapping = async () => {
    if(!window.confirm('CFマッピング計算対象をクリアします。よろしいですか？（マッピング計算対象に手入力で数値等を入力したものについてもクリアします）')) return;

    const data = await computeCfMapping(allConsolidationAccountItems, allConsolidationJournalTypes, company, relatedCompanies, filteredYearMonths, prevYearMonths, accountItems, cfAccountItemsById, prevExchangeRate, exchangeRate, allRows);
    const keys = data.map(_ => _.key);
    await cfChange.ref.update({
      values: {
        ...omit(cfChange.values, keys),
      },
    });
    toast.success('CFマッピング計算対象をクリアしました');
  };
  const onSubmitRelatedCompanyMapping = async (index, columnType, { cfChangeTypeId, cfAccountItemId }, { onClickClose }) => {
    if(!window.confirm('本当にマッピング計算しますか？')) return;

    const data = rowGroups
      .filter(_ => _.category.type === 'bs' || _.category.name === '当期純損益金額')
      .flatMap(_ => _.rows.map(_ => ({ ..._[`${columnType}Columns`][index], rowSign: _.sign, })))
      .reduce((x, { key: itemKey, exchangedAmount, rowSign, }) => {
        const key = [itemKey, cfChangeTypeId].join('__');
        return {
          ...x,
          [key]: {
            amount: exchangedAmount * rowSign * ({ join: -1, except: 1 })[columnType],
            cfAccountItemId,
            itemKey,
            cfChangeTypeId,
            dataType: 'auto',
          },
        }
      }, {});
    await cfChange.ref.update({
      values: {
        ...cfChange.values,
        ...data,
      },
    });
    toast.success('マッピング計算しました');
    onClickClose();
  };
  useEffect(() => {
    if(cfChange === null) {
      cfChangeRef.set({ values: {}, createdAt: new Date(), });
    }
  }, [cfChange]);

  return props.translate(
    <div className="company-cfws container-fluid">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結CFWSを作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結CFWS</h4>
        </div>
        <WithLoading loading={!rowGroups}>
          <Alerts alerts={alerts} />
          <div className="mb-3 d-flex justify-content-start align-items-end gap-1">
            <ProgressButton color="primary" process={onClickCfMapping} disabled={isLockedMonth}>
              <span className="fas fa-calculator mr-1" />CFマッピング計算
            </ProgressButton>
            <ProgressButton process={onClickCfUnmapping} disabled={isLockedMonth} color="danger">
              <span className="fas fa-eraser mr-1" />CFマッピング計算対象をクリアする
            </ProgressButton>
            <ExportButton fileName="連結CFWS.csv" rows={rowsForExport(company, relatedCompanies, period, items, prevItems, joinItems, exceptItems, sortedCfChangeTypes, cfChange, sortedCfAccountItems, showsAll === '1', yearMonth)} />
            <QueryBoolean paramName="showsAll" label="金額なしの行も表示" defaultValue={'0'} />
            {periodSetting !== undefined && <Field type="boolean" label="前期数値を使用する" value={periodSetting?.usesPrevPeriodAmounts || false} setValue={_ => periodSettingRef.set({ usesPrevPeriodAmounts: _ }, { merge: true })} formGroupProps={{ className: 'm-0', }} readOnly={_ => isLockedMonth || ['reader'].includes(role)} />}
          </div>
          <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
            <table className="table table-bordered sticky-table m-0">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 180 }} className="sticky" rowSpan={2}>連結科目</th>
                  <th rowSpan={2}>前期末</th>
                  {joinedRelatedCompanies.length > 0 && <th colSpan={joinedRelatedCompanies.length}>連結対象になった時点(C)</th>}
                  {exceptedRelatedCompanies.length > 0 && <th colSpan={exceptedRelatedCompanies.length}>連結除外になった時点(D)</th>}
                  <th rowSpan={2}>当期末</th>
                  <th rowSpan={2}>当期末-前期末{joinedRelatedCompanies.length > 0 ? '-C' : ''}{exceptedRelatedCompanies.length > 0 ? '+D' : ''}(A)</th>
                  {abroadRelatedCompanies.length > 0 && <th colSpan={abroadRelatedCompanies.length}>換算差額 (B)</th>}
                  <th rowSpan={2}>A + B</th>
                  {
                    sortedCfChangeTypes.map(_ => (
                      <th key={_.id} style={{ minWidth: 200 }} rowSpan={2}>
                        {_.name}
                      </th>
                    ))
                  }
                  <th rowSpan={2} className="sticky sticky-right">合計{joinedRelatedCompanies.length > 0 ? '+C' : ''}{exceptedRelatedCompanies.length > 0 ? '-D' : ''}</th>
                </tr>
                <tr>
                  {
                    joinedRelatedCompanies.map((company, i) => (
                      <th key={company.id} style={{ minWidth: 200 }}>
                        {company.display_name}
                        <ModalButton className="ml-1" color="primary" size="sm" label={<span className="fas fa-calculator" />} Modal={ModelFormModal} modalProps={{ fields: joinAndExceptMappingFields({ cfChangeTypes: sortedCfChangeTypes, cfAccountItems: sortedCfAccountItems, }), title: '連結対象になった会社のマッピング計算', onSubmit: onSubmitRelatedCompanyMapping.bind(null, i, 'join'), submitLabel: 'マッピング計算する', }} disabled={isLockedMonth} />
                      </th>
                    ))
                  }
                  {
                    exceptedRelatedCompanies.map((company, i) => (
                      <th key={company.id} style={{ minWidth: 200 }}>
                        {company.display_name}
                        <ModalButton className="ml-1" color="primary" size="sm" label={<span className="fas fa-calculator" />} Modal={ModelFormModal} modalProps={{ fields: joinAndExceptMappingFields({ cfChangeTypes: sortedCfChangeTypes, cfAccountItems: sortedCfAccountItems, }), title: '連結除外になった会社のマッピング計算', onSubmit: onSubmitRelatedCompanyMapping.bind(null, i, 'except'), submitLabel: 'マッピング計算する', }} disabled={isLockedMonth} />
                      </th>
                    ))
                  }
                  {
                    abroadRelatedCompanies.map((company) => (
                      <th key={company.id} style={{ minWidth: 200 }}>
                        {company.display_name}
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody className="thead-light">
                {
                  rowGroups?.filter(_ => _.category.type === 'bs').map(({ category, rows }) => {
                    return (
                      <Fragment key={category.name}>
                        {
                          rows.map(({ itemType, itemKey, itemName, conclusiveAmount, prevConclusiveAmount, conclusiveChange, exchangedChange, joinColumns, exceptColumns, exchangeDiffColumns, diffWithoutExchange, cfChangeColumns, sign, }) => {
                            return (
                              <tr key={itemKey} className={classnames({ 'font-weight-bold': itemType === 'category' })}>
                                <th className="sticky" style={{ textIndent: false && (category.indent() + (itemType === 'consolidationAccountItem' ? 1 : 0)) * 18 }}>
                                  {itemName}
                                </th>
                                <td className="text-right">
                                  {integerFormat(prevConclusiveAmount * sign)}
                                </td>
                                {
                                  joinColumns.map(({ relatedCompany, exchangedAmount, }) => {
                                    return (
                                      <td className="text-right" key={relatedCompany?.id}>
                                        {integerFormat(exchangedAmount * sign)}
                                      </td>
                                    );
                                  })
                                }
                                {
                                  exceptColumns.map(({ relatedCompany, exchangedAmount, }) => {
                                    return (
                                      <td className="text-right" key={relatedCompany?.id}>
                                        {integerFormat(exchangedAmount * sign)}
                                      </td>
                                    );
                                  })
                                }
                                <td className="text-right">
                                  {integerFormat(conclusiveAmount * sign)}
                                </td>
                                <td className="text-right">
                                  {integerFormat(conclusiveChange * sign)}
                                </td>
                                {
                                  exchangeDiffColumns.map(({ relatedCompany, exchangeDiff }) => {
                                    return (
                                      <td className="text-right" key={relatedCompany.id}>
                                        {integerFormat(-exchangeDiff * sign)}
                                      </td>
                                    );
                                  })
                                }
                                <td className="text-right">
                                  {integerFormat(diffWithoutExchange * sign)}
                                </td>
                                {
                                  cfChangeColumns.map((cfChangeColumn) => {
                                    const { cfChangeType, amount, cfAccountItemId, } = cfChangeColumn;
                                    const key = [itemKey, cfChangeType.id].join('__');
                                    const updateValue = async (fieldName, value) => {
                                      await cfChange.ref.update({
                                        values: {
                                          ...cfChange.values,
                                          [key]: {
                                            ...cfChange.values?.[key],
                                            [fieldName]: value,
                                            itemKey,
                                            cfChangeTypeId: cfChangeType.id,
                                            dataType: 'manual',
                                          },
                                        },
                                      });
                                    };
                                    const onFix = async (value) => {
                                      await updateValue('amount', numeral(integerFormat(moji(value).convert('ZE', 'HE').toString())).value());
                                    };

                                    return (
                                      <td key={cfChangeType.id} className="text-right position-relative">
                                        <ContentEditable value={integerFormat(amount)} onFix={onFix} disabled={isLockedMonth || ['reader'].includes(role)} />
                                        {
                                          Math.abs(amount) > 0 && (
                                            <Select
                                              value={cfAccountItemOptions.find(_ => _.value === cfAccountItemId) || null}
                                              onChange={_ => _ && updateValue('cfAccountItemId', _.value)}
                                              options={cfAccountItemOptions}
                                              className="text-left"
                                              isDisabled={isLockedMonth || ['reader'].includes(role)}
                                              isClearable
                                              components={{ IndicatorsContainer: _ => null }}
                                            />
                                          )
                                        }
                                        {
                                          Math.abs(amount) > 0 && cfChange.values?.[key]?.dataType === 'manual' && (
                                            <div className="position-absolute small" style={{ bottom: -1, right: -1, }}>
                                              <span className="fas fa-user-edit text-info" data-tooltip-id={`tooltip-${key}`} data-tooltip-content="手入力で変更" />
                                              <Tooltip id={`tooltip-${key}`} />
                                            </div>
                                          )
                                        }
                                      </td>
                                    );
                                  })
                                }
                                <th className="text-right sticky sticky-right">
                                  {integerFormat(diffWithoutExchange * sign + sumBy(cfChangeColumns, _ => _.amount || 0) + sumBy(joinColumns, _ => _.exchangedAmount * sign) - sumBy(exceptColumns, _ => _.exchangedAmount * sign))}
                                </th>
                              </tr>
                            );
                          })
                        }
                      </Fragment>
                    );
                  })
                }
                <tr className="font-weight-bold">
                  <th className="sticky">
                    合計
                  </th>
                  <th className="text-right">
                    {integerFormat(sumBy(bsRows, _ => _.prevConclusiveAmount * _.sign))}
                  </th>
                  {
                    joinedRelatedCompanies.map((company, i) => (
                      <th className="text-right" key={company?.id}>
                        {integerFormat(sumBy(bsRows, _ => _.joinColumns[i]?.exchangedAmount * _.sign))}
                      </th>
                    ))
                  }
                  {
                    exceptedRelatedCompanies.map((company, i) => (
                      <th className="text-right" key={company?.id}>
                        {integerFormat(sumBy(bsRows, _ => _.exceptColumns[i]?.exchangedAmount * _.sign))}
                      </th>
                    ))
                  }
                  <th className="text-right">
                    {integerFormat(sumBy(bsRows, _ => _.conclusiveAmount * _.sign))}
                  </th>
                  <th className="text-right">
                    {integerFormat(sumBy(bsRows, _ => _.conclusiveChange * _.sign))}
                  </th>
                  {
                    abroadRelatedCompanies.map((company, i) => {
                      return (
                        <th className="text-right" key={company?.id}>
                          {integerFormat(sumBy(bsRows, _ => -_.exchangeDiffColumns[i].exchangeDiff * _.sign))}
                        </th>
                      );
                    })
                  }
                  <th className="text-right">
                    {integerFormat(sumBy(bsRows, _ => _.diffWithoutExchange * _.sign))}
                  </th>
                  {
                    sortedCfChangeTypes.map((cfChangeType, i) => {
                      const amount = sumBy(bsRows, _ => _.cfChangeColumns[i].amount);
                      return (
                        <th key={cfChangeType.id} className="text-right">
                          {integerFormat(amount)}
                        </th>
                      );
                    })
                  }
                  <th className="text-right sticky sticky-right">
                    {integerFormat(sumBy(bsRows, _ => _.diffWithoutExchange * _.sign + sumBy(_.cfChangeColumns, _ => _.amount || 0)))}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </WithLoading>
      </div>
    </div>
  );
});
