import React, { useState, useEffect } from 'react';
import { keyBy, omit, range, inRange, get, pick, last, } from 'lodash';
import { useHistory, useParams, useLocation, } from 'react-router';
import { useSessionStorage, } from 'react-use';
import { Nav, NavItem, } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import qs from 'qs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { getRates, } from '../../shared/models/exchangeRate';
import { pcaCompanyFields, } from '../../shared/models/company';
import { pcaDataCenters, } from '../../shared/config';
import firebase, { functions } from '../../firebase';
import CompanyPage from './CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import ProgressButton from '../ProgressButton';
import ModalButton from '../ModalButton';
import useCompanySelector from '../hooks/useCompanySelector';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import QuerySelector from '../QuerySelector';

const { keys, entries, } = Object;
const auth = firebase.auth();
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const getBugyoAuthUrl = functions.httpsCallable('getBugyoAuthUrl');
const getPcaAuthUrl = functions.httpsCallable('getPcaAuthUrl');
const tabs = [
  { name: 'settings', label: '設定', path: '/settings', isActive: _ => _.pathname.match(/settings/) },
  { name: 'accountItemMappings', label: '科目マッピング', path: '/accountItemMappings', isActive: _ => _.pathname.match(/accountItemMappings/) },
  { name: 'sectionMappings', label: '部門マッピング', path: '/sectionMappings', isActive: _ => _.pathname.match(/sectionMappings/) },
  { name: 'individualTrials', label: '試算表', path: '/individualTrials', isActive: _ => _.pathname.match(/individualTrials/) },
  { name: 'sectionTrials', label: '部門別試算表', path: '/sectionTrials', isActive: _ => _.pathname.match(/sectionTrials/) },
  { name: 'individualAdjustments', label: '個別修正', path: '/individualAdjustments', isActive: _ => _.pathname.match(/individualAdjustments/) },
  { name: 'individualAdjusted', label: '個別修正・換算後', path: '/individualAdjusted', isActive: _ => _.pathname.match(/individualAdjusted/) },
  { name: 'exchangeDiffs', label: '為替換算差額', path: '/exchangeDiffs', isActive: _ => _.pathname.match(/exchangeDiffs/), filter: _ => (_.currency || 'jpy') !== 'jpy', },
  { name: 'relatedCompanyBalances', label: '関係会社債権債務・取引高', path: '/relatedCompanyBalances', isActive: _ => _.pathname.match(/relatedCompanyBalances/) },
  { name: 'cashes', label: '現金及び現金同等物', path: '/cashes', isActive: _ => _.pathname.match(/cashes/) },
  { name: 'fixedAssets', label: '固定資産', path: '/fixedAssets', isActive: _ => _.pathname.match(/fixedAssets/) },
  { name: 'investments', label: '投資関連', path: '/investments', isActive: _ => _.pathname.match(/investments/) },
  { name: 'finances', label: '財務関連', path: '/finances', isActive: _ => _.pathname.match(/finances/) },
  { name: 'miscellaneousAccounts', label: '雑勘定', path: '/miscellaneousAccounts', isActive: _ => _.pathname.match(/miscellaneousAccounts/) },
  { name: 'allowances', label: '引当金', path: '/allowances', isActive: _ => _.pathname.match(/allowances/) },
];


export default function RelatedCompanyPageHOC(WrappedComponent) {
  return CompanyPage(function RelatedCompanyPage (props) {
    const { prevEndYearMonth, yearMonth, company, user, location, history, match: { params: { companyId, relatedCompanyId } }, } = props;
    const { role, enabledRelatedCompanyIds, } = company?.users?.[user.id] || {};
    const canAccess = (user?.admin || ['owner', 'admin'].includes(role)) || enabledRelatedCompanyIds?.includes(relatedCompanyId);
    const isParent = company.id === relatedCompanyId;
    const subsidiaryId = isParent ? null : relatedCompanyId;
    const queryParams = useQueryParams();
    const [bugyoCompanies, setBugyoCompanies] = useState(null);
    const [pcaDataAreas, setPcaDataAreas] = useState(null);
    const [bugyoIntegrationPath, setBugyoIntegrationPath] = useSessionStorage('bugyoIntegrationPath');
    const [pcaDataCenterCode, setPcaDataCenterCode] = useSessionStorage('pcaDataCenterCode');
    const [pcaIntegrationPath, setPcaIntegrationPath] = useSessionStorage('pcaIntegrationPath');
    const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'), [company]);
    const relatedCompany = isParent ? company : subsidiaries.find(_ => _.id === relatedCompanyId);
    const prevExchangeRate = useDocumentSubscription(prevEndYearMonth && company.ref.collection('exchangeRates').doc(prevEndYearMonth), [prevEndYearMonth]);
    const exchangeRate = useDocumentSubscription(company.ref.collection('exchangeRates').doc(yearMonth), [yearMonth]);
    const { cr: prevCr } = getRates(relatedCompany?.currency, prevExchangeRate);
    const { ar, cr } = getRates(relatedCompany?.currency, exchangeRate);
    const relatedCompanies = [company, ...subsidiaries];
    const companyOptions = (
      (user?.admin || ['owner', 'admin'].includes(role)) ? relatedCompanies : relatedCompanies.filter(_ => enabledRelatedCompanyIds?.includes(_.id))
    ).map(_ => ({ label: _.display_name || _.name, value: _.id }));
    const onSelectCompany = async (selected) => {
      history.push(`${location.pathname.replace(/relatedCompanies\/[^/]+/, `relatedCompanies/${selected.value}`)}${location.search}`);
    };
    const isPkgLocked = !!relatedCompany?.pkgLocks?.[yearMonth];
    const reintegrateWithBugyo = async () => {
      setBugyoIntegrationPath(location.pathname + location.search);
      const { data: url } = await getBugyoAuthUrl();
      window.location.href = url;
    }
    const reintegrateWithPca = async (values) => {
      setPcaIntegrationPath(location.pathname + location.search);
      setPcaDataCenterCode(values.dataCenterCode);
      const { data: url } = await getPcaAuthUrl({ dataCenterCode: values.dataCenterCode, });
      window.location.href = url;
    }
    useEffect(() => {
      (async () => {
        if(queryParams.bugyo === '1' && relatedCompany != null) {
          const { bugyoCompanies, } = queryParams;
          if(bugyoCompanies.some(_ => _.id === relatedCompany.sourceId)) {
            await relatedCompany.ref.update({
              bugyo: {
                ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
              },
            });
            toast.success('再認証しました');
            history.replace(location.pathname + '?' + qs.stringify({ ...omit(queryParams, ['bugyo', 'accessToken', 'refreshToken', 'expires', 'bugyoCompanies']), }));
          } else if(relatedCompany.sourceId == null) {
            setBugyoCompanies(bugyoCompanies);
          } else {
            toast.error('再認証できませんでした');
          }
        }
      })();
    }, [relatedCompany != null]);
    useEffect(() => {
      (async () => {
        if(queryParams.pca === '1' && relatedCompany != null) {
          const { dataAreas, } = queryParams;
          setPcaDataAreas(dataAreas);
        }
      })();
    }, [relatedCompany != null]);

    return relatedCompany != null && (
      canAccess ? (
        <div className="related-company-page h-100 my-4 container-fluid">
          <div className="px-4 bg-white">
            {
              props.translate(
                <div>
                  <div className="d-flex justify-content-end mb-3">
                    <HelpLink text="個社別の連結PKGを作成する" />
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <div style={{ width: 200, zIndex: 6, }} className="position-relative">
                      <Select
                        value={companyOptions.find(_ => _.value === relatedCompanyId) || null}
                        options={companyOptions}
                        onChange={onSelectCompany}
                        className="w-100"
                      />
                    </div>
                    {
                      (isParent || relatedCompany.externalType !== null) && (
                        <CompanySyncButton className="ml-1" company={company} companySourceId={relatedCompany.sourceId} subsidiaryId={subsidiaryId} relatedCompany={relatedCompany} />
                      )
                    }
                    {
                      relatedCompany.externalType === 'bugyo' && (
                        <ProgressButton className="ml-1" color="secondary" process={reintegrateWithBugyo}>
                          再認証
                        </ProgressButton>
                      )
                    }
                    {
                      relatedCompany.externalType === 'pca' && (
                        <ModalButton
                          className="ml-1"
                          color="secondary"
                          Modal={ModelFormModal}
                          modalProps={{
                            title: '再認証',
                            fields: {
                              dataCenterCode: {
                                label: 'データセンター',
                                type: 'select',
                                options: entries(pcaDataCenters).map(([k, v]) => ({ label: v.label, value: k })),
                                validations: { required: _ => _ != null },
                              },
                            },
                            submitLabel: '次へ',
                            onSubmit: reintegrateWithPca,
                          }}
                        >
                          再認証
                        </ModalButton>
                      )
                    }
                  </div>
                  <Nav tabs className="mt-4 mb-4">
                    {
                      tabs.filter(_ => !_.filter || _.filter(relatedCompany)).map(({ label, name, isActive, path, }) => {
                        return (
                          <NavItem key={name}>
                            <Link className={classnames('nav-link cursor-pointer', { active: isActive(location) })} to={`/companies/${companyId}/relatedCompanies/${relatedCompanyId}${path}${location.search}`}>
                              {label}
                            </Link>
                          </NavItem>
                        )
                      })
                    }
                  </Nav>
                </div>
              )
            }
            <WrappedComponent {...props} {...{ relatedCompany, relatedCompanies, isParent, subsidiaryId, prevCr, ar, cr, prevExchangeRate, exchangeRate, isLockedMonth: props.isLockedMonth || isPkgLocked, } } />
          </div>
          {
            bugyoCompanies && (
              <ModelFormModal
                title="法人選択"
                isOpen
                onSubmit={async (values) => {
                  const bugyoCompany = bugyoCompanies.find(_ => _.id === values.sourceId);
                  await relatedCompany.ref.update({
                    ...values,
                    bugyo: {
                      ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
                    },
                  });
                  toast.success('再認証しました');
                  history.replace(location.pathname);
                  setBugyoCompanies(null);
                }}
                fields={{
                  sourceId: {
                    label: '事業所',
                    type: 'select',
                    options: bugyoCompanies.map(_ => ({ label: _.display_name || _.name, value: _.id })),
                    validations: {
                      required: v => v != null,
                    },
                  },
                }}
                onClickClose={_ => {
                  history.replace(location.pathname);
                  setBugyoCompanies(null);
                }}
              />
            )
          }
          {
            pcaDataAreas && (
              <ModelFormModal
                title="再認証"
                isOpen
                onSubmit={async (values) => {
                  const pcaDataAreasByName = keyBy(pcaDataAreas, 'Name');
                  await relatedCompany.ref.set({
                    fiscalYears: values.dataAreaIds.map(_ => pcaDataAreasByName[_]).map(_ => ({ ..._, start_date: [_.CompanyTermStart.slice(0, 4), _.CompanyTermStart.slice(4, 6), _.CompanyTermStart.slice(6)].join('-'), end_date: [_.CompanyTermEnd.slice(0, 4), _.CompanyTermEnd.slice(4, 6), _.CompanyTermEnd.slice(6)].join('-'), })),
                    pca: {
                      ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
                      dataCenterCode: pcaDataCenterCode,
                    },
                  }, { merge: true });
                  toast.success('再認証しました');
                  history.replace(location.pathname + '?' + qs.stringify({ ...omit(queryParams, ['pca', 'accessToken', 'refreshToken', 'expires', 'dataCenterCode', 'dataAreas', 'tokenType']), }));
                  setPcaDataAreas(null);
                  setPcaIntegrationPath('');
                  setPcaDataCenterCode('');
                }}
                values={{ dataAreaIds: relatedCompany.fiscalYears?.map(_ => _.Name).filter(_ => _) }}
                fields={omit(pcaCompanyFields({ dataAreas: pcaDataAreas, }), 'display_name')}
                onClickClose={_ => {
                  history.replace('/');
                  setPcaDataAreas(null);
                }}
              />
            )
          }
        </div>
      ) : (
        <div className="container">
          <div className="alert alert-danger mt-3">権限がありません</div>
        </div>
      )
    );
  });
};
