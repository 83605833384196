const { invert, mapValues, } = require('lodash')

const itemCodes = {
  勘定科目コード: {
    code: 'GL1010001',
  },
  勘定科目名: {
    code: 'GL1010002',
  },
  科目区分コード: {
    code: 'GL1010101',
  },
  貸借: {
    code: 'GL1010102',
  },
  使用: {
    code: 'GL1010103',
  },
};

const invertedItemCodes = invert(mapValues(itemCodes, 'code'));

const reportCodes = {
  科目区分コード: {
    code: 'GL4020001',
  },
  勘定科目コード: {
    code: 'GL4020002',
  },
  階層レベル: {
    code: 'GL4020003',
  },
  親科目区分コード: {
    code: 'GL4020004',
  },
  マスターコード: {
    code: 'GL4020005',
  },
  開始残高: {
    code: 'GL4020010',
  },
  借方: {
    code: 'GL4020011',
  },
  貸方: {
    code: 'GL4020012',
  },
  発生: {
    code: 'GL4020013',
  },
  残高: {
    code: 'GL4020014',
  },
  構成比: {
    code: 'GL4020020',
  },
};

const invertedReportCodes = invert(mapValues(reportCodes, 'code'));

exports.itemCodes = itemCodes;
exports.invertedItemCodes = invertedItemCodes;
exports.reportCodes = reportCodes;
exports.invertedReportCodes = invertedReportCodes;
