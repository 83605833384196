import React, { Fragment, Component } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, pick, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { getCategory, } from '../../shared/models/accountItem';
import { changeFieldNames, fields, } from '../../shared/models/allowance';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import PkgChangesTableSection from '../PkgChangesTableSection';
import PkgSimpleTableSection from '../PkgSimpleTableSection';

const { keys, entries, } = Object;

export default PkgPage(function CompanyPkgAllowances (props) {
  const { company, exchangeRate, period, yearMonth, relatedCompanies, consolidationAccountItemsById, location, } = props;

  return props.translate(
    <div className="company-pkg-allowances">
      <PkgChangesTableSection
        section="引当金"
        title="引当金"
        collectionName="allowances"
        path="allowances"
        fields={fields}
        changeFieldNames={changeFieldNames}
        {...props}
      />
    </div>
  );
});

