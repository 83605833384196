import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import Field from '../Field';

const { entries } = Object;

export default function ModelFormModal(props) {
  const { submitLabel = '保存', fields, title, isOpen, values, onClickClose } = props;
  const statedFields = useFormState(values, isFunction(fields) ? fields() : fields, isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    await props.onSubmit({ ...mapValues(statedFields, 'value'), }, { onClickClose });
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div>
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              key={fieldName}
              name={fieldName}
              values={mapValues(statedFields, 'value')}
              {...fieldSetting}
            />
          ))
        }
      </div>
      <div className="d-flex gap-1 justify-content-end">
        <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
          {isSubmitting && <span className="fas fa-spin fa-spinner mr-1" />}
          {submitLabel}
        </Button>
      </div>
    </Form>
  );
};

