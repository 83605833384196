import React, { useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { mapValues, pick, last, sortBy, omit } from 'lodash';
import Select from 'react-select';
import { useAsync } from 'react-use';

import firebase, { functions } from '../../firebase';
import SettingsPage from '../hocs/SettingsPage';
import { fieldDisplayValue } from '../../shared/util';
import { integrationSettingsFields } from '../../shared/models/company';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { log } from '../../utils';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';

const db = firebase.firestore();
const { entries, keys, } = Object;

export default SettingsPage(function CompanyIntegrationSettings (props) {
  const { user, company, } = props;

  return props.translate(
    <div className="company-integration-settings container">
      <div className="d-flex justify-content-center mb-2">
        <h4>連携設定</h4>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <EditButton color="primary" itemRef={company.ref} FormModal={ModelFormModal} formProps={{ title: '連携設定', fields: integrationSettingsFields() }} withBatch={(batch, values) => log(company, 'generalSetting', 'update', user, { fields: mapValues(integrationSettingsFields(), _ => pick(_, ['label', 'type'])), data: values, prevData: pick(company, keys(integrationSettingsFields())), }, batch)} />
      </div>
      <div>
        <table className="table table-bordered">
          <tbody className="thead-light">
            {
              entries(integrationSettingsFields()).map(([fieldName, fieldSetting]) => {
                const { label, hidden = _ => false, } = fieldSetting;
                return !hidden(company) && (
                  <tr key={fieldName}>
                    <th style={{ width: 300 }}>{label}</th>
                    <td>{fieldDisplayValue(company, fieldName, fieldSetting)}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
