import React, { Component, Fragment, useMemo, } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { fields } from '../../shared/models/cash';
import { generateRowGroups, computeAlerts, rowsForExport, } from '../../shared/lib/pkgCashes';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import usePkgItemGroups from '../hooks/usePkgItemGroups';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';

const { keys } = Object;

export default PkgPage(function CompanyCashes (props) {
  const { company, exchangeRate, period, yearMonth, relatedCompanies, relatedCompaniesById, accountItems, accountItemsById, consolidationAccountItems, consolidationAccountItemsById, location, filteredYearMonths, } = props;
  const { itemGroups, } = usePkgItemGroups(company, relatedCompanies, accountItems, 'cashes', [], 'amount', [], filteredYearMonths);
  const individualAdjustmentJournals = useCollectionSubscription(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth), [yearMonth], { initialItems: null });
  const consolidationJournals = useCollectionSubscription(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth), [yearMonth], { initialItems: null });
  const rowGroups = useMemo(_ => itemGroups && individualAdjustmentJournals && consolidationJournals && generateRowGroups(itemGroups, individualAdjustmentJournals, consolidationJournals, company, accountItems, consolidationAccountItems, exchangeRate), [itemGroups, individualAdjustmentJournals, consolidationJournals]);

  return props.translate(
    <div className="company-cashes">
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ExportButton fileName="全社_現金及び現金同等物.csv" rows={rowsForExport(itemGroups, individualAdjustmentJournals, consolidationJournals, company, accountItems, consolidationAccountItems, exchangeRate)} />
      </div>
      <div>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 150 }}>連結科目</th>
              <th style={{ minWidth: 150 }}>会社</th>
              <th style={{ minWidth: 150 }}>個別科目</th>
              <th style={{ minWidth: 150 }}>金額</th>
              <th style={{ minWidth: 250 }}>備考</th>
            </tr>
          </thead>
          <tbody>
            {
              rowGroups?.map(({ consolidationAccountItem, relatedCompanyRowGroups, consolidationJournalsRow, }) => {

                return (
                  <Fragment key={consolidationAccountItem.id}>
                    {
                      relatedCompanyRowGroups.filter(_ => _.rows.length > 0).map(({ relatedCompany, rows }, relatedCompanyIndex) => {
                        return (
                          <Fragment key={relatedCompany.id}>
                            {
                              rows.map((row, rowIndex) => {
                                const { rowType, id, accountItem, exchangedAmounts, note, relatedCompany, exchangedAmount, } = row;
                                const url = ({
                                  accountItem: `/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/cashes${location.search}`,
                                  individualAdjustment: `/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/individualAdjustments${location.search}`,
                                  consolidation: `/companies/${company.id}/consolidationJournals?${qs.stringify({ period, yearMonth, consolidationAccountItemIds: [consolidationAccountItem?.id], })}`,
                                })[rowType];

                                return (
                                  <tr key={id}>
                                    {
                                      relatedCompanyIndex === 0 && rowIndex === 0 && (
                                        <td rowSpan={sumBy(relatedCompanyRowGroups, _ => _.rows.length) + 1}>
                                          {consolidationAccountItem?.name}
                                        </td>
                                      )
                                    }
                                    <td>
                                      {relatedCompany?.display_name}
                                    </td>
                                    <td>
                                      {accountItem?.name}
                                    </td>
                                    <td className="text-right">
                                      <Link to={url}>
                                        {integerFormat(exchangedAmounts.amount)}
                                      </Link>
                                    </td>
                                    <td>
                                      <AutoLinkText>
                                        {note}
                                      </AutoLinkText>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </Fragment>
                        );
                      })
                    }
                    <tr className="font-weight-bold">
                      <td colSpan={2} className="text-right">
                        合計
                      </td>
                      <td className="text-right">
                        {integerFormat(sumBy(relatedCompanyRowGroups.flatMap(_ => _.rows), 'exchangedAmount'))}
                      </td>
                      <td>
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            }
            <tr className="font-weight-bold">
              <td colSpan={3} className="text-right">
                全合計
              </td>
              <td className="text-right">
                {integerFormat(sumBy(rowGroups?.flatMap(_ => _.relatedCompanyRowGroups.flatMap(_ => _.rows)), 'exchangedAmount'))}
              </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
