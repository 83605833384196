import React from 'react';
import { useToggle } from 'react-use';
import { findKey } from 'lodash';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase, { functions } from '../firebase';
import useQueryParams from './hooks/useQueryParams';

const syncCompany = functions.httpsCallable('syncCompany');

export default function CompanySyncButton (props) {
  const { company, companySourceId, subsidiaryId, relatedCompany, ...extraProps } = props;
  const { yearMonth } = useQueryParams();
  const [isSyncing, toggleSyncing] = useToggle();
  const onClickSync = async () => {
    toggleSyncing(true);
    try {
      await syncCompany({ externalType: (relatedCompany || company).externalType, companyId: company.id, companySourceId: companySourceId || company.sourceId, ownerId: findKey(company.users, { role: 'owner' }), subsidiaryId, yearMonth, });
      toast.success('会社情報を同期しました');
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
    toggleSyncing(false);
  };

  return (
    <Button color="secondary" disabled={isSyncing} onClick={onClickSync} {...extraProps} data-operation-type="write">
      <span className={classnames('mr-1 fas fa-sync cursor-pointer', { 'fa-spin': isSyncing })} />
      事業所を同期
    </Button>
  );
};
