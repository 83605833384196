import React from 'react';
import { isEmpty, } from 'lodash';

export default function Alerts(props) {
  const { alerts, ...extraProps } = props;

  return !isEmpty(alerts) && (
    <div className="alert alert-danger" {...extraProps}>
      {
        alerts.length > 1 ? (
          <ul className="m-0 pl-3">
            {
              alerts.map((alert) => {
                return (
                  <li key={alert}>
                    {alert}
                  </li>
                );
              })
            }
          </ul>
        ) : (
          alerts[0]
        )
      }
    </div>
  );
};
