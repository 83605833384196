import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { get, sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { fiscalYearOfPeriod, } from '../../utils';
import { generateRows, generateOtherCiCategoryAmounts, rowsForExport, } from '../../shared/lib/ci';
import { presetConsolidationAccountItems, computeDisclosureAmounts, } from '../../shared/models/consolidationAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { isTrialNetIncome0, } from '../../shared/models/trial';
import { fields, } from '../../shared/models/equity';
import { accountItemCategories, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useDisclosureAmounts from '../hooks/useDisclosureAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import SsItemFormModal from '../modals/SsItemFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import Field from '../Field';
import WithLoading from '../WithLoading';
import QuerySelector from '../QuerySelector';

const { entries } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const niCategory = accountItemCategoriesByName['当期純利益'];

export default CompanyPage(function CompanyCi (props) {
  const { company, role, period, yearMonth, prevYearMonths, filteredYearMonths, history, location, prevEndYearMonth, isLockedMonth, } = props;
  const periodSettingRef = company.ref.collection('periodSettings').doc(period);
  const periodSetting = useDocumentSubscription(periodSettingRef, [period]);
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [subsidiaries]);
  const prevPeriodAmounts = useCollectionSubscription(company.ref.collection('prevPeriodAmounts').where('period', '==', period), [period]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const allConsolidationAccountItems = useMemo(_ => [...consolidationAccountItems, ...presetConsolidationAccountItems], [consolidationAccountItems]);
  const prevYearEquity = useDocumentSubscription(prevEndYearMonth && company.ref.collection('equities').doc(prevEndYearMonth), [prevEndYearMonth]);
  const equity = useDocumentSubscription(company.ref.collection('equities').doc(yearMonth), [yearMonth]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const prevYearDisclosureSetting = useDocumentSubscription(prevEndYearMonth && company.ref.collection('disclosureSettings').doc(prevEndYearMonth), [prevEndYearMonth]);
  const disclosureSetting = useDocumentSubscription(company.ref.collection('disclosureSettings').doc(yearMonth), [yearMonth]);
  const { items: prevYearItems, } = useDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevYearMonths, true, prevPeriodAmounts, periodSetting?.usesPrevPeriodAmounts, prevYearDisclosureSetting);
  const { items, } = useDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true, null, false, disclosureSetting);
  const itemsByCategory = useMemo(_ => keyBy(items, 'accountItemCategory.name'), [items]);

  const homeIncome = itemsByCategory['親会社株主に帰属する当期純利益']?.categoryItem.amount;
  const minorityIncome = itemsByCategory['非支配株主持分損益']?.categoryItem.amount;
  const totalIncome = homeIncome + minorityIncome;
 
  const otherCiCategoryAmounts = useMemo(_ => items && prevYearItems && equity && generateOtherCiCategoryAmounts(items, prevYearItems, equity, prevYearEquity), [items, prevYearItems, equity, prevYearEquity]);
  const totalEquityAmount = sumBy(otherCiCategoryAmounts, 'equityAmount');
  const rows = useMemo(_ => items && prevYearItems && equity && generateRows(items, prevYearItems, equity, prevYearEquity), [items, prevYearItems, equity, prevYearEquity]);
  useEffect(() => {
    if(equity === null) {
      company.ref.collection('equities').doc(yearMonth).set({ period, yearMonth });
    }
  }, [equity]);

  return props.translate(
    <div className="company-ci container-fluid">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結財務諸表（連結BS、PL、SS、CI）を確認する" />
        </div>
        <div className="mb-3 d-flex justify-content-between align-items-end">
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>算定基礎</h4>
        </div>
        <WithLoading loading={!(otherCiCategoryAmounts && rows)}>
          <div className="d-flex gap-1 justify-content-between align-items-end">
            <Field type="boolean" label="前期数値を使用する" value={periodSetting?.usesPrevPeriodAmounts || false} setValue={_ => periodSettingRef.set({ usesPrevPeriodAmounts: _ }, { merge: true })} formGroupProps={{ className: 'm-0', }} readOnly={_ => isLockedMonth || ['reader'].includes(role)} />
            <EditButton label="持分法適用会社に対する持分相当額" processValues={_ => ({ ..._, period, yearMonth, })} itemRef={equity?.ref} FormModal={ModelFormModal} formProps={{ title: '持分法適用会社に対する持分相当額', fields, }} disabled={isLockedMonth} />
          </div>
          <div className="mt-2 d-flex overflow-auto">
            <table className="table flex-fill table-bordered">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 200 }}></th>
                  <th>親会社持分</th>
                  <th>非支配株主持分</th>
                  <th>持分法適用会社に対する持分相当額</th>
                  <th>計</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>当期純利益</th>
                  <td className="text-right">{integerFormat(homeIncome)}</td>
                  <td className="text-right">{integerFormat(minorityIncome)}</td>
                  <td className="text-right">-</td>
                  <td className="text-right">{integerFormat(totalIncome)}</td>
                </tr>
                <tr>
                  <th>その他の包括利益</th>
                  <td colSpan={4}></td>
                </tr>
                {
                  otherCiCategoryAmounts?.map(({ category, homeAmount, minorityAmount, equityAmount, totalAmount, }) => {
                    return (
                      <tr key={category.name}>
                        <th style={{ textIndent: 1 * 18 }}>{category.ciName || category.name}</th>
                        <td className="text-right">{integerFormat(homeAmount)}</td>
                        <td className="text-right">{integerFormat(minorityAmount)}</td>
                        <td className="text-right">{integerFormat(equityAmount)}</td>
                        <td className="text-right">{integerFormat(totalAmount)}</td>
                      </tr>
                    );
                  })
                }
                <tr>
                  <th style={{ textIndent: 1 * 18 }}>持分法適用会社に対する持分相当額</th>
                  <td className="text-right">-</td>
                  <td className="text-right">-</td>
                  <td className="text-right">-</td>
                  <td className="text-right">{integerFormat(totalEquityAmount)}</td>
                </tr>
                <tr>
                  <th style={{ textIndent: 2 * 18 }}>その他の包括利益 計</th>
                  <td className="text-right">{integerFormat(sumBy(otherCiCategoryAmounts, 'homeAmount'))}</td>
                  <td className="text-right">{integerFormat(sumBy(otherCiCategoryAmounts, 'minorityAmount'))}</td>
                  <td className="text-right">-</td>
                  <td className="text-right">{integerFormat(sumBy(otherCiCategoryAmounts, 'totalAmount') + totalEquityAmount)}</td>
                </tr>
                <tr>
                  <th>包括利益</th>
                  <td className="text-right">{integerFormat(homeIncome + sumBy(otherCiCategoryAmounts, 'homeAmount'))}</td>
                  <td className="text-right">{integerFormat(minorityIncome + sumBy(otherCiCategoryAmounts, 'minorityAmount'))}</td>
                  <td className="text-right">-</td>
                  <td className="text-right">{integerFormat(totalIncome + sumBy(otherCiCategoryAmounts, 'totalAmount') + totalEquityAmount)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr className="my-5" />
          <div className="d-flex justify-content-center mb-3">
            <h4>連結CI</h4>
          </div>
          <div className="d-flex row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="mb-2 d-flex justify-content-end align-items-end gap-1">
                <ExportButton fileName="連結CI.csv" rows={rowsForExport(items, prevYearItems, equity, prevYearEquity)} />
              </div>
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ minWidth: 200 }}>科目</th>
                    <th>金額</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rows?.map((row) => {
                      const { label, amount, indentLevel = 0, } = row;
                      return (
                        <tr key={label}>
                          <th style={{ textIndent: indentLevel * 18 }}>{label}</th>
                          <td className="text-right">{amount != null && integerFormat(amount)}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </WithLoading>
      </div>
    </div>
  );
});
