import React, { Fragment, } from 'react';

import { floatFormat, integerFormat } from '../util';

export default function AmountWithExchange (props) {
  const { currency = 'jpy', amounts: [amount, exchangedAmount] } = props;
  return (
    <Fragment>
      <div>
        {currency === 'jpy' ? integerFormat(amount) : floatFormat(amount)}
      </div>
      {
        currency !== 'jpy' && (
          <div className="text-muted small">
            {integerFormat(exchangedAmount)}
          </div>
        )
      }
    </Fragment>
  );
};
