const { isEmpty, get, last } = require('lodash');

const { entries } = Object;

const parents = (section, allSections) => {
  if(section.parent_id == null) {
    return [];
  } else {
    const parent = allSections.find(_ => _.id === section.parent_id);
    if(parent == null) return [];

    return [parent, ...parents(parent, allSections)];
  };
};

const parseSections = (sections) => {
  const parentSectionIds = sections.map(_ => _.parent_id).filter(_ => _ != null);
  const tipSections = sections
    .filter(_ => _.isMf || !parentSectionIds.includes(_.sourceId || _.id))
    .map(_ => ({ ..._, parents: parents(_, sections) }));
  return { parentSectionIds, tipSections, };
};

const noneSection = (relatedCompany, company) => {
  return { id: 'none', code: '0', name: '未選択', consolidationSegmentId: relatedCompany?.noneConsolidationSegmentId, subsidiaryId: relatedCompany?.id === company.id ? null : relatedCompany?.id, };
};

const filedsToImport = () => {
  return {
    name: {
      label: '部門名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    consolidationSegmentName: {
      label: '連結セグメント名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fields = ({ consolidationSegments, subsidiaryId }) => {
  return {
    name: {
      label: '部門名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    consolidationSegmentId: {
      label: '連結セグメント',
      type: 'select',
      options: consolidationSegments.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

exports.fields = fields;
exports.filedsToImport = filedsToImport;
exports.parents = parents;
exports.parseSections = parseSections;
exports.noneSection = noneSection;
