import React, { Fragment } from 'react';
import { omit, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { useToggle, } from 'react-use';
import classnames from 'classnames';

function nl2br(text) {
  return text?.replace(/(\n|\r\n)/g, '<br />');
};

export default function AutoLinkText (props) {
  const { children } = props;
  const text = children?.replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, `<a href="$1" target="_blank">$1</a> `);

  return (
    <div className="text-break" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: nl2br(text), }} />
  );
};

