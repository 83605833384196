const { mergeWith, sum, mapValues, isEmpty, get, sumBy, pick, groupBy, keyBy, } = require('lodash');
const numeral = require('numeral');

const { getCategory, } = require('../models/accountItem');

const { keys, entries, } = Object;

function generateRowGroups (relatedCompanies, itemGroups, prevYearItemGroups, companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames) {
  const itemGroupsByRelatedCompanyId = keyBy(itemGroups, 'relatedCompany.id');
  const prevYearItemGroupsByRelatedCompanyId = keyBy(prevYearItemGroups, 'relatedCompany.id');
  const companiesAmountItemsByItemId = keyBy(companiesAmountItems, 'consolidationAccountItem.id');
  return consolidationAccountItems.map((consolidationAccountItem) => {
    const category = getCategory(consolidationAccountItem);
    const companiesAmountItem = companiesAmountItemsByItemId[consolidationAccountItem.id];
    const relatedCompanyRowGroups =  relatedCompanies.map((relatedCompany) => {
      const currency = get(relatedCompany, 'currency', 'jpy');
      const prevCr = currency === 'jpy' ? 1 : get(prevExchangeRate, [currency, 'cr'].join('-'));
      const cr = currency === 'jpy' ? 1 : get(exchangeRate, [currency, 'cr'].join('-'));
      const ar = currency === 'jpy' ? 1 : get(exchangeRate, [currency, 'ar'].join('-'));
      const itemGroup = itemGroupsByRelatedCompanyId[relatedCompany.id];
      const prevYearItemGroup = prevYearItemGroupsByRelatedCompanyId[relatedCompany.id];
      const pkgItems = itemGroup.pkgItemsGroupedByConsolidationAccountId[consolidationAccountItem.id] || [];
      const pkgIndividualAdjustments = itemGroup.pkgIndividualAdjustmentsGroupedByConsolidationAccountId[consolidationAccountItem.id] || [];
      const prevYearPkgIndividualAdjustments = prevYearItemGroup.pkgIndividualAdjustmentsGroupedByConsolidationAccountId[consolidationAccountItem.id] || [];

      const [consolidationJournalItemsGroupedByType, prevYearConsolidationJournalItemsGroupedByType] = ['consolidationJournalItemsGroupedByItemId', 'prevYearConsolidationJournalItemsGroupedByItemId']
        .map(_ => itemGroup[_][consolidationAccountItem.id] || [])
        .map(_ => groupBy(_, 'journal.type'));
      const rows = [
        ...([
          ...pkgItems.map((item) => {
            const { id, ref, accountItemId, subsidiaryId, opening = 0, } = item;
            const accountItem = accountItemsById[accountItemId];
            const change = sumBy(changeFieldNames, _ => get(item, _, 0));
            const exchangedChange = sumBy(changeFieldNames, _ => item.exchangedAmounts[_] || 0);

            return {
              rowType: 'pkgItem',
              ...item,
              accountItem,
              consolidationAccountItem,
              change,
              exchangedChange,
            };
          }),
          ...[{ pkgIndividualAdjustments: prevYearPkgIndividualAdjustments, isPrevYear: true }, { pkgIndividualAdjustments, }].filter(_ => !isEmpty(_.pkgIndividualAdjustments)).map(({ pkgIndividualAdjustments, isPrevYear }) => {
            const sign = isPrevYear ? -1 : 1;
            const change = sumBy(pkgIndividualAdjustments, 'change') * sign;
            const exchangedChange = sumBy(pkgIndividualAdjustments, 'exchangedChange') * sign;
            const opening = isPrevYear ? -change : 0;
            return {
              rowType: isPrevYear ? 'prevYearIndividualAdjustmentJournal' : 'individualAdjustmentJournal',
              rowLabel: `個別修正(${isPrevYear ? '前期' : '当期'})`,
              opening,
              exchangedAmounts: mapValues(mergeWith(...pkgIndividualAdjustments.map(_ => _.exchangedAmounts), (x, y) => sum([x, y])), _ => _ * sign),
              change,
              exchangedChange,
            };
          }),
        ]).map((row) => {
          const exchangedOpening = row.opening * prevCr;
          const closing = row.opening + row.change;
          const exchangedClosing = closing * cr;
          const exchangeDiff = exchangedClosing - exchangedOpening - row.exchangedChange;
          return {
            ...row,
            exchangedOpening,
            closing,
            exchangedClosing,
            exchangeDiff,
          };
        }),
        ...allConsolidationJournalTypes.flatMap((consolidationJournalType) => {
          const consolidationJournalItems = consolidationJournalItemsGroupedByType[consolidationJournalType.id] || [];
          const prevYearConsolidationJournalItems = prevYearConsolidationJournalItemsGroupedByType[consolidationJournalType.id] || [];
          return [{ consolidationJournalItems: prevYearConsolidationJournalItems, isPrevYear: true }, { consolidationJournalItems, }].filter(_ => !isEmpty(_.consolidationJournalItems)).map(({ consolidationJournalItems, isPrevYear }) => {
            const consolidationJournalItemsGroupedByChangeType = groupBy(consolidationJournalItems, _ => _.changeType.split('__')[1]);
            const changeAmounts = mapValues(consolidationJournalItemsGroupedByChangeType, (consolidationJournalItems) => {
              const amount = sumBy(consolidationJournalItems, _ => _.amount * (_.direction === category.direction ? 1 : -1)) * (isPrevYear ? -1 : 1);
              const exchangedAmount = amount;
              return {
                amount,
                exchangedAmount,
              };
            });
            const change = sumBy(Object.values(changeAmounts), 'amount');
            const opening = isPrevYear ? -change : 0;
            if(consolidationAccountItem.name === '一括償却資産') {
              const closing = opening + change;
              console.log(1, consolidationJournalType.name, changeAmounts, opening, closing);
            }
            const exchangedChange = sumBy(Object.values(changeAmounts), 'exchangedAmount');
            const exchangedOpening = opening;
            const closing = opening + change;
            const exchangedClosing = closing;
            const exchangeDiff = exchangedClosing - exchangedOpening - exchangedChange;
            return {
              rowType: isPrevYear ? 'prevYearConsolidationJournal' : 'consolidationJournal',
              rowLabel: `${consolidationJournalType.name}(${isPrevYear ? '前期' : '当期'})`,
              consolidationJournalType,
              opening,
              exchangedOpening,
              exchangedAmounts: mapValues(changeAmounts, 'exchangedAmount'),
              change,
              exchangedChange,
              closing,
              exchangedClosing,
              exchangeDiff,
            };
          });
        }),
      ]
        .filter(_ => _)
        .map((row) => {
          return {
            ...row,
            relatedCompany,
            consolidationAccountItem,
          };
        });
      return {
        relatedCompany,
        prevCr,
        cr,
        rows,
        opening: sumBy(rows, 'opening'),
        closing: sumBy(rows, 'closing'),
        exchangedOpening: sumBy(rows, 'exchangedOpening'),
        exchangedClosing: sumBy(rows, 'exchangedClosing'),
      };
    }).filter(_ => _.rows.length > 0);
    const consolidationAccountItemRows = relatedCompanyRowGroups.flatMap(_ => _.rows);
    const pkgConclusiveAmount = sumBy(relatedCompanyRowGroups, 'exchangedClosing');
    const diff = Math.round(pkgConclusiveAmount) - Math.round(companiesAmountItem?.conclusiveAmount);
    return {
      consolidationAccountItem,
      relatedCompanyRowGroups, 
      consolidationAccountItemRows,
      companiesAmountItem,
      pkgConclusiveAmount,
      diff,
    };
  }).filter(_ => _.relatedCompanyRowGroups.length > 0);
}

const rowsForExport = (relatedCompanies, itemGroups, prevYearItemGroups, companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames, fields) => () => {
  return generateRowGroups(relatedCompanies, itemGroups, prevYearItemGroups, companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames).flatMap(({ consolidationAccountItem, relatedCompanyRowGroups, }) => relatedCompanyRowGroups.flatMap(({ relatedCompany, rows, prevCr, cr, }) => {
    return rows.map((row) => {
      const { id, rowType, rowLabel, exchangedOpening, exchangedClosing, change, exchangeDiff, exchangedAmounts, } = row;
      return {
        id,
        consolidationAccountItemName: consolidationAccountItem?.name,
        relatedCompanyName: relatedCompany?.display_name,
        rowType: rowLabel,
        opening: exchangedOpening,
        ...entries(pick(fields(), changeFieldNames)).reduce((x, [fieldName, { label }]) => {
          return {
            ...x,
            [fieldName]: exchangedAmounts[fieldName],
          };
        }, {}),
        exchangeDiff,
        closing: exchangedClosing,
      };
    });
  }));
};

function generateSimpleRowGroups (relatedCompanies, itemGroups, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate) {
  const itemGroupsByRelatedCompanyId = keyBy(itemGroups, 'relatedCompany.id');
  return consolidationAccountItems.map((consolidationAccountItem) => {
    const category = getCategory(consolidationAccountItem);
    return {
      consolidationAccountItem,
      relatedCompanyRowGroups: relatedCompanies.map((relatedCompany) => {
        const currency = get(relatedCompany, 'currency', 'jpy');
        const prevCr = currency === 'jpy' ? 1 : get(prevExchangeRate, [currency, 'cr'].join('-'));
        const cr = currency === 'jpy' ? 1 : get(exchangeRate, [currency, 'cr'].join('-'));
        const itemGroup = itemGroupsByRelatedCompanyId[relatedCompany.id];
        const {
          pkgItemsGroupedByConsolidationAccountId,
        } = itemGroup;
        const items = pkgItemsGroupedByConsolidationAccountId[consolidationAccountItem.id] || [];
        const rows = items.map((item) => {
          const { id, ref, accountItemId, opening, closing, note, subsidiaryId, exchangedAmounts, } = item;
          const accountItem = accountItemsById[accountItemId];
          const exchangedChange = exchangedAmounts._change;
          const exchangeDiff = exchangedAmounts.closing - opening * prevCr - exchangedChange;
          return {
            ...item,
            relatedCompany,
            accountItem,
            consolidationAccountItem,
            exchangedChange,
            exchangeDiff,
          };
        });
        return {
          relatedCompany,
          rows,
          prevCr,
          cr,
          opening: sumBy(rows, 'opening'),
          closing: sumBy(rows, 'closing'),
          consolidationAccountItem,
        };
      }).filter(_ => _.rows.length > 0),
    };
  }).filter(_ => _.relatedCompanyRowGroups.length > 0);
}

const simpleRowsForExport = (...args) => () => {
  return generateSimpleRowGroups(...args).flatMap(({ consolidationAccountItem, relatedCompanyRowGroups, }) => relatedCompanyRowGroups.flatMap(({ relatedCompany, rows, prevCr, cr, }) => {
    return rows.map((row) => {
      const { id, relatedCompany, opening, exchangedChange, exchangeDiff, rate, closing, exchangedAmounts, } = row;
      return {
        id,
        consolidationAccountItemName: consolidationAccountItem?.name,
        relatedCompanyName: relatedCompany?.display_name,
        opening: opening * prevCr,
        exchangedChange,
        exchangeDiff,
        closing: exchangedAmounts.closing,
      };
    });
  }));
};

const computeAlerts = (...args) => {
  const rowGroups = generateRowGroups(...args);
  return [
    ...rowGroups.filter(_ => Math.abs(_.diff) > 0 && _.consolidationAccountItemRows.length > 0).map((rowGroup) => {
      const { consolidationAccountItem, diff, } = rowGroup;
      return `${consolidationAccountItem.name}に差額があります(${numeral(diff).format()})`;
    }),
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.generateSimpleRowGroups = generateSimpleRowGroups;
exports.simpleRowsForExport = simpleRowsForExport;
exports.computeAlerts = computeAlerts;
