import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js'

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css'

import Root from './components/Root';
import routes from './routes';
import './bugsnag';
import './firebase';
import './bootstrap.scss';
import './index.scss';

const ErrorBoundary = ['production', 'staging'].includes(import.meta.env.VITE_ENV) ? Bugsnag.getPlugin('react') : Fragment;

ReactDOM.render(
  <ErrorBoundary>
    <Root routes={routes} />
  </ErrorBoundary>,
  document.getElementById('root')
);
