import { useMemo } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { zip, last, sumBy, sum, isEmpty, } from 'lodash';
import { format as formatDate, addMonths, } from 'date-fns';

import { getCollectionData, getDocumentData, } from '../../shared/firebase';
import { computeDisclosureAmounts, } from '../../shared/models/consolidationAccountItem';
import useCollectionSubscription from './useCollectionSubscription';
import useDocumentSubscription from './useDocumentSubscription';

export default function useDisclosureAmounts (company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, yearMonthDates, withPrevYearMonth = false, periodAmounts, usesPrevPeriodAmounts = false, disclosureSetting) {
  const computeItems = async (relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, yearMonthDates) => {
    if(isEmpty(yearMonthDates)) return;

    const yearMonths = (company.usesMonthlyAr ? yearMonthDates : [last(yearMonthDates)]).map(_ => formatDate(_, 'yyyyMM'));
    const getData = async (yearMonth) => {
      const trials = await getCollectionData(company.ref.collection('trials').where('yearMonth', '==', yearMonth));
      const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth));
      const consolidationJournals = await getCollectionData(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth));
      const exchangedItems = await getCollectionData(company.ref.collection('exchangedItems').where('yearMonth', '==', yearMonth));
      const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
      return { yearMonth, trials, individualAdjustmentJournals, consolidationJournals, exchangedItems, exchangeRate };
    };
    const monthGroups = await Promise.all(yearMonths.map(getData));
    const prevMonthGroup = company.usesMonthlyAr && withPrevYearMonth ? await getData(formatDate(addMonths(yearMonthDates[0], -1), 'yyyyMM')) : null;
    const items = computeDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup, periodAmounts, usesPrevPeriodAmounts, disclosureSetting);
    return { items, monthGroups, prevMonthGroup, };
  };
  const { value: { items, monthGroups, prevMonthGroup, } = {}, loading: isLoading, error } = useAsync(async _ => computeItems(relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, yearMonthDates), [company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, yearMonthDates, withPrevYearMonth, periodAmounts, usesPrevPeriodAmounts, disclosureSetting]);
  if(error) throw error;

  return { items, monthGroups, prevMonthGroup, isLoading };
};
