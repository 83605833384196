const { pick, groupBy, keyBy, sortBy, omit, } = require('lodash');

const { accountItemCategories } = require('../config');

const accountItemCategoryNames = accountItemCategories.map(_ => _.name);

const generateRows = (relatedCompanyBalances, relatedCompanyBalanceLinks, consolidationJournals, relatedCompanies, accountItems, sections) => {
  const consolidationJournalLinkIds = consolidationJournals.map(_ => _.linkId);
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const accountItemsById = keyBy(accountItems, 'id');
  const sectionsById = keyBy(sections, 'id');
  const sortedRows = sortBy(
    relatedCompanyBalances.map((relatedCompanyBalance) => {
      const { id, opponentCompanyId, accountItemId, sectionId, } = relatedCompanyBalance;
      const relatedCompanyBalanceLink = relatedCompanyBalanceLinks.find(_ => _.items.some(_ => _.relatedCompanyBalanceId === id));
      const hasLink = relatedCompanyBalanceLink != null;
      const hasConsolicationJournal = hasLink && consolidationJournalLinkIds.includes(relatedCompanyBalanceLink.id);
      return {
        ...relatedCompanyBalance,
        relatedCompanyBalanceLink,
        hasLink,
        hasConsolicationJournal,
        opponentCompany: relatedCompaniesById[opponentCompanyId],
        accountItem: accountItemsById[accountItemId],
        section: sectionsById[sectionId],
      };
    })
  , [_ => relatedCompanies.indexOf(_.opponentCompany), _ => accountItemCategoryNames.indexOf(_.accountItem?.account_category), _ => _.accountItem?.index]);
  return sortedRows;
};

const rowsForExport = (company, relatedCompanyBalances, relatedCompanyBalanceLinks, consolidationJournals, relatedCompanies, accountItems, sections) => () => {
  return generateRows(relatedCompanyBalances, relatedCompanyBalanceLinks, consolidationJournals, relatedCompanies, accountItems, sections).map((relatedCompanyBalance) => {
    const { opponentCompany, accountItem, section, amount, remoteAmount, note, } = relatedCompanyBalance;
    return {
      opponentCompanyName: opponentCompany?.display_name,
      ...(company.externalType === 'bugyo' && { accountItemCode: accountItem?.shortcut_num }),
      accountItemName: accountItem?.name,
      sectionName: section?.name,
      amount,
      remoteAmount,
      note,
    };
  });
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
