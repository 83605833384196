import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { isEmpty, mapValues, zip, sumBy, groupBy, keyBy, sortBy, last, omit, uniq, } from 'lodash';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { integerFormat, integerChangeFormat, } from '../../util';
import { generateRowGroups, rowsForExport, } from '../../shared/lib/segmentsInfo';
import { presetConsolidationAccountItems, computeSegmentsAmounts, computeCompaniesAmounts, } from '../../shared/models/consolidationAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { isTrialNetIncome0, } from '../../shared/models/trial';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import { accountItemCategories, accountItemCategoryNames, } from '../../shared/config';
import { prevYearMonth, } from '../../shared/util';
import useCompany from '../hooks/useCompany';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useSegmentsAmounts from '../hooks/useSegmentsAmounts';
import useCompaniesAmounts from '../hooks/useCompaniesAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import WithLoading from '../WithLoading';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import ExportButton from '../ExportButton';
import QueryBoolean from '../QueryBoolean';
import Field from '../Field';

const { keys, entries } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');
const COLUMN_WIDTH = 120;

export default CompanyPage(function CompanySegmentsInfo (props) {
  const { company, history, location, period, yearMonth, filteredYearMonths, } = props;
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [subsidiaries]);
  const queryParams = useQueryParams();
  const {
    itemKeys,
    showsAll = '0',
  } = queryParams;
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const sortedConsolidationAccountItems = useMemo(_ => sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
  const allConsolidationAccountItems = useMemo(_ => [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems], [sortedConsolidationAccountItems]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const consolidationJournalTypesById = keyBy(allConsolidationJournalTypes, 'id');
  const sections = useCollectionSubscription(company.ref.collection('sections'), [company]);
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'), [company]);
  const sortedConsolidationSegments = useMemo(_ => sortBy(consolidationSegments, 'index'), [consolidationSegments]);
  const allConsolidationSegments = useMemo(_ => [...sortedConsolidationSegments, generalSegment], [sortedConsolidationSegments]);
  const exchangeRate = useDocumentSubscription(company.ref.collection('exchangeRates').doc(yearMonth), [yearMonth]);
  const sectionTrials = useCollectionSubscription(company.ref.collection('sectionTrials').where('yearMonth', '==', yearMonth), [yearMonth]);
  const individualAdjustmentJournals = useCollectionSubscription(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth), [yearMonth]);
  const consolidationJournals = useCollectionSubscription(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth), [yearMonth]);
  const exchangedItems = useCollectionSubscription(company.ref.collection('exchangedItems').where('yearMonth', '==', yearMonth), [yearMonth]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('yearMonth', '==', yearMonth), [yearMonth]);
  const { items, } = useSegmentsAmounts(company, relatedCompanies, allConsolidationAccountItems, accountItems, sections, allConsolidationSegments, filteredYearMonths);
  const { items: consolidationCalculationItems, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true);

  const rowGroups = useMemo(_ => items && consolidationCalculationItems && generateRowGroups(items, consolidationCalculationItems, allConsolidationSegments, showsAll === '1'), [items, consolidationCalculationItems, allConsolidationSegments, showsAll]);
  const itemKeyOptions = rowGroups?.flatMap(_ => _.rowGroups.flatMap(_ => _.rowGroups.map(_ => _.item))).map(({ key, item, itemType, }) => {
    return {
      label: (
        <div className={classnames({ 'font-weight-bold': itemType === 'category' })}>
          {item.name}{item.shortcut_num ? ` [${item.shortcut_num}]` : ''}
        </div>
      ),
      value: key,
      name: [item.name, item.shortcut_num].join(' '),
    };
  });

  let filteredRowGroups = rowGroups;
  if(!isEmpty(itemKeys)) {
    filteredRowGroups = filteredRowGroups?.map(({ type, rowGroups }) => {
      return {
        type,
        rowGroups: rowGroups.map(({ category, rowGroups }) => {
          return {
            category,
            rowGroups: rowGroups.filter(_ => itemKeys.includes(_.item.key)),
          };
        }).filter(_ => _.rowGroups.length > 0),
      };
    }).filter(_ => _.rowGroups.length > 0);
  }

  return props.translate(
    <div className="company-segments-info container-fluid">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結セグメント情報を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結セグメント情報</h4>
        </div>
        <WithLoading loading={!filteredRowGroups}>
          <div className="mb-2 d-flex align-items-end justify-content-start gap-1 position-relative" style={{ zIndex: 3 }}>
            <QuerySelector paramName="itemKeys" width={500} isMulti options={itemKeyOptions} label="連結科目で絞込み" />
            <QueryBoolean paramName="showsAll" label="金額なしの行も表示" defaultValue={'0'} />
            <ExportButton fileName="連結セグメント情報.csv" rows={rowsForExport(items, consolidationCalculationItems, allConsolidationSegments, showsAll === '1')} />
          </div>
          <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
            <table className="table table-bordered sticky-table m-0">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 300 + COLUMN_WIDTH * 2.5}} className="sticky">
                    <div className="d-flex">
                      <div style={{ minWidth: 300 }}>連結科目</div>
                      <div style={{ minWidth: COLUMN_WIDTH }}>数値項目</div>
                      <div style={{ minWidth: COLUMN_WIDTH * 2 }}>会社</div>
                    </div>
                  </th>
                  {
                    sortedConsolidationSegments.map((consolidationSegment) => {
                      const { id, name } = consolidationSegment;
                      return (
                        <th key={id} style={{ minWidth: COLUMN_WIDTH }}>
                          {name}
                        </th>
                      );
                    })
                  }
                  <th style={{ minWidth: COLUMN_WIDTH }}>小計</th>
                  <th style={{ minWidth: COLUMN_WIDTH }}>調整額</th>
                  <th style={{ minWidth: COLUMN_WIDTH }}>合計</th>
                  <th style={{ minWidth: COLUMN_WIDTH }}>連結精算表</th>
                  <th style={{ minWidth: COLUMN_WIDTH }}>差額</th>
                </tr>
              </thead>
              {
                filteredRowGroups?.map(({ type, rowGroups }) => {
                  return (
                    <tbody key={type} className="thead-light">
                      {
                        rowGroups.map(({ category, rowGroups, }) => {
                          return (
                            <Fragment key={category.name}>
                              {
                                rowGroups.map(({ item, rows, }, itemIndex) => {
                                  return (
                                    <RowGroup key={itemIndex} {...{ category, item, rows, itemIndex }} />
                                  );
                                })
                              }
                            </Fragment>
                          );
                        })
                      }
                    </tbody>
                  );
                })
              }
            </table>
          </div>
        </WithLoading>
      </div>
    </div>
  );
});

function RowGroup(props) {
  const { category, item: { item, itemType, }, rows, itemIndex } = props;
  const company = useCompany();
  const queryParams = useQueryParams();
  const { period, yearMonth, } = queryParams;
  const [showsAllRows, toggleAllRows] = useToggle(false);
  const displayRows = showsAllRows ? rows : [last(rows)];
  return (
    <Fragment key={item.key}>
      {
        displayRows.map(({ rowType, metricLabel, companyName, columns = [], }, i) => {
          const RowCellComponent = ['consolidated', 'total'].includes(rowType) ? 'th' : 'td';
          return (
            <tr key={i} className={itemType === 'category' && 'font-weight-bold'}>
              {
                i === 0 && (
                  <RowCellComponent rowSpan={displayRows.length} style={{ padding: 0 }} className="sticky bg-white">
                    <div className="d-flex justify-content-between">
                      <div style={{ padding: '0.75rem', minWidth: 300, textIndent: (category.indent() + (itemType === 'category' ? 0 : 1)) * 18 }} className={classnames({ 'font-weight-normal': itemType !== 'category' })}>
                        <span className={classnames('fas mr-1 cursor-pointer', { 'fa-plus': !showsAllRows, 'fa-minus': showsAllRows })} onClick={toggleAllRows.bind(null, !showsAllRows)} />
                        {item.name}
                      </div>
                      <table style={{ minWidth: 360, }}>
                        <tbody>
                          {
                            displayRows.map(({ rowType, metricLabel, companyName, columns = [], }, i) => {
                              const RowCellComponent = ['consolidated', 'total'].includes(rowType) ? 'th' : 'td';
                              const isLast = i === displayRows.length - 1;
                              return (
                                <tr key={rowType}>
                                  <RowCellComponent colSpan={rowType === 'company' ? 1 : 2} style={{ minWidth: COLUMN_WIDTH, borderTop: 0, borderRight: rowType !== 'company' && 0, borderBottom: isLast && 0, }}>
                                    {metricLabel}
                                  </RowCellComponent>
                                  {
                                    rowType === 'company' && (
                                      <RowCellComponent className="text-truncate" style={{ minWidth: COLUMN_WIDTH * 2, maxWidth: COLUMN_WIDTH * 2, borderTop: 0, borderRight: 0, borderBottom: isLast && 0, }}>
                                        {companyName}
                                      </RowCellComponent>
                                    )
                                  }
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </RowCellComponent>
                )
              }
              {
                columns.map((column, i) => {
                  const { columnType, amount, format = integerFormat, segment, } = column;
                  const CellComponent = (RowCellComponent === 'th' || ['total', 'totalWithoutGeneral'].includes(columnType)) ? 'th' : 'td';
                  const Component = itemType !== 'category' && ['intersegment', 'consolidationJournal'].includes(rowType) && ['segment', 'general'].includes(columnType) ? Link : 'span';
                  const to = `/companies/${company.id}/consolidationJournals/?${qs.stringify({ period, yearMonth, onlyFilteredRows: 1, consolidationAccountItemIds: [item.id], consolidationSegmentIds: [segment?.id], ...(rowType === 'intersegment' && { intersegments: ['intersegment'] }), ...(rowType === 'intersegment' && { onlyIntersegment: 1, }), })}`;
                  return (
                    <CellComponent key={i} className="text-right">
                      <Component to={to}>
                        {amount == null ? '' : format(amount)}
                      </Component>
                    </CellComponent>
                  );
                })
              }
            </tr>
          );
        })
      }
    </Fragment>
  );
};
