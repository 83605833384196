import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { round, sumBy, pickBy, pick, get, keyBy, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';
import classnames from 'classnames';

import { numberFormat } from '../../util';
import useFormState from '../hooks/useFormState';
import useLocale from '../hooks/useLocale';
import Field from '../Field';
import { fields, itemFields, } from '../../shared/models/individualAdjustmentJournal';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function IndividualAdjustmentJournalFormModal(props) {
  const { isOpen, values, consolidationAccountItems, sections, relatedCompany, onClickClose, } = props;
  const { Translate, translate } = useLocale();
  const statedFields = useFormState(values, fields(), isOpen);
  const isNew = !values || !values.id;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.items || []);
  const totalDebitAmount = round(sumBy(items, 'debitAmount'), 6);
  const totalCreditAmount = round(sumBy(items, 'creditAmount'), 6);
  const isMatchedDebitAndCredit = totalDebitAmount === totalCreditAmount;
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    (items.length === 0) ||
    !isMatchedDebitAndCredit ||
    (items.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 930 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            <Translate>修正仕訳{isNew ? '追加' : '編集'}</Translate>
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="d-flex align-items-end gap-3">
            <div style={{ width: 150 }}>
              <Field
                name="inheritType"
                {...statedFields.inheritType}
              />
            </div>
          </div>
          <div className="mt-3">
            <ListForm
              addButtonLabel="行追加"
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      index={itemIndex}
                      values={item}
                      relatedCompany={relatedCompany}
                      consolidationAccountItems={consolidationAccountItems}
                      sections={sections}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={1}
            />
          </div>
          <div className="mt-3">
            <Field
              name="description"
              {...statedFields.description}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Translate>
            <div className="d-flex gap-3">
              <div>
                借方合計 {numberFormat(relatedCompany.currency, totalDebitAmount)}
              </div>
              <div>
                貸方合計 {numberFormat(relatedCompany.currency, totalCreditAmount)}
              </div>
              <div className={classnames({ 'text-danger': !isMatchedDebitAndCredit })}>
                差額 {numberFormat(relatedCompany.currency, totalDebitAmount - totalCreditAmount)}
              </div>
            </div>
            <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
            <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
          </Translate>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, consolidationAccountItems, sections, relatedCompany, onChange, } = props;
  const statedFields = useFormState(values, itemFields({ consolidationAccountItems, sections, relatedCompany }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column gap-1 flex-fill">
          <div className="d-flex gap-1">
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitItemId"
                {...statedFields.debitItemId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitAmount"
                {...statedFields.debitAmount}
              />
            </div>
          </div>
          <div className="d-flex gap-1">
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitSectionId"
                {...statedFields.debitSectionId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitChangeType"
                {...statedFields.debitChangeType}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-1 flex-fill">
          <div className="d-flex gap-1">
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditItemId"
                {...statedFields.creditItemId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditAmount"
                {...statedFields.creditAmount}
              />
            </div>
          </div>
          <div className="d-flex gap-1">
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditSectionId"
                {...statedFields.creditSectionId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditChangeType"
                {...statedFields.creditChangeType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
