import React, { Component, Fragment, useState, useEffect, } from 'react';
import { useToggle, useAsync, useMap, } from 'react-use';
import { isEmpty, get, orderBy, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { dateStringToDate, } from '../../util';
import { batch } from '../../shared/firebase';
import firebase, { functions } from '../../firebase';
import { accountItemCategoryNames, } from '../../shared/config';
import { numberFormat, floatFormat } from '../../util';
import { fiscalYearOfPeriod, } from '../../utils';
import { withConsolidationAccountItem, } from '../../shared/models/trial';
import { getCategory, } from '../../shared/models/accountItem';
import { fields as fixedAssetRelatedItemFields } from '../../shared/models/fixedAssetRelatedItem';
import { changeFieldNames, fields, } from '../../shared/models/fixedAsset';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import ProgressButton from '../ProgressButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import AmountWithExchange from '../AmountWithExchange';
import IndividualChangesTableSection from '../IndividualChangesTableSection';
import IndividualSimpleTableSection from '../IndividualSimpleTableSection';
import Alerts from '../Alerts';

const { keys, entries, } = Object;
const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyFixedAssets (props) {
  const { period, relatedCompany, yearMonth, company, subsidiaryId, isLockedMonth, } = props;
  const [state, { set: setState }] = useMap({});
  const { start_date: startDate, } = fiscalYearOfPeriod(period, (company || {}).fiscalYears) || {};
  const startYearMonth = formatDate(dateStringToDate(startDate), 'yyyyMM');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const itemTrials = useCollectionSubscription(company.ref.collection('itemTrials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);

  return props.translate(
    <div className="company-fixed-assets">
      <IndividualChangesTableSection
        section="固定資産"
        title="増減"
        filterTrials={_ => _.account_item_name && ['有形固定資産', '無形固定資産'].includes(_.account_category_name)}
        collectionName="fixedAssets"
        fields={fields}
        changeFieldNames={changeFieldNames}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, }}
      />
      <hr className="my-5" />
      <IndividualSimpleTableSection
        section="固定資産"
        title="取得または売却にかかる債権債務・売却損益"
        itemsRef={company.ref.collection('fixedAssetRelatedItems')}
        collectionName="fixedAssetRelatedItems"
        fields={fixedAssetRelatedItemFields}
        {...{ ...props, accountItems, trials, }}
      />
    </div>
  );
});
