import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Button, } from 'reactstrap';
import { useCounter, } from 'react-use';

import useLocale from './hooks/useLocale';

export default function ListForm (props) {
  const { items, onChange, renderItem, itemClassName, deleteDisabled = false, addButtonLabel = '追加' } = props;
  const { translate } = useLocale();
  const [version, { inc: updateVersion }] = useCounter();
  const onClickAdd = () => {
    onChange([...items, {}], items);
    updateVersion();
  };
  const onClickDelete = (index) => {
    onChange([...items.slice(0, index), ...items.slice(index + 1)], items);
    updateVersion();
  };
  return (
    <Fragment>
      {
        items.map((item, i) => {
          return (
            <div key={[version, i].join('-')} className={classnames('d-flex mb-2 align-items-start', itemClassName)}>
              <div className="mr-1 flex-grow-1">
                {renderItem(item, i)}
              </div>
              <Button color="danger" onClick={onClickDelete.bind(null, i)} disabled={deleteDisabled}>
                <span className="fas fa-trash" />
              </Button>
            </div>
          )
        })
      }
      <div>
        <Button color="secondary" onClick={onClickAdd}>
          <span className="fas fa-plus mr-1" />
          {translate(addButtonLabel)}
        </Button>
      </div>
    </Fragment>
  );
};
ListForm.disabledTranslation = true;
