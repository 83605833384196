import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { uniq, groupBy, keyBy, isEmpty, get, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import env from '../../env';
import firebase, { functions } from '../../firebase';
import { uniqAccountName, } from '../../shared/util';
import { bugyoCategoryMaps, accountItemCategories, accountItemCategoriesByName, accountItemCategoryChildren, } from '../../shared/config';
import { batch, getCollectionData, } from '../../shared/firebase';
import { fields, filedsToImport } from '../../shared/models/accountItem';
import { presetConsolidationAccountItems, } from '../../shared/models/consolidationAccountItem';
import { generateRowGroups, rowsForExport, } from '../../shared/lib/accountItemMappings';
import useLocale from '../hooks/useLocale';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';

const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyAccountItemMappings (props) {
  const { company, role, relatedCompany, subsidiaryId, user, } = props;
  const queryParams = useQueryParams();
  const {
    onlyNotSelected = '0',
  } = queryParams;
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [relatedCompany.id]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const allConsolidationAccountItems = [...consolidationAccountItems, ...presetConsolidationAccountItems];
  const consolidationAccountItemsByName = keyBy(allConsolidationAccountItems, 'name');
  const consolidationAccountItemsByCategoryAndName = keyBy(allConsolidationAccountItems, _ => [_.account_category, _.name].join('__'));
  const rowGroups = generateRowGroups(accountItems, allConsolidationAccountItems, onlyNotSelected === '1');
  const processRow = (batch, row, i) => {
    const { name, accountCode, consolidationAccountItemName, categoryName, groupName, } = row;
    if(isEmpty(name)) return;

    let consolidationAccountItem;
    consolidationAccountItem = consolidationAccountItemsByCategoryAndName[[categoryName, consolidationAccountItemName].join('__')];
    if(consolidationAccountItem == null) {
      consolidationAccountItem = consolidationAccountItemsByName[consolidationAccountItemName];
    }
    if(consolidationAccountItem != null && consolidationAccountItem?.account_category !== categoryName) {
      throw new Error(`${name}の行でマッピング対象の連結科目とカテゴリが一致しません`);
    }
    const id = [subsidiaryId, name].join('__').replace(/\//g, '\\');
    const ref = company.ref.collection('accountItems').doc(id);
    batch.set(ref, {
      account_category: categoryName,
      available: true,
      consolidationAccountItemId: consolidationAccountItemName ? ('__' + consolidationAccountItemName?.replace(/\//g, '_')) : null,
      name,
      shortcut_num: accountCode || null,
      subsidiaryId,
      index: i,
      ...(
        subsidiaryId == null && {
          group_name: groupName || null,
        }
      ),
      createdInYui: true,
    }, { merge: true });
  };

  return props.translate(
    <div className="company-account-item-mappings">
      <div className="d-flex justify-content-center">
        <div>
          <div className="d-flex align-items-end gap-1">
            <div className="d-flex gap-1">
              <SetSameNameButton {...{ company, relatedCompany, accountItems, consolidationAccountItems: allConsolidationAccountItems, }} />
              <ExportButton fileName="勘定科目.csv" rows={rowsForExport(accountItems, allConsolidationAccountItems, subsidiaryId == null)} />
              {relatedCompany.externalType === null && <ImportButton processRow={processRow} documentName="accountItem" fields={filedsToImport({ allConsolidationAccountItems })} onFailed={_ => _.message && toast.error(_.message)} importKey={`accountItems__${relatedCompany.id}`} />}
              <AddButton itemRef={_ => company.ref.collection('accountItems').doc([subsidiaryId, _[relatedCompany.externalType === 'bugyo' ? 'shortcut_num' : 'name']].join('__').replace(/\//g, '\\'))} processValues={_ => ({ ..._, available: true, categories: [_.account_category], subsidiaryId, createdInYui: true, })} FormModal={ModelFormModal} formProps={{ title: '勘定科目', fields: fields({ relatedCompany, otherAccountItems: accountItems, consolidationAccountItems: allConsolidationAccountItems, subsidiaryId, }), }} />
            </div>
            <QueryBoolean paramName="onlyNotSelected" label="未選択のみ" defaultValue={'0'} />
          </div>
          <div className="mt-2 overflow-auto" style={{ minHeight: '50vh', maxHeight: '95vh', }}>
            <table className="table table-bordered sticky-table mb-0 flex-table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ width: 50 }} className="sticky"></th>
                  <th style={{ width: 300, }} className="sticky flex-fill">科目名</th>
                  <th style={{ width: 200, }} className="sticky flex-fill">科目コード</th>
                  {
                    subsidiaryId == null && (
                      <th style={{ width: 200, }} className="sticky">開示</th>
                    )
                  }
                  <th style={{ width: 200, }} className="sticky">カテゴリ</th>
                  <th style={{ width: 300, }} className="sticky">連結科目</th>
                  <th style={{ width: 180, }} className="sticky"></th>
                </tr>
              </thead>
              {
                rowGroups.filter(_ => _.filteredRows.length > 0).map(({ accountItemCategory, filteredRows }) => {
                  return (
                    <RowsGroup key={accountItemCategory.name} {...{ subsidiaryId, user, company, role, consolidationAccountItems: allConsolidationAccountItems, accountItemCategory, rows: filteredRows, accountItems, relatedCompany, }} />
                  );
                })
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

function RowsGroup (props) {
  const { translate, } = useLocale();
  const { subsidiaryId, user, company, role, consolidationAccountItems, accountItemCategory, rows, accountItems, relatedCompany, } = props;
  const sameCategoryConsolidationAccountItems = consolidationAccountItems.filter(_ => _.account_category === accountItemCategory.name);
  const consolidationAccountItemOptions = sameCategoryConsolidationAccountItems.map(_ => ({ label: translate(_.name), value: _.id }));
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(rows, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('accountItems').doc(id), { index });
    });
  };

  return (
    <Container
      dragHandleSelector=".drag-handle"
      onDrop={onDrop}
      dropPlaceholder={{ style: { background: 'eee', } }}
      render={(ref) => (
        <tbody ref={ref} className="thead-light border-3">
          {
            rows.map((accountItem, i) => {
              const { id, ref, name, group_name, consolidationAccountItemId, account_category: accountCategoryName, shortcut_num: accountCode, createdInYui = false, isNotInFreee = false, isNotInBugyo = false, isNotInPca = false, isMinus = false, } = accountItem;
              const beforeDelete = async () => {
                if(
                  (await Promise.all(
                    ['relatedCompanyBalances', 'fixedAssets', 'fixedAssetRelatedItems', 'cashes', 'investments', 'investmentRelatedItems', 'fixedDeposits', 'loans', 'incomeInterestItems', 'debts', 'bonds', 'capitals', 'treasuryStocks', 'stockOptions', 'expenseInterestItems', 'miscellaneousAccounts', 'allowances'].map(_ => getCollectionData(company.ref.collection(_).where('accountItemId', '==', id).limit(1)))
                  )).some(_ => _.length > 0) ||
                  (await getCollectionData(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId))).some(_ => _.balances?.some(_ => _.account_item_name === name)) ||
                  (
                    subsidiaryId === null && (
                      (await getCollectionData(company.ref.collection('individualAdjustmentJournals'))).some(_ => _.items?.some(_ => [_.debitItemId, _.creditItemId].includes(id))) ||
                      (await getCollectionData(company.ref.collection('consolidationJournals'))).some(_ => _.items?.some(_ => [_.debitItemId, _.creditItemId].includes(id))) ||
                      (await getCollectionData(company.ref.collection('accountItems'))).some(_ => _.id !== id && _.consolidationAccountItemId === id)
                    )
                  )
                ) {
                  toast.error('使用されているため、削除できません');
                  return false;
                }
              };

              return (
                <Draggable
                  key={id}
                  render={() => (
                    <tr data-id={id} style={{ display: 'flex', overflow: 'initial', }}>
                      <th style={{ width: 50, }}>
                        <div className="drag-handle text-muted cursor-pointer px-2 py-1">
                          <span className="fas fa-grip-vertical" />
                        </div>
                      </th>
                      <td style={{ width: 300, }}>
                        {name}
                        {relatedCompany.externalType != null && createdInYui && <div><div className="badge badge-info">YUIで作成</div></div>}
                        {relatedCompany.externalType === 'freee' && isNotInFreee && <div><div className="badge badge-danger">freeeに存在しない科目です</div></div>}
                        {relatedCompany.externalType === 'bugyo' && isNotInBugyo && <div><div className="badge badge-danger">奉行クラウドに存在しない科目です</div></div>}
                        {relatedCompany.externalType === 'pca' && isNotInPca && <div><div className="badge badge-danger">PCAクラウドに存在しない科目です</div></div>}
                        {user.dev && <div><a href={`https://console.firebase.google.com/u/0/project/${env('FIREBASE_PROJECT_ID')}/firestore/data/~2Fcompanies~2F${company.id}~2FaccountItems~2F${id}`} target="_blank">F</a></div>}
                      </td>
                      <td style={{ width: 200, }}>
                        {accountCode}
                      </td>
                      {
                        subsidiaryId == null && (
                          <td style={{ width: 200, }}>
                            {group_name}
                          </td>
                        )
                      }
                      <td style={{ width: 200, }}>
                        {accountCategoryName}
                      </td>
                      <td style={{ width: 300, }}>
                        <Select
                          isClearable
                          value={consolidationAccountItemOptions.find(_ => _.value === consolidationAccountItemId)}
                          options={consolidationAccountItemOptions}
                          onChange={_ => accountItem.ref.update({ consolidationAccountItemId: get(_, 'value', null) })}
                          isDisabled={['reader'].includes(role)}
                        />
                      </td>
                      <td style={{ width: 180 }} className="text-nowrap text-right">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '勘定科目', fields: fields({ accountItem, relatedCompany, isEditing: true, otherAccountItems: accountItems.filter(_ => _.id !== id), consolidationAccountItems, subsidiaryId, }), }} onFinish={_ => !!isMinus !== !!_.isMinus && window.alert('マイナス表示付与について変更したので試算表を再同期する必要があります')} />
                        <DeleteButton className="ml-2" item={accountItem} itemRef={ref} beforeDelete={beforeDelete} />
                      </td>
                    </tr>
                  )}
                />
              );
            })
          }
        </tbody>
      )}
    />
  );
};

function SetSameNameButton(props) {
  const { company, relatedCompany, accountItems, consolidationAccountItems } = props;
  const consolidationAccountItemsByName = keyBy(consolidationAccountItems, _ => uniqAccountName(relatedCompany, _.name, _.shortcut_num));
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const onClick = async () => {
    if(!window.confirm('連結科目未選択の科目について、同名の科目が見つかったものはすべて自動マッピングします。よろしいですか？')) return;

    // DEBUG
    // await batch(db, accountItems, (batch, accountItem) => {
    // batch.update(accountItem.ref, { consolidationAccountItemId: null });
    // });
    // return;
    await batch(db, accountItems.filter(_ => consolidationAccountItemsById[_.consolidationAccountItemId] == null), (batch, accountItem) => {
      const sameNameConsolidationAccountItem = consolidationAccountItemsByName[uniqAccountName(relatedCompany, accountItem.name, accountItem.shortcut_num)];
      sameNameConsolidationAccountItem && batch.update(accountItem.ref, { consolidationAccountItemId: sameNameConsolidationAccountItem.id });
    });
    toast.success('一括マッピングしました');
  };

  return (
    <Button onClick={onClick} data-operation-type="write">
      連結科目未選択の科目を一括マッピング
    </Button>
  );
}
