const { isEmpty, last, groupBy, keyBy } = require('lodash');
const lI = require('lodash-inflection');
const { format: formatDate, addMonths, } = require('date-fns');

const { getCollectionData } = require('../firebase');
const { disassembleJournalItems, serial, } = require('../util');
const { changeTypes, } = require('../changeTypes');
const { generateRowGroups, generateSimpleRowGroups, } = require('./pkg');
const { pkgCategories, } = require('../models/consolidationAccountItem');
const { getCategory, } = require('../models/accountItem');
const { computeItems, } = require('./pkgItems');

const computeCfMapping = async (allConsolidationAccountItems, allConsolidationJournalTypes, company, relatedCompanies, yearMonths, prevYearMonths, accountItems, cfAccountItemsById, prevExchangeRate, exchangeRate, cfWsRows) => {
  const accountItemsById = keyBy(accountItems, 'id');
  const dataForComputation = {
    ...(
      (await Promise.all([
        ...[
          'fixedAsset',
          'investment',
          'fixedDeposit',
          'loan',
          'otherLiability',
          'debt',
          'bond',
          'capital',
          'treasuryStock',
          'stockOption',
        ].map(_ => [_, generateRowGroups, false]),
        ...[
          'fixedAssetRelatedItem',
          'investmentRelatedItem',
          'incomeInterestItem',
          'expenseInterestItem',
        ].map(_ => [_, generateSimpleRowGroups, true]),
      ].map(async ([modelName, generateRowGroups, isSimple]) => {
        const targetChangeTypes = Object.keys(changeTypes).filter(_ => _.split('__')[0] === modelName);
        const collectionName = lI.pluralize(modelName);
        const { changeFieldNames } = require(`../models/${modelName}`);
        const [itemGroups, prevYearItemGroups] = await serial([yearMonths, prevYearMonths], async (yearMonths) => {
          return await serial(relatedCompanies, async (relatedCompany) => {
            const subsidiaryId = relatedCompany.id === company.id ? null : relatedCompany.id;
            const { pkgItems, pkgIndividualAdjustments, } = await computeItems(company, relatedCompany, subsidiaryId, accountItems, collectionName, [], 'closing', changeFieldNames || [], yearMonths);
            const pkgItemsGroupedByConsolidationAccountId = groupBy(pkgItems, _ => accountItemsById[_.accountItemId]?.consolidationAccountItemId);
            const pkgIndividualAdjustmentsGroupedByConsolidationAccountId = groupBy(pkgIndividualAdjustments, _ => _.accountItem.consolidationAccountItemId);
            const [consolidationJournals, prevYearConsolidationJournals] = await Promise.all([last(yearMonths), addMonths(yearMonths[0], -1)].map(_ => getCollectionData(company.ref.collection('consolidationJournals').where('changeTypes', 'array-contains-any', isEmpty(targetChangeTypes) ? ['dummy'] : targetChangeTypes).where('yearMonth', '==', formatDate(_, 'yyyyMM')))));
            const [consolidationJournalItemsGroupedByItemId, prevYearConsolidationJournalItemsGroupedByItemId] = [consolidationJournals, prevYearConsolidationJournals].map(journals => (
              groupBy(journals.flatMap(disassembleJournalItems).filter(_ => _.changeType?.split('__')[0] === modelName && _.companyId === relatedCompany.id), 'itemId')
            ));
            return {
              relatedCompany,
              pkgItems,
              pkgIndividualAdjustments,
              pkgItemsGroupedByConsolidationAccountId,
              pkgIndividualAdjustmentsGroupedByConsolidationAccountId,
              consolidationJournalItemsGroupedByItemId,
              prevYearConsolidationJournalItemsGroupedByItemId,
            };
          });
        });
        const rows = generateRowGroups(relatedCompanies, itemGroups, ...(isSimple ? [] : [prevYearItemGroups, []]), company, allConsolidationJournalTypes, accountItemsById, allConsolidationAccountItems, prevExchangeRate, exchangeRate, changeFieldNames || []).flatMap(_ => _.relatedCompanyRowGroups.flatMap(_ => _.rows));
        return [modelName, rows];
      }))).reduce((x, [modelName, rows]) => ({ ...x, [`${modelName}Rows`]: rows }), {})
    ),
    cfWsRows,
  };
  const data = allConsolidationAccountItems.flatMap((consolidationAccountItem) => {
    const { id, cfMappingItems = [], } = consolidationAccountItem;
    const category = getCategory(consolidationAccountItem);
    return cfMappingItems.map((cfMappingItem) => {
      const { pkgCategory, columnKey, cfChangeTypeId, cfAccountItemId, } = cfMappingItem;
      const cfAccountItem = cfAccountItemsById[cfAccountItemId];
      const key = [category.type === 'pl' ? 'bs--当期純損益金額' : id, cfChangeTypeId].join('__');
      const amount = pkgCategories()[pkgCategory].computeAmount(consolidationAccountItem, cfAccountItem, columnKey, dataForComputation);
      return {
        consolidationAccountItemId: id,
        key,
        amount,
        cfAccountItemId,
        itemKey: id,
        cfChangeTypeId,
        dataType: 'auto',
      };
    });
  });
  return data;
};

exports.computeCfMapping = computeCfMapping;
