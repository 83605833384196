const { pick, keyBy, } = require('lodash');

const { keys, entries } = Object;

const changeTypes = keyBy([
    ['cash', '現金及び現金同等物'],
    ['fixedAsset', '固定資産', true],
    ['fixedAssetRelatedItem', '固定資産に係る債権債務・売却損益'],
    ['investment', '投資その他', true],
    ['investmentRelatedItem', '投資に係る債権債務・売却損益'],
    ['fixedDeposit', '定期預金', true],
    ['loan', '外部貸付', true],
    ['incomeInterestItem', '未収利息・前受利息'],
    ['otherLiability', 'その他負債', true],
    ['debt', '外部借入', true],
    ['bond', '社債', true],
    ['expenseInterestItem', '未払利息・前払利息'],
    ['capital', '資本', true],
    ['treasuryStock', '自己株式', true],
    ['stockOption', '新株予約権', true],
    ['allowance', '引当金', true],
  ].map(_ => ({
    key: _[0],
    label: _[1],
    ...(_[2] && pick(require(`./models/${_[0]}`), ['changeFieldNames', 'fields'])),
  })).flatMap(({ key, label, changeFieldNames, fields }) => {
    return changeFieldNames ? (
      changeFieldNames.map(_ => ({ label: `${label} > ${fields()[_].label}`, key: `${key}__${_}`, }))
    ) : [{ label, key, }]
  }),
  'key'
);

const changeTypeOptions = entries(changeTypes).map(([k, v]) => ({ label: v.label, value: k, }));

exports.changeTypes = changeTypes;
exports.changeTypeOptions = changeTypeOptions;
