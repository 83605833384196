import React, { Fragment, Component } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, pick, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { getCategory, } from '../../shared/models/accountItem';
import { generateRowGroups, rowsForExport, } from '../../shared/lib/pkgMiscellaneousAccounts';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import PkgSimpleTableSection from '../PkgSimpleTableSection';

const { keys, entries, } = Object;

export default PkgPage(function CompanyMiscellaneousAccounts (props) {
  const { company, exchangeRate, period, yearMonth, relatedCompanies, relatedCompaniesById, accountItems, consolidationAccountItems, consolidationAccountItemsById, location, } = props;
  const miscellaneousAccounts = useCollectionSubscription(company.ref.collection('miscellaneousAccounts').where('yearMonth', '==', yearMonth), [company, yearMonth]);
  const rowGroups = generateRowGroups(miscellaneousAccounts, company, relatedCompanies, accountItems, consolidationAccountItems, exchangeRate);

  return props.translate(
    <div className="company-pkg-miscellaneous-accounts">
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ExportButton fileName="全社_雑勘定.csv" rows={rowsForExport(miscellaneousAccounts, company, relatedCompanies, accountItems, consolidationAccountItems, exchangeRate)} />
      </div>
      <div>
        <table className="table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }}>勘定科目</th>
              <th style={{ minWidth: 200 }}>会社</th>
              <th style={{ minWidth: 200 }}>内訳</th>
              <th style={{ minWidth: 200 }}>金額</th>
              <th style={{ minWidth: 250 }}>備考</th>
            </tr>
          </thead>
          <tbody>
            {
              rowGroups.map(({ consolidationAccountItem, rows, }) => {

                return (
                  <Fragment key={consolidationAccountItem.id}>
                    {
                      rows.map((row, i) => {
                        const { id, ref, accountItemId, items = [], rate, note, subsidiaryId, relatedCompany, consolidationAccountItem, } = row;

                        return (
                          <Fragment key={id}>
                            {
                              items.map((item, i2) => {
                                const { content, amount } = item;
                                const exchangedAmount = amount * rate;
                                return (
                                  <tr key={i2}>
                                    {
                                      i === 0 && i2 === 0 && (
                                        <td rowSpan={sumBy(rows, 'items.length') + 1}>
                                          {consolidationAccountItem && consolidationAccountItem.name}
                                        </td>
                                      )
                                    }
                                    {
                                      i2 === 0 && (
                                        <td rowSpan={items.length}>
                                          {relatedCompany && relatedCompany.display_name}
                                        </td>
                                      )
                                    }
                                    <td>
                                      {content}
                                    </td>
                                    <td className="text-right">
                                      <Link to={`/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/miscellaneousAccounts${location.search}`}>
                                        {integerFormat(exchangedAmount)}
                                      </Link>
                                    </td>
                                    {
                                      i2 === 0 && (
                                        <td rowSpan={items.length}>
                                          <AutoLinkText>
                                            {note}
                                          </AutoLinkText>
                                        </td>
                                      )
                                    }
                                  </tr>
                                );
                              })
                            }
                          </Fragment>
                        );
                      })
                    }
                    <tr className="font-weight-bold">
                      <td colSpan={2} className="text-right">
                        合計
                      </td>
                      <td className="text-right">
                        {integerFormat(sumBy(rows, 'exchangedAmount'))}
                      </td>
                      <td>
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
