import React, { useState, Fragment, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { groupBy, get, range, inRange, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';
import qs from 'qs';
import numeral from 'numeral';
import { toast } from 'react-toastify';

import { integerFormat } from '../../util';
import { startOfMonthByFiscalYears, periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { getCategory, } from '../../shared/models/accountItem';
import firebase, { functions } from '../../firebase';
import { batch } from '../../shared/firebase';
import { accountItemCategoriesByName } from '../../shared/config';
import { fieldsToImport } from '../../shared/models/prevPeriodAmount';
import { presetConsolidationAccountItems } from '../../shared/models/consolidationAccountItem';
import { generateRows, rowsForExport, } from '../../shared/lib/prevPeriodAmounts';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDependentState from '../hooks/useDependentState';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';

const db = firebase.firestore();

export default CompanyPage(function CompanyPrevPeriodAmounts (props) {
  const { period, yearMonth, company, isLockedMonth, } = props;
  const prevPeriodAmounts = useCollectionSubscription(company.ref.collection('prevPeriodAmounts').where('period', '==', period), [period]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const allConsolidationAccountItems = [...consolidationAccountItems, ...presetConsolidationAccountItems];
  const consolidationAccountItemsByCode = keyBy(allConsolidationAccountItems, 'shortcut_num');
  const consolidationAccountItemsByName = keyBy(allConsolidationAccountItems, 'name');
  const rows = generateRows(prevPeriodAmounts, allConsolidationAccountItems);
  const processRow = (batch, row, i) => {
    const consolidationAccountItem = ['bugyo', 'mf'].includes(company.externalType) ? consolidationAccountItemsByCode[row.consolidationAccountItemCode] : consolidationAccountItemsByName[row.consolidationAccountItemName];
    const ref = company.ref.collection('prevPeriodAmounts').doc();
    batch.set(ref, {
      consolidationAccountItemId: consolidationAccountItem.id,
      amount: numeral(row.amount).value(),
      period,
      createdAt: new Date(),
    }, { merge: true });
  };
  const beforeImport = async () => {
    await batch(db, prevPeriodAmounts, (batch, _) => batch.delete(_.ref));
  };
  const onClickClear = async () => {
    if(!window.confirm('本当にクリアしますか？')) return;

    await batch(db, prevPeriodAmounts, (batch, _) => batch.delete(_.ref));
    toast.success('クリアしました');
  };

  return props.translate(
    <div className="company-prev-period-amounts">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="前期数値を設定する" />
        </div>
        <div className="mb-3 d-flex justify-content-end gap-1">
          <ExportButton fileName="前期数値.csv" rows={rowsForExport(company, prevPeriodAmounts, allConsolidationAccountItems)} />
          <ImportButton processRow={processRow} documentName="prevPeriodAmount" fields={fieldsToImport({ consolidationAccountItems: allConsolidationAccountItems, })} beforeSave={beforeImport} disabled={isLockedMonth} importKey={`prevPeriodAmounts__${yearMonth}`} />
          <ProgressButton color="danger" process={onClickClear} disabled={isLockedMonth}>
            <span className="fas fa-eraser mr-1" />
            クリア
          </ProgressButton>
        </div>
        <div>
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr>
                <th>カテゴリー</th>
                <th>連結科目</th>
                <th>前期末残高</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((prevPeriodAmount, i) => {
                  const { id, ref, consolidationAccountItem, accountItemCategory, amount, } = prevPeriodAmount;

                  return (
                    <tr key={i}>
                      <td>
                        {accountItemCategory?.name}
                      </td>
                      <td>
                        {consolidationAccountItem?.name}
                      </td>
                      <td className="text-right">
                        {integerFormat(amount)}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
