import React, { useEffect } from 'react'
import { useRaf, } from 'react-use'

export default function FadeOut (props) {
  const { onFadedOut } = props;
  const elapsed = useRaf(1000, 600);
  useEffect(() => {
    if(elapsed === 1) onFadedOut();
  }, [elapsed]);
  return (
    <div style={{ opacity: 1 - elapsed, }}>
      {props.children}
    </div>
  );
};
