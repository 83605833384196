import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { round, sumBy, pickBy, pick, get, keyBy, uniq, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';
import classnames from 'classnames';

import { integerFormat } from '../../util';
import useFormState from '../hooks/useFormState';
import useCompany from '../hooks/useCompany';
import Field from '../Field';
import { fields, itemFields, } from '../../shared/models/consolidationJournal';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function ConsolidationJournalFormModal(props) {
  const { isOpen, values, companies, consolidationAccountItems, consolidationSegments, allConsolidationJournalTypes, onClickClose, } = props;
  const statedFields = useFormState(values, fields({ allConsolidationJournalTypes }), isOpen);
  const isNew = !values || !values.id;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.items || []);
  const totalDebitAmount = round(sumBy(items, _ => _.debitAmount || 0), 6);
  const totalCreditAmount = round(sumBy(items, _ => _.creditAmount || 0), 6);
  const isMatchedDebitAndCredit = totalDebitAmount === totalCreditAmount;
  const isOverCompanies = uniq(items.flatMap(_ => [_.debitCompanyId, _.creditCompanyId]).filter(_ => _)).length > 2;
  const isAllBlankCompanies = items.flatMap(_ => [_.debitCompanyId, _.creditCompanyId]).every(_ => _ == null);
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    (items.length === 0) ||
    !isMatchedDebitAndCredit ||
    isOverCompanies ||
    isAllBlankCompanies ||
    (items.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 1350 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            修正仕訳{isNew ? '追加' : '編集'}
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="d-flex align-items-end gap-3">
            <div style={{ width: 300 }}>
              <Field
                name="type"
                {...statedFields.type}
              />
            </div>
            <Field name="isOpening" {...statedFields.isOpening} />
            <div style={{ width: 150 }}>
              <Field
                name="inheritType"
                {...statedFields.inheritType}
              />
            </div>
          </div>
          <div className="mt-3">
            <ListForm
              addButtonLabel="行追加"
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      index={itemIndex}
                      values={item}
                      companies={companies}
                      consolidationAccountItems={consolidationAccountItems}
                      consolidationSegments={consolidationSegments}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={1}
            />
          </div>
          <div className="mt-3">
            <Field
              name="description"
              {...statedFields.description}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {
            isOverCompanies && (
              <div className="text-danger">
                3社以上選択されています。
              </div>
            )
          }
          {
            isAllBlankCompanies && (
              <div className="text-danger">
                会社を1つは選択してください。
              </div>
            )
          }
          <div className="d-flex gap-3">
            <div>
              借方合計 {integerFormat(totalDebitAmount)}
            </div>
            <div>
              貸方合計 {integerFormat(totalCreditAmount)}
            </div>
            <div className={classnames({ 'text-danger': !isMatchedDebitAndCredit })}>
              差額 {integerFormat(totalDebitAmount - totalCreditAmount)}
            </div>
          </div>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, companies, consolidationAccountItems, consolidationSegments, onChange, } = props;
  const company = useCompany();
  const statedFields = useFormState(values, itemFields({ company, companies, consolidationAccountItems, consolidationSegments, }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column gap-1 flex-fill">
          <div className="d-flex gap-1">
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitCompanyId"
                {...statedFields.debitCompanyId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitItemId"
                {...statedFields.debitItemId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitAmount"
                {...statedFields.debitAmount}
              />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitSegmentId"
                {...statedFields.debitSegmentId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitIsIntersegment"
                {...statedFields.debitIsIntersegment}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="debitChangeType"
                {...statedFields.debitChangeType}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-1 flex-fill">
          <div className="d-flex gap-1">
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditCompanyId"
                {...statedFields.creditCompanyId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditItemId"
                {...statedFields.creditItemId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditAmount"
                {...statedFields.creditAmount}
              />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditSegmentId"
                {...statedFields.creditSegmentId}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditIsIntersegment"
                {...statedFields.creditIsIntersegment}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <Field
                name="creditChangeType"
                {...statedFields.creditChangeType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
