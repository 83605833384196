const { isEmpty, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const fields = ({ relatedCompanies, consolidationAccountItems, consolidationSegments, }) => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    relatedCompanyId1: {
      label: '会社1',
      type: 'select',
      options: relatedCompanies.map(_ => ({ label: _.display_name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    relatedCompanyId2: {
      label: '会社2',
      type: 'select',
      options: relatedCompanies.map(_ => ({ label: _.display_name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    createsJournal: {
      label: '自動で仕訳を作成する',
      type: 'boolean',
      initialValue: false,
    },
    adjustmentRelatedCompanyNumber: {
      label: '差額の会社',
      type: 'select',
      options: [1, 2].map(_ => _.toString()).map(_ => ({ label: '会社' + _, value: _ })),
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => !_.createsJournal,
    },
    adjustmentDebitConsolidationAccountItemId: {
      label: '差額の科目(借方)',
      type: 'select',
      options: consolidationAccountItems.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: (v, s) => s.adjustmentCreditConsolidationAccountItemId != null || !isEmpty(v),
      },
      hidden: _ => !_.createsJournal,
    },
    adjustmentCreditConsolidationAccountItemId: {
      label: '差額の科目(貸方)',
      type: 'select',
      options: consolidationAccountItems.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: (v, s) => s.adjustmentDebitConsolidationAccountItemId != null || !isEmpty(v),
      },
      hidden: _ => !_.createsJournal,
    },
    adjustmentSegmentId: {
      label: '差額のセグメント',
      type: 'select',
      options: consolidationSegments.map(_ => ({ label: _.name, value: _.id })),
      hidden: _ => !_.createsJournal,
    },
    isIntersegment: {
      label: 'セグメント間',
      type: 'boolean',
      initialValue: false,
      hidden: _ => !_.createsJournal,
    },
    description: {
      label: '仕訳の説明',
      type: 'text',
      hidden: _ => !_.createsJournal,
    }
  };
};

const itemFields = ({ company, consolidationSegments = [], consolidationAccountItems = [], relatedCompaniesByNumber, sectionsGroupedByRelatedCompanyId, }) => {
  return {
    isRequired: {
      label: '必須',
      type: 'boolean',
      initialValue: true,
    },
    relatedCompanyNumber: {
      label: '会社',
      type: 'select',
      options: [1, 2].map(_ => _.toString()).map(_ => ({ label: '会社' + _, value: _ })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    consolidationAccountItemId: {
      label: '科目',
      type: 'select',
      options: consolidationAccountItems.map(_ => ({ label: optionLabel(_, company), value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    consolidationSegmentId: {
      label: 'セグメント',
      type: 'select',
      options: consolidationSegments.map(_ => ({ label: _.name, value: _.id })),
    },
    sectionIds: {
      label: '部門',
      type: 'multiSelect',
      options: s => (sectionsGroupedByRelatedCompanyId[relatedCompaniesByNumber[s.relatedCompanyNumber]?.id] || []).map(_ => ({ label: _.name, value: _.id })),
    },
  };
};

exports.itemFields = itemFields;
exports.fields = fields;
