const { isEmpty, omit, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const changeFieldNames = [
  'evalDiffReturn',
  'acquisition',
  'sale',
  'eval',
  'amortization',
  'refund',
  'evalDiff',
  'transfer',
  'other',
];

const fields = ({ accountItems = [], otherItems, } = {}) => {
  const unique = (v, s) => otherItems?.every(o => ['accountItemId'].some(_ => o[_] !== s[_]));
  return {
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
        unique,
      },
    },
    opening: {
      label: '期首',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    evalDiffReturn: {
      label: '評価差額戻し',
      type: 'float',
    },
    acquisition: {
      label: '取得',
      type: 'float',
    },
    sale: {
      label: '売却',
      type: 'float',
    },
    eval: {
      label: '評価損',
      type: 'float',
    },
    amortization: {
      label: '償却',
      type: 'float',
    },
    refund: {
      label: '払戻',
      type: 'float',
    },
    evalDiff: {
      label: '評価差額',
      type: 'float',
    },
    transfer: {
      label: '組替・振替',
      type: 'float',
    },
    other: {
      label: 'その他',
      type: 'float',
    },
  };
};

exports.fields = fields;
exports.changeFieldNames = changeFieldNames;
