const { get, isEmpty, } = require('lodash');
const getRates = (currency = 'jpy', exchangeRate) => {
  const ar = currency === 'jpy' ? 1 : get(exchangeRate, [currency, 'ar'].join('-'), 0);
  const cr = currency === 'jpy' ? 1 : get(exchangeRate, [currency, 'cr'].join('-'), 0);
  return { ar, cr, };
};

const fields = () => {
  return {
    ar: {
      label: 'AR',
      type: 'float',
      validations: {
        greaterThanOrEqualTo0: v => v >= 0,
      },
    },
    cr: {
      label: 'CR',
      type: 'float',
      validations: {
        greaterThanOrEqualTo0: v => v >= 0,
      },
    },
  };
};

exports.fields = fields;
exports.getRates = getRates;
