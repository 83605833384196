const { isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');

const roles = {
  owner: 'オーナー',
  admin: '管理者',
  member: '一般',
  reader: '閲覧者',
};
const userLocaleFields = ({ locales }) => {
  return {
    localeId: {
      type: 'select',
      options: locales.map(_ => ({ value: _.id, label: _.name })),
      initialValue: 'ja',
      validations: {
        required: v => v != null,
      },
    },
  };
};

const fieldsToSignUp = () => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    displayName: {
      type: 'string',
      label: 'ユーザー名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fieldsToSignIn = () => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
  };
};

const adminFields = () => {
  return {
    bugyoEnabled: {
      label: '勘定奉行クラウド',
      type: 'boolean',
      initialValue: false,
    },
  };
};

exports.adminFields = adminFields;
exports.fieldsToSignIn = fieldsToSignIn;
exports.fieldsToSignUp = fieldsToSignUp;
exports.roles = roles;
exports.userLocaleFields = userLocaleFields;
