import { useMemo } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { zip, last, sumBy, sum, isEmpty, } from 'lodash';
import { format as formatDate, addMonths, } from 'date-fns';

import { getCollectionData, getDocumentData, } from '../../shared/firebase';
import { computeSingleAmounts, } from '../../shared/models/consolidationAccountItem';
import useCollectionSubscription from './useCollectionSubscription';
import useDocumentSubscription from './useDocumentSubscription';

export default function useSingleAmounts (company, relatedCompany, subsidiaryId, allConsolidationAccountItems, accountItems, yearMonthDates, withPrevYearMonth = false, deps = []) {
  const computeItems = async (relatedCompany, subsidiaryId, allConsolidationAccountItems, accountItems, yearMonthDates) => {
    if(isEmpty(yearMonthDates)) return;

    const yearMonths = (company.usesMonthlyAr ? yearMonthDates : [last(yearMonthDates)]).map(_ => formatDate(_, 'yyyyMM'));
    const getData = async (yearMonth) => {
      const trials = await getCollectionData(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth));
      const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth));
      const exchangedItemRef = company.ref.collection('exchangedItems').doc([relatedCompany.id, yearMonth].join('__'));
      const exchangedItem = await getDocumentData(exchangedItemRef);
      const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
      return { yearMonth, trials, individualAdjustmentJournals, exchangedItem, exchangeRate };
    };
    const monthGroups = await Promise.all(yearMonths.map(getData));
    const prevMonthGroup = company.usesMonthlyAr && withPrevYearMonth ? await getData(formatDate(addMonths(yearMonthDates[0], -1), 'yyyyMM')) : null;
    const items = computeSingleAmounts(company, relatedCompany, allConsolidationAccountItems, accountItems, relatedCompany.currency, monthGroups, prevMonthGroup);
    return items;
  };
  // NOTE: そもそもallConsolidationAccountItemsをメモ化したいところ
  const { value: items, loading: isLoading, error } = useAsync(async _ => computeItems(relatedCompany, subsidiaryId, allConsolidationAccountItems, accountItems, yearMonthDates), [company, relatedCompany, subsidiaryId, isEmpty(allConsolidationAccountItems), accountItems, yearMonthDates, ...deps]);
  if(error) throw error;

  return { items, isLoading };
};
