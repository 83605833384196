const { keyBy, sortBy, sumBy, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategoryNames } = require('../config');
const { getCategory, } = require('../models/accountItem');

const generateRowGroups = (miscellaneousAccounts, accountItems, trials) => {
  const accountItemsById = keyBy(accountItems, 'id');
  const items = miscellaneousAccounts.map(_ => ({ ..._, accountItem: accountItemsById[_.accountItemId] }));
  const sortedItems = sortBy(items, _ => accountItemCategoryNames.indexOf(_.accountItem?.account_category), 'accountItem.index');
  const trialBalances = trials.flatMap(_ => _.balances);
  return sortedItems.map((miscellaneousAccount) => {
    const { accountItemId, items = [], } = miscellaneousAccount;
    const accountItem = accountItemsById[accountItemId];
    const category = getCategory(accountItem);
    const totalAmount = sumBy(items, 'amount') || 0;
    const totalExchangedAmount = sumBy(items, 'exchangedAmounts.amount') || 0;
    const trialAmount = trialBalances.find(_ => _.account_item_name === accountItem?.name)?.closing_balance;
    return {
      ... miscellaneousAccount,
      category,
      accountItem,
      trialAmount,
      totalAmount,
      totalExchangedAmount,
    }
  });
};

const rowsForExport = (...args) => () => {
  return generateRowGroups(...args).flatMap((rowGroup) => {
    const { id, accountItem, items, note, } = rowGroup;
    return items.map((item) => {
      const { content, amount, } = item;
      return {
        id,
        accountItemName: accountItem?.name,
        content,
        amount,
        note,
      };
    });
  });
};

const computeAlerts = (...args) => {
  return [
    generateRowGroups(...args).some(_ => floatFormat(_.totalAmount) !== floatFormat(_.trialAmount)) && '差額が発生しています',
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.computeAlerts = computeAlerts;
exports.rowsForExport = rowsForExport;
