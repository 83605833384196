import React, { Fragment, useState, useMemo, useEffect, } from 'react';
import { Nav, NavItem, Button, Form, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, groupBy, isNaN, mapValues, pick, last, sortBy, omit, keyBy, sumBy, } from 'lodash';
import Select from 'react-select';
import { useToggle, useAsync } from 'react-use';
import { format as formatDate, addYears, addMonths, } from 'date-fns';
import numeral from 'numeral';
import classnames from 'classnames';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import CompanyPage from '../hocs/CompanyPage';
import { getDocumentData, getCollectionData, } from '../../shared/firebase';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { presetConsolidationAccountItems, computeCompaniesAmounts, } from '../../shared/models/consolidationAccountItem';
import { aggregationTypes, metrics, generateRowGroups, computeItemSummaryRowGroup, displayColumnTypes, comparisons, } from '../../shared/lib/monthlyTrends';
import { accountItemCategoryNames, documentTypes, accountItemCategories, } from '../../shared/config';
import { fieldDisplayValue, yearMonthsOfPeriod, } from '../../shared/util';
import { generalSettingsFields, } from '../../shared/models/company';
import useCompany from '../hooks/useCompany';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { log } from '../../utils';
import { integerFormat } from '../../util';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import EditButton from '../EditButton';
import ModalButton from '../ModalButton';
import WithLoading from '../WithLoading';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';
import HoveredNoter from '../HoveredNoter';

const db = firebase.firestore();
const { entries, keys, } = Object;
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const COLUMN_WIDTH = 140;

export default CompanyPage(function CompanyMonthlyTrends (props) {
  const { user, history, location, company, period, yearMonths, filteredYearMonths, filteredQuarters, prevYearMonths, } = props;
  const queryParams = useQueryParams();
  const {
    documentType,
    comparison,
  } = queryParams;
  const conclusiveDisplayColumnTypes = isEmpty(queryParams.displayColumnTypes) ? keys(displayColumnTypes) : queryParams.displayColumnTypes;
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'), { initialItems: null });
  const notes = useCollectionSubscription(company.ref.collection('notes').where('pageType', '==', 'monthlyTrends'), { initialItems: null });
  const notesByKey = keyBy(notes, 'noteKey');
  const relatedCompanies = useMemo(() => subsidiaries && [company, ...subsidiaries], [subsidiaries]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company], { initialItems: null });
  const sortedConsolidationAccountItems = useMemo(() => consolidationAccountItems && sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
  const allConsolidationAccountItems = useMemo(() => sortedConsolidationAccountItems && [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems], [sortedConsolidationAccountItems]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company], { initialItems: null });
  const sortedConsolidationJournalTypes = useMemo(() => consolidationJournalTypes && sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(() => sortedConsolidationJournalTypes && [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'), { initialItems: null });

  const computeItems = async (yearMonths) => {
    if(!(subsidiaries && allConsolidationJournalTypes && allConsolidationAccountItems && !isEmpty(accountItems) && !isEmpty(yearMonths))) return;

    const getData = async (yearMonthDate) => {
      const yearMonth = formatDate(yearMonthDate, 'yyyyMM');
      const trials = await getCollectionData(company.ref.collection('trials').where('yearMonth', '==', yearMonth));
      const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth));
      const consolidationJournals = await getCollectionData(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth));
      const exchangedItems = await getCollectionData(company.ref.collection('exchangedItems').where('yearMonth', '==', yearMonth));
      const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
      return { yearMonth, trials, individualAdjustmentJournals, consolidationJournals, exchangedItems, exchangeRate };
    };
    const allMonthGroups = await Promise.all(yearMonths.map(getData));
    const prevMonthGroup = company.usesMonthlyAr ? await getData(addMonths(yearMonths[0], -1)) : null;
    return yearMonths.map((yearMonthDate, i) => {
      const monthGroups = company.usesMonthlyAr ? allMonthGroups.slice(0, i + 1) : [allMonthGroups[i]];
      const items = computeCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup);
      return items;
    });
  };
  const { value: monthItems, loading: isLoadingMonthItems, } = useAsync(_ => computeItems(filteredYearMonths), [filteredYearMonths, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems]);
  const { value: prevYearMonthItems, loading: isLoadingComparisonMonthItems, error, } = useAsync(_ => computeItems(prevYearMonths), [prevYearMonths, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems]);
  const rowGroups = useMemo(() => relatedCompanies && monthItems && prevYearMonthItems && documentType && !isLoadingMonthItems && !isLoadingComparisonMonthItems && generateRowGroups(relatedCompanies, yearMonths, monthItems, prevYearMonthItems, documentType, comparison), [relatedCompanies, yearMonths, monthItems, prevYearMonthItems, isLoadingMonthItems, isLoadingComparisonMonthItems, documentType, comparison]);
  const rowsForExport = async () => {
    await new Promise(_ => setTimeout(_, 100));
    return filteredRowGroups.flatMap(({ category, filteredItemRowGroups }) => {
      return filteredItemRowGroups.flatMap(({ key, item, filteredRelatedCompanyRowGroups }) => {
        return [
          ...[computeItemSummaryRowGroup(documentType, comparison, filteredYearMonths, prevYearMonths, filteredRelatedCompanyRowGroups), ...filteredRelatedCompanyRowGroups].flatMap(({ relatedCompany, aggregationTypeRowGroups, filteredAggregationTypeRowGroups }) => {
            return (filteredAggregationTypeRowGroups || aggregationTypeRowGroups).flatMap(({ aggregationType, metricRows }) => {
              const filteredMetricRows = isEmpty(queryParams.metrics) ? metricRows : metricRows.filter(_ => queryParams.metrics.includes(_.metric));
              return filteredMetricRows.map(({ metric, quarters, summaryColumns, }) => {
                return {
                  category: item.itemType === 'category' ? category.name : null,
                  itemName: item.itemType === 'consolidationAccountItem' ? item.name : null,
                  relatedCompanyName: relatedCompany?.display_name,
                  aggregationType: aggregationTypes[aggregationType].label,
                  metric: metrics[metric].label({ comparison }),
                  ...filteredQuarters.reduce((x, yearMonths, quarterIndex) => {
                    return {
                      ...x,
                      ...(conclusiveDisplayColumnTypes.includes('month') && yearMonths.reduce((x, yearMonth, yearMonthIndex) => {
                        const value = quarters[quarterIndex]?.monthColumns[yearMonthIndex]?.value();
                        return {
                          ...x,
                          [formatDate(yearMonth, 'yyyy/MM')]: isNaN(value) ? null : value,
                        };
                      }, {})),
                      ...(conclusiveDisplayColumnTypes.includes('quarter') && { [`${quarterIndex + 1}Q`]: quarters[quarterIndex]?.quarterColumn.value() }),
                    };
                  }, {}),
                  ...(
                    ['pl', 'cr'].includes(documentType) && {
                      total: summaryColumns[0].value(),
                    }
                  ),
                };
              });
            });
          }),
          ...(
            filteredYearMonths.some(_ => notesByKey[[item.id, formatDate(_, 'yyyyMM')].join('__')]) ? [{
              category: item.itemType === 'category' ? category.name : null,
              itemName: item.itemType === 'consolidationAccountItem' ? item.name : null,
              relatedCompanyName: null,
              aggregationType: null,
              metric: 'メモ',
              ...filteredYearMonths.reduce((x, yearMonth, yearMonthIndex) => {
                const note = notesByKey[[item.id, formatDate(yearMonth, 'yyyyMM')].join('__')];
                return {
                  ...x,
                  [formatDate(yearMonth, 'yyyy/MM')]: note?.value,
                };
              }, {}),
            }] : []
          ),
        ];
      });
    });
  };
  const filterRowGroups = (rowGroups) => {
    let filteredRowGroups = rowGroups || [];
    filteredRowGroups = filteredRowGroups.map((rowGroup) => {
      const { category, itemRowGroups } = rowGroup;
      const filteredItemRowGroups = isEmpty(queryParams.consolidationAccountItemIds) ? itemRowGroups : itemRowGroups.filter(_ => queryParams.consolidationAccountItemIds.includes(_.item.id));
      return {
        ...rowGroup,
        filteredItemRowGroups: filteredItemRowGroups.map((itemRowGroup) => {
          const { relatedCompanyRowGroups } = itemRowGroup;
          let filteredRelatedCompanyRowGroups = isEmpty(queryParams.relatedCompanyIds) ? relatedCompanyRowGroups : relatedCompanyRowGroups.filter(_ => queryParams.relatedCompanyIds.includes(_.relatedCompany.id));
          let isSummaryAmountOk = true;
          [['change', 2, 1], ['changeRate', 3, 100]].map(([metric, metricIndex, rate]) => {
            filteredQuarters.map((yearMonths, quarterIndex) => {
              yearMonths.map((yearMonth, monthIndex) => {
                const queryParam = queryParams[`${metric}__${formatDate(yearMonth, 'yyyyMM')}`];
                if(!isEmpty(queryParam)) {
                  isSummaryAmountOk = isSummaryAmountOk && Math.abs(last(itemSummaryRowGroup.aggregationTypeRowGroups).metricRows[metricIndex].quarters[quarterIndex].monthColumns[monthIndex]?.value() * rate) >= parseInt(queryParam, 10);
                  filteredRelatedCompanyRowGroups = filteredRelatedCompanyRowGroups.filter(_ => Math.abs(last(_.aggregationTypeRowGroups).metricRows[metricIndex].quarters[quarterIndex].monthColumns[monthIndex]?.value() * rate) >= parseInt(queryParam, 10));
                }
              });
              const quarterQueryParam = queryParams[`${metric}__${quarterIndex + 1}q`];
              if(!isEmpty(quarterQueryParam)) {
                isSummaryAmountOk = isSummaryAmountOk && Math.abs(last(itemSummaryRowGroup.aggregationTypeRowGroups).metricRows[metricIndex].quarters[quarterIndex]?.quarterColumn.value() * rate) >= parseInt(quarterQueryParam, 10);
                filteredRelatedCompanyRowGroups = filteredRelatedCompanyRowGroups.filter(_ => Math.abs(last(_.aggregationTypeRowGroups).metricRows[metricIndex].quarters[quarterIndex]?.quarterColumn.value() * rate) >= parseInt(quarterQueryParam, 10));
              }
            });
            if(documentType !== 'bs') {
              const totalQueryParam = queryParams[`${metric}__total`];
              if(!isEmpty(totalQueryParam)) {
                isSummaryAmountOk = isSummaryAmountOk && Math.abs(last(itemSummaryRowGroup.aggregationTypeRowGroups).metricRows[metricIndex].summaryColumns[0].value() * rate) >= parseInt(totalQueryParam, 10);
                filteredRelatedCompanyRowGroups = filteredRelatedCompanyRowGroups.filter(_ => Math.abs(last(_.aggregationTypeRowGroups).metricRows[metricIndex].summaryColumns[0].value() * rate) >= parseInt(totalQueryParam, 10));
              }
            }
          });
          const itemSummaryRowGroup = computeItemSummaryRowGroup(category.type, comparison, filteredYearMonths, prevYearMonths, filteredRelatedCompanyRowGroups);
          return {
            ...itemRowGroup,
            itemSummaryRowGroup,
            isSummaryAmountOk,
            filteredRelatedCompanyRowGroups: filteredRelatedCompanyRowGroups.map((relatedCompanyRowGroup) => {
              const { aggregationTypeRowGroups } = relatedCompanyRowGroup;
              const filteredAggregationTypeRowGroups = isEmpty(queryParams.aggregationTypes) ? aggregationTypeRowGroups : aggregationTypeRowGroups.filter(_ => queryParams.aggregationTypes.includes(_.aggregationType));
              return {
                ...relatedCompanyRowGroup,
                filteredAggregationTypeRowGroups: filteredAggregationTypeRowGroups.map((aggregationTypeRowGroup) => {
                  const { metricRows } = aggregationTypeRowGroup;
                  const filteredMetricRows = isEmpty(queryParams.metrics) ? metricRows : metricRows.filter(_ => queryParams.metrics.includes(_.metric));
                  return {
                    ...aggregationTypeRowGroup,
                    filteredMetricRows,
                  };
                }),
              };
            }),
          };
        }).filter(_ => _.isSummaryAmountOk || _.filteredRelatedCompanyRowGroups.length > 0),
      };
    });
    if(!isEmpty(queryParams.categories)) {
      filteredRowGroups = filteredRowGroups.filter(_ => queryParams.categories.includes(_.category.name));
    }
    return filteredRowGroups;
  };
  const filteredRowGroups = useMemo(() => filterRowGroups(rowGroups), [rowGroups, queryParams]);
  const setInitialQueryParams = () => {
    const newParams = {
      documentType: documentType || 'bs',
      comparison: 'prevYear',
    };
    history.replace(encodeURI(`${location.pathname}?${qs.stringify({ ...queryParams, ...newParams })}`));
  };
  const categoryOptions = (accountItemCategoriesGroupedByType[documentType] || []).map(_ => ({ label: _.name, value: _.name }));
  const consolidationAccountItemOptions = (allConsolidationAccountItems || []).filter(_ => accountItemCategoriesByName[_.account_category]?.type === documentType).map(_ => ({ label: `${_.name}${_.shortcut_num ? ` [${_.shortcut_num}]` : ''}`, value: _.id }));
  const relatedCompanyOptions = (relatedCompanies || []).map(_ => ({ label: _.display_name, value: _.id }));
  const aggregationTypeOptions = entries(aggregationTypes).map(([k, v]) => ({ value: k, label: v.label }));
  const displayColumnTypeOptions = entries(displayColumnTypes).map(([k, v]) => ({ value: k, label: v.label }));
  const comparisonOptions = entries(comparisons).map(([k, v]) => ({ value: k, label: v.label }));
  const metricOptions = entries(metrics).map(([k, v]) => ({ value: k, label: v.label({ comparison }), }));
  useEffect(() => {
    if(queryParams.documentType === undefined) setInitialQueryParams();
  }, [queryParams]);

  return props.translate(
    <div className="company-monthly-trends container-fluid">
      <Nav tabs className="mt-4 mb-4">
        {
          entries(documentTypes).map(([documentType, { label }]) => {
            return (
              <NavItem key={documentType}>
                <Link className={classnames('nav-link cursor-pointer', { active: queryParams.documentType === documentType, })} to={`/companies/${company.id}/monthlyTrends?${qs.stringify({ ...queryParams, documentType, })}`}>
                  {label}
                </Link>
              </NavItem>
            )
          })
        }
      </Nav>
      <WithLoading loading={isEmpty(rowGroups)}>
        <div className="position-relative">
          <div className="mb-2 d-flex align-items-end justify-content-between">
            <div className="d-flex align-items-end justify-content-start gap-1">
              <QuerySelector simple paramName="categories" width={150} isMulti options={categoryOptions} label="カテゴリ" />
              <QuerySelector simple paramName="consolidationAccountItemIds" width={150} isMulti options={consolidationAccountItemOptions} label="連結科目" />
              <QuerySelector simple paramName="relatedCompanyIds" width={150} isMulti options={relatedCompanyOptions} label="会社" />
              <QuerySelector simple paramName="aggregationTypes" width={150} isMulti options={aggregationTypeOptions} label="集計種別" />
              <QuerySelector simple paramName="displayColumnTypes" width={150} isMulti options={displayColumnTypeOptions} label="表示列" />
              <QuerySelector simple paramName="comparison" width={150} options={comparisonOptions} label="比較対象" />
              <QuerySelector simple paramName="metrics" width={150} isMulti options={metricOptions} label="数値種別" />
              <ExportButton fileName={'月次推移.csv'} rows={rowsForExport} disabled={rowGroups == null} />
            </div>
          </div>
          <div className="overflow-auto position-relative" style={{ maxHeight: '100vh', zIndex: 0, }}>
            <table className="table table-bordered sticky-table m-0 table-sm w-auto table-hover">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ width: COLUMN_WIDTH * 1.5, minWidth: COLUMN_WIDTH * 1.5 }} className="sticky">連結科目</th>
                  <th style={{ width: COLUMN_WIDTH * 1, minWidth: COLUMN_WIDTH * 1, }} className="sticky">会社</th>
                  <th style={{ width: COLUMN_WIDTH * 0.7, minWidth: COLUMN_WIDTH * 0.7, }} className="sticky"></th>
                  <th style={{ width: COLUMN_WIDTH * 0.8, minWidth: COLUMN_WIDTH * 0.8, }}></th>
                  {
                    filteredQuarters.map((yearMonths, quarterIndex) => {
                      return (
                        <Fragment key={quarterIndex}>
                          {
                            conclusiveDisplayColumnTypes.includes('month') && yearMonths.map((yearMonth, yearMonthIndex) => {
                              return (
                                <th className="position-relative" key={yearMonth.toString()} style={{ width: COLUMN_WIDTH, minWidth: COLUMN_WIDTH, }}>
                                  {formatDate(yearMonth, 'yyyy/MM')}
                                  <AmountFilterButton columnKey={formatDate(yearMonth, 'yyyyMM')} title={formatDate(yearMonth, 'yyyy/MM')} />
                                </th>
                              );
                            })
                          }
                          {
                            conclusiveDisplayColumnTypes.includes('quarter') && (
                              <th className="font-weight-bold position-relative" style={{ width: COLUMN_WIDTH, minWidth: COLUMN_WIDTH, }}>
                                {quarterIndex + 1}Q
                                <AmountFilterButton columnKey={`${quarterIndex + 1}q`} title={`${quarterIndex + 1}Q`} />
                              </th>
                            )
                          }
                        </Fragment>
                      );
                    })
                  }
                  {
                    ['pl', 'cr'].includes(documentType) && (
                      <th style={{ width: COLUMN_WIDTH, minWidth: COLUMN_WIDTH, }}>
                        合計
                        <AmountFilterButton columnKey="total" title="合計" />
                      </th>
                    )
                  }
                </tr>
              </thead>
              <tbody className="thead-light">
                {
                  filteredRowGroups.map(({ category, filteredItemRowGroups, }) => {
                    return (
                      <Fragment key={category.key()}>
                        {
                          filteredItemRowGroups.map(({ key, item, relatedCompanyRowGroups, filteredRelatedCompanyRowGroups }, itemIndex) => {
                            return (
                              <ItemRowGroup key={key} {...{ notesByKey, item, relatedCompanyRowGroups, filteredRelatedCompanyRowGroups, itemIndex, category, quarters: filteredQuarters, yearMonths: filteredYearMonths, prevYearMonths, documentType, displayColumnTypes: conclusiveDisplayColumnTypes, comparison, }} />
                            );
                          })
                        }
                      </Fragment>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </WithLoading>
    </div>
  );
});

function ItemRowGroup (props) {
  const { item, relatedCompanyRowGroups, filteredRelatedCompanyRowGroups, itemIndex, category, quarters, yearMonths, prevYearMonths, documentType, notesByKey, displayColumnTypes, comparison, } = props;
  const company = useCompany();
  const itemSummaryRowGroup = computeItemSummaryRowGroup(category.type, comparison, yearMonths, prevYearMonths, filteredRelatedCompanyRowGroups);
  const queryParams = useQueryParams();
  const { metricRows } = itemSummaryRowGroup.aggregationTypeRowGroups[0];
  const filteredMetricRows = isEmpty(queryParams.metrics) ? metricRows : metricRows.filter(_ => queryParams.metrics.includes(_.metric));
  const [isOpen, toggle] = useToggle(false);
  return (
    <Fragment>
      <tr>
        <th colSpan={3} style={{ textIndent: (category.indent() + (item.itemType === 'category' ? 0 : 1)) * 18 }} className={classnames('sticky', { 'font-weight-normal': item.itemType !== 'category' })}>
          <Button size="sm" className="small px-0 mr-1" color="link" onClick={toggle}>
            <span className={classnames('fas cursor-pointer', { 'fa-plus': !isOpen, 'fa-minus': isOpen })} />
          </Button>
          {item.name}
        </th>
        <td>
          {
            filteredMetricRows.map(({ metric, }) => {
              return (
                <div key={metric}>{metrics[metric].label({ comparison })}</div>
              );
            })
          }
        </td>
        {
          quarters.map((yearMonths, quarterIndex) => {
            return (
              <Fragment key={quarterIndex}>
                {
                  displayColumnTypes.includes('month') && yearMonths.map((yearMonth, yearMonthIndex) => {
                    const noteKey = [item.id, formatDate(yearMonth, 'yyyyMM')].join('__');
                    return (
                      <td key={yearMonth.toString()} className="text-right position-relative has-hovered-contents">
                        {
                          filteredMetricRows.map(({ metric, quarters, }) => {
                            const value = quarters[quarterIndex]?.monthColumns[yearMonthIndex]?.value();
                            return (
                              <div key={metric}>{isNaN(value) ? '-' : metrics[metric].format(value)}</div>
                            );
                          })
                        }
                        {item.itemType === 'consolidationAccountItem' && <HoveredNoter companyId={company.id} noteKey={noteKey} pageType="monthlyTrends" queryKey="monthlyTrends" note={notesByKey[noteKey]} writable />}
                      </td>
                    );
                  })
                }
                {
                  displayColumnTypes.includes('quarter') && (
                    <td className="text-right position-relative has-hovered-contents">
                      {
                        filteredMetricRows.map(({ metric, quarters, }) => {
                          const value = quarters[quarterIndex]?.quarterColumn.value();
                          return (
                            <div key={metric} className="font-weight-bold">{isNaN(value) ? '-' : metrics[metric].format(value)}</div>
                          );
                        })
                      }
                    </td>
                  )
                }
              </Fragment>
            );
          })
        }
        {
          ['pl', 'cr'].includes(documentType) && (
            <td className="text-right">
              {
                filteredMetricRows.map(({ metric, summaryColumns, }) => {
                  const value = summaryColumns[0].value();
                  return (
                    <div key={metric} className="font-weight-bold">{isNaN(value) ? '-' : metrics[metric].format(value)}</div>
                  );
                })
              }
            </td>
          )
        }
      </tr>
      {
        isOpen && filteredRelatedCompanyRowGroups.map(({ relatedCompany, filteredAggregationTypeRowGroups }, relatedCompanyIndex) => {
          return (
            <CompanyRowGroup key={relatedCompany.id} {...{ relatedCompanyIndex, filteredRelatedCompanyRowGroups, category, item, relatedCompany, filteredAggregationTypeRowGroups, isOpenItemRowGroup: isOpen, toggleItemRowGroup: toggle, quarters, yearMonths, documentType, displayColumnTypes, comparison, }} />
          );
        })
      }
    </Fragment>
  );
}

function CompanyRowGroup (props) {
  const { relatedCompanyIndex, filteredRelatedCompanyRowGroups, category, item, relatedCompany, filteredAggregationTypeRowGroups, isOpenItemRowGroup, toggleItemRowGroup, quarters, yearMonths, documentType, displayColumnTypes, comparison, } = props;
  const [isOpen, toggle] = useToggle(false);
  const displayAggregationTypeRowGroups = isOpen ? filteredAggregationTypeRowGroups : [filteredAggregationTypeRowGroups.find(_ => _.aggregationType === 'consolidated') || filteredAggregationTypeRowGroups[0]];
  return (
    <Fragment>
      {
        displayAggregationTypeRowGroups.map(({ aggregationType, filteredMetricRows }, aggregationTypeIndex) => {
          return (
            <tr key={aggregationType}>
              <th colSpan={3} className="sticky">
                <div className="d-flex">
                  <div style={{ width: COLUMN_WIDTH * 1.5, minWidth: COLUMN_WIDTH * 1.5 }} />
                  <div style={{ width: COLUMN_WIDTH * 1, minWidth: COLUMN_WIDTH * 1 }}>
                    {
                      aggregationTypeIndex === 0 && (
                        <Fragment>
                          <Button size="sm" className="small px-0 mr-1" color="link" onClick={toggle}>
                            <span className={classnames('fas cursor-pointer', { 'fa-plus': !isOpen, 'fa-minus': isOpen })} />
                          </Button>
                          {relatedCompany.display_name}
                        </Fragment>
                      )
                    }
                  </div>
                  <div style={{ width: COLUMN_WIDTH * 0.7, minWidth: COLUMN_WIDTH * 0.7 }}>
                    {aggregationTypes[aggregationType].label}
                  </div>
                </div>
              </th>
              <td>
                {
                  filteredMetricRows.map(({ metric, }, metricIndex) => {
                    return (
                      <div key={metric}>{metrics[metric].label({ comparison })}</div>
                    );
                  })
                }
              </td>
              {
                quarters.map((yearMonths, quarterIndex) => {
                  return (
                    <Fragment key={quarterIndex}>
                      {
                        displayColumnTypes.includes('month') && yearMonths.map((yearMonth, yearMonthIndex) => {
                          return (
                            <td key={yearMonth.toString()} className="text-right">
                              {
                                filteredMetricRows.map(({ metric, quarters, }, metricIndex) => {
                                  const value = quarters[quarterIndex]?.monthColumns[yearMonthIndex]?.value();
                                  return (
                                    <div key={metric}>{isNaN(value) ? '-' : metrics[metric].format(value, aggregationType, relatedCompany)}</div>
                                  );
                                })
                              }
                            </td>
                          );
                        })
                      }
                      {
                        displayColumnTypes.includes('quarter') && (
                          <td className="text-right">
                            {
                              filteredMetricRows.map(({ metric, quarters, }, metricIndex) => {
                                const value = quarters[quarterIndex]?.quarterColumn.value();
                                return (
                                  <div key={metric} className="font-weight-bold">{isNaN(value) ? '-' : metrics[metric].format(value, aggregationType, relatedCompany)}</div>
                                );
                              })
                            }
                          </td>
                        )
                      }
                    </Fragment>
                  );
                })
              }
              {
                ['pl', 'cr'].includes(documentType) && (
                  <td className="text-right">
                    {
                      filteredMetricRows.map(({ metric, summaryColumns, }, metricIndex) => {
                        const totalColumn = summaryColumns[0];
                        return (
                          <div key={metric} className="font-weight-bold">{metrics[metric].format(totalColumn.value(), aggregationType, relatedCompany)}</div>
                        );
                      })
                    }
                  </td>
                )
              }
            </tr>
          );
        })
      }
    </Fragment>
  );
}

function AmountFilterButton (props) {
  const { title, columnKey } = props;
  const queryParams = useQueryParams();
  const isActive = ['change', 'changeRate'].some(_ => !isEmpty(queryParams[`${_}__${columnKey}`]));
  const content = () => {
    return (
      <div>
        <QueryInput type="integer" paramName={`change__${columnKey}`} width={150} label="増減額(以上)" inputClassName="text-right" />
        <QueryInput type="float" paramName={`changeRate__${columnKey}`} width={150} label="増減率(以上)" inputClassName="text-right" />
      </div>
    );
  };

  return (
    <ModalButton color="link" size="sm" className="p-0 position-absolute" title={title} content={content} style={{ top: 5, right: 5 }}>
      <span className={classnames('fas fa-filter', { 'text-info': isActive, 'text-secondary': !isActive, })} />
    </ModalButton>
  );
}
