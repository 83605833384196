import React from 'react';
import { isEmpty, isFunction, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';
import classnames from 'classnames';

export default function ProgressButton ({ label = '', icon = null, children, process = _ => _, disabled, ...extraProps }) {
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    toggleProcessing(true);
    await process();
    toggleProcessing(false);
  };
  const content = label || (isFunction(children) ? children({ isProcessing }) : children);
  const marginClass = isEmpty(content) ? '' : 'mr-1';

  return (
    <Button onClick={onClick} disabled={isProcessing || disabled} data-operation-type="write" {...extraProps}>
      {isProcessing ? <span className={classnames('fas fa-spin fa-spinner', marginClass)} /> : (icon && <span className={classnames(marginClass)}>{icon}</span>)}
      {content}
    </Button>
  );
};
