import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { batch } from '../../shared/firebase';
import { accountItemCategoryNames, } from '../../shared/config';
import { numberFormat, floatFormat } from '../../util';
import { fields } from '../../shared/models/cash';
import { generateRows, computeAlerts, rowsForExport, } from '../../shared/lib/cashes';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import usePkgItems from '../hooks/usePkgItems';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import AmountWithExchange from '../AmountWithExchange';
import Alerts from '../Alerts';

const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyCashes (props) {
  const { relatedCompany, company, subsidiaryId, period, yearMonth, filteredYearMonths, cr, isLockedMonth, } = props;
  const cashesRef = company.ref.collection('cashes');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const accountItemsByName = keyBy(accountItems, 'name');
  const { items: cashes } = usePkgItems(company, relatedCompany, subsidiaryId, accountItems, 'cashes', [], 'amount', [], filteredYearMonths);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const rows = generateRows(cashes || [], accountItems, trials);
  const onClickImportFromFreee = async () => {
    if(!window.confirm('試算表から取り込みます。よろしいですか？')) return;

    await batch(db, trials.flatMap(_ => _.balances).filter(_ => _.account_category_name === '現金・預金').filter(_ => Math.abs(_.closing_balance) > 0), (batch, balance) => {
      const accountItemId = accountItemsByName[balance.account_item_name]?.id;
      if(cashes.some(_ => _.accountItemId === accountItemId)) return;

      batch.set(cashesRef.doc(), {
        accountItemId,
        amount: balance.closing_balance,
        note: '',
        subsidiaryId,
        period,
        yearMonth,
        createdAt: new Date(),
      });
    });
    toast.success('取り込みました');
  };
  const alerts = computeAlerts(cashes || [], accountItems, trials);

  return props.translate(
    <div className="company-cashes">
      <Alerts alerts={alerts} />
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ProgressButton label="試算表から取込み" process={onClickImportFromFreee} disabled={isLockedMonth} />
        <ExportButton fileName="現金及び現金同等物.csv" rows={rowsForExport(cashes, accountItems, trials)} />
        <AddButton itemRef={cashesRef.doc()} processValues={_ => ({ ..._, subsidiaryId, period, yearMonth, })} FormModal={ModelFormModal} formProps={{ title: '現金及び現金同等物 追加', documentName: 'generalPkgItem', fields: fields({ accountItems, otherItems: rows, }), }} disabled={isLockedMonth} />
      </div>
      <div>
        <table className="table sticky-table">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }}>個別勘定科目</th>
              <th style={{ minWidth: 200 }}>金額</th>
              <th style={{ minWidth: 200 }}>試算表残高</th>
              <th style={{ minWidth: 250 }}>備考</th>
              <th style={{ minWidth: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              rows.map((cash) => {
                const { id, ref, accountItemId, accountItem, trialAmount, amount, exchangedAmounts, note, } = cash;

                return (
                  <tr key={id}>
                    <td>
                      {accountItem && accountItem.name}
                    </td>
                    <td className={classnames('text-right', { 'table-danger': floatFormat(amount) !== floatFormat(trialAmount) })}>
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[amount, exchangedAmounts.amount]} />
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[trialAmount, trialAmount * cr]} />
                    </td>
                    <td>
                      <AutoLinkText>
                        {note}
                      </AutoLinkText>
                    </td>
                    <td className="text-nowrap text-right">
                      <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '現金及び現金同等物 編集', documentName: 'generalPkgItem', fields: fields({ accountItems, otherItems: rows.filter(_ => _.id !== id), }), }} disabled={isLockedMonth} />
                      <DeleteButton item={cash} itemRef={ref} className="ml-2" disabled={isLockedMonth} />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
