import React, { Fragment, Component, useRef, useEffect, useMemo, } from 'react';
import { useToggle, useAsync, useRaf, } from 'react-use';
import { isEmpty, last, pick, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { generateRows, rowsForExport, } from '../../shared/lib/consolidationSegments';
import { noneSection, } from '../../shared/models/section';
import { fields, generalSegment, } from '../../shared/models/consolidationSegment';
import { integerFormat } from '../../util';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ModelFormModal from '../modals/ModelFormModal';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();

export default CompanyPage(function CompanyConsolidationSegments (props) {
  const { company, } = props;
  const queryParams = useQueryParams();
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const companies = [company, ...subsidiaries];
  const sections = useCollectionSubscription(company.ref.collection('sections'));
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'));
  const consolidationSegmentsById = keyBy(consolidationSegments, 'id');
  const sortedConsolidationSegments = sortBy(consolidationSegments, 'index');
  const rows = generateRows(sortedConsolidationSegments, sections, company, companies);
  const rowsWithoutGeneral = rows.slice(0, rows.length - 1);
  const generalRow = last(rows);
  const processRow = (batch, row, i) => {
    const { id, name, } = row;
    const ref = db.collection('consolidationSegments').doc(...(id ? [id] : []));
    const exists = consolidationSegmentsById[id] != null;
    batch.set(ref, {
      name,
      ...(
        !exists && {
          index: (isEmpty(consolidationSegments) ? 0 : last(consolidationSegments).index) + i + 1,
          createdAt: new Date(),
        }
      ),
    }, { merge: true });
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(rowsWithoutGeneral, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('consolidationSegments').doc(id), { index });
    });
  };
  const renderRow = (row) => {
    const { id, ref, name, columns, createdInYui = false, } = row;
    return (
      <tr data-id={id} style={{ display: 'flex', overflow: 'initial', }}>
        <th style={{ width: 110, }} className="sticky">
          {
            id !== 'general' && (
              <div className="d-flex gap-1">
                <div className="drag-handle text-muted cursor-pointer px-2 py-1">
                  <span className="fas fa-grip-vertical" />
                </div>
                <EditButton size="sm" label={false} itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '連結セグメント', fields: fields(), }} />
                <DeleteButton size="sm" label={false} item={row} itemRef={ref} />
              </div>
            )
          }
        </th>
        <th style={{ width: 200, }} className="sticky">
          {name}
        </th>
        {
          columns.map((column, i) => {
            return (
              <td key={i} style={{ width: 200 }}>
                {
                  column.sections.map((section) => {
                    return (
                      <div key={section.id}>
                        {section.name}
                      </div>
                    );
                  })
                }
              </td>
            );
          })
        }
      </tr>
    );
  };

  return props.translate(
    <div className="company-account-item-mappings container-fluid">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結セグメントを作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結セグメント</h4>
        </div>
        <div className="mb-3 d-flex align-items-end justify-content-start gap-1">
          <ImportButton processRow={processRow} documentName="consolidationSegment" fields={fields()} importKey="consolidationSegments" />
          <ExportButton fileName="連結セグメント.csv" rows={rowsForExport(sortedConsolidationSegments, sections, company, companies)} />
          <AddButton itemRef={company.ref.collection('consolidationSegments').doc()} FormModal={ModelFormModal} processValues={_ => ({ ..._, index: (last(sortedConsolidationSegments)?.index || 0) + 1 })} formProps={{ title: '連結セグメント 追加', fields, }} />
        </div>
        <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
          <table className="table table-bordered sticky-table mb-0 flex-table">
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th style={{ width: 110 }} className="sticky"></th>
                <th style={{ width: 200, }} className="sticky">連結セグメント名</th>
                {
                  companies.map((company) => {
                    return (
                      <th key={company.id} style={{ width: 200 }}>
                        {company.display_name}
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            <Container
              dragHandleSelector=".drag-handle"
              onDrop={onDrop}
              dropPlaceholder={{ style: { background: 'eee', } }}
              render={(ref) => (
                <tbody ref={ref} className="thead-light">
                  {
                    rowsWithoutGeneral.map((row) => {
                      return (
                        <Draggable
                          key={row.id}
                          render={() => renderRow(row)}
                        />
                      );
                    })
                  }
                </tbody>
              )}
            />
            <tbody className="thead-light">
              {
                renderRow(generalRow)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
