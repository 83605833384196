import React from 'react';
import { useToggle } from 'react-use';
import { findKey, sortBy, last, first, } from 'lodash';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { startOfMonth, endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';

import { serial, } from '../shared/util';
import { documentTypes, } from '../shared/config';
import { startOfMonthByFiscalYears, } from '../utils';
import ExportButton from './ExportButton';
import firebase, { functions } from '../firebase';

const syncTrials = functions.httpsCallable('syncTrials');
const fetchRawTrials = functions.httpsCallable('fetchRawTrials');

export default function TrialsSyncButton (props) {
  const { relatedCompany, company, companySourceId, subsidiaryId, subsidiaryStartPeriod, subsidiaryStartYearMonth, subsidiaryEndPeriod, subsidiaryEndYearMonth, yearMonth, fiscalYears, admin, ...extraProps } = props;
  const [isSyncing, toggleSyncing] = useToggle();
  const onClickSync = async () => {
    toggleSyncing(true);
    const startDate = formatDate(startOfMonth(parseDate(subsidiaryStartYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const endDate = formatDate(endOfMonth(parseDate(subsidiaryEndYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const filteredFiscalYears = fiscalYears.filter(_ => _.end_date >= startDate).filter(_ => _.start_date <= endDate);
    const dateRanges = filteredFiscalYears
      .map(_ => ({ dataAreaName: _.Name, startDate: last(sortBy([_.start_date, startDate], _ => _)), endDate: first(sortBy([_.end_date, endDate])), }));
    const sync = async (type) => {
      try {
        const { data } = await syncTrials({ type, externalType: relatedCompany.externalType, companyId: company.id, companySourceId: companySourceId || relatedCompany.sourceId, ownerId: findKey(company.users, { role: 'owner' }), subsidiaryId, startDate, endDate, yearMonth, dateRanges, });
        if(data?.errors) {
          toast.error(`${documentTypes[type].label}の試算表の同期に失敗しました。 / ${data.errors.join(',')}`);
        } else {
          toast.success(`${documentTypes[type].label}の試算表を同期しました`);
        }
      } catch(e) {
        toast.error(`${documentTypes[type].label}の試算表の同期に失敗しました`);
        console.error(e);
      }
    };
    if(relatedCompany.externalType === 'pca') {
      await serial(props.documentTypes, async (type) => {
        await sync(type);
      });
    } else {
      await Promise.all(props.documentTypes.map(async (type, i) => {
        if(new Date() > new Date(relatedCompany[relatedCompany.externalType]?.expires) && i !== 0) {
          await new Promise(_ => setTimeout(_, 10000));
        }
        await sync(type);
      }));
    }
    toggleSyncing(false);
  };
  const rawRows = async () => {
    const startDate = formatDate(startOfMonth(parseDate(subsidiaryStartYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const endDate = formatDate(endOfMonth(parseDate(subsidiaryEndYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const filteredFiscalYears = fiscalYears.filter(_ => _.end_date >= startDate).filter(_ => _.start_date <= endDate);
    const dateRanges = filteredFiscalYears
      .map(_ => ({ startDate: last(sortBy([_.start_date, startDate], _ => _)), endDate: first(sortBy([_.end_date, endDate])), }));
    const results = await Promise.all(props.documentTypes.map(async (type) => {
      return {
        type,
        result: await fetchRawTrials({ type, externalType: relatedCompany.externalType, companyId: company.id, companySourceId: companySourceId || relatedCompany.sourceId, ownerId: findKey(company.users, { role: 'owner' }), subsidiaryId, startDate, endDate, yearMonth, dateRanges, }),
      };
    }));
    return results.flatMap(({ type, result }) => {
      return result.data.flatMap(({ balances, startDate, endDate }) => {
        return balances.map(_ => ({ type, startDate, endDate, ..._, }));
      });
    });
  };
  const mainButton = (
    <Button color="secondary" {...extraProps} data-operation-type="write" disabled={isSyncing || extraProps.disabled} onClick={onClickSync}>
      <span className={classnames('mr-1 fas fa-sync cursor-pointer', { 'fa-spin': isSyncing })} />
      試算表を同期
    </Button>
  );

  return admin && relatedCompany.externalType ===  'bugyo' ? (
    <div className="d-flex gap-1">
      {mainButton}
      <ExportButton fileName="raw_trials.csv" label="raw trials" rows={rawRows} />
    </div>
  ) : (
    mainButton
  );
};
