const { mapValues, zip, keyBy, sortBy, groupBy, sumBy, get, sum, omit, } = require('lodash');
const numeral = require('numeral');

const { floatFormat } = require('../util');
const { cfAccountItemCategories, } = require('../config');
const { generateRowGroups, } = require('./pkgCashes');

const { keys, entries, } = Object;
const { abs, round } = Math;

const sumRow = (rowsGroupedByCategory, ...cfAccountItemCategoryKeys) => sumBy(cfAccountItemCategoryKeys.map(_ => get(rowsGroupedByCategory, _, [])).flat(), _ => _.totalAmount || 0);

const generateRowsGroupedByCategory = (cfChange, cfChangeTypes, cfAccountItems, disclosureSetting) => {
  const cfChangeTypeIds = cfChangeTypes.map(_ => _.id);
  const cfChangeValuesGroupedByCfAccountItemId = groupBy(Object.values(cfChange?.values || {}).filter(_ => isFinite(_?.amount)), 'cfAccountItemId');
  const cfAccountItemsGroupedByCategoryKey = groupBy(cfAccountItems, 'cfAccountItemCategoryKey');
  return mapValues(cfAccountItemsGroupedByCategoryKey, (cfAccountItems) => {
    const rows = cfAccountItems.map((cfAccountItem) => {
      const cfChangeValues = cfChangeValuesGroupedByCfAccountItemId[cfAccountItem.id] || [];
      const totalAmount = sumBy(cfChangeValues.filter(_ => cfChangeTypeIds.includes(_.cfChangeTypeId)), 'amount');
      return {
        cfAccountItem,
        totalAmount,
      };
    });
    const asOtherRows = rows.filter(_ => disclosureSetting?.otherCfAccountItems?.includes(_.cfAccountItem.id));
    return [
      ...rows.filter(_ => !asOtherRows.includes(_)),
      ...(
        asOtherRows.length > 0 ? [
          {
            cfAccountItem: { name: 'その他' },
            totalAmount: sumBy(asOtherRows, 'totalAmount'),
          }
        ] : []
      ),
    ];
  });
};

const rowsForExport = (cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount) => () => {
  const rowsGroupedByCategory = generateRowsGroupedByCategory(cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting);
  return [
    ...(
      (rowsGroupedByCategory.sales || []).map((row) => {
        const { cfAccountItem, totalAmount, } = row;
        return {
          category: '',
          cfAccountItemName: cfAccountItem?.name,
          amount: totalAmount,
        };
      }, {})
    ),
    {
      category: '小計',
      amount: sumRow(rowsGroupedByCategory, 'sales'),
    },
    ...(
      (rowsGroupedByCategory.salesBottom || []).map((row) => {
        const { cfAccountItem, totalAmount, } = row;
        return {
          cfAccountItemName: cfAccountItem?.name,
          amount: totalAmount,
        };
      }, {})
    ),
    {
      category: '営業活動によるキャッシュ・フロー',
      amount: sumRow(rowsGroupedByCategory, 'sales', 'salesBottom'),
    },
    ...(
      (rowsGroupedByCategory.investment || []).map((row) => {
        const { cfAccountItem, totalAmount, } = row;
        return {
          cfAccountItemName: cfAccountItem?.name,
          amount: totalAmount,
        };
      }, {})
    ),
    {
      category: '投資活動によるキャッシュ・フロー',
      amount: sumRow(rowsGroupedByCategory, 'investment'),
    },
    ...(
      (rowsGroupedByCategory.finance || []).map((row) => {
        const { cfAccountItem, totalAmount, } = row;
        return {
          cfAccountItemName: cfAccountItem?.name,
          amount: totalAmount,
        };
      }, {})
    ),
    {
      category: '財務活動によるキャッシュ・フロー',
      amount: sumRow(rowsGroupedByCategory, 'finance'),
    },
    {
      category: '現金及び現金同等物に係る換算差額',
      amount: sumRow(rowsGroupedByCategory, 'cashExchangeDiff'),
    },
    {
      category: '現金及び現金同等物の増加（減少）額',
      amount: sumRow(rowsGroupedByCategory, 'sales', 'salesBottom', 'investment', 'finance', 'cashExchangeDiff'),
    },
    {
      category: '現金及び現金同等物の期首残高',
      amount: generalAmount?.openingCashBalance,
    },
    ...(
      (rowsGroupedByCategory.openingCash || []).map((row) => {
        const { cfAccountItem, totalAmount, } = row;
        return {
          cfAccountItemName: cfAccountItem?.name,
          amount: totalAmount,
        };
      }, {})
    ),
    {
      category: '現金及び現金同等物の期末残高',
      amount: sumRow(rowsGroupedByCategory, 'sales', 'salesBottom', 'investment', 'finance', 'cashExchangeDiff', 'openingCash') + numeral(generalAmount?.openingCashBalance).value(),
    },
  ];
};

const computeAlerts = (cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount, cashGroups, company, individualAdjustments, consolidationJournals, relatedCompanies, accountItems, consolidationAccountItems, exchangeRate) => {
  const rowsGroupedByCategory = generateRowsGroupedByCategory(cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting);
  const cashRowGroups = generateRowGroups(cashGroups, individualAdjustments, consolidationJournals, company, accountItems, consolidationAccountItems, exchangeRate);
  const cfClosingBalance = sumRow(rowsGroupedByCategory, 'sales', 'salesBottom', 'investment', 'finance', 'cashExchangeDiff', 'openingCash') + numeral(generalAmount?.openingCashBalance).value();
  const totalCashesAmount = Math.round(sumBy(cashRowGroups.flatMap(_ => _.relatedCompanyRowGroups.flatMap(_ => _.rows)), 'exchangedAmount'));
  const diff = cfClosingBalance - totalCashesAmount;
  return [
    Math.abs(diff) > 0 && `全社連結PKGの現金及び現金同等物と不一致です (${numeral(diff).format()})`,
  ].filter(_ => _);
};


exports.sumRow = sumRow;
exports.generateRowsGroupedByCategory = generateRowsGroupedByCategory;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
