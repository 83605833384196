const { isEmpty, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const changeFieldNames = [
  'issue',
  'interest',
  'fulfill',
  'fulfillmentDiff',
  'repayment',
  'transfer',
  'other',
];


const fields = ({ accountItems = [], otherItems, } = {}) => {
  const unique = (v, s) => otherItems?.every(o => ['accountItemId'].some(_ => o[_] !== s[_]));
  return {
    accountItemId: {
      label: '個別勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
        unique,
      },
    },
    opening: {
      label: '期首',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    issue: {
      label: '計上',
      type: 'float',
    },
    interest: {
      label: '利息費用',
      type: 'float',
    },
    fulfill: {
      label: '履行',
      type: 'float',
    },
    fulfillmentDiff: {
      label: '履行差額',
      type: 'float',
    },
    repayment: {
      label: '返済',
      type: 'float',
    },
    transfer: {
      label: '振替',
      type: 'float',
    },
    other: {
      label: 'その他',
      type: 'float',
    },
  };
};

exports.fields = fields;
exports.changeFieldNames = changeFieldNames;
