import React, { Fragment, useState, } from 'react';
import { TextDecoder, } from 'text-encoding';
import { parse as parseCsv, unparse as unparseCsv } from 'papaparse';
import { isFunction, get, isEmpty, chunk } from 'lodash';
import classnames from 'classnames';
import { format as formatDate, } from 'date-fns';
import { Button, Input, } from 'reactstrap';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import XLSX from 'xlsx';

import firebase, { functions } from '../firebase';
import { readFile } from '../utils';
import { encode } from '../util';
import texts from '../shared/texts';
import ModelFormModal from './modals/ModelFormModal';
import ModalButton from './ModalButton';
import DeleteButton from './DeleteButton';
import ProgressButton from './ProgressButton';
import useCompany from './hooks/useCompany';
import useFirebaseUser from './hooks/useFirebaseUser';
import useCollectionSubscription from './hooks/useCollectionSubscription';

const { entries } = Object;
const db = firebase.firestore();
const storageRef = firebase.storage().ref();
const getImportFileSinedUrl = functions.httpsCallable('getImportFileSinedUrl');

export default function ImportButton ({ label = 'インポート', encoding = 'Shift_JIS', onComplete = _ => _, processRows = _ => _, processValidatedRows = _ => _, processRow = _ => _, validateRow: _validateRow, beforeSave = _ => _, disabled = false, documentName = '', fields, onFailed, importKey, beforeDeleteImport = _ => true, deleteImportConfirmMessage, deleteImportDisabled = _ => false, ...extraProps }) {
  const [isImporting, setIsImporting] = useState(false);
  const [workbook, setWorkbook] = useState(null);
  const [file, setFile] = useState(null);
  const { firebaseUser } = useFirebaseUser();
  const company = useCompany();

  const onSelectFiles = async({ target, target: { files: [file] } } ) => {
    if(!file) return;

    setFile(file);
    target.value = '';
    const fileContent = await readFile(file, 'readAsArrayBuffer');
    if(file.name.toLowerCase().endsWith('.csv')) {
      const decoder = new TextDecoder(encoding);
      const csv = decoder.decode(fileContent);
      await importCsv(csv, file);
    } else {
      const workbook = XLSX.read(fileContent);
      setWorkbook(workbook);
    }
  };
  const defaultRowValidator = (row, rows) => {
    const _fields = isFunction(fields) ? fields({ row, rows }) : fields || {};
    const errors = entries(_fields).map(([fieldName, { hidden = _ => false, validations = {} }]) => {
      const shouldHide = hidden(row);
      return entries(hidden(row) ? {} : validations)
        .filter(([k, v]) => !v(row[fieldName], row, k))
        .map(([key]) => `[${fieldName}] ` + (
          get(texts.validations[documentName], `${fieldName}.${key}`)
          || get(texts.validations[documentName], key)
          || get(texts.validations.general, key)
        ));
    }).flat();
    // NOTE: rowがarrayのときあるため、mutableになってる
    row.errors = errors;
    return row;
  };
  const validateRow = (row, rows) => {
    return _validateRow ? _validateRow(row, defaultRowValidator, rows) : defaultRowValidator(row, rows);
  };
  const onSubmitWorkbookSheetName = async ({ sheetName }) => {
    const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
    await importCsv(csv, file);
    setWorkbook(null);
  };
  const importCsv = async (csv, file) => {
    setIsImporting(true);
    try {
      const importRef = company.ref.collection('imports').doc();
      const { data: rows } = parseCsv(csv, { header: true });
      const presentRows = rows.filter(_ => !Object.values(_).every(isEmpty));
      const processedRows = await processRows(presentRows);
      const validatedRows = processedRows.map(_ => validateRow(_, processedRows));
      if(validatedRows.filter(_ => !isEmpty(_.errors)).length > 0) {
        const content = encode(unparseCsv(validatedRows.map((row, i) => {
          return {
            ...(presentRows.length === validatedRows.length ? rows[i] : validatedRows[i]),
            errors: row.errors.join('\n'),
          };
        })));
        fileDownload(content, 'エラー結果.csv');
        throw new Error();
      }

      const validatedProcessedRows = processValidatedRows(validatedRows);
      await beforeSave(validatedProcessedRows, importRef);

      const storagePath = `companies/${company.id}/imports/${importRef.id}/${formatDate(new Date(), 'yyyyMMddHHmmss')}/${file.name}`;
      const fileRef = storageRef.child(storagePath);
      await fileRef.put(file, { name: file.name, contentType: file.type });
      await importRef.set({
        key: importKey || null,
        storagePath,
        fileName: file.name,
        fileType: file.type,
        createdBy: firebaseUser.uid,
        createdAt: new Date(),
      });

      try {
        await chunk(validatedProcessedRows, 500).reduce(async (x, rows, i1) => {
          await x;
          const batch = db.batch();
          rows.forEach((_, i) => processRow(batch, _, i1 * 500 + i, importRef));
          await batch.commit();
        }, Promise.resolve());
      } catch(e) {
        await importRef.delete();
        throw e;
      }
      await onComplete(validatedProcessedRows);
      toast.success('インポートしました');
    } catch(e) {
      console.error(e);
      toast.error('インポート失敗しました');
      onFailed && onFailed(e);
    }
    setIsImporting(false);
  };

  return (
    <div>
      <div className="d-flex align-items-end gap-1">
        <Button color="secondary" disabled={disabled || isImporting} {...extraProps} data-operation-type="write">
          <label className="m-0 cursor-pointer">
            <span className={classnames('fas', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting, 'mr-1': !isEmpty(label), })} />
            {label}
            {
              !disabled && (
                <Input type="file" className="d-none" onChange={onSelectFiles} accept=".csv,.xls,.xlsx" />
              )
            }
          </label>
          <ModalButton title="インポート履歴" color="lightgray" size="sm" className="ml-2 px-2 py-0" content={_ => <HistoryModalContent importKey={importKey} beforeDeleteImport={beforeDeleteImport} deleteImportConfirmMessage={deleteImportConfirmMessage} deleteImportDisabled={deleteImportDisabled} />}>
            <span className="fas fa-history" />
          </ModalButton>
        </Button>
      </div>
      {
        workbook != null && (
          <ModelFormModal
            submitLabel="インポート"
            title="Excelインポート"
            fields={{
              sheetName: {
                label: 'シート',
                type: 'select',
                options: workbook.SheetNames.map(_ => ({ label: _, value: _, })),
                validations: { required: _ => _ != null },
              }
            }}
            isOpen
            onSubmit={onSubmitWorkbookSheetName}
            onClickClose={_ => setWorkbook(null)}
          />
        )
      }
    </div>
  );
};

function HistoryModalContent (props) {
  const { importKey, beforeDeleteImport, deleteImportConfirmMessage, deleteImportDisabled, } = props;
  const company = useCompany();
  const imports = useCollectionSubscription(company.ref.collection('imports').where('key', '==', importKey), [company, importKey]);
  return (
    <table className="table table-bordered">
      <tbody>
        {
          imports.map((_import) => {
            const { id, ref, fileName, createdAt, } = _import;
            const onClickDownload = async () => {
              const { data: { url } } = await getImportFileSinedUrl({ companyId: company.id, importId: id });
              window.open(url);
            };

            return (
              <tr key={id}>
                <td>
                  {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td>
                  {fileName}
                </td>
                <td className="text-nowrap">
                  <ProgressButton icon={<span className="fas fa-download" />} process={onClickDownload} />
                  <DeleteButton className="ml-1" item={_import} itemRef={ref} beforeDelete={beforeDeleteImport} confirmMessage={deleteImportConfirmMessage} disabled={deleteImportDisabled(id)} />
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}
