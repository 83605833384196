import React, { Component, Fragment, useState, useEffect, } from 'react';
import { useToggle, useAsync, useMap, } from 'react-use';
import { isEmpty, pick, get, orderBy, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { batch } from '../../shared/firebase';
import firebase, { functions } from '../../firebase';
import { numberFormat, floatFormat, } from '../../util';
import { withConsolidationAccountItem, } from '../../shared/models/trial';
import { accountItemCategoryNames, } from '../../shared/config';
import { getCategory, } from '../../shared/models/accountItem';
import { changeFieldNames as debtChangeFieldNames, fields as debtFields, planFields as debtPlanFields, } from '../../shared/models/debt';
import { changeFieldNames as bondChangeFieldNames, fields as bondFields, planFields as bondPlanFields} from '../../shared/models/bond';
import { changeFieldNames as capitalChangeFieldNames, fields as capitalFields, } from '../../shared/models/capital';
import { changeFieldNames as treasuryStockChangeFieldNames, fields as treasuryStockFields, } from '../../shared/models/treasuryStock';
import { changeFieldNames as stockOptionChangeFieldNames, fields as stockOptionFields, } from '../../shared/models/stockOption';
import { fields as expenseInterestItemFields } from '../../shared/models/expenseInterestItem';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import PledgedAssetsModal from '../modals/PledgedAssetsModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ProgressButton from '../ProgressButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModalButton from '../ModalButton';
import QuerySelector from '../QuerySelector';
import AmountWithExchange from '../AmountWithExchange';
import IndividualChangesTableSection from '../IndividualChangesTableSection';
import IndividualSimpleTableSection from '../IndividualSimpleTableSection';

const { keys, entries } = Object;
const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyFinances (props) {
  const { period, relatedCompany, yearMonth, company, subsidiaryId, } = props;
  const [state, { set: setState }] = useMap({});
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const itemTrials = useCollectionSubscription(company.ref.collection('itemTrials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);

  return props.translate(
    <div className="company-finances">
      <IndividualChangesTableSection
        section="財務関連"
        title="外部借入"
        filterTrials={_ => _.consolidationAccountItem?.group_name?.includes('借入金')}
        collectionName="debts"
        fields={debtFields}
        changeFieldNames={debtChangeFieldNames}
        onLoadItems={_ => setState('外部借入', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="財務関連"
        title="社債"
        filterTrials={_ => _.consolidationAccountItem?.group_name?.includes('社債')}
        collectionName="bonds"
        fields={bondFields}
        changeFieldNames={bondChangeFieldNames}
        onLoadItems={_ => setState('社債', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, }}
      />
      <hr className="my-5" />
      <IndividualSimpleTableSection
        section="財務関連"
        title="未払利息・前払利息"
        collectionName="expenseInterestItems"
        fields={expenseInterestItemFields}
        {...{ ...props, accountItems, trials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="財務関連"
        title="資本"
        filterTrials={_ => _.consolidationAccountItem?.group_name?.match(/資本|新株式/)}
        collectionName="capitals"
        fields={capitalFields}
        changeFieldNames={capitalChangeFieldNames}
        onLoadItems={_ => setState('資本', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="財務関連"
        title="自己株式増減"
        filterTrials={_ => _.consolidationAccountItem?.group_name?.match(/自己株式/)}
        collectionName="treasuryStocks"
        fields={treasuryStockFields}
        changeFieldNames={treasuryStockChangeFieldNames}
        onLoadItems={_ => setState('自己株式増減', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="財務関連"
        title="新株予約権"
        filterTrials={_ => _.consolidationAccountItem?.group_name?.match(/新株予約権/)}
        collectionName="stockOptions"
        fields={stockOptionFields}
        changeFieldNames={stockOptionChangeFieldNames}
        onLoadItems={_ => setState('新株予約権', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, }}
      />
    </div>
  );
});
