import React, { Fragment, Component, useRef, useEffect, useMemo, } from 'react';
import { useToggle, useAsync, useRaf, } from 'react-use';
import { invert, mapValues, orderBy, isEmpty, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { fieldDisplayValue, } from '../../shared/util';
import { generateRowGroups, rowsForExport, } from '../../shared/lib/cfMappings';
import { accountItemCategories, cfAccountItemCategories, } from '../../shared/config';
import { cfMappingPresetConsolidationAccountItems, cfMappingItemFields, pkgCategories, } from '../../shared/models/consolidationAccountItem';
import { presetCfAccontItems, } from '../../shared/models/cfAccountItem';
import { integerFormat } from '../../util';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ModelFormModal from '../modals/ModelFormModal';
import CfMappingModal from '../modals/CfMappingModal';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import QueryBoolean from '../QueryBoolean';

const { keys, entries } = Object;
const db = firebase.firestore();
const accountItemCategoryNames = accountItemCategories.map(_ => _.name);
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);
const pkgCategoriesByName = invert(mapValues(pkgCategories(), 'label'));

export default CompanyPage(function CompanyCfMappings (props) {
  const { company, } = props;
  const queryParams = useQueryParams();
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const companies = [company, ...subsidiaries];
  const cfChangeTypes = useCollectionSubscription(company.ref.collection('cfChangeTypes'), [company]);
  const cfChangeTypesByName = keyBy(cfChangeTypes, 'name');
  const sortedCfChangeTypes = sortBy(cfChangeTypes, 'index');
  const cfAccountItems = useCollectionSubscription(company.ref.collection('cfAccountItems'), [company]);
  const allCfAccountItems = [...cfAccountItems, ...presetCfAccontItems];
  const cfAccountItemsByName = keyBy(allCfAccountItems, 'name');
  const sortedCfAccountItems = orderBy(allCfAccountItems, [_ => cfAccountItemCategoryKeys.indexOf(_.cfAccountItemCategoryKey), 'index'], ['asc', 'asc'])
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const presetConsolidationAccountItemSettings = useCollectionSubscription(company.ref.collection('presetConsolidationAccountItemSettings'), [company], { initialItems: null });
  const presetConsolidationAccountItemSettingsById = keyBy(presetConsolidationAccountItemSettings, 'id');
  const accountItemsGroupedByConsolidationAccountItemId = groupBy(accountItems, 'consolidationAccountItemId');
  const consolidationAccountItems = accountItems.filter(_ => _.subsidiaryId === null);
  const sortedConsolidationAccountItems = sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category));
  const mappedConsolidationAccountItems = sortedConsolidationAccountItems.filter(_ => accountItemsGroupedByConsolidationAccountItemId[_.id]);
  const allConsolidationAccountItems = [...mappedConsolidationAccountItems, ...cfMappingPresetConsolidationAccountItems.map(_ => ({ ..._, ...presetConsolidationAccountItemSettingsById[_.id], }))];
  const consolidationAccountItemsByName = keyBy(allConsolidationAccountItems, 'name');
  const rowGroups = generateRowGroups(allConsolidationAccountItems);
  const processRows = (rows) => {
    return rows.map((row) => {
      const pkgCategory = pkgCategoriesByName[row.pkgCategoryName];
      const columnKeysByName = invert(mapValues(pkgCategories()[pkgCategory]?.children, 'label'));
      return {
        consolidationAccountItemId: consolidationAccountItemsByName[row.consolidationAccountItemName]?.id,
        pkgCategory,
        columnKey: columnKeysByName[row.columnName],
        cfChangeTypeId: cfChangeTypesByName[row.cfChangeTypeName]?.id,
        cfAccountItemId: cfAccountItemsByName[row.cfAccountItemName]?.id,
        ...row,
      };
    });
  };
  const processValidatedRows = (rows) => {
    return entries(groupBy(rows.filter(_ => _.pkgCategoryName), 'consolidationAccountItemId')).map(([consolidationAccountItemId, rows]) => {
      return {
        consolidationAccountItemId,
        cfMappingItems: rows,
      };
    });
  };
  const validateRow = (row, defaultRowValidator) => {
    if(isEmpty(row.pkgCategoryName)) {
      return { ...row, errors: [], };
    } else {
      return defaultRowValidator(row);
    }
  };
  const processRow = (batch, row, i) => {
    const { consolidationAccountItemId, cfMappingItems = [], } = row;
    const ref = cfMappingPresetConsolidationAccountItems.map(_ => _.id).includes(consolidationAccountItemId) ? company.ref.collection('presetConsolidationAccountItemSettings').doc(consolidationAccountItemId) : company.ref.collection('accountItems').doc(consolidationAccountItemId);
    batch.update(ref, { cfMappingItems }); 
  };
  useEffect(() => {
    if(presetConsolidationAccountItemSettings != null) {
      batch(db, cfMappingPresetConsolidationAccountItems.filter(_ => !presetConsolidationAccountItemSettings.map(_ => _.id).includes(_.id)), (batch, _) => batch.set(company.ref.collection('presetConsolidationAccountItemSettings').doc(_.id), { cfMappingItems: [] }));
    }
  }, [presetConsolidationAccountItemSettings]);

  return props.translate(
    <div className="company-account-item-mappings container">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="CFマッピングを設定する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>CFマッピング</h4>
        </div>
        <div className="mb-3 d-flex justify-content-end">
          <ImportButton validateRow={validateRow} processRows={processRows} processValidatedRows={processValidatedRows} processRow={processRow} documentName="cfMappingItem" fields={cfMappingItemFields({ cfChangeTypes, cfAccountItems: allCfAccountItems, })} importKey="cfMappings" />
          <ExportButton className="ml-2" fileName="CFマッピング.csv" rows={rowsForExport(allConsolidationAccountItems, cfChangeTypes, allCfAccountItems)} />
        </div>
        <div className="overflow-auto" style={{ maxHeight: '95vh', }}>
          <table className="table table-bordered sticky-table mb-0">
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th></th>
                <th>連結科目</th>
                <th style={{ minWidth: 600 }}>マッピング</th>
              </tr>
            </thead>
            {
              rowGroups.filter(_ => _.rows.length > 0).map(({ accountItemCategory, rows }) => {
                return (
                  <RowsGroup key={accountItemCategory.name} {...{ company, accountItemCategory, rows, cfChangeTypes: sortedCfChangeTypes, cfAccountItems: sortedCfAccountItems, }} />
                );
              })
            }
          </table>
        </div>
      </div>
    </div>
  );
});

function RowsGroup (props) {
  const { company, cfChangeTypes, cfAccountItems, accountItemCategory, rows, } = props;
  const _fields = cfMappingItemFields({ cfChangeTypes, cfAccountItems });

  return (
    <tbody className="thead-light">
      {
        rows.map((row, i) => {
          const { id, ref, name, cfMappingItems = [], createdInYui = false, } = row;
          return (
            <tr key={id} data-id={id}>
              <td>
                <div className="d-flex gap-1">
                  <EditButton size="sm" label={false} itemRef={ref} FormModal={CfMappingModal} formProps={{ cfChangeTypes, cfAccountItems, }} />
                </div>
              </td>
              <td>
                {name}
              </td>
              <td>
                {
                  cfMappingItems.map((cfMappingItem, i) => {
                    return (
                      <div key={i} className="border rounded p-2 d-flex gap-3">
                        <div>
                          {fieldDisplayValue(cfMappingItem, 'pkgCategory', _fields.pkgCategory)}
                        </div>
                        <div>
                          {fieldDisplayValue(cfMappingItem, 'columnKey', _fields.columnKey)}
                        </div>
                        <div>
                          {fieldDisplayValue(cfMappingItem, 'cfChangeTypeId', _fields.cfChangeTypeId)}
                        </div>
                        <div>
                          {fieldDisplayValue(cfMappingItem, 'cfAccountItemId', _fields.cfAccountItemId)}
                        </div>
                      </div>
                    );
                  })
                }
              </td>
            </tr>
          );
        })
      }
    </tbody>
  );
};
