const { zip, keyBy, sortBy, groupBy, sumBy, get, sum, omit, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategories, accountItemCategoryNames } = require('../config');

const { keys, entries, } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const otherCiCategory = accountItemCategoriesByName['評価・換算差額等'];
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateOtherCiCategoryAmounts = (amountItems, prevYearAmountItems, equity, prevYearEquity) => {
  const amountItemsByCategory = keyBy(amountItems, 'accountItemCategory.name');
  const prevYearAmountItemsByCategory = keyBy(prevYearAmountItems, 'accountItemCategory.name');
  return otherCiCategory.children().map((category) => {
    const { categoryItem } = amountItemsByCategory[category.name];
    const { categoryItem: prevYearCategoryItem } = prevYearAmountItemsByCategory[category.name];
    const homeAmount = categoryItem.amount - prevYearCategoryItem?.amount;
    const minorityAmount = -(sumBy(categoryItem.companyItems, _ => _.consolidationColumns.find(_ => _.type === 'minorityInterest')?.amount) - sumBy(prevYearCategoryItem.companyItems, _ => _.consolidationColumns.find(_ => _.type === 'minorityInterest')?.amount));
    const equityAmount = (equity?.[category.name] || 0) - (prevYearEquity?.[category.name] || 0);
    return {
      category,
      categoryItem,
      prevYearCategoryItem,
      homeAmount,
      minorityAmount,
      equityAmount,
      totalAmount: homeAmount - equityAmount + minorityAmount,
    };
  });
};

const generateRows = (amountItems, prevYearAmountItems, equity, prevYearEquity) => {
  const amountItemsByCategory = keyBy(amountItems, 'accountItemCategory.name');
  const homeIncome = amountItemsByCategory['親会社株主に帰属する当期純利益'].categoryItem.amount;
  const minorityIncome = amountItemsByCategory['非支配株主持分損益'].categoryItem.amount;
  const totalIncome = homeIncome + minorityIncome;
  const otherCiCategoryAmounts = generateOtherCiCategoryAmounts(amountItems, prevYearAmountItems, equity, prevYearEquity);
  const totalEquityAmount = sumBy(otherCiCategoryAmounts, 'equityAmount');
  return [
    {
      integratesPronexus: true,
      roma: 'toukijunrieki',
      label: '当期純利益',
      amount: totalIncome,
    },
    {
      label: 'その他の包括利益',
      amount: null,
    },
    ...otherCiCategoryAmounts.map(({ category, homeAmount, minorityAmount, totalAmount, }) => {
      return {
        integrates: true,
        roma: category.roma,
        category,
        label: category.ciName || category.name,
        amount: totalAmount,
        indentLevel: 1,
      };
    }),
    {
      integrates: true,
      roma: 'mochibunsoutou',
      label: '持分法適用会社に対する持分相当額',
      amount: totalEquityAmount,
      indentLevel: 1,
    },
    {
      label: 'その他の包括利益 計',
      amount: sumBy(otherCiCategoryAmounts, 'totalAmount') + totalEquityAmount,
      indentLevel: 2,
    },
    {
      label: '包括利益',
      amount: totalIncome + sumBy(otherCiCategoryAmounts, 'totalAmount') + totalEquityAmount,
    },
    {
      label: '(内訳)',
      amount: null,
    },
    {
      integrates: true,
      roma: 'oya_houkatsu',
      label: '親会社株主に係る包括利益',
      amount: homeIncome + sumBy(otherCiCategoryAmounts, 'homeAmount'),
    },
    {
      integrates: true,
      roma: 'hishihai_houkatsu',
      label: '非支配株主に係る包括利益',
      amount: minorityIncome + sumBy(otherCiCategoryAmounts, 'minorityAmount'),
    },
  ];
};

const rowsForExport = (...args) => () => {
  return generateRows(...args).filter(_ => _.amount !== null).map(_ => omit(_, ['indentLevel', 'integratesPronexus']));
};

exports.generateRows = generateRows;
exports.generateOtherCiCategoryAmounts = generateOtherCiCategoryAmounts;
exports.rowsForExport = rowsForExport;
