const { get, mapValues, sumBy, groupBy, keyBy, sortBy, omit, isEmpty, } = require('lodash');

const { accountItemCategories } = require('../config');
const { fieldDisplayValue } = require('../util');
const { cfMappingItemFields, } = require('../models/consolidationAccountItem');
const { generalSegment, } = require('../models/consolidationSegment');
const { noneSection, } = require('../models/section');

const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRows = (consolidationSegments, sections, company, companies) => {
  const sectionsGroupedByConsolidationSegmentId = groupBy(sections, 'consolidationSegmentId');
  return [...consolidationSegments, generalSegment].map((consolidationSegment) => {
    const sections = sectionsGroupedByConsolidationSegmentId[consolidationSegment.id] || [];
    const columns = companies.map((_company) => {
      const allSections = [...sections, noneSection(_company, company)].filter(_ => _.consolidationSegmentId === consolidationSegment.id);
      const _sections = allSections.filter(_ => _.subsidiaryId === (_company === company ? null : _company.id));
      return {
        relatedCompany: _company,
        sections: _sections,
      };
    });
    return {
      ...consolidationSegment,
      columns,
    };
  });
};

const rowsForExport = (...args) => () => {
  return generateRows(...args).map((row) => {
    const { id, name, columns, } = row;
    return {
      id,
      name,
      ...columns.reduce((x, column, i) => {
        const { relatedCompany, sections, } = column;
        return {
          ...x,
          [relatedCompany.display_name]: sections.map(_ => _.name).join(','),
        };
      }, {}),
    };
  });
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
