const { keyBy, sortBy, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategoryNames } = require('../config');

const generateRows = (cashes, accountItems, trials) => {
  const accountItemsById = keyBy(accountItems, 'id');
  const trialBalances = trials.flatMap(_ => _.balances);
  return sortBy(cashes.map((cash) => {
    const { id, ref, accountItemId, amount, note, } = cash;
    const accountItem = accountItemsById[accountItemId];
    const trialAmount = trialBalances.find(_ => _.account_item_name === accountItem?.name)?.closing_balance;
    return {
      ...cash,
      accountItem,
      trialAmount,
    };
  }), _ => accountItemCategoryNames.indexOf(_.accountItem?.account_category), 'accountItem.index');
};

const rowsForExport = (...args) => () => {
  return generateRows(...args).map((row) => {
    const { id, amount, accountItem, note, } = row;
    return {
      id,
      accountItemName: accountItem?.name,
      amount,
      note,
    };
  });
};

const computeAlerts = (...args) => {
  return [
    generateRows(...args).some(_ => floatFormat(_.amount) !== floatFormat(_.trialAmount)) && '差額が発生しています',
  ].filter(_ => _);
};

exports.generateRows = generateRows;
exports.computeAlerts = computeAlerts;
exports.rowsForExport = rowsForExport;
