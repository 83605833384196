const { isEmpty, get, last } = require('lodash');

const { accountItemCategoriesByName, accountItemCategoryChildren, presetConsolidationAccountItems, } = require('../config');

const getCategory = (accountItem) => {
  return accountItemCategoriesByName[get(accountItem, 'account_category')];
};

const { entries } = Object;

const filedsToImport = ({ allConsolidationAccountItems }) => {
  return {
    name: {
      label: '科目名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        exclusive: v => !presetConsolidationAccountItems.map(_ => _.id).includes(v),
      },
    },
    accountCode: {
      label: '科目コード',
      type: 'string',
    },
    consolidationAccountItemName: {
      label: '連結科目名',
      type: 'string',
      validations: {
        exists: v => isEmpty(v) || allConsolidationAccountItems.map(_ => _.name).includes(v),
      },
    },
    categoryName: {
      label: 'カテゴリ',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        exists: v => v == null || accountItemCategoriesByName[v] != null,
      },
    },
  };
};

const fields = ({ relatedCompany, isEditing = false, accountItem, otherAccountItems = [], consolidationAccountItems, subsidiaryId }) => {
  return {
    name: {
      label: '科目名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        ...(!['bugyo', 'pca'].includes(relatedCompany.externalType) && { exclusive: v => ![...otherAccountItems, ...presetConsolidationAccountItems].map(_ => _.name).includes(v) }),
      },
      readOnly: _ => isEditing,
    },
    shortcut_num: {
      label: '科目コード',
      type: 'string',
      readOnly: _ => isEditing && !accountItem?.createdInYui,
      ...(
        relatedCompany.externalType === 'bugyo' && {
          validations: {
            required: v => !isEmpty(v),
            exclusive: v => ![...otherAccountItems, ...presetConsolidationAccountItems].map(_ => _.shortcut_num).includes(v),
          },
        }
      ),
    },
    account_category: {
      type: 'select',
      label: 'カテゴリ',
      options: accountItemCategoryChildren.filter(_ => !_.computable).map(_ => ({ label: [...[...(_.parents() || [])].reverse(), _.name].join(' > '), value: _.name })),
      readOnly: _ => isEditing && !accountItem?.createdInYui && !['bugyo', 'pca'].includes(relatedCompany.externalType),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    consolidationAccountItemId: {
      label: '連結科目',
      type: 'select',
      options: ({ account_category }) => {
        const sameCategoryConsolidationAccountItems = consolidationAccountItems.filter(_ => _.account_category === account_category);
        return sameCategoryConsolidationAccountItems.map(_ => ({ label: _.name, value: _.id }));
      },
    },
    isMinus: {
      label: 'マイナス表示付与している科目',
      type: 'boolean',
      initialValue: false,
      hidden: _ => !['bugyo', 'pca'].includes(relatedCompany.externalType),
    },
    ...(
      subsidiaryId == null && {
        group_name: {
          label: '決算書表示名',
          type: 'string',
          readOnly: _ => isEditing && !accountItem?.createdInYui,
          validations: {
            required: v => !isEmpty(v),
          },
        },
      }
    ),
  };
};

exports.fields = fields;
exports.filedsToImport = filedsToImport;
exports.getCategory = getCategory;
