import React, { Component, Fragment, } from 'react';
import { useToggle, useAsync, useMap, } from 'react-use';
import { sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { numberFormat, floatFormat, } from '../../util';
import { generateRowGroups, computeAlerts, rowsForExport, } from '../../shared/lib/miscellaneousAccounts';
import { fields } from '../../shared/models/miscellaneousAccount';
import { getCategory, } from '../../shared/models/accountItem';
import { accountItemCategoryNames, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import MiscellaneousAccountFormModal from '../modals/MiscellaneousAccountFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import AmountWithExchange from '../AmountWithExchange';
import Alerts from '../Alerts';

export default RelatedCompanyPage(function CompanyMiscellaneousAccounts (props) {
  const { relatedCompany, company, subsidiaryId, period, yearMonth, cr, ar, isLockedMonth, } = props;
  const miscellaneousAccountsRef = company.ref.collection('miscellaneousAccounts');
  const miscellaneousAccounts = useCollectionSubscription(miscellaneousAccountsRef.where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [company, subsidiaryId, yearMonth]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const rowGroups = generateRowGroups(miscellaneousAccounts, accountItems, trials);
  const alerts = computeAlerts(miscellaneousAccounts, accountItems, trials);

  return props.translate(
    <div className="company-miscellaneousAccounts">
      <Alerts alerts={alerts} />
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ExportButton fileName="雑勘定.csv" rows={rowsForExport(miscellaneousAccounts, accountItems, trials)} />
        <AddButton itemRef={miscellaneousAccountsRef.doc()} processValues={_ => ({ ..._, subsidiaryId, period, yearMonth, })} FormModal={MiscellaneousAccountFormModal} formProps={{ accountItems, }} disabled={isLockedMonth} />
      </div>
      <div>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }}>個別勘定科目</th>
              <th style={{ minWidth: 200 }}>内訳</th>
              <th style={{ minWidth: 200 }}>金額</th>
              <th style={{ minWidth: 200 }}>試算表残高</th>
              <th style={{ minWidth: 250 }}>備考</th>
              <th style={{ minWidth: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              rowGroups.map((miscellaneousAccount) => {
                const { id, ref, category, accountItem, trialAmount, totalAmount, items = [{}], note, } = miscellaneousAccount;
                const rate = ({ bs: cr, pl: ar, cr: ar })[category?.type];

                return (
                  <Fragment key={id}>
                    {
                      items.map((item, i) => {
                        const { content, amount } = item;
                        return (
                          <tr key={i}>
                            {
                              i === 0 && (
                                <td rowSpan={items.length + 1}>
                                  {accountItem?.name}
                                </td>
                              )
                            }
                            <td>
                              {content}
                            </td>
                            <td className={classnames('text-right')}>
                              <AmountWithExchange currency={relatedCompany.currency} amounts={[amount, amount * rate]} />
                            </td>
                            {
                              i === 0 && (
                                <Fragment>
                                  <td rowSpan={items.length + 1} className="text-right">
                                    <AmountWithExchange currency={relatedCompany.currency} amounts={[trialAmount, trialAmount * rate]} />
                                  </td>
                                  <td rowSpan={items.length + 1}>
                                    <AutoLinkText>
                                      {note}
                                    </AutoLinkText>
                                  </td>
                                  <td rowSpan={items.length + 1} className="text-nowrap text-right">
                                    <EditButton itemRef={ref} FormModal={MiscellaneousAccountFormModal} formProps={{ accountItems, }} disabled={isLockedMonth} />
                                    <DeleteButton item={miscellaneousAccount} itemRef={ref} className="ml-2" disabled={isLockedMonth} />
                                  </td>
                                </Fragment>
                              )
                            }
                          </tr>
                        );
                      })
                    }
                    <tr>
                      <th className="text-right">
                        合計
                      </th>
                      <th className={classnames('text-right', { 'table-danger': floatFormat(totalAmount) !== floatFormat(trialAmount) })}>
                        <AmountWithExchange currency={relatedCompany.currency} amounts={[totalAmount, totalAmount * rate]} />
                      </th>
                    </tr>
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
