import React, { Component, useState, } from 'react';
import { Form, FormGroup, Button, Input, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useToggle, } from 'react-use';
import { useLocation, } from 'react-router';
import { isEmpty, } from 'lodash';

import firebase, { functions } from '../firebase';
import useCompany from './hooks/useCompany';
import useQueryParams from './hooks/useQueryParams';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import { autoLink, nl2br } from '../utils';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const setNote = functions.httpsCallable('setNote');
const deleteNote = functions.httpsCallable('deleteNote');

export default function PageNoter (props) {
  const { readOnly } = props;
  const company = useCompany();
  const location = useLocation();
  const { yearMonth } = useQueryParams();
  const key = [location.pathname.split('/').slice(3), yearMonth].join('__');
  const note = useDocumentSubscription(company.ref.collection('pageNotes').doc(key), [key]);
  const [formValue, setFormValue] = useState();
  const [showsDropdown, toggleDropdown] = useToggle();
  const [showsForm, toggleForm] = useToggle();
  const [isSubmitting, toggleSubmitting] = useToggle();
  const onSubmit = async (event) => {
    event.preventDefault();

    toggleSubmitting(true);
    try {
      await company.ref.collection('pageNotes').doc(key).set({ value: formValue, });
      closeForm();
      toggleDropdown(false);
      toast.success('保存しました');
    } catch(e) {
      console.error(e);
      toast.error('保存失敗しました');
    }
    toggleSubmitting(false);
  }
  const refresh = async () => {
    toggleDropdown(false);
    await new Promise(_ => setTimeout(_, 0));
    toggleDropdown(true);
  };
  const openForm = async () => {
    if(readOnly) return;

    toggleForm(true);
    await refresh();
    setFormValue(note?.value || '');
  };
  const closeForm = async () => {
    toggleForm(false);
    await refresh();
  };
  const clear = () => setFormValue('');
  const cancel = () => {
    closeForm();
    clear();
  }

  return (
    <div className={classnames('page-noter position-fixed', { 'always-visible': showsDropdown || note?.value })} style={{ bottom: 20, left: 20, zIndex: showsDropdown ? 100 : 1, }}>
      <Dropdown direction="up" isOpen={showsDropdown} toggle={toggleDropdown}>
        <DropdownToggle
          tag="span"
          style={{ fontSize: 22 }}
          onClick={_ => !note?.value && openForm()}
        >
          <span className={classnames('fa-sticky-note cursor-pointer', { 'text-info fas': note?.value, 'text-muted far': !note?.value })} />
        </DropdownToggle>
        <DropdownMenu>
          <div className="p-2" style={{ minWidth: 250 }}>
            <div>
              {
                showsForm ? (
                  <Form onSubmit={onSubmit} className="m-0">
                    <FormGroup>
                      <Input type="textarea" rows="6" value={formValue} onChange={_ => setFormValue(_.target.value)} />
                    </FormGroup>
                    <div className="d-flex justify-content-between">
                      <Button className="flex-grow-1" tag="span" onClick={cancel}>キャンセル</Button>
                      <Button className="ml-2 flex-grow-1" color="primary" onClick={onSubmit} disabled={isSubmitting}>保存</Button>
                    </div>
                  </Form>
                ) : (
                  <div>
                    <div className="cursor-pointer" onClick={openForm} dangerouslySetInnerHTML={{ __html: nl2br(autoLink(note?.value || '', { linkAttr: { target: '_blank' } })) }} />
                  </div>
                )
              }
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
