const { isEmpty, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const fields = ({ accountItems = [], otherItems, } = {}) => {
  const unique = (v, s) => otherItems?.every(o => ['accountItemId'].some(_ => o[_] !== s[_]));
  return {
    accountItemId: {
      label: '個別勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
        unique,
      },
    },
    opening: {
      label: '期首',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    closing: {
      label: '期末',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    note: {
      label: '備考',
      type: 'text',
    }
  };
};

exports.fields = fields;
