import React from 'react';

const helps = {
  'ログインする': 'https://yui.wraptas.site/ca19891dada442a1a7a9e15353d2e9b0',
  '会計ソフトと連携する': 'https://yui.wraptas.site/c213201992fc4103ab2fcbc7c83ccca6',
  '個社別の連結PKGを作成する': 'https://yui.wraptas.site/8bb6431cb9114ba6b35b59a038fc2639',
  '為替レートを設定する': 'https://yui.wraptas.site/62c1a32f60f54370ac2c9a18c1780f07',
  '前期数値を設定する': 'https://yui.wraptas.site/3586956340c74c7b936fc336064e4965',
  '連結科目を整備する': 'https://yui.wraptas.site/90db1e1305af495daebcf43e8946c0c5',
  'グループ全社の連結PKGを確認する': 'https://yui.wraptas.site/06c3915730bb4f0da57cf3534e051096',
  '開始・連結仕訳を作成する': 'https://yui.wraptas.site/a183448ee76345a79df269abc1084587',
  '連結精算表を確認する': 'https://yui.wraptas.site/f3e2633a832f427697ea85e199ea4257',
  '連結財務諸表（連結BS、PL、SS、CI）を確認する': 'https://yui.wraptas.site/a94083b201a9402aa7f3dcff698c230d',
  'CF増減種別を作成する': 'https://yui.wraptas.site/24b00af7a1c24af399bc92b4ac796749',
  'CF科目を作成する': 'https://yui.wraptas.site/5db75f8e2c7a4cf78e41f64954b40dac',
  'CFマッピングを設定する': 'https://yui.wraptas.site/f6c782c12516495ba73549fecbe76194',
  '連結CFWSを作成する': 'https://yui.wraptas.site/9884097eaa7346f19eaae0454c1a11d1',
  '連結CFWS2を確認する': 'https://yui.wraptas.site/3e86b85568324b538d919e16edb1e0a5',
  '連結CFを確認する': 'https://yui.wraptas.site/bb3aa2c1718b4ae397539deaff6dd26f',
  'メンバー管理': 'https://yui.wraptas.site/b6f608bad1604f4fb6a0bceab43763b9',
  '連結セグメントを作成する': 'https://yui.wraptas.site/1dd030cd03c94e01bf784df0e3d621f2',
  '連結セグメント情報を作成する': 'https://yui.wraptas.site/52e88d56323445d7ab1d944692c9f490',
};

export default function HelpLink (props) {
  const { text, ...extraProps } = props;
  return (
    <a href={helps[text]} target="_blank" className="unprint">
      <span className="fas fa-question-circle mr-2" />
      {text}
      <span className="small">
        <span className="fas fa-external-link-alt ml-1" />
      </span>
    </a>
  );
};

