const { get, sumBy, keyBy, sortBy, groupBy, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategoryNames } = require('../config');
const { getCategory } = require('../models/accountItem');

const { keys } = Object;

const generateRowGroups = (miscellaneousAccounts, company, relatedCompanies, accountItems, consolidationAccountItems, exchangeRate) => {
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const accountItemsById = keyBy(accountItems, 'id');
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const rows = miscellaneousAccounts.map((miscellaneousAccount) => {
    const { id, ref, accountItemId, items = [], note, subsidiaryId, } = miscellaneousAccount;
    const relatedCompany = relatedCompaniesById[subsidiaryId || company.id];
    const accountItem = accountItemsById[accountItemId];
    const category = getCategory(accountItem);
    const consolidationAccountItem = consolidationAccountItemsById[accountItem && accountItem.consolidationAccountItemId]
    const currency = get(relatedCompany, 'currency', 'jpy');
    const rate = currency === 'jpy' ? 1 : get(exchangeRate, [currency, ({ bs: 'cr', pl: 'ar', cr: 'ar' })[category?.type]].join('-'));
    const exchangedAmount = sumBy(items, _ => _.amount * rate);
    return {
      ...miscellaneousAccount,
      relatedCompany,
      accountItem,
      consolidationAccountItem,
      currency,
      rate,
      exchangedAmount,
    };
  });
  const rowsGroupedByConsolidationAccountItemId = groupBy(rows, 'consolidationAccountItem.id');
  const sortedItems = consolidationAccountItems.filter(_ => keys(rowsGroupedByConsolidationAccountItemId).includes(_.id));
  return sortedItems.map((consolidationAccountItem) => {
    const rows = rowsGroupedByConsolidationAccountItemId[consolidationAccountItem.id];
    return {
      consolidationAccountItem,
      rows,
    };
  });
};

const rowsForExport = (...args) => () => {
  return generateRowGroups(...args).flatMap(({ consolidationAccountItem, rows, }) => {
    return rows.flatMap((row) => {
      const { id, items = [], rate, note, relatedCompany, consolidationAccountItem, } = row;
      return items.map((item) => {
        const { content, amount } = item;
        const exchangedAmount = amount * rate;
        return {
          id,
          consolidationAccountItemName: consolidationAccountItem?.name,
          relatedCompanyName: relatedCompany?.display_name,
          content,
          exchangedAmount,
          note,
        };
      });
    });
  });
};

const computeAlerts = (...args) => {
  return [
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.computeAlerts = computeAlerts;
exports.rowsForExport = rowsForExport;
