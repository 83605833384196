import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { isEmpty, pickBy, pick, get, keyBy, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';

import { itemFields, } from '../../shared/models/exchangedItem';
import useFormState from '../hooks/useFormState';
import useCompany from '../hooks/useCompany';
import Field from '../Field';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function ExchangedItemFormModal (props) {
  const { isOpen, values, consolidationAccountItems, onClickClose, } = props;
  const isNew = !values || !values.id;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.items || []);
  const isUnsubmittable = (items.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            換算後金額
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="mt-3">
            <ListForm
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      index={itemIndex}
                      values={item}
                      consolidationAccountItems={consolidationAccountItems}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, consolidationAccountItems, onChange, } = props;
  const company = useCompany();
  const statedFields = useFormState(values, itemFields({ company, consolidationAccountItems }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex">
        {
          entries(statedFields).map(([fieldName, fieldSetting], i) => (
            <div key={i} className="mr-2" style={{ minWidth: ['debitCompanyId', 'debitItemId', 'creditCompanyId', 'creditItemId'].includes(fieldName) ? 200 : 150 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
