import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import AdminRoot from './components/pages/AdminRoot';
import AdminUsers from './components/pages/AdminUsers';
import AdminCompanies from './components/pages/AdminCompanies';
import AdminLocales from './components/pages/AdminLocales';
import Dashboard from './components/pages/Dashboard';
import CompanyRoot from './components/pages/CompanyRoot';
import CompanyExchangeRates from './components/pages/CompanyExchangeRates';
import CompanyPrevPeriodAmounts from './components/pages/CompanyPrevPeriodAmounts';
import CompanyRelatedCompanies from './components/pages/CompanyRelatedCompanies';
import CompanyRelatedCompany from './components/pages/CompanyRelatedCompany';
import CompanyAccountItemMappings from './components/pages/CompanyAccountItemMappings';
import CompanySectionMappings from './components/pages/CompanySectionMappings';
import CompanyIndividualTrials from './components/pages/CompanyIndividualTrials';
import CompanySectionTrials from './components/pages/CompanySectionTrials';
import CompanyIndividualAdjustments from './components/pages/CompanyIndividualAdjustments';
import CompanyIndividualAdjusted from './components/pages/CompanyIndividualAdjusted';
import CompanyExchangeDiffs from './components/pages/CompanyExchangeDiffs';
import CompanyRelatedCompanyBalances from './components/pages/CompanyRelatedCompanyBalances';
import CompanyCashes from './components/pages/CompanyCashes';
import CompanyFixedAssets from './components/pages/CompanyFixedAssets';
import CompanyInvestments from './components/pages/CompanyInvestments';
import CompanyFinances from './components/pages/CompanyFinances';
import CompanyMiscellaneousAccounts from './components/pages/CompanyMiscellaneousAccounts';
import CompanyAllowances from './components/pages/CompanyAllowances';
import CompanyConsolidationAccountItems from './components/pages/CompanyConsolidationAccountItems';
import CompanyConsolidationJournalTypes from './components/pages/CompanyConsolidationJournalTypes';
import CompanyConsolidationJournals from './components/pages/CompanyConsolidationJournals';
import CompanyConsolidationCalculation from './components/pages/CompanyConsolidationCalculation';
import CompanyBs from './components/pages/CompanyBs';
import CompanyPl from './components/pages/CompanyPl';
import CompanySs from './components/pages/CompanySs';
import CompanyCi from './components/pages/CompanyCi';
import CompanyCfChangeTypes from './components/pages/CompanyCfChangeTypes';
import CompanyCfAccountItems from './components/pages/CompanyCfAccountItems';
import CompanyCfMappings from './components/pages/CompanyCfMappings';
import CompanyCfWs from './components/pages/CompanyCfWs';
import CompanyCfWs2 from './components/pages/CompanyCfWs2';
import CompanyCf from './components/pages/CompanyCf';
import CompanyConsolidationSegments from './components/pages/CompanyConsolidationSegments';
import CompanySegmentsInfo from './components/pages/CompanySegmentsInfo';
import CompanyAlerts from './components/pages/CompanyAlerts';
import CompanyMonthlyTrends from './components/pages/CompanyMonthlyTrends';
import CompanyIntegration from './components/pages/CompanyIntegration';
import CompanyPkgRelatedCompanyBalances from './components/pages/CompanyPkgRelatedCompanyBalances';
import CompanyPkgCashes from './components/pages/CompanyPkgCashes';
import CompanyPkgFixedAssets from './components/pages/CompanyPkgFixedAssets';
import CompanyPkgInvestments from './components/pages/CompanyPkgInvestments';
import CompanyPkgFinances from './components/pages/CompanyPkgFinances';
import CompanyPkgMiscellaneousAccounts from './components/pages/CompanyPkgMiscellaneousAccounts';
import CompanyPkgAllowances from './components/pages/CompanyPkgAllowances';
import CompanyGeneralSettings from './components/pages/CompanyGeneralSettings';
import CompanyIntegrationSettings from './components/pages/CompanyIntegrationSettings';
import CompanyMembers from './components/pages/CompanyMembers';
import CompanyMonthLocks from './components/pages/CompanyMonthLocks';
import Profile from './components/pages/Profile';
import Confirmation from './components/pages/Confirmation';
// PLOP: import

const routeObjs = [
  {
    exact: true,
    path: '/admin',
    component: AdminRoot,
  },
  {
    exact: true,
    path: '/admin/companies',
    component: AdminCompanies,
  },
  {
    exact: true,
    path: '/admin/users',
    component: AdminUsers,
  },
  {
    exact: true,
    path: '/admin/locales',
    component: AdminLocales,
  },
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/companies/:companyId',
    component: _ => <Redirect to={`/companies/${_.match.params.companyId}/relatedCompanies${_.location.search}`} />
  },
  {
    exact: true,
    path: '/companies/:companyId/exchangeRates',
    component: CompanyExchangeRates,
  },
  {
    exact: true,
    path: '/companies/:companyId/prevPeriodAmounts',
    component: CompanyPrevPeriodAmounts,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies',
    component: CompanyRelatedCompanies,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId',
    component: _ => <Redirect to={`/companies/${_.match.params.companyId}/relatedCompanies/${_.match.params.relatedCompanyId}/settings${_.location.search}`} />
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/settings',
    component: CompanyRelatedCompany,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/accountItemMappings',
    component: CompanyAccountItemMappings,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/sectionMappings',
    component: CompanySectionMappings,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/individualTrials',
    component: CompanyIndividualTrials,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/sectionTrials',
    component: CompanySectionTrials,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/individualAdjustments',
    component: CompanyIndividualAdjustments,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/individualAdjusted',
    component: CompanyIndividualAdjusted,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/exchangeDiffs',
    component: CompanyExchangeDiffs,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/relatedCompanyBalances',
    component: CompanyRelatedCompanyBalances,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/cashes',
    component: CompanyCashes,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/fixedAssets',
    component: CompanyFixedAssets,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/investments',
    component: CompanyInvestments,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/finances',
    component: CompanyFinances,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/miscellaneousAccounts',
    component: CompanyMiscellaneousAccounts,
  },
  {
    exact: true,
    path: '/companies/:companyId/relatedCompanies/:relatedCompanyId/allowances',
    component: CompanyAllowances,
  },
  {
    exact: true,
    path: '/companies/:companyId/consolidationAccountItems',
    component: CompanyConsolidationAccountItems,
  },
  {
    exact: true,
    path: '/companies/:companyId/consolidationJournalTypes',
    component: CompanyConsolidationJournalTypes,
  },
  {
    exact: true,
    path: '/companies/:companyId/consolidationJournals',
    component: CompanyConsolidationJournals,
  },
  {
    exact: true,
    path: '/companies/:companyId/consolidationCalculation',
    component: CompanyConsolidationCalculation,
  },
  {
    exact: true,
    path: '/companies/:companyId/bs',
    component: CompanyBs,
  },
  {
    exact: true,
    path: '/companies/:companyId/pl',
    component: CompanyPl,
  },
  {
    exact: true,
    path: '/companies/:companyId/ss',
    component: CompanySs,
  },
  {
    exact: true,
    path: '/companies/:companyId/ci',
    component: CompanyCi,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg',
    component: _ => <Redirect to={`/companies/${_.match.params.companyId}/pkg/relatedCompanyBalances${_.location.search}`} />,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/relatedCompanyBalances',
    component: CompanyPkgRelatedCompanyBalances,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/cashes',
    component: CompanyPkgCashes,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/fixedAssets',
    component: CompanyPkgFixedAssets,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/investments',
    component: CompanyPkgInvestments,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/finances',
    component: CompanyPkgFinances,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/miscellaneousAccounts',
    component: CompanyPkgMiscellaneousAccounts,
  },
  {
    exact: true,
    path: '/companies/:companyId/pkg/allowances',
    component: CompanyPkgAllowances,
  },
  {
    exact: true,
    path: '/companies/:companyId/cfChangeTypes',
    component: CompanyCfChangeTypes,
  },
  {
    exact: true,
    path: '/companies/:companyId/cfAccountItems',
    component: CompanyCfAccountItems,
  },
  {
    exact: true,
    path: '/companies/:companyId/cfMappings',
    component: CompanyCfMappings,
  },
  {
    exact: true,
    path: '/companies/:companyId/cfWs',
    component: CompanyCfWs,
  },
  {
    exact: true,
    path: '/companies/:companyId/cfWs2',
    component: CompanyCfWs2,
  },
  {
    exact: true,
    path: '/companies/:companyId/cf',
    component: CompanyCf,
  },
  {
    exact: true,
    path: '/companies/:companyId/consolidationSegments',
    component: CompanyConsolidationSegments,
  },
  {
    exact: true,
    path: '/companies/:companyId/segmentsInfo',
    component: CompanySegmentsInfo,
  },
  {
    exact: true,
    path: '/companies/:companyId/alerts',
    component: CompanyAlerts,
  },
  {
    exact: true,
    path: '/companies/:companyId/monthlyTrends',
    component: CompanyMonthlyTrends
  },
  {
    exact: true,
    path: '/companies/:companyId/integration',
    component: CompanyIntegration,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings',
    component: CompanyGeneralSettings,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings/integration',
    component: CompanyIntegrationSettings,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings/members',
    component: CompanyMembers,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings/monthLocks',
    component: CompanyMonthLocks,
  },
  {
    exact: true,
    path: '/profile',
    component: Profile,
  },
  {
    exact: true,
    path: '/confirmation',
    component: Confirmation,
  },
  // PLOP: routeObject
]

export default function routes (extraProps) {
  return (
    <Switch>
      {
        routeObjs.map((route, i) => {
          const { exact, path, render, props } = route;
          return (
            <Route exact={exact} path={path} render={render || (_ =>
              <route.component {..._} {...extraProps} {...props} />
            )} key={i} />
          );
        })
      }
    </Switch>
  );
};
