import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { groupBy, keyBy, isEmpty, get, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { sectionFields as subsidiarySectionFields } from '../../shared/models/subsidiary';
import { batch, getCollectionData, } from '../../shared/firebase';
import { fields, filedsToImport, } from '../../shared/models/section';
import { generateRows, rowsForExport, } from '../../shared/lib/sectionMappings';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();

export default RelatedCompanyPage(function CompanySectionMappings (props) {
  const { company, role, relatedCompany, subsidiaryId, } = props;
  const queryParams = useQueryParams();
  const {
    onlyNotSelected = '0',
    yearMonth,
  } = queryParams;
  const sections = useCollectionSubscription(company.ref.collection('sections').where('subsidiaryId', '==', subsidiaryId), [relatedCompany.id]);
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'), [company]);
  const rows = generateRows(sections, consolidationSegments);
  const sortedConsolidationSegments = sortBy(consolidationSegments, 'index');
  const allConsolidationSegments = [...sortedConsolidationSegments, generalSegment];
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const consolidationSegmentsByName = keyBy(allConsolidationSegments, 'name');

  let filteredRows = rows;
  if(onlyNotSelected === '1') {
    filteredRows = filteredRows.filter(_ => consolidationSegmentsById[_.consolidationSegmentId] == null);
  }

  const processRow = (batch, row, i) => {
    const { name, consolidationSegmentName, } = row;
    if(isEmpty(name)) return;

    const id = [subsidiaryId, name].join('__').replace(/\//g, '\\');
    const ref = company.ref.collection('sections').doc(id);
    batch.set(ref, {
      consolidationSegmentId: consolidationSegmentsByName[consolidationSegmentName]?.id || null,
      name,
      subsidiaryId,
      index: i,
    }, { merge: true });
  };
  const consolidationSegmentOptions = allConsolidationSegments.map(_ => ({ label: props.translate(_.name), value: _.id }));
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(rows, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('sections').doc(id), { index });
    });
  };

  return props.translate(
    <div className="company-section-mappings">
      <div className="d-flex justify-content-center">
        <div>
          <div className="mb-3 d-flex justify-content-end gap-1 align-items-center">
            <div>
              <span>未選択</span>: {consolidationSegmentsById[relatedCompany.noneConsolidationSegmentId]?.name || '(選択なし)'}
            </div>
            <EditButton label={false} size="sm" color="primary" outline itemRef={relatedCompany.ref} FormModal={ModelFormModal} formProps={{ title: '事業所 編集', fields: subsidiarySectionFields({ consolidationSegments: allConsolidationSegments, }), }} />
          </div>
          <div className="d-flex justify-content-between align-items-end gap-1">
            <div className="d-flex gap-1">
              <QueryBoolean paramName="onlyNotSelected" label="連結セグメント未選択行のみ表示" defaultValue={'0'} />
            </div>
            <div className="d-flex gap-1">
              <ExportButton fileName="部門.csv" rows={rowsForExport(sections, consolidationSegments)} />
              {relatedCompany.externalType === null && <ImportButton processRow={processRow} documentName="section" fields={filedsToImport} importKey={`sections__${relatedCompany.id}`} />}
              {relatedCompany.externalType === null && <AddButton itemRef={_ => company.ref.collection('sections').doc([subsidiaryId, _.name].join('__').replace(/\//g, '\\'))} processValues={_ => ({ ..._, available: true, subsidiaryId, createdInYui: true, })} FormModal={ModelFormModal} formProps={{ title: '部門', fields: fields({ consolidationSegments: allConsolidationSegments, subsidiaryId, }), }} />}
            </div>
          </div>
          <div className="mt-2 overflow-auto" style={{ minHeight: '50vh', maxHeight: '95vh', }}>
            <table className="table table-bordered sticky-table mb-0 flex-table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ width: 50 }}></th>
                  <th style={{ width: 500, }}>部門名</th>
                  <th style={{ width: 300, }}>連結セグメント</th>
                  <th style={{ width: 180, }}></th>
                </tr>
              </thead>
              <Container
                dragHandleSelector=".drag-handle"
                onDrop={onDrop}
                dropPlaceholder={{ style: { background: 'eee', } }}
                render={(ref) => (
                  <tbody ref={ref} className="thead-light border-3">
                    {
                      filteredRows.map((section) => {
                        const { id, ref, name, consolidationSegmentId, account_category: accountCategoryName, createdInYui = false, } = section;
                        const beforeDelete = async () => {
                          if((await Promise.all(
                            ['relatedCompanyBalances', 'fixedAssets', 'fixedAssetRelatedItems', 'cashes', 'investments', 'investmentRelatedItems', 'fixedDeposits', 'loans', 'incomeInterestItems', 'debts', 'bonds', 'capitals', 'treasuryStocks', 'stockOptions', 'expenseInterestItems', 'miscellaneousAccounts', 'allowances'].map(_ => getCollectionData(company.ref.collection(_).where('sectionId', '==', id).limit(1)))
                          )).some(_ => _.length > 0)) {
                            toast.error('使用されているため、削除できません');
                            return false;
                          }
                        };

                        return (
                          <Draggable
                            key={id}
                            render={() => (
                              <tr data-id={id} style={{ display: 'flex', overflow: 'initial', }}>
                                <th style={{ width: 50, }}>
                                  <div className="drag-handle text-muted cursor-pointer px-2 py-1">
                                    <span className="fas fa-grip-vertical" />
                                  </div>
                                </th>
                                <td style={{ width: 500, }}>
                                  {name}
                                </td>
                                <td style={{ width: 300, }}>
                                  <Select
                                    isClearable
                                    value={consolidationSegmentOptions.find(_ => _.value === consolidationSegmentId)}
                                    options={consolidationSegmentOptions}
                                    onChange={_ => section.ref.update({ consolidationSegmentId: get(_, 'value', null) })}
                                    isDisabled={['reader'].includes(role)}
                                  />
                                </td>
                                <td style={{ width: 180 }} className="text-nowrap text-right">
                                  <EditButton className={classnames({ invisible: !createdInYui })} itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '部門', fields: fields({ consolidationSegments: allConsolidationSegments, subsidiaryId, }), }} />
                                  <DeleteButton className="ml-2" item={section} itemRef={ref} beforeDelete={beforeDelete} />
                                </td>
                              </tr>
                            )}
                          />
                        );
                      })
                    }
                  </tbody>
                )}
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});
