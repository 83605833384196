import React, { Fragment, } from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

export default function DeleteButton ({ confirmMessage = '本当に削除しますか？', label = '削除', item, itemRef, beforeDelete = _ => true, onFinish, disabled, renderBody = _ => _, ...extraProps }) {
  const [isDeleting, toggleDeleting] = useToggle(false);
  const onClickDelete = async (props) => {
    if (!window.confirm(confirmMessage)) return;

    toggleDeleting(true);
    try {
      if((await beforeDelete(item)) === false) return toggleDeleting(false);

      await itemRef.delete();
      toast.success('削除しました');
      onFinish && await onFinish();
    } catch(e) {
      toast.error('失敗しました');
      throw e;
    }
    toggleDeleting(false);
  }

  return (
    <Button color="danger" onClick={onClickDelete} disabled={disabled || isDeleting} {...extraProps} data-operation-type="write">
      {
        renderBody((
          <Fragment>
            <span className="fas fa-trash" />
            {label && <span className="ml-1">{label}</span>}
          </Fragment>
        ))
      }
    </Button>
  );
};
