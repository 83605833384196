const { range, inRange, get, isEmpty, keyBy, } = require('lodash');

const fields = () => {
  return {
    note: {
      label: 'メモ',
      type: 'text',
    },
  };
};

exports.fields = fields;
