import { useMemo } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { zip, last, sumBy, groupBy, sum, isEmpty, keyBy, } from 'lodash';
import { format as formatDate, addMonths, } from 'date-fns';

import { getCollectionData, getDocumentData, } from '../../shared/firebase';
import { computeItems, } from '../../shared/lib/pkgItems';
import { getCategory, } from '../../shared/models/accountItem';
import { computeSingleAmounts, } from '../../shared/models/consolidationAccountItem';
import useCollectionSubscription from './useCollectionSubscription';

export default function usePkgItems (company, relatedCompany, subsidiaryId, accountItems, collectionName, uniqKeyFields, closingFieldName, changeFieldNames, yearMonthDates) {
  const lastMonthPkgItems = useCollectionSubscription(last(yearMonthDates) && company.ref.collection(collectionName).where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', formatDate(last(yearMonthDates), 'yyyyMM')), [collectionName, subsidiaryId, yearMonthDates]);
  const { value, loading: isLoading, error } = useAsync(async _ => computeItems(company, relatedCompany, subsidiaryId, accountItems, collectionName, uniqKeyFields, closingFieldName, changeFieldNames, yearMonthDates), [company, relatedCompany, accountItems, lastMonthPkgItems]);
  if(error) throw error;

  return { items: value?.pkgItems, isLoading };
};
