const fields = () => {
  return {
    code: {
      label: 'コード',
      type: 'string',
      hint: 'ja, en, ...etc',
    },
    name: {
      label: '言語名',
      type: 'string',
    },
    content: {
      label: '内容',
      type: 'text',
      rows: 10,
      hint: 'コロンで1セット、改行区切り',
    },
  };
};

exports.fields = fields;
