const { isEmpty, omit, } = require('lodash');

const { entries } = Object;

const joinAndExceptMappingFields = ({ cfChangeTypes, cfAccountItems, }) => {
  return {
    cfChangeTypeId: {
      label: 'CF増減種別',
      type: 'select',
      options: cfChangeTypes.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    cfAccountItemId: {
      label: 'CF科目',
      type: 'select',
      options: cfAccountItems.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

exports.joinAndExceptMappingFields = joinAndExceptMappingFields;
