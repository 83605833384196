import React from 'react';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { keyBy } from 'lodash';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import { adminFields, plans, } from '../../shared/models/company';
import { fields as adminNoteFields } from '../../shared/models/adminNote';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default AdminPage(function AdminCompanies(props) {
  const companies = useCollectionSubscription(companiesRef.orderBy('createdAt', 'desc'));
  const adminNotes = useCollectionSubscription(db.collection('adminNotes').where('type', '==', 'company'));
  const adminNotesByCompanyId = keyBy(adminNotes, 'objectId');

  return (
    <div className="admin-companies">
      <div className="container-fluid py-5">
        <div className="d-flex justify-content-center mb-2">
          <h3>会社管理</h3>
        </div>
        <table className="table mt-5 table-bordered">
          <thead className="thead-light text-nowrap">
            <tr>
              <th style={{ minWidth: 250 }}>会社名</th>
              <th style={{ minWidth: 200 }}>作成日時</th>
              <th style={{ minWidth: 120 }}>試用期間(日)</th>
              <th style={{ minWidth: 120 }}>子会社数上限</th>
              <th style={{ minWidth: 130 }}>プラン</th>
              <th style={{ minWidth: 250 }}>メモ</th>
              <th style={{ minWidth: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              companies.map(({ id, ref, display_name: name, trialDays = 30, subsidiariesLimit, plan = 'trial', createdAt, }) => {
                const adminNote = adminNotesByCompanyId[id];
                return (
                  <tr key={id} id={id}>
                    <td>
                      <Link to={`/companies/${id}`}>
                        {name}
                      </Link>
                    </td>
                    <td>
                      {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                    </td>
                    <td className="text-right">
                      {trialDays}
                    </td>
                    <td className="text-right">
                      {subsidiariesLimit != null && numeral(subsidiariesLimit).format()}
                    </td>
                    <td>
                      {plans[plan].label}
                    </td>
                    <td style={{ whiteSpace: 'pre-line' }}>
                      {adminNote?.note}
                    </td>
                    <td>
                      <div className="text-nowrap d-flex justify-content-end gap-1">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '会社 編集', fields: adminFields, }} />
                        <EditButton label="メモ" type="set" processValues={_ => ({ ..._, type: 'company', objectId: id })} itemRef={db.collection('adminNotes').doc('company__' + id)} FormModal={ModelFormModal} formProps={{ title: '管理メモ 編集', fields: adminNoteFields, }} />
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
