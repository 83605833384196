import React, { useState, useEffect, useMemo, } from 'react';
import { chunk, pickBy, findKey, range, inRange, get, pick, last, sortBy, } from 'lodash';
import { useHistory, useParams, useLocation, } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { format as formatDate, endOfMonth, addYears, } from 'date-fns';
import qs from 'qs';
import { useAsync } from 'react-use';
import classnames from 'classnames';

import { dateStringToDate, } from '../../util';
import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { yearMonthsOfPeriod, } from '../../shared/util';
import firebase, { functions } from '../../firebase';
import { screens, } from '../../shared/config';
import { roles, } from '../../shared/models/user';
import { isTrialExpired, } from '../../shared/models/company';
import HeaderNav from '../HeaderNav';
import PageNoter from '../PageNoter';
import CompanyContext from '../contexts/company';
import CompanySyncButton from '../CompanySyncButton';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';

const auth = firebase.auth();
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const headerLinks = pickBy(screens, 'showsInHeader');
const { entries, keys, } = Object;

export default function CompanyPageHOC(WrappedComponent) {
  return function CompanyPage (props) {
    const { user, match, } = props;
    const location = useLocation();
    const history = useHistory();
    const { companyId } = useParams();
    const queryParams = useQueryParams();
    const { period, yearMonth, } = queryParams;
    const yearMonthDate = useMemo(() => yearMonth && endOfMonth(new Date(Number(yearMonth.slice(0, 4)), Number(yearMonth.slice(4)) - 1, 1)), [yearMonth]);
    const company = useDocumentSubscription(companiesRef.doc(companyId), [companyId]);
    const { role, enabledScreens, enabledRelatedCompanyIds, } = company?.users?.[user.id] || {};
    const headerLinksForUser = (user?.admin || ['owner', 'admin'].includes(role)) ? headerLinks : pickBy(headerLinks, (v, k) => enabledScreens?.includes(k));
    const pathName = get(match.path.match(/:companyId\/([^/]+)?/), 1) || '';
    const currentScreen = findKey(screens, ({ pathNames = [] }, screen) => {
      return [...pathNames, screen].includes(pathName);
    });
    const canAccess = keys(headerLinksForUser).includes(currentScreen);
    const periodSetting = useDocumentSubscription(period && company?.ref.collection('periodSettings').doc(period), [company, period]);
    const isLockedMonth = periodSetting?.locks?.[yearMonth] === true;
    const setInitialPeriod = () => {
      if(!period && company && company.fiscalYears) {
        const path = fullPathWithParams({ period: periodOfFiscalYear(last(company.fiscalYears)) }, location);
        history.replace(encodeURI(path));
      }
    };
    const fiscalYears = get(company, 'fiscalYears', []);
    const periodOptions = fiscalYears.map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), }));
    const fiscalYear = fiscalYearOfPeriod(period, fiscalYears) || {};
    const prevFiscalYear = fiscalYears[fiscalYears.indexOf(fiscalYear) - 1];
    const prevEndYearMonth = get(prevFiscalYear, 'end_date', '').replace(/-/g, '').slice(0, 6);
    const prevEndYearMonthDate = useMemo(() => prevEndYearMonth && endOfMonth(new Date(Number(prevEndYearMonth.slice(0, 4)), Number(prevEndYearMonth.slice(4)) - 1, 1)), [prevEndYearMonth]);
    const { start_date: startDate, end_date: endDate } = fiscalYear;
    const monthOptions = startDate ? range(formatDate(dateStringToDate(startDate), 'yyyyMM'), formatDate(dateStringToDate(endDate), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
    const yearMonths = useMemo(() => yearMonthsOfPeriod(period, company?.fiscalYears), [company, period]);
    const filteredYearMonths = useMemo(() => yearMonths.filter(_ => _ <= yearMonthDate), [yearMonths, yearMonthDate]);
    const filteredQuarters = useMemo(_ => chunk(filteredYearMonths, 3), [filteredYearMonths]);
    const prevYearMonths = useMemo(_ => yearMonths.map(_ => addYears(_, -1)), [yearMonths]);
    const filteredPrevYearMonths = useMemo(() => filteredYearMonths.map(_ => addYears(_, -1)), [filteredYearMonths]);
    const setInitialTargetMonth = () => {
      if (!company || !period || !fiscalYear) return;
      if(monthOptions.map(_ => _.value).includes(yearMonth)) return;
      const path = fullPathWithParams({ yearMonth: formatDate(dateStringToDate(fiscalYear.end_date), 'yyyyMM') }, location);
      history.replace(encodeURI(path));
    };
    useEffect(() => {
      setInitialPeriod();
    }, [period, company]);
    useEffect(() => {
      setInitialTargetMonth();
    }, [period, company]);
    return (
      <div className={classnames('company-page h-100 position-relative', { 'read-only': !user.admin && role === 'reader' })}>
        {
          company != null && (
            <CompanyContext.Provider value={company}>
              {
                (
                  <div className="h-100 d-flex flex-column">
                    <HeaderNav {...props} {...{ company, role, period, yearMonth, periodOptions, monthOptions, periodSetting, isLockedMonth, }} links={headerLinksForUser} />
                    <div className="flex-grow-1">
                      {
                        !isTrialExpired(company) ? (
                          (match.path.includes('settings/members') || company.fiscalYears != null) ? (
                            canAccess ? (
                              <WrappedComponent
                                {...props}
                                {...{
                                  company,
                                  role,
                                  period,
                                  yearMonth,
                                  yearMonthDate,
                                  periodOptions,
                                  monthOptions,
                                  fiscalYear,
                                  prevFiscalYear,
                                  prevEndYearMonth,
                                  prevEndYearMonthDate,
                                  yearMonths,
                                  filteredYearMonths,
                                  filteredQuarters,
                                  prevYearMonths,
                                  filteredPrevYearMonths,
                                  periodSetting,
                                  isLockedMonth,
                                }}
                              />
                            ) : (
                              <div className="container">
                                <div className="alert alert-danger mt-3">権限がありません</div>
                              </div>
                            )
                          ) : (
                            <div className="py-5 container text-danger text-center">
                              <CompanySyncButton company={company} />
                            </div>
                          )
                        ) : (
                          <div className="py-5 container text-danger text-center">
                            <div className="row">
                              <div className="col-md-6 offset-md-3">
                                <div className="alert alert-info">
                                  <div className="mb-3">
                                    YUIの試用期間が終了しました。
                                    <br />
                                    有償版をぜひご利用ください。
                                  </div>
                                  <Button color="primary" tag="a" href="https://docs.google.com/forms/d/e/1FAIpQLSd4AzP_zKZBiBiEtRg_DZutvESgJsMi5aMSPnihq57vkR9X4g/viewform" target="_blank">
                                    <span className="fas fa-external-link-alt mr-1" />
                                    有償版ご利用を申し込む
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )
              }
              <PageNoter readOnly={!user.admin && role === 'reader'} />
            </CompanyContext.Provider>
          )
        }
      </div>
    );
  };
};
