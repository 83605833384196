import React from 'react';
import Select from 'react-select';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router';

import { fullPathWithParams, } from '../util';
import useLocale from './hooks/useLocale';

export default function QuerySelector(props) {
  const { paramName = 'filter', defaultValue = [], label = '絞込み', isMulti = false, width = 200, options: _options = [], disabled = false, selectorProps, simple = false, ...extraProps } = props;
  const { translate } = useLocale();
  const options = _options.map(_ => ({ ..._, label: translate(_.label) }));
  const history = useHistory();
  const location = useLocation();
  const { [paramName]: currentValue = defaultValue, } = qs.parse(decodeURI(location.search.slice(1)));
  const onSelect = (selected) => {
    const value = isMulti ? (selected || []).map(_ => _.value) : (selected ? selected.value : null);
    const path = fullPathWithParams({ [paramName]: value, }, location);
    history.replace(encodeURI(path));
  };

  return (
    <div style={{ width, }} {...extraProps}>
      <div className="text-muted small">{label}</div>
      <Select
        isMulti={isMulti}
        value={options.filter(_ => currentValue.includes(_.value))}
        options={options}
        onChange={onSelect}
        className="w-100"
        isDisabled={disabled}
        isClearable
        components={simple && { IndicatorsContainer: _ => null }}
        getOptionLabel={_ => _.label + _.name}
        formatOptionLabel={_ => _.label}
        {...selectorProps}
      />
    </div>
  );
};
