import React, { Fragment, Component, useRef, useEffect, useMemo, } from 'react';
import { useToggle, useAsync, useRaf, } from 'react-use';
import { sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { accountItemCategories } from '../../shared/config';
import { presetConsolidationAccountItems, formFields as fields, } from '../../shared/models/consolidationAccountItem';
import { generateRowGroups, rowsForExport, } from '../../shared/lib/consolidationAccountItems';
import { integerFormat } from '../../util';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ModelFormModal from '../modals/ModelFormModal';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();
const accountItemCategoryNames = accountItemCategories.map(_ => _.name);

export default CompanyPage(function CompanyConsolidationAccountItems (props) {
  const { company, } = props;
  const queryParams = useQueryParams();
  const {
    showsAll = '0',
  } = queryParams;
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = [company, ...subsidiaries];
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = accountItems.filter(_ => _.subsidiaryId === null);
  const sortedConsolidationAccountItems = sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category));
  const rowGroups = generateRowGroups(consolidationAccountItems, accountItems, company, relatedCompanies, showsAll === '1');

  return props.translate(
    <div className="company-account-item-mappings container-fluid">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結科目を整備する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結科目</h4>
        </div>
        <div className="mb-3 d-flex align-items-end justify-content-start gap-1">
          <ExportButton fileName="連結科目.csv" rows={rowsForExport(consolidationAccountItems, accountItems, company, relatedCompanies, true)} />
          <QueryBoolean paramName="showsAll" label="すべて表示" defaultValue={'0'} />
        </div>
        <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
          <table className="table table-bordered sticky-table mb-0 flex-table">
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th style={{ width: 110 }} className="sticky"></th>
                <th style={{ width: 200, }} className="sticky">カテゴリ</th>
                <th style={{ width: 200, }} className="sticky">開示</th>
                <th style={{ width: 200, }} className="sticky">連結科目</th>
                {
                  relatedCompanies.map((company) => {
                    return (
                      <th key={company.id} style={{ width: 200 }}>
                        {company.display_name}
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            {
              rowGroups.filter(_ => _.filteredRows.length > 0).map(({ accountItemCategory, filteredRows }) => {
                return (
                  <RowsGroup key={accountItemCategory.name} {...{ company, consolidationAccountItems, accountItemCategory, rows: filteredRows, }} />
                );
              })
            }
          </table>
        </div>
      </div>
    </div>
  );
});

function RowsGroup (props) {
  const { company, consolidationAccountItems, accountItemCategory, rows, } = props;
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(rows, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('accountItems').doc(id), { index });
    });
  };

  return (
    <Container
      dragHandleSelector=".drag-handle"
      onDrop={onDrop}
      dropPlaceholder={{ style: { background: 'eee', } }}
      render={(ref) => (
        <tbody ref={ref} className="thead-light border-3">
          {
            rows.map((row, i) => {
              const { id, ref, name, shortcut_num: accountCode, group_name, columns, createdInYui = false, } = row;
              const beforeDelete = async () => {
                if(
                  (await Promise.all(
                    ['relatedCompanyBalances', 'fixedAssets', 'fixedAssetRelatedItems', 'cashes', 'investments', 'investmentRelatedItems', 'fixedDeposits', 'loans', 'incomeInterestItems', 'debts', 'bonds', 'capitals', 'treasuryStocks', 'stockOptions', 'expenseInterestItems', 'miscellaneousAccounts', 'allowances'].map(_ => getCollectionData(company.ref.collection(_).where('accountItemId', '==', id).limit(1)))
                  )).some(_ => _.length > 0) ||
                  (await getCollectionData(company.ref.collection('trials').where('subsidiaryId', '==', null))).some(_ => _.balances.some(_ => _.account_item_name === name)) ||
                  (await getCollectionData(company.ref.collection('individualAdjustmentJournals'))).some(_ => _.items.some(_ => console.log(_.debitItemId, id) || [_.debitItemId, _.creditItemId].includes(id))) ||
                  (await getCollectionData(company.ref.collection('consolidationJournals'))).some(_ => _.items.some(_ => console.log(_.debitItemId, id) || [_.debitItemId, _.creditItemId].includes(id))) ||
                  (await getCollectionData(company.ref.collection('accountItems'))).some(_ => _.id !== id && _.consolidationAccountItemId === id)
                ) {
                  toast.error('使用されているため、削除できません');
                  return false;
                }
              };

              return (
                <Draggable
                  key={id}
                  render={() => (
                    <tr data-id={id} style={{ display: 'flex', overflow: 'initial', }}>
                      <th style={{ width: 110, }} className="sticky">
                        <div className="d-flex gap-1">
                          <div className="drag-handle text-muted cursor-pointer px-2 py-1">
                            <span className="fas fa-grip-vertical" />
                          </div>
                        </div>
                      </th>
                      <th style={{ width: 200, }} className="sticky">
                        {accountItemCategory.name}
                      </th>
                      <th style={{ width: 200, }} className="sticky">
                        {group_name}
                      </th>
                      <th style={{ width: 200, }} className="sticky">
                        {name}
                        {accountCode && <span className="text-muted ml-1 small">({accountCode})</span>}
                        {company.externalType != null && createdInYui && <div><div className="badge badge-info">YUIで作成</div></div>}
                      </th>
                      {
                        columns.map((column, i) => {
                          return (
                            <td key={i} style={{ width: 200 }}>
                              {
                                column.accountItems.map((accountItem) => {
                                  return (
                                    <div key={accountItem.id}>
                                      {accountItem.name}
                                    </div>
                                  );
                                })
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  )}
                />
              );
            })
          }
        </tbody>
      )}
    />
  );
};
