const { sortBy, isEmpty, } = require('lodash');
const { differenceInDays } = require('date-fns');

const { accountItemCategoryNames, accountItemCategoriesByName, } = require('../config');
const ConsolidationAccountItem = require('./consolidationAccountItem');
const ConsolidationJournalType = require('./consolidationJournalType');
const CfAccountItem = require('./cfAccountItem');
const { getDocumentData, getCollectionData, } = require('../firebase');

const { presetConsolidationAccountItems } = ConsolidationAccountItem;
const { presetConsolidationJournalTypes, } = ConsolidationJournalType;
const { presetCfAccontItems } = CfAccountItem;
const currentYear = (new Date()).getFullYear();

const { entries } = Object;

const plans = {
  trial: { label: '試用プラン' },
  standard: { label: 'スタンダードプラン' },
};

const leftTrialDays = (company = {}) => {
  return company.createdAt ? (company.trialDays >= 0 ? company.trialDays : 30) - differenceInDays(new Date(), company.createdAt.toDate()) : -1;
};
const isTrialExpired = (company) => {
  return (company?.plan || 'trial') === 'trial' && leftTrialDays(company) < 0;
};

const computeCompaniesAmounts = async (company, yearMonth) => {
  const subsidiaries = await getCollectionData(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = [company, ...subsidiaries];
  const accountItems = await getCollectionData(company.ref.collection('accountItems'));
  const consolidationAccountItems = await getCollectionData(company.ref.collection('accountItems').where('subsidiaryId', '==', null));
  const sortedConsolidationAccountItems = sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index');
  const allConsolidationAccountItems = [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems];
  const consolidationJournalTypes = await getCollectionData(company.ref.collection('consolidationJournalTypes'));
  const sortedConsolidationJournalTypes = sortBy(consolidationJournalTypes, 'index');
  const allConsolidationJournalTypes = [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes];
  const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
  const trials = await getCollectionData(company.ref.collection('trials').where('yearMonth', '==', yearMonth));
  const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth));
  const consolidationJournals = await getCollectionData(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth));
  const exchangedItems = await getCollectionData(company.ref.collection('exchangedItems').where('yearMonth', '==', yearMonth));
  const monthGroup = { yearMonth, trials, individualAdjustmentJournals, consolidationJournals, exchangedItems, exchangeRate };
  const items = ConsolidationAccountItem.computeCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, [monthGroup]);
  return items;
};

const computeDisclosureAmounts = async (company, yearMonth) => {
  const subsidiaries = await getCollectionData(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = [company, ...subsidiaries];
  const accountItems = await getCollectionData(company.ref.collection('accountItems'));
  const consolidationAccountItems = await getCollectionData(company.ref.collection('accountItems').where('subsidiaryId', '==', null));
  const sortedConsolidationAccountItems = sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index');
  const allConsolidationAccountItems = [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems];
  const consolidationJournalTypes = await getCollectionData(company.ref.collection('consolidationJournalTypes'));
  const sortedConsolidationJournalTypes = sortBy(consolidationJournalTypes, 'index');
  const allConsolidationJournalTypes = [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes];
  const disclosureSetting = await getDocumentData(company.ref.collection('disclosureSettings').doc(yearMonth));
  const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
  const trials = await getCollectionData(company.ref.collection('trials').where('yearMonth', '==', yearMonth));
  const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth));
  const consolidationJournals = await getCollectionData(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth));
  const exchangedItems = await getCollectionData(company.ref.collection('exchangedItems').where('yearMonth', '==', yearMonth));
  const monthGroup = { yearMonth, trials, individualAdjustmentJournals, consolidationJournals, exchangedItems, exchangeRate };
  const items = ConsolidationAccountItem.computeDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, [monthGroup], null, null, false, disclosureSetting);
  return items;
};

const generalSettingsFields = (company) => {
  return {
    usesApprovement: {
      label: '仕訳承認＆連結PKGロック機能を使う',
      type: 'boolean',
      initialValue: false,
    },
    usesMonthlyAr: {
      label: '換算に毎月のARを使う',
      type: 'boolean',
      initialValue: false,
    },
    usesWizLabo: {
      label: 'WizLaboと連携する',
      type: 'boolean',
      initialValue: false,
    },
    wizlaboCompanyId: {
      type: 'string',
      label: 'WizLabo 会社ID',
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => !_.usesWizLabo,
    },
    wizlaboLoginId: {
      type: 'string',
      label: 'WizLabo ログインID',
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => !_.usesWizLabo,
    },
    wizlaboLoginPassword: {
      type: 'password',
      label: 'WizLabo パスワード',
      hidden: _ => !_.usesWizLabo,
      hint: '変更しない場合は空にしてください',
    },
    usesPronexus: {
      label: 'PRONEXUS WORKSと連携する',
      type: 'boolean',
      initialValue: false,
    },
    pronexusLoginId: {
      type: 'string',
      label: 'PRONEXUS WORKS ログインID',
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => !_.usesPronexus,
    },
    pronexusLoginPassword: {
      type: 'password',
      label: 'PRONEXUS WORKS パスワード',
      hidden: _ => !_.usesPronexus,
      hint: '変更しない場合は空にしてください',
    },
  };
};

const integrationSettingsFields = () => {
  return {
    ...presetConsolidationAccountItems.reduce((x, presetConsolidationAccountItem) => {
      const { id, name, } = presetConsolidationAccountItem;
      return {
        ...x,
        [`integration_account_id_${id}`]: {
          type: 'string',
          label: `${name} 科目ID`,
        },
      };
    }, {}),
    'integration_account_id_売上原価': {
      type: 'string',
      label: `売上原価 科目ID`,
    },
    ...presetCfAccontItems.reduce((x, presetCfAccontItem) => {
      const { id, name, } = presetCfAccontItem;
      return {
        ...x,
        [`integration_account_id_${id}`]: {
          type: 'string',
          label: `${name} 科目ID`,
        },
      };
    }, {}),
  };
};

const fieldsToAdd = ({ externalCompanies = [] } = {}) => {
  return {
    sourceId: {
      label: '事業所',
      type: 'select',
      options: externalCompanies.map(_ => ({ label: _.display_name || _.name, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
  };
};

const pcaCompanyFields = ({ dataAreas, } = {}) => {
  return {
    display_name: {
      label: '事業所名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    dataAreaIds: {
      label: 'データ領域(会計期間)',
      type: 'multiSelect',
      options: dataAreas.map(_ => ({ label: `${_.CompanyName} (${_.CompanyTerm})`, value: _.Name })),
      validations: {
        required: v => v?.length > 0,
      },
    },
  };
};

const adminFields = () => {
  return {
    trialDays: {
      label: '試用期間(日)',
      type: 'integer',
      validations: {
        greaterThanOrEqualTo0: v => v != null && v >= 0,
      },
      initialValue: 30,
    },
    subsidiariesLimit: {
      label: '子会社数上限',
      type: 'integer',
      placeholder: '空なら5',
    },
    plan: {
      label: 'プラン',
      type: 'select',
      options: entries(plans).map(([k, { label }]) => ({ label, value: k })),
      validations: {
        required: v => !isEmpty(v),
      },
      initialValue: 'trial',
    },
  };
};

const nonFreeeFields = () => {
  return {
    display_name: {
      label: '事業所名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fiscalYearFields = () => {
  return {
    start_date: {
      label: '開始',
      type: 'date',
      selector: true,
      yearRange: [2018, currentYear + 5],
      inputProps: { onlyYearMonth: true },
      initialValue: null,
    },
    end_date: {
      label: '終了',
      type: 'date',
      selector: true,
      yearRange: [2018, currentYear + 5],
      inputProps: { onlyYearMonth: true },
      initialValue: null,
    },
  };
};

exports.fiscalYearFields = fiscalYearFields;
exports.nonFreeeFields = nonFreeeFields;
exports.adminFields = adminFields;
exports.fieldsToAdd = fieldsToAdd;
exports.pcaCompanyFields = pcaCompanyFields;
exports.plans = plans;
exports.leftTrialDays = leftTrialDays;
exports.isTrialExpired = isTrialExpired;
exports.computeCompaniesAmounts = computeCompaniesAmounts;
exports.computeDisclosureAmounts = computeDisclosureAmounts;
exports.generalSettingsFields = generalSettingsFields;
exports.integrationSettingsFields = integrationSettingsFields;
