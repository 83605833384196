const { isEmpty, omit, } = require('lodash');

const { cfAccountItemCategories, } = require('../config');

const { entries } = Object;

const presetCfAccontItems = [
  {
    id: '現金及び現金同等物に係る換算差額',
    name: '現金及び現金同等物に係る換算差額',
    cfAccountItemCategoryKey: 'cashExchangeDiff',
  },
];

const fields = ({ otherItems = [], consolidationAccountItems = [] }) => {
  const otherItemNames = otherItems.map(_ => _.name);
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        unique: v => !otherItemNames.includes(v),
      },
    },
    accountCode: {
      label: '科目コード',
      type: 'string',
      validations: {
        format: v => isEmpty(v) || v.match(/^[a-zA-Z0-9_]+$/),
      },
    },
    cfAccountItemCategoryKey: {
      label: '区分',
      type: 'select',
      options: entries(omit(cfAccountItemCategories, ['cashExchangeDiff'])).map(([k, v]) => ({ label: v.label, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    consolidationAccountItemIds: {
      label: '対応する連結科目',
      type: 'multiSelect',
      options: consolidationAccountItems.map(_ => ({ label: `${_.name}${_.shortcut_num ? ` [${_.shortcut_num}]` : ''}`, value: _.id })),
    },
  };
};

exports.fields = fields;
exports.presetCfAccontItems = presetCfAccontItems;
