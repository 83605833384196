const { isEmpty, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const fields = ({ accountItems = [] } = {}) => {
  return {
    accountItemId: {
      label: '個別勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
    closing: {
      label: '期末',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
  };
};

exports.fields = fields;
