const { inRange, range, isEmpty, } = require('lodash');
const { format: formatDate, } = require('date-fns');

const { optionLabel, } = require('../lib/consolidationAccountItems');
const { changeTypeOptions, } = require('../changeTypes');

const { entries } = Object;

const inheritTypes = {
  none: { label: 'なし', },
  carry: { label: '繰越', },
  reversal: { label: '洗替', },
};

const batchCloneFields = ({ company, periodOptions, fiscalYearOfPeriod, allConsolidationJournalTypes, }) => {
  return {
    period: {
      label: '事業年度',
      type: 'select',
      options: periodOptions,
      validations: {
        required: v => v != null,
      },
    },
    yearMonth: {
      label: '月度',
      type: 'select',
      options: (state) => {
        const fiscalYear = fiscalYearOfPeriod(state.period, company.fiscalYears) || {};
        const { start_date: startDate, end_date: endDate } = fiscalYear;
        const monthOptions = startDate ? range(formatDate(new Date(startDate), 'yyyyMM'), formatDate(new Date(endDate), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
        return monthOptions;
      },
      validations: {
        required: v => v != null,
      },
    },
    types: {
      label: '種別',
      type: 'multiSelect',
      options: allConsolidationJournalTypes.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fields = ({ allConsolidationJournalTypes }) => {
  return {
    type: {
      label: '種別',
      type: 'select',
      options: allConsolidationJournalTypes.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    isOpening: {
      label: '開始仕訳',
      type: 'boolean',
      initialValue: false,
    },
    inheritType: {
      label: '翌期への引き継ぎ',
      type: 'select',
      options: entries(inheritTypes).map(([k, v]) => ({ label: v.label, value: k })),
      initialValue: 'none',
      validations: {
        required: v => v != null,
      },
    },
    description: {
      label: '説明',
      type: 'text',
    }
  };
};

const itemFields = ({ company, companies = [], consolidationSegments = [], consolidationAccountItems = [] }) => {
  return {
    debitCompanyId: {
      label: '借方会社',
      type: 'select',
      options: companies.map(_ => ({ label: _.display_name, value: _.id })),
      validations: {
        requiredInSomeCase: (v, s) => !(Math.abs(s.debitAmount) > 0 && isEmpty(v)),
      },
    },
    debitItemId: {
      label: '借方科目',
      type: 'select',
      options: consolidationAccountItems.map(_ => ({ label: optionLabel(_, company), value: _.id })),
      validations: {
        requiredInSomeCase: (v, s) => !(Math.abs(s.debitAmount) > 0 && isEmpty(v)),
      },
    },
    debitSegmentId: {
      label: '借方セグメント',
      type: 'select',
      options: consolidationSegments.map(_ => ({ label: _.name, value: _.id })),
    },
    debitAmount: {
      label: '借方金額',
      type: 'integer',
    },
    debitIsIntersegment: {
      label: '借方セグメント間',
      type: 'boolean',
      initialValue: false,
    },
    debitChangeType: {
      label: '借方増減種別',
      type: 'select',
      options: changeTypeOptions,
    },
    creditCompanyId: {
      label: '貸方会社',
      type: 'select',
      options: companies.map(_ => ({ label: _.display_name, value: _.id })),
      validations: {
        requiredInSomeCase: (v, s) => !(Math.abs(s.creditAmount) > 0 && isEmpty(v)),
      },
    },
    creditItemId: {
      label: '貸方科目',
      type: 'select',
      options: consolidationAccountItems.map(_ => ({ label: optionLabel(_, company), value: _.id })),
      validations: {
        requiredInSomeCase: (v, s) => !(Math.abs(s.creditAmount) > 0 && isEmpty(v)),
      },
    },
    creditSegmentId: {
      label: '貸方セグメント',
      type: 'select',
      options: consolidationSegments.map(_ => ({ label: _.name, value: _.id })),
    },
    creditAmount: {
      label: '貸方金額',
      type: 'integer',
    },
    creditIsIntersegment: {
      label: '貸方セグメント間',
      type: 'boolean',
      initialValue: false,
    },
    creditChangeType: {
      label: '貸方増減種別',
      type: 'select',
      options: changeTypeOptions,
    },
  };
};

exports.itemFields = itemFields;
exports.fields = fields;
exports.batchCloneFields = batchCloneFields;
exports.inheritTypes = inheritTypes;
