const { get, mapValues, sumBy, groupBy, keyBy, sortBy, omit, isEmpty, } = require('lodash');

const { accountItemCategories } = require('../config');
const { fieldDisplayValue } = require('../util');
const { cfMappingItemFields, } = require('../models/consolidationAccountItem');

const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRowGroups = (allConsolidationAccountItems) => {
  const consolidationAccountItemsGroupedByCategory = groupBy(allConsolidationAccountItems, _ => _.account_category);
  return accountItemCategories.map((accountItemCategory) => {
    const rows = sortBy((consolidationAccountItemsGroupedByCategory[accountItemCategory.name] || []).map((consolidationAccountItem) => {
      return {
        ...consolidationAccountItem,
      };
    }), 'index');
    return {
      accountItemCategory,
      rows,
    };
  });
};

const rowsForExport = (allConsolidationAccountItems, cfChangeTypes, allCfAccountItems) => () => {
  return generateRowGroups(allConsolidationAccountItems).flatMap(_ => _.rows).flatMap((row) => {
    const _fields = cfMappingItemFields({ cfChangeTypes, cfAccountItems: allCfAccountItems });
    const { name, cfMappingItems = [{}] } = row;
    return (isEmpty(cfMappingItems) ? [{}] : cfMappingItems).map((cfMappingItem) => {
      return {
        consolidationAccountItemName: name,
        pkgCategoryName: fieldDisplayValue(cfMappingItem, 'pkgCategory', _fields.pkgCategory),
        columnName: fieldDisplayValue(cfMappingItem, 'columnKey', _fields.columnKey),
        cfChangeTypeName: fieldDisplayValue(cfMappingItem, 'cfChangeTypeId', _fields.cfChangeTypeId),
        cfAccountItemName: fieldDisplayValue(cfMappingItem, 'cfAccountItemId', _fields.cfAccountItemId),
      };
    });
  });
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
