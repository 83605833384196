import React, { useRef, useState, useEffect, } from 'react'
import { useRaf, useToggle, usePrevious, } from 'react-use'

import FadeOut from './FadeOut';

const ContentEditable = ({ value, onChange = _ => _, onFix, disabled = false, }) => {
  const elm = useRef(null);
  const [internalValue, setInternalValue] = useState();
  const [hasFixed, toggleHasFixed] = useToggle(false);
  const prevInternalValue = usePrevious(internalValue);
  const onInput = (e) => {
    setInternalValue(e.target.innerText);
  };
  const fix = async () => {
    await onFix(elm.current.innerText);
    toggleHasFixed(true);
  };
  const onKeyPress = async (e) => {
    if(e.code === 'Enter') {
      e.preventDefault();
      await fix();
    }
    if(e.code === 'Tab') {
      await fix();
      elm.current.blur();
    }
  };
  const onBlur = async () => {
    await fix();
  };
  const onFocus = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    const textNode = elm.current.firstChild;
    if(textNode == null) return;

    range.setStart(textNode, 0);
    range.setEnd(textNode, textNode.length);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  useEffect(() => {
    onChange(internalValue, prevInternalValue);
  }, [internalValue]);

  return (
    <div className="position-relative">
      <div
        ref={elm}
        contentEditable={!disabled}
        className="px-1"
        onInput={onInput}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        dangerouslySetInnerHTML={{ __html: value, }}
      />
      {
        hasFixed && (
          <FadeOut onFadedOut={toggleHasFixed.bind(null, false)}>
            <div className="fa fa-check text-success position-absolute" style={{ top: 4, right: -11, fontSize: 13, }} />
          </FadeOut>
        )
      }
    </div>
  );
};

export default ContentEditable
