import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { uniq, round, get, invert, mapValues, pick, isEmpty, last, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import numeral from 'numeral';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { useHistory, useLocation, } from 'react-router';
import { toast } from 'react-toastify';
import { addSeconds, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { numberFormat } from '../../util';
import texts from '../../shared/texts';
import { fiscalYearOfPeriod, } from '../../utils';
import { batch, getCollectionData, } from '../../shared/firebase';
import { changeTypes } from '../../shared/changeTypes';
import { accountItemCategoryNames } from '../../shared/config';
import { parseSections, } from '../../shared/models/section';
import { fields, itemFields, inheritTypes, batchCloneFields, } from '../../shared/models/individualAdjustmentJournal';
import { getCategory, } from '../../shared/models/accountItem';
import { generateRows, rowsForExport, } from '../../shared/lib/individualAdjustments';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import IndividualAdjustmentJournalFormModal from '../modals/IndividualAdjustmentJournalFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import AutoLinkText from '../AutoLinkText';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';

const { entries } = Object;
const db = firebase.firestore();
const inheritTypesByLabel = invert(mapValues(inheritTypes, _ => _.label));

export default RelatedCompanyPage(function CompanyIndividualAdjustments (props) {
  const { user, company, relatedCompany, subsidiaryId, period, yearMonth, periodOptions, prevEndYearMonth, isLockedMonth, } = props;
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const [isDragging, toggleDragging] = useToggle(false);
  const individualAdjustmentJournalsRef = company.ref.collection('individualAdjustmentJournals');
  const individualAdjustmentJournals = useCollectionSubscription(individualAdjustmentJournalsRef.where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [company, subsidiaryId, yearMonth]);
  const individualAdjustmentJournalsById = keyBy(individualAdjustmentJournals, 'id');
  const sections = useCollectionSubscription(company.ref.collection('sections').where('subsidiaryId', '==', subsidiaryId), [relatedCompany.id]);
  const { tipSections } = parseSections(sections);
  const sectionsById = keyBy(tipSections, 'id');
  const sectionsByName = keyBy(tipSections, 'name');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const sortedAccountItems = sortBy(accountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index');
  const accountItemsGroupedByConsolidationAccountItemId = groupBy(accountItems, 'consolidationAccountItemId');
  const consolidationAccountItems = sortedAccountItems.filter(_ => _.subsidiaryId === null);
  const mappedConsolidationAccountItems = consolidationAccountItems.filter(_ => accountItemsGroupedByConsolidationAccountItemId[_.id]);
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const consolidationAccountItemsByCode = keyBy(consolidationAccountItems, 'shortcut_num');
  const consolidationAccountItemsByName = keyBy(consolidationAccountItems, 'name');
  const rows = generateRows(individualAdjustmentJournals, sections, accountItems, consolidationAccountItems);

  let filteredRows = rows;
  let filtersContent = null;
  [['debit', 'credit', '借方'], ['credit', 'debit', '貸方']].map(([direction, opponent, directionJa]) => {
    const itemIdName = direction + 'ItemId';
    if(queryParams[itemIdName]) {
      const consolidationAccountItem = consolidationAccountItemsById[queryParams[itemIdName]];
      filteredRows = filteredRows
        .map(_ => ({ ..._, items: _.items.filter(_ => _[itemIdName] === queryParams[itemIdName]).map(_ => omit(_, [opponent + 'ItemId', opponent + 'Amount', opponent + 'SectionId'])), }))
        .filter(_ => _.items.length > 0);
      const onClickClearFilters = _ => history.replace(location.pathname + '?' + qs.stringify(omit(queryParams, itemIdName)));
      filtersContent = (
        <div className="alert alert-info d-flex align-items-center gap-2">
          {directionJa}科目「{consolidationAccountItem?.name}」で絞り込み中
          <Button outline color="link" className="text-secondary" onClick={onClickClearFilters}>
            <span className="fas fa-times mr-1" />
            クリア
          </Button>
        </div>
      );
    }
  });

  const processRows = (rows) => {
    return rows.reduce((x, y) => {
      return isEmpty(y.delimiter) ? [...x.slice(0, x.length - 1), [...last(x), y]] : [...x, [y]];
    }, [])
      .map((group, i) => {
        const [{ delimiter, inheritType = 'なし', description, }] = group;
        return {
          inheritType: inheritTypesByLabel[inheritType],
          items: group.map(({ debitAccountItemCode, debitAccountItemName, debitSectionName, debitAmount, creditAccountItemCode, creditAccountItemName, creditSectionName, creditAmount }) => {
            return {
              debitItemId: (relatedCompany.externalType === 'bugyo' ? consolidationAccountItemsByCode[debitAccountItemCode] : consolidationAccountItemsByName[debitAccountItemName])?.id || null,
              debitSectionId: sectionsByName[debitSectionName]?.id || null,
              debitAmount: numeral(debitAmount).value(),
              creditItemId: (relatedCompany.externalType === 'bugyo' ? consolidationAccountItemsByCode[creditAccountItemCode] : consolidationAccountItemsByName[creditAccountItemName])?.id || null,
              creditSectionId: sectionsByName[creditSectionName]?.id || null,
              creditAmount: numeral(creditAmount).value(),
            };
          }),
          description,
          index: i,
        };
      });
  };
  const validateRow = (row) => {
    const errors = entries(fields).map(([fieldName, { validations = {} }]) => {
      return entries(validations)
        .filter(([k, v]) => !v(row[fieldName], row, k))
        .map(([key]) => `[${fieldName}] ` + get(texts.validations.general, key));
    }).flat();
    const itemErrors = row.items.flatMap((item) => {
      return entries(itemFields({ consolidationAccountItems, sections, relatedCompany })).map(([fieldName, { validations = {} }]) => {
        return entries(validations)
          .filter(([k, v]) => !v(item[fieldName], item, k))
          .map(([key]) => `[${fieldName}] ` + get(texts.validations.general, key));
      }).flat();
    });
    const totalDebitAmount = round(sumBy(row.items, 'debitAmount'), 6);
    const totalCreditAmount = round(sumBy(row.items, 'creditAmount'), 6);
    const isMatchedDebitAndCredit = totalDebitAmount === totalCreditAmount;
    row.errors = [...(isMatchedDebitAndCredit ? [] : ['貸借一致していません']), ...errors, ...itemErrors];
    return row;
  };
  const processRow = (batch, row, i, importRef) => {
    const ref = individualAdjustmentJournalsRef.doc();
    batch.set(ref, {
      ...row,
      createdAt: new Date(),
      subsidiaryId,
      period,
      yearMonth,
      importId: importRef.id,
    }, { merge: true });
  };
  const onSubmitBatchClone = async (values, { onClickClose }) => {
    if(!window.confirm('本当にコピーしますか？')) return;

    const { yearMonth: sourceYearMonth, types } = values;
    const sources = await getCollectionData(individualAdjustmentJournalsRef.where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', sourceYearMonth));
    await batch(db, sortBy(sources, 'index'), (batch, source, i) => {
      batch.set(individualAdjustmentJournalsRef.doc(), {
        ...omit(source, ['id', 'ref']),
        period,
        yearMonth,
        createdAt: addSeconds(new Date(), i),
        description: (source.description || ''),
      })
    });
    toast.success('コピーしました');
    onClickClose();
  };
  const onClickInheritFromPrevEnd = async () => {
    if(!window.confirm('前期末から開始仕訳を引き継ぎます。よろしいですか？')) return;

    const prevEndYearMonthIndividualAdjustmentJournals = await getCollectionData(individualAdjustmentJournalsRef.where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', prevEndYearMonth));
    const items = sortBy(prevEndYearMonthIndividualAdjustmentJournals, 'index').flatMap((individualAdjustmentJournal) => {
      const { inheritType, items, } = individualAdjustmentJournal;
      const source = omit(individualAdjustmentJournal, ['id', 'ref']);
      return ({
        none: [],
        carry: [
          {
            ...source,
            items: items.map((item) => {
              return {
                ...item,
                debitItemId: accountItemIdWithInheritBenefitId(item.debitItemId),
                creditItemId: accountItemIdWithInheritBenefitId(item.creditItemId),
              };
            }),
          },
        ],
        reversal: [
          {
            ...source,
            inheritType: 'none',
            items: items.map((item) => {
              return {
                ...item,
                debitItemId: accountItemIdWithInheritBenefitId(item.debitItemId),
                creditItemId: accountItemIdWithInheritBenefitId(item.creditItemId),
              };
            }),
          },
          {
            ...source,
            inheritType: 'none',
            items: items.map((item) => {
              return ['ItemId', 'Amount'].reduce((x, y) => {
                return { ...x, [`debit${y}`]: item[`credit${y}`], [`credit${y}`]: item[`debit${y}`], };
              }, {});
            }),
          },
        ],
      })[inheritType];
    });
    await batch(db, items, (batch, item, i) => batch.set(individualAdjustmentJournalsRef.doc(), { ...item, isInherited: true, period, yearMonth, createdAt: addSeconds(new Date(), i) }));
    toast.success('開始仕訳を引き継ぎました');

    function accountItemIdWithInheritBenefitId (itemId) {
      if(itemId == null) return null;

      const consolidationAccountItem = consolidationAccountItemsById[itemId];
      const category = getCategory(consolidationAccountItem);
      return category?.type === 'bs' ? itemId : consolidationAccountItems.find(_ => _.name.startsWith('繰越利益'))?.id || consolidationAccountItems.find(_ => getCategory(_)?.name === '繰越利益剰余金')?.id;
    }
  };
  const onClickDeleteInheritedJournals = async () => {
    if(!window.confirm('前期末から引き継いだ開始仕訳を削除します。。よろしいですか？')) return;

    await batch(db, individualAdjustmentJournals.filter(_ => _.isInherited), (batch, _) => batch.delete(_.ref));
    toast.success('前期末から引き継いだ開始仕訳を削除しました。');
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(rows, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('individualAdjustmentJournals').doc(id), { index });
    });
  };
  const beforeDeleteImport = async (_import) => {
    const data = await getCollectionData(individualAdjustmentJournalsRef.where('importId', '==', _import.id));
    await batch(db, data, (batch, _) => batch.delete(_.ref));
  };

  return props.translate(
    <div className="company-individual-adjustments">
      <div className="mb-3 d-flex justify-content-end gap-1">
        {
          individualAdjustmentJournals.some(_ => _.isInherited) ? (
            <ProgressButton color="danger" icon={<span className="fas fa-trash" />} process={onClickDeleteInheritedJournals} disabled={isLockedMonth}>
              前期末から引き継いだ開始仕訳を削除
            </ProgressButton>
          ) : (
            <ProgressButton process={onClickInheritFromPrevEnd} disabled={isLockedMonth}>
              前期末から開始仕訳を引き継ぐ
            </ProgressButton>
          )
        }
        <ModalButton label="他の年月からコピー" Modal={ModelFormModal} modalProps={{ values: { period, yearMonth, }, fields: batchCloneFields({ periodOptions, company, fiscalYearOfPeriod, }), title: '他の年月からコピー', onSubmit: onSubmitBatchClone, submitLabel: 'コピー', }} disabled={isLockedMonth} data-operation-type="write" />
        <ImportButton processRows={processRows} processRow={processRow} documentName="individualAdjustmentJournal" disabled={isLockedMonth} validateRow={validateRow} beforeDeleteImport={beforeDeleteImport} importKey={`individualAdjustmentJournals__${relatedCompany.id}__${yearMonth}`} deleteImportConfirmMessage="インポートされた仕訳も削除されます。よろしいですか？" />
        <ExportButton fileName="修正仕訳.csv" rows={rowsForExport(relatedCompany, individualAdjustmentJournals, sections, accountItems, consolidationAccountItems)} />
        <AddButton itemRef={individualAdjustmentJournalsRef.doc()} processValues={_ => ({ ..._, subsidiaryId, period, yearMonth, changeTypes: uniq(_.items.flatMap(_ => [_.debitChangeType, _.creditChangeType]).filter(_ => _)), })} FormModal={IndividualAdjustmentJournalFormModal} initialValues={{ items: [{}], }} formProps={{ consolidationAccountItems: mappedConsolidationAccountItems, sections: tipSections, relatedCompany, }} disabled={isLockedMonth} />
      </div>
      {filtersContent}
      <div>
        <table className="table sticky-table">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 75 }}></th>
              <th style={{ minWidth: 200 }}>借方</th>
              <th style={{ minWidth: 200 }}>貸方</th>
              <th style={{ minWidth: 150, maxWidth: 300, }}>説明</th>
              <th style={{ minWidth: 100 }}></th>
            </tr>
          </thead>
          <Container
            dragHandleSelector={`.drag-handle`}
            onDrop={onDrop}
            onDragStart={_ => toggleDragging(true)}
            onDragEnd={_ => toggleDragging(false)}
            dropPlaceholder={{ style: { background: 'eee', } }}
            render={(ref) => (
              <tbody ref={ref}>
                {
                  filteredRows.map((row) => {
                    const { id, ref, inheritType = 'none', mappedItems, description, } = row;
                    const onClickCopy = async () => {
                      if(!window.confirm('本当にコピーしますか？')) return;

                      await individualAdjustmentJournalsRef.doc().set({
                        ...pick(row, ['inheritType', 'description', 'items', 'changeTypes']),
                        index: row.index + 0.01,
                        subsidiaryId,
                        period,
                        yearMonth,
                        createdAt: new Date(),
                        description: (row.description || '') + 'のコピー',
                      });
                      toast.success('コピーしました');
                    };
                    return (
                      <Draggable
                        key={id}
                        render={() => {
                          return (
                            <tr key={id} style={{ display: !isDragging && 'table-row', }}>
                              <td style={{ width: 75 }}>
                                <div className="d-flex align-items-start gap-2">
                                  <div className={`drag-handle text-muted cursor-pointer`}>
                                    <span className="fas fa-grip-vertical" />
                                  </div>
                                  <div className="d-flex flex-column gap-1">
                                    {inheritType !== 'none' && <div className="badge badge-info">{inheritTypes[inheritType].label}</div>}
                                  </div>
                                </div>
                                {
                                  user.dev && (
                                    <a href={`https://console.firebase.google.com/u/0/project/freee-consolidation-staging/firestore/data/~2Fcompanies~2F${company.id}~2FindividualAdjustmentJournals~2F${id}`} target="_blank">F</a>
                                  )
                                }
                              </td>
                              <td style={{ minWidth: 200, }}>
                                {
                                  mappedItems.map((item, i) => {
                                    const { debitAccountItem, debitSection, debitAmount, debitChangeType, } = item;
                                    return (
                                      <div key={i} className="d-flex justify-content-between">
                                        <div>{debitAccountItem?.name}</div>
                                        <div className="text-muted">{debitSection?.name}</div>
                                        <div className="text-muted">{changeTypes[debitChangeType]?.label}</div>
                                        <div>{debitAmount != null && numberFormat(relatedCompany.currency, debitAmount)}</div>
                                      </div>
                                    );
                                  })
                                }
                              </td>
                              <td style={{ minWidth: 200, }}>
                                {
                                  mappedItems.map((item, i) => {
                                    const { creditAccountItem, creditSection, creditAmount, creditChangeType, } = item;
                                    return (
                                      <div key={i} className="d-flex justify-content-between">
                                        <div>{creditAccountItem?.name}</div>
                                        <div className="text-muted">{creditSection?.name}</div>
                                        <div className="text-muted">{changeTypes[creditChangeType]?.label}</div>
                                        <div>{creditAmount != null && numberFormat(relatedCompany.currency, creditAmount)}</div>
                                      </div>
                                    );
                                  })
                                }
                              </td>
                              <td style={{ minWidth: 150, maxWidth: 300, }}>
                                <AutoLinkText>
                                  {description}
                                </AutoLinkText>
                              </td>
                              <td style={{ minWidth: 100 }} className="text-nowrap text-right">
                                {
                                  user.admin && (
                                    <ProgressButton color="link" size="sm" process={_ => row.ref.update({ isInherited: !row.isInherited })}>
                                      (inherited{row.isInherited ? 'を解除' : 'にする'})
                                    </ProgressButton>
                                  )
                                }
                                <ProgressButton process={onClickCopy} disabled={isLockedMonth}>
                                  <span className="fas fa-copy mr-1" />
                                  コピー
                                </ProgressButton>
                                <EditButton className="ml-1" itemRef={ref} FormModal={IndividualAdjustmentJournalFormModal} formProps={{ consolidationAccountItems: mappedConsolidationAccountItems, sections: tipSections, values: row, relatedCompany, }} disabled={isLockedMonth} processValues={_ => ({ ..._, changeTypes: uniq(_.items.flatMap(_ => [_.debitChangeType, _.creditChangeType]).filter(_ => _)), })} />
                                <DeleteButton item={row} itemRef={ref} className="ml-1" disabled={isLockedMonth} />
                              </td>
                            </tr>
                          );
                        }}
                      />
                    );
                  })
                }
              </tbody>
            )}
          />
        </table>
      </div>
    </div>
  );
});
