import React, { Component, Fragment, useState, useEffect, } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, Input, } from 'reactstrap';
import qs from 'qs';
import classnames from 'classnames';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';
import { omit, findKey, pickBy } from 'lodash';
import { createBrowserHistory } from 'history';
import { useToggle, useInterval, } from 'react-use';
import { generate as generatePassword } from 'generate-password-browser';
import { DOMObserver } from '@untemps/dom-observer'

import { initialize as initializeChannelIo, boot as bootChannelIo } from '../channelIo';
import firebase, { functions } from '../firebase';
import LocaleContext from './contexts/locale';
import useFirebaseUser from './hooks/useFirebaseUser';
import useLocale from './hooks/useLocale';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import logo from '../images/logo_square_1.jpg';
import HelpLink from './HelpLink';
import SignInForm from './forms/SignInForm';
import SignUpForm from './forms/SignUpForm';
import AcceptionFormModal from './modals/AcceptionFormModal';

const checkUserEmail = functions.httpsCallable('checkUserEmail');
const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const queryParams = qs.parse(window.location.search.slice(1), { arrayLimit: Infinity });
const history = new createBrowserHistory();
const observer = new DOMObserver()

export default function Root (props) {
  const { applyLocale, translate } = useLocale();
  const queryParams = qs.parse(window.location.search.slice(1));
  const { tab: defaultTab = queryParams.signUp === '1' ? 'signUp' : 'signIn', tk: refererKey = null, accessToken, refreshToken, expires } = queryParams;
  const [tab, setTab] = useState(defaultTab);
  const [showsChannelIo, toggleChannelIo] = useToggle(import.meta.env.VITE_ENV === 'production');
  const { firebaseUser, emailVerified, reloadEmailVerified } = useFirebaseUser();
  const [isSignInWithEmailLink, toggleSignInWithEmailLink] = useToggle(false);
  const [showsLoginForm, toggleLoginForm] = useToggle(false);
  const user = useDocumentSubscription(firebaseUser && usersRef.doc(firebaseUser.uid), [firebaseUser]);
  const locale = useDocumentSubscription(user && db.collection('locales').doc(user.localeId || 'ja'), [user]);
  const Translate = function Translate(props) {
    return translate(props.children, locale);
  };
  const companies = useCollectionSubscription(user && db.collection('companies').where(`users.${user.id}.role`, '!=', null), [user], { initialItems: null });
  const onSubmitSignInForm = async (values) => {
    const { email } = values;
    try {
      const { data: exists } = await checkUserEmail(email);
      if(!exists) {
        toast.error('そちらのメールアドレスのユーザーは見つかりませんでした');
        return;
      }

      await auth.sendSignInLinkToEmail(email, {
        url: window.location.href,
        handleCodeInApp: true,
      });
      window.localStorage.setItem('emailForSignIn', email);
      toast.success(`${email}にログインリンクをお送りしました`);
    } catch(e) {
      console.error(e);
      const message = ({
        'auth/invalid-email': `メールアドレスの形式が正しくありません`,
        'auth/user-not-found': 'ユーザーが存在しません',
      })[e.code] || 'ログインに失敗しました';
      toast.error(message);
    }
  };
  const onSubmitSignUpForm = async (values) => {
    const { email, displayName, } = values;
    try {
      const { user, user: { uid } } = await auth.createUserWithEmailAndPassword(email, generatePassword());
      await auth.currentUser.updateProfile({ displayName });
    } catch(e) {
      console.error(e);
      const message = ({
        'auth/invalid-email': 'メールアドレスの形式が正しくありません',
        'auth/weak-password': 'パスワードは最低6文字以上で指定してください',
      })[e.code] || '登録に失敗しました';
      toast.error(message);
    }
  };
  const signInWithProvider = (providerName) => {
    const provider = new firebase.auth[`${providerName}AuthProvider`]();
    auth.signInWithRedirect(provider);
  }
  const updateUser = async () => {
    const { uid, email, displayName } = firebaseUser;
    const userDoc = await usersRef.doc(uid).get();
    if(!userDoc.exists) {
      await usersRef.doc(uid).set({ uid, email, displayName, createdAt: new Date(), });
    } else if(!userDoc.data().displayName) {
      await usersRef.doc(uid).update({ displayName, });
    }
  }
  const checkSignInWithEmailLink = async () => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      toggleSignInWithEmailLink(true);
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('ログインリンク送信に利用したデバイスでこちらにアクセスするか、こちらにメールアドレスを再度入力してください');
      }
      try {
        await auth.signInWithEmailLink(email, window.location.href);
      } catch (e) {
        toast.error('不正なログインURLです。再度ログインリンク送信をお試しください。');
        history.replace('/');
        toggleSignInWithEmailLink(false);
      }
    }
  };
  useInterval(() => {
    reloadEmailVerified();
  }, (firebaseUser && !emailVerified) ? 3000 : null);
  useEffect(() => {
    (async () => {
      if (accessToken && refreshToken && expires && user) {
        if(queryParams.freee === '1') {
          const externalType = findKey(queryParams, _ => _ === '1') || 'freee';
          await user.ref.update({ [externalType]: { accessToken, refreshToken, expires } });
          window.location.href = '/';
        }
      }
    })();
  }, [window.location.search, user]);
  useEffect(() => {
    if (firebaseUser != null) {
      toggleLoginForm(false);
      updateUser();
    } else if (firebaseUser === null) {
      toggleLoginForm(true);
    }
  }, [firebaseUser]);
  useEffect(() => {
    checkSignInWithEmailLink();
  }, []);
  useEffect(() => {
    initializeChannelIo();
  }, []);
  useEffect(() => {
    if(user != null && companies != null) {
      bootChannelIo({ user, companies, });
    }
  }, [user, companies]);
  useEffect(() => {
    if(import.meta.env.VITE_ENV === 'production' && showsChannelIo === false) {
      window.ChannelIO && window.ChannelIO('shutdown');
      toast.info('画面を再読み込みすると、チャットを再び表示します。');
    }
  }, [showsChannelIo]);
  useEffect(() => {
    const originalConfirm = window.confirm;
    window.confirm = (message, ...args) => {
      return originalConfirm.call(window, translate(message, locale), ...args);
    };
    const { success, warn, error } = toast;
    toast.success = (message, ...args) => success.call(toast, translate(message, locale), ...args);
    toast.warn = (message, ...args) => warn.call(toast, translate(message, locale), ...args);
    toast.error = (message, ...args) => error.call(toast, translate(message, locale), ...args);
  }, [locale != null]);

  return (
    <LocaleContext.Provider value={locale}>
      <div>
        {
          (user && firebaseUser) ? (
            <BrowserRouter>
              {props.routes({ user, translate: _ => translate(_, locale), Translate, companies: companies || [], firebaseUser, })}
              {!user.isAccepted && <AcceptionFormModal user={user} />}
            </BrowserRouter>
          ) : (
            !isSignInWithEmailLink && showsLoginForm && (
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div className="d-flex justify-content-center">
                      <img src={logo} style={{ height: 200 }} />
                    </div>
                    <div className="card mt-5">
                      <div className="card-body">
                        <div className="d-flex justify-content-end mb-3">
                          <HelpLink text="ログインする" />
                        </div>
                        <Nav tabs>
                          <NavItem>
                            <NavLink className={classnames('cursor-pointer', { active: tab === 'signIn' })} onClick={setTab.bind(null, 'signIn')}>
                              ログイン
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink className={classnames('cursor-pointer', { active: tab === 'signUp' })} onClick={setTab.bind(null, 'signUp')}>
                              ユーザー登録
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={tab} className="pt-3">
                          <TabPane tabId="signIn">
                            <SignInForm onSubmit={onSubmitSignInForm} />
                          </TabPane>
                          <TabPane tabId="signUp">
                            <SignUpForm onSubmit={onSubmitSignUpForm} />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                    <div className="card mt-5">
                      <div className="card-body">
                        <h6 className="text-center mb-3">他のアカウントでログイン</h6>
                        <div className="d-flex justify-content-center">
                          <Button onClick={signInWithProvider.bind(null, 'Google')} block>
                            <span className="fab fa-google mr-1" />
                            Google
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        }
        <ToastContainer />
        {
          showsChannelIo && (
            <Button color="link" className="text-secondary rounded-pill" style={{ position: 'fixed', bottom: 64, right: 4, zIndex: 10000000 }} onClick={toggleChannelIo.bind(null, false)}>
              <span className="fas fa-times" />
            </Button>
          )
        }
      </div>
    </LocaleContext.Provider>
  );
};
