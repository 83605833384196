import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { pickBy, pick, get, keyBy, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';

import { dateStringToDate, } from '../../util';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { nonFreeeFields, fiscalYearFields, } from '../../shared/models/company';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function NonFreeeCompanyFormModal(props) {
  const { isOpen, fields, values, companies, consolidationAccountItems, onClickClose, } = props;
  const statedFields = useFormState(values, fields || nonFreeeFields(), isOpen);
  const isNew = !values || !values.id;
  const [fiscalYears, { set: setItems, updateAt: updateItemAt }] = useList(values?.fiscalYears?.map(_ => ({ ...(_.start_date && { start_date: dateStringToDate(_.start_date) }), ...( _.end_date && { end_date: dateStringToDate(_.end_date) }), })) || [{ start_date: null, end_date: null }, { start_date: null, end_date: null }]);
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    (fiscalYears.length <= 1) ||
    (fiscalYears.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), fiscalYears: fiscalYears.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            事業所{isNew ? '追加' : '編集'}
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => {
              return (
                <div key={fieldName} style={{ width: 300 }}>
                  <Field
                    name={fieldName}
                    values={mapValues(statedFields, 'value')}
                    {...fieldSetting}
                  />
                </div>
              );
            })
          }
          <div className="mt-3">
            <ListForm
              addButtonLabel="会計期間追加"
              items={fiscalYears}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      index={itemIndex}
                      values={item}
                      companies={companies}
                      consolidationAccountItems={consolidationAccountItems}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={2}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, companies, consolidationAccountItems, onChange, } = props;
  const statedFields = useFormState(values, fiscalYearFields(), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div key={fieldName} className="mr-2" style={{ minWidth: 150 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

