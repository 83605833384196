import { useMemo } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { zip, last, sumBy, groupBy, sum, isEmpty, keyBy, } from 'lodash';
import { format as formatDate, addMonths, } from 'date-fns';
import lI from 'lodash-inflection';

import { disassembleJournalItems, } from '../../shared/util';
import { getCollectionData, getDocumentData, } from '../../shared/firebase';
import { computeItems, } from '../../shared/lib/pkgItems';
import { getCategory, } from '../../shared/models/accountItem';
import { changeTypes, } from '../../shared/changeTypes';
import { computeSingleAmounts, } from '../../shared/models/consolidationAccountItem';
import useCollectionSubscription from './useCollectionSubscription';
import useDocumentSubscription from './useDocumentSubscription';

export default function usePkgItemGroups (company, relatedCompanies, accountItems, collectionName, uniqKeyFields, closingFieldName, changeFieldNames, yearMonthDates) {
  const accountItemsById = keyBy(accountItems, 'id');
  const targetChangeTypes = Object.keys(changeTypes).filter(_ => _.split('__')[0] === lI.singularize(collectionName));
  const lastMonthPkgItems = useCollectionSubscription(last(yearMonthDates) && company.ref.collection(collectionName).where('yearMonth', '==', formatDate(last(yearMonthDates), 'yyyyMM')), [collectionName, yearMonthDates]);
  const { value: itemGroups, loading: isLoading, error } = useAsync(async _ => {
    return await Promise.all(relatedCompanies.map(async (relatedCompany) => {
      const subsidiaryId = relatedCompany.id === company.id ? null : relatedCompany.id;
      const { pkgItems, pkgIndividualAdjustments, } = await computeItems(company, relatedCompany, subsidiaryId, accountItems, collectionName, uniqKeyFields, closingFieldName, changeFieldNames, yearMonthDates);
      const pkgItemsGroupedByConsolidationAccountId = groupBy(pkgItems, _ => accountItemsById[_.accountItemId]?.consolidationAccountItemId);
      const pkgIndividualAdjustmentsGroupedByConsolidationAccountId = groupBy(pkgIndividualAdjustments, _ => _.accountItem.consolidationAccountItemId);
      const [consolidationJournals, prevYearConsolidationJournals] = isEmpty(yearMonthDates) ? [[], []] : await Promise.all([last(yearMonthDates), addMonths(yearMonthDates[0], -1)].map(_ => getCollectionData(company.ref.collection('consolidationJournals').where('changeTypes', 'array-contains-any', isEmpty(targetChangeTypes) ? ['dummy'] : targetChangeTypes).where('yearMonth', '==', formatDate(_, 'yyyyMM')))));
      const [consolidationJournalItemsGroupedByItemId, prevYearConsolidationJournalItemsGroupedByItemId] = [consolidationJournals, prevYearConsolidationJournals].map(journals => (
        groupBy(journals.flatMap(disassembleJournalItems).filter(_ => _.changeType?.split('__')[0] === lI.singularize(collectionName) && _.companyId === relatedCompany.id), 'itemId')
      ));
      return {
        relatedCompany,
        pkgItems,
        pkgIndividualAdjustments,
        pkgItemsGroupedByConsolidationAccountId,
        pkgIndividualAdjustmentsGroupedByConsolidationAccountId,
        consolidationJournalItemsGroupedByItemId,
        prevYearConsolidationJournalItemsGroupedByItemId,
      };
    }));
  }, [company, relatedCompanies, accountItems, lastMonthPkgItems]);
  if(error) throw error;

  return { itemGroups, isLoading };
};

