import React, { Fragment, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { isFunction, get, } from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { setHours, setMinutes, getHours, getMinutes } from 'date-fns';
import numeral from 'numeral';
import moji from 'moji';

import { integerFormat, formFloatFormat, } from '../util';
import texts from '../shared/texts';
import DateSelector from './DateSelector';
import useLocale from './hooks/useLocale';

const validationText = (documentName, fieldName, key) => {
  return (
    get(texts.validations[documentName], `${fieldName}.${key}`)
    || get(texts.validations[documentName], key)
    || get(texts.validations.general, key)
  );
}

export default function Field (props) {
  const { documentName, type, name: fieldName, label, value, setValue, options: _options = [], validationErrors = [], readOnly = _ => false, shouldHide, placeholder, selector = false, yearRange = [], rows, values, hint, inputProps, formGroupProps, } = props;
  const { translate } = useLocale();
  const options = (isFunction(_options) ? _options(values) : _options).map(_ => ({ ..._, label: translate(_.label) }));
  const [hasStarted, setHasStarted] = useState(false);
  const [localValue, setLocalValue] = useState((({
    integer: _ => value != null ? integerFormat(value) : null,
    float: _ => value != null ? formFloatFormat(value) : null,
  })[type] || (() => null))());
  const setValueAndStart = (value) => {
    setValue(value);
    setHasStarted(true);
  };
  const isValid = validationErrors.length === 0;
  const validationCss = hasStarted ? classnames({ 'is-valid': isValid, 'is-invalid': isValid === false }) : '';
  if(shouldHide) return null;

  return translate(
    <FormGroup key={fieldName} {...formGroupProps}>
      {
        ({
          string: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <Input name={fieldName} value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} />
            </Fragment>
          ),
          password: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <Input type="password" name={fieldName} value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} />
            </Fragment>
          ),
          integer: () => {
            const parse = (value) => value === '' ? null : numeral(integerFormat(moji(value).convert('ZE', 'HE').toString())).value();
            const onChange = ({ target }) => {
              setLocalValue(target.value);
              setValueAndStart(parse(target.value));
            };
            const onBlur = _ => setLocalValue(value != null ? integerFormat(value) : '');
            return (
              <Fragment>
                {label && <Label>{label}</Label>}
                <Input name={fieldName} value={localValue} onChange={onChange} className={classnames('text-right', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} onBlur={onBlur} />
              </Fragment>
            );
          },
          float: () => {
            const parse = (value) => value === '' ? null : numeral(formFloatFormat(moji(value).convert('ZE', 'HE').toString())).value();
            const onChange = ({ target }) => {
              setLocalValue(target.value);
              setValueAndStart(parse(target.value));
            };
            const onBlur = _ => setLocalValue(value != null ? formFloatFormat(value) : '');
            return (
              <Fragment>
                {label && <Label>{label}</Label>}
                <Input name={fieldName} value={localValue} onChange={onChange} className={classnames('text-right', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} onBlur={onBlur} />
              </Fragment>
            );
          },
          boolean: () => (
            <Fragment>
              <FormGroup check>
                <Label check>
                  <Input name={fieldName} type="checkbox" checked={value} onChange={_ => setValueAndStart(_.target.checked)} className={validationCss} disabled={readOnly(value, props)} />
                  {label}
                </Label>
              </FormGroup>
            </Fragment>
          ),
          select: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <Select
                data-test={fieldName}
                value={options.find(_ => _.value === value) || null}
                onChange={_ => setValueAndStart(_ && _.value)}
                className={classnames('form-select', validationCss)}
                options={options}
                isDisabled={readOnly(value, props)}
                isClearable
                components={{ IndicatorsContainer: _ => null }}
              />
            </Fragment>
          ),
          multiSelect: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <Select
                data-test={fieldName}
                isMulti
                value={options.filter(_ => (value || []).includes(_.value)) || null}
                onChange={_ => setValueAndStart((_ || []).map(_ => _.value))}
                className={classnames('form-select', validationCss)}
                options={options}
                isDisabled={readOnly(value, props)}
                isClearable
              />
            </Fragment>
          ),
          date: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <div>
                {
                  selector ? (
                    <DateSelector
                      data-test={fieldName}
                      value={value}
                      onChange={setValueAndStart}
                      className={classnames(validationCss)}
                      readOnly={readOnly(value, props)}
                      yearRange={yearRange}
                      {...inputProps}
                    />
                  ) : (
                    <DatePicker
                      data-test={fieldName}
                      dateFormat="yyyy/MM/dd"
                      timeIntervals={10}
                      selected={value}
                      onChange={setValueAndStart}
                      className={classnames('form-control', validationCss)}
                      readOnly={readOnly(value, props)}
                      placeholderText={placeholder}
                      isClearable
                      {...inputProps}
                    />
                  )
                }
              </div>
            </Fragment>
          ),
          time: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <div>
                <DatePicker
                  data-test={fieldName}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  selected={value}
                  onChange={_ => value != null ? setValueAndStart(setHours(setMinutes(value, getMinutes(_)), getHours(_))) : _}
                  className={classnames('form-control', validationCss)}
                  readOnly={readOnly(value, props)}
                  placeholder={placeholder}
                />
              </div>
            </Fragment>
          ),
          text: () => (
            <Fragment>
              {label && <Label>{label}</Label>}
              <Input type="textarea" value={value} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} rows={rows} />
            </Fragment>
          ),
        })[type]()
      }
      {hint && <small className="form-text text-muted" style={{ whiteSpace: 'pre-line' }}>{hint}</small>}
      {
        hasStarted && type !== 'list' && validationErrors.length > 0 && (
          <div className="small text-danger mt-1">
            {
              validationErrors.map(k => (
                <div key={k}>{validationText(documentName, fieldName.replace(/\.\d+\./g, '.fields.'), k)}</div>
              ))
            }
          </div>
        )
      }
    </FormGroup>
  );
};

Field.disabledTranslation = true;
