const { zip, keyBy, sortBy, groupBy, sumBy, get, sum, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategories, accountItemCategoryNames } = require('../config');

const { keys, entries, } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const categoriesForHeader = [...accountItemCategoriesByName['純資産'].descendants(), accountItemCategoriesByName['純資産']].filter(_ => _.indent() <= 3);
const headers = [...categoriesForHeader.map((category) => {
  const children = category.children().filter(_ => _.indent() <= 3);
  return {
    category,
    children,
    label: category.name,
    roma: category.roma,
  };
})];
const rowTypes = {
  当期首残高: {
    roma: 'toukishu',
    integrates: true,
    columns: (columnItems) => {
      return columnItems.map(_ => ({ value: _.openingValue(), }));
    },
  },
  会計方針の変更による累積的影響額: {
    roma: 'kaikeihoushinhenkou',
    integrates: true,
    columns: (columnItems) => {
      return columnItems.map(_ => ({ value: _.policyChangeValue(), }));
    },
  },
  会計方針の変更を反映した当期首残高: {
    columns: (columnItems) => {
      return columnItems.map(_ => ({ value: _.openingValue() + _.policyChangeValue(), }));
    },
  },
  当期中の変動額: {
    columns: _ => _.map(_ => ({ value: '' })),
    childRows: (columnItems, ssItem) => {
      return get(ssItem, 'changeItems', []).map((changeItem, i) => {
        return {
          level: 2,
          label: changeItem.label,
          integrates: true,
          roma: `hendou${i.toString()}`,
          columns: columnItems.map(_ => ({ value: _.changeItemValues()[i], })),
        };
      });
    },
  },
  当期変動額合計: {
    columns: (columnItems) => {
      return columnItems.map(_ => ({ value: sum(_.changeItemValues()), }));
    },
  },
  当期末残高: {
    columns: (columnItems) => {
      return columnItems.map(_ => ({ invalid: _.computedValue() !== _.closingValue(), value: _.computedValue() }));
    },
  },
  '当期末残高 (連結精算表)': {
    columns: (columnItems) => {
      return columnItems.map(_ => ({ value: _.closingValue() }));
    },
  },
};
const generateColumnItems = (amountItems, prevYearAmountItems, ssItem) => {
  const amountItemsByCategory = keyBy(amountItems, 'accountItemCategory.name');
  const prevYearAmountItemsByCategory = keyBy(prevYearAmountItems, 'accountItemCategory.name');
  const columnItems = headers.map((header) => {
    const { children } = header;
    if(children.length > 0) {
      const childItems = _ => children.map(_ => columnItemsByHeaderLabel[_.name]);
      return {
        header,
        children,
        openingValue: _ => sumBy(childItems(), _ => _.openingValue()),
        policyChangeValue: _ => sumBy(childItems(), _ => _.policyChangeValue()),
        changeItemValues:_ => get(ssItem, 'changeItems', []).map((changeItem, i) => {
          return sumBy(childItems(), _ => _.changeItemValues()[i]);
        }),
        computedValue: _ => sumBy(childItems(), _ => _.computedValue()),
        closingValue: _ => sumBy(childItems(), _ => _.closingValue()),
      };
    } else {
      const openingValue = _ => prevYearAmountItemsByCategory[header.category.name].categoryItem.amount || 0;
      const policyChangeValue = _ => get(ssItem, ['policyChange', header.label].join('--'), 0);
      const changeItemValues = _ => get(ssItem, 'changeItems', []).map((changeItem) => {
        return changeItem[header.label] || 0;
      });
      const computedValue = _ => openingValue() + policyChangeValue() + sum(changeItemValues());
      const closingValue = _ => amountItemsByCategory[header.category.name].categoryItem.amount;
      return {
        header,
        children,
        openingValue,
        policyChangeValue,
        changeItemValues,
        computedValue,
        closingValue,
      };
    }
  });
  const columnItemsByHeaderLabel = keyBy(columnItems, 'header.label');
  return columnItems;
};

const generateRows = (amountItems, prevYearAmountItems, ssItem) => {
  const columnItems = generateColumnItems(amountItems, prevYearAmountItems, ssItem);
  const rows = entries(rowTypes).map(([rowType, { columns, integrates, roma, childRows = _ => [] }]) => {
    return [{
      level: 1,
      label: rowType,
      integrates,
      roma,
      columns: columns ? columns(columnItems) : columnItems.map(() => {
        return {};
      }),
    }, ...childRows(columnItems, ssItem)];
  }).flat();
  return rows;
};

const rowsForExport = (...args) => () => {
  const columnItems = generateColumnItems(...args);
  return generateRows(...args).map((row) => {
    const { level, label, columns, } = row;
    return {
      項目: level === 1 ? label : '',
      内訳: level === 2 ? label : '',
      ...(
        columnItems.reduce((x, { header, children, }, i) => {
          return {
            ...x,
            [header.label + (children.length > 0 ? ' 計' : '')]: columns[i].value,
          };
        }, {})
      ),
    };
  });
};

const computeAlerts = (...args) => {
  const rows = generateRows(...args);
  return [
    rows.find(_ => _.label === '当期末残高').columns.some(_ => _.invalid) && '当期末残高に差額が発生しています',
  ].filter(_ => _);
};


exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
exports.headers = headers;
exports.computeAlerts = computeAlerts;
