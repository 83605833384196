import React, { useEffect, } from 'react';
import qs from 'qs';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';

const db = firebase.firestore();
const usersRef = db.collection('users');
const invitationsRef = db.collection('invitations');
const confirmInvitation = functions.httpsCallable('confirmInvitation');

export default function Confirmation (props) {
  const { user, history } = props;
  const confirm = async () => {
    const { token } = qs.parse(window.location.search.slice(1), { arrayLimit: Infinity });
    const ref = invitationsRef.doc(token);
    const invitationSnap = await ref.get();
    if (!invitationSnap.exists) return redirectToRoot('Invalid URL or expired');

    const { companyId, } = invitationSnap.data();
    if (user.companies && user.companies[companyId]) return redirectToRoot('すでに参加済みです');

    await confirmInvitation({ token });
    toast.success('参加しました');
    history.push(`/companies/${companyId}`);
  };
  const redirectToRoot = (errorMessage) => {
    toast.error(errorMessage);
    history.push('/');
  };
  useEffect(() => {
    confirm();
  }, []);

  return <div className="confirmation" />;
}
