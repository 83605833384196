import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { format as formatDate, startOfMonth, endOfMonth, } from 'date-fns';
import { isEmpty, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useToggle, } from 'react-use';
import copy from 'copy-to-clipboard';

import { leftTrialDays } from '../shared/models/company';
import { userLocaleFields } from '../shared/models/user';
import logo from '../images/logo_horizontal.jpg';
import firebase from '../firebase';
import useLocale from './hooks/useLocale';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import ModelFormModal from './modals/ModelFormModal';
import CompanySyncButton from './CompanySyncButton';
import EditButton from './EditButton';
import NonFreeeCompanyFormModal from './modals/NonFreeeCompanyFormModal';
import { periodOfFiscalYear, fullPathWithParams, fiscalYearOfPeriod, pickSearch } from '../utils';
import env from '../env';

const auth = firebase.auth();
const db = firebase.firestore();
const { entries } = Object;

export default function HeaderNav (props) {
  const { links, user, match: { params: { companyId } }, location: { search }, yearMonth, period, company, periodSelectDisabled = false, monthSelectDisabled = false, periodOptions, monthOptions, admin = false, } = props;
  const { locale } = useLocale();
  const locales = useCollectionSubscription(db.collection('locales'));
  const [isOpen, toggleOpen] = useToggle(false);
  const onSelectPeriod = ({ value }) => {
    const { history, location } = props;
    const path = fullPathWithParams({ period: value }, location);
    history.replace(encodeURI(path));
  };
  const onSelectMonth = ({ value }) => {
    const { history, location } = props;
    const path = fullPathWithParams({ yearMonth: value }, location);
    history.replace(encodeURI(path));
  }
  const onClickLogOut = async () => {
    if(!window.confirm('ログアウトしますか？')) return;
    await auth.signOut();
    window.location.href = '/';
  }
  const { isSyncing = false } = {};
  const pickedSearch = pickSearch(search, ['period', 'yearMonth']);
  const onClickInspection = () => {
    const now = new Date();
    copy(`mv ~/Downloads/連結精算表_${now.getTime()}.csv ./deliverables/株式会社フォーデジット/202106/連結精算表.csv && mv ~/Downloads/連結CFWS2_${now.getTime()}.csv ./deliverables/株式会社フォーデジット/202106/連結CFWS2.csv`);
    window.open(`/companies/wqKFAa5gcU0bDjvgzqSm/consolidationCalculation?period=202007&yearMonth=202106&encoding=UTF8&filename=連結精算表_${now.getTime()}.csv`);
    window.open(`/companies/wqKFAa5gcU0bDjvgzqSm/cfWs2?period=202007&yearMonth=202106&encoding=UTF8&filename=連結CFWS2_${now.getTime()}.csv`);
  };

  return props.translate(
    <header className="header-nav">
      <Navbar color={admin ? 'dark' : 'primary'} dark expand="md">
        {
          admin ? (
            <Link to="/admin" className="navbar-brand">
              YUI Admin
            </Link>
          ) : (
            <Link to="/" className="navbar-brand">
              <img src={logo} style={{ height: 35 }} />
            </Link>
          )
        }
        {
          import.meta.env.VITE_ENV !== 'production' && (
            <Button onClick={onClickInspection}>
              検証CSV
            </Button>
          )
        }
        <div className="ml-auto d-flex align-items-center gap-1">
          {
            companyId && (
              <Fragment>
                {
                  !periodSelectDisabled && (
                    <div style={{ width: 220, position: 'relative', zIndex: 7 }}>
                      <Select
                        value={periodOptions.find(_ => _.value === period)}
                        options={periodOptions}
                        onChange={onSelectPeriod}
                        className="w-100"
                      />
                    </div>
                  )
                }
                {
                  !monthSelectDisabled && (
                    <div style={{ width: 150, position: 'relative', zIndex: 7 }}>
                      <Select
                        value={monthOptions.find(_ => _.value === yearMonth)}
                        options={monthOptions}
                        onChange={onSelectMonth}
                        className="w-100"
                      />
                    </div>
                  )
                }
                {
                  company && (
                    <span className="text-white">{company.display_name}</span>
                  )
                }
                {
                  (company?.plan || 'trial') === 'trial' && (
                    <div className="ml-3">
                      <Button size="sm" color="success" tag="a" href="https://docs.google.com/forms/d/e/1FAIpQLSd4AzP_zKZBiBiEtRg_DZutvESgJsMi5aMSPnihq57vkR9X4g/viewform" target="_blank">
                        有償版を申し込む{leftTrialDays(company) >= 0 ? `(試用期間残り${leftTrialDays(company)}日)` : ''}
                      </Button>
                    </div>
                  )
                }
                {
                  company != null && (
                    ['bugyo',].includes(company.externalType) && (
                      <div className="ml-1">
                        <EditButton
                          itemRef={company.ref}
                          FormModal={NonFreeeCompanyFormModal}
                          label="事業所編集"
                          processValues={(values) => ({
                            ...values,
                            fiscalYears: values.fiscalYears.map(_ => ({ ..._, start_date: formatDate(startOfMonth(_.start_date), 'yyyy-MM-dd'), end_date: formatDate(endOfMonth(_.end_date), 'yyyy-MM-dd'), })),
                          })}
                        />
                      </div>
                    )
                  )
                }
              </Fragment>
            )
          }
          <Nav navbar className="d-flex justify-content-end" style={{ minWidth: 150 }}>
            {
              locale != null && (
                <EditButton icon={false} size="sm" className="small text-white" label={<span>lang</span>} color="link" itemRef={user.ref} FormModal={ModelFormModal} formProps={{ title: 'Language', fields: userLocaleFields({ locales }), }} onFinish={_ => window.location.reload()} />
              )
            }
            {
              user != null && (
                <UncontrolledDropdown tag="div">
                  <DropdownToggle nav caret>
                    {user.displayName || '(未設定)'}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag={Link} to="/profile">
                      プロフィール
                    </DropdownItem>
                    <DropdownItem onClick={onClickLogOut}>
                      ログアウト
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )
            }
          </Nav>
        </div>
      </Navbar>
      {
        companyId && (
          <div className="d-flex flex-row bg-light p-2 text-nowrap flex-wrap gap-3">
            {
              entries(links).map(([name, { path, label }]) => {
                return (
                  <div key={name}>
                    <Link className="text-dark" to={`/companies/${companyId}/${path || name}${pickedSearch}`}>{label}</Link>
                  </div>
                );
              })
            }
          </div>
        )
      }
    </header>
  );
};
