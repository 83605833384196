import React, { Component, Fragment, useState, useMemo, } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, useList, useCounter, } from 'react-use';
import 'lodash.combinations';
import { mapValues, combinations, pick, zip, orderBy, isEmpty, get, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import qs from 'qs';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import numeral from 'numeral';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { integerFormat, floatFormat, } from '../../util';
import { computeAlerts, } from '../../shared/lib/pkgRelatedCompanyBalances';
import { getCategory, } from '../../shared/models/accountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { presetConsolidationAccountItems } from '../../shared/models/consolidationAccountItem';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import { fields } from '../../shared/models/relatedCompanyBalance';
import { fields as relatedCompanyBalanceLinkFields, } from '../../shared/models/relatedCompanyBalanceLink';
import { noneSection } from '../../shared/models/section';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import usePkgItemGroups from '../hooks/usePkgItemGroups';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import ConsolidationJournalFormModal from '../modals/ConsolidationJournalFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ProgressButton from '../ProgressButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModalButton from '../ModalButton';
import QuerySelector from '../QuerySelector';
import LinkingRules from '../LinkingRules';
import AmountWithExchange from '../AmountWithExchange';
import Alerts from '../Alerts';

const { keys } = Object;
const db = firebase.firestore();

const computeType = (newConsolidationJournalItemConsolidationAccountItems) => {
  return newConsolidationJournalItemConsolidationAccountItems.some(_ => _?.name.includes('貸倒引当金')) ? (
    'allowance'
  ) : newConsolidationJournalItemConsolidationAccountItems.some(_ => getCategory(_)?.type === 'bs') ? (
    'debtAndCredit'
  ) : (
    'profitAndCost'
  );
};

export default PkgPage(function CompanyPkgRelatedCompanyBalances (props) {
  const { company, exchangeRate, period, filteredYearMonths, yearMonth, isLockedMonth, relatedCompanies, relatedCompaniesById, accountItems, accountItemsById, consolidationAccountItems, location, } = props;
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const sections = useCollectionSubscription(company.ref.collection('sections'), [company.id]);
  const sectionsGroupedByRelatedCompanyId = mapValues(groupBy(sections, _ => _.subsidiaryId || company.id), (v, k) => [...v, noneSection(relatedCompaniesById[k], company)]);
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'), [company]);
  const sortedConsolidationSegments = useMemo(_ => sortBy(consolidationSegments, 'index'), [consolidationSegments]);
  const [version, { inc: updateVersion }] = useCounter();
  const allConsolidationSegments = useMemo(_ => [...sortedConsolidationSegments, generalSegment], [sortedConsolidationSegments]);
  const consolidationSegmentsById = useMemo(_ => keyBy(allConsolidationSegments, 'id'), [allConsolidationSegments]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const relatedCompanyBalanceLinks = useCollectionSubscription(company.ref.collection('relatedCompanyBalanceLinks').where('yearMonth', '==', yearMonth), [company, yearMonth]);
  const relatedCompanyBalances = useCollectionSubscription(company.ref.collection('relatedCompanyBalances').where('yearMonth', '==', yearMonth), [company, yearMonth]);
  const linkingRules = useCollectionSubscription(company.ref.collection('linkingRules').orderBy('index'), [company]);
  const { itemGroups, } = usePkgItemGroups(company, relatedCompanies, accountItems, 'relatedCompanyBalances', ['opponentCompanyId', 'sectionId'], 'amount', [], filteredYearMonths);
  const relatedCompanyBalanceItems = itemGroups?.flatMap((itemGroup) => {
    const { relatedCompany, pkgItems } = itemGroup;
    return pkgItems.map((relatedCompanyBalance) => {
      const { accountItemId, amount, sectionId, subsidiaryId, remoteAmount, exchangedAmounts, opponentCompanyId, } = relatedCompanyBalance || {};
      const accountItem = accountItemsById[accountItemId];
      const category = getCategory(accountItem);
      const relatedCompany = relatedCompaniesById[subsidiaryId || company.id];
      const opponentRelatedCompany = relatedCompaniesById[opponentCompanyId || company.id];
      const allSections = [...sections, noneSection(relatedCompany, company)];
      const sectionsById = keyBy(allSections, 'id');
      const section = sectionsById[sectionId];
      const consolidationSegment = consolidationSegmentsById[section?.consolidationSegmentId];
      const currency = get(relatedCompany, 'currency', 'jpy');
      const cr = currency === 'jpy' ? 1 : get(exchangeRate, [currency, 'cr'].join('-'));
      const exchangedAmount = exchangedAmounts.amount;
      const jpyAmount = currency === 'jpy' ? amount : exchangedAmount;
      const remoteCurrencyAmount = currency === 'jpy' ? remoteAmount : amount;
      return {
        ...relatedCompanyBalance,
        relatedCompany,
        opponentRelatedCompany,
        accountItem,
        consolidationSegment,
        category,
        currency,
        cr,
        exchangedAmount,
        jpyAmount,
        remoteCurrencyAmount,
      };
    });
  });
  const onClickApplyLinkingRules = async () => {
    if(!window.confirm('本当に自動マッチング実行しますか？')) return;

    const relatedCompanyCombinations = combinations(relatedCompanies, 2);
    const linkedRelatedCompanyBalanceIds = relatedCompanyBalanceLinks.flatMap(_ => _.items).map(_ => _.relatedCompanyBalanceId);
    let restRelatedCompanyBalanceItems = [...(relatedCompanyBalanceItems || [])].filter(_ => !linkedRelatedCompanyBalanceIds.includes(_.id));
    const data = relatedCompanyCombinations.map(([leftRelatedCompany, rightRelatedCompany]) => {
      const _likingRules = linkingRules.filter(_ => [_.relatedCompanyId1, _.relatedCompanyId2].every(_ => [leftRelatedCompany.id, rightRelatedCompany.id].includes(_)));
      const newData = _likingRules.map((linkingRule) => {
        const { relatedCompanyId1, relatedCompanyId2, items, createsJournal = false, adjustmentRelatedCompanyNumber, adjustmentDebitConsolidationAccountItemId, adjustmentCreditConsolidationAccountItemId, adjustmentSegmentId, isIntersegment, description, } = linkingRule;
        const relatedCompany1 = relatedCompaniesById[relatedCompanyId1];
        const relatedCompany2 = relatedCompaniesById[relatedCompanyId2];
        const relatedCompaniesByNumber = { 1: relatedCompany1, 2: relatedCompany2 };
        const computedRules = items
          .map((item) => {
            const { isRequired, relatedCompanyNumber, consolidationAccountItemId, consolidationSegmentId, sectionIds, } = item;
            const relatedCompany = relatedCompaniesByNumber[relatedCompanyNumber];
            const opponentRelatedCompany = relatedCompaniesByNumber[({ 1: 2, 2: 1 })[relatedCompanyNumber]];
            const matchedRelatedCompanyBalances = restRelatedCompanyBalanceItems.filter(_ => (
              _.relatedCompany?.id === relatedCompany?.id &&
              _.opponentRelatedCompany?.id === opponentRelatedCompany?.id &&
              _.accountItem?.consolidationAccountItemId === consolidationAccountItemId &&
              (consolidationSegmentId == null || _.consolidationSegment?.id === consolidationSegmentId) &&
              (isEmpty(sectionIds) || sectionIds.includes(_.sectionId))
            ));
            return {
              ...item,
              matchedRelatedCompanyBalances,
            };
          });
        const isSatisfied = computedRules.filter(_ => _.isRequired).every(_ => !isEmpty(_.matchedRelatedCompanyBalances));
        if(isSatisfied) {
          computedRules.filter(_ => !isEmpty(_.matchedRelatedCompanyBalances)).map(_ => _.matchedRelatedCompanyBalances.map(_ => restRelatedCompanyBalanceItems.splice(restRelatedCompanyBalanceItems.indexOf(_), 1)));
        }
        const relatedCompanyBalances = computedRules.filter(_ => !isEmpty(_.matchedRelatedCompanyBalances)).flatMap(_ => _.matchedRelatedCompanyBalances);
        const linkingItems = relatedCompanyBalances.map((relatedCompanyBalance) => {
          return {
            companyType: relatedCompanyBalance.relatedCompany.id === leftRelatedCompany.id ? 'self' : 'opponent',
            relatedCompanyBalanceId: relatedCompanyBalance.id,
          };
        });
        const linkRef = company.ref.collection('relatedCompanyBalanceLinks').doc();

        const newConsolidationJournal = createsJournal && (() => {
          const relatedCompanyBalancesGroupedByDirection = groupBy(relatedCompanyBalances, 'category.direction');
          const diff = sumBy(relatedCompanyBalancesGroupedByDirection.debit || [], 'exchangedAmount') - sumBy(relatedCompanyBalancesGroupedByDirection.credit || [], 'exchangedAmount');
          const diffContent = {
            relatedCompany: relatedCompaniesByNumber[adjustmentRelatedCompanyNumber],
            accountItem: { consolidationAccountItemId: diff > 0 ? adjustmentDebitConsolidationAccountItemId : adjustmentCreditConsolidationAccountItemId },
            consolidationSegment: { id: adjustmentSegmentId, },
            exchangedAmount: Math.abs(diff),
            isIntersegment,
          };
          const newConsolidationJournalItems = zip([...(relatedCompanyBalancesGroupedByDirection.credit || []), ...(diff > 0 ? [diffContent] : [])], [...(relatedCompanyBalancesGroupedByDirection.debit || []), ...(diff < 0 ? [diffContent] : [])]) // NOTE: 貸借反対にする
            .map(([creditItem, debitItem]) => {
              return {
                debitCompanyId: get(creditItem, 'relatedCompany.id') || null,
                debitItemId: get(creditItem, 'accountItem.consolidationAccountItemId') || null,
                debitAmount: Math.round(get(creditItem, 'exchangedAmount')),
                debitSegmentId: get(creditItem, 'consolidationSegment.id') || null,
                debitIsIntersegment: creditItem != null && isIntersegment,
                creditCompanyId: get(debitItem, 'relatedCompany.id') || null,
                creditItemId: get(debitItem, 'accountItem.consolidationAccountItemId') || null,
                creditAmount: Math.round(get(debitItem, 'exchangedAmount')),
                creditSegmentId: get(debitItem, 'consolidationSegment.id') || null,
                creditIsIntersegment: debitItem != null && isIntersegment,
              };
            });
          const newConsolidationJournalItemConsolidationAccountItems = newConsolidationJournalItems.flatMap(_ => [_.debitItemId, _.creditItemId].map(_ => consolidationAccountItemsById[_]));
          return {
            type: computeType(newConsolidationJournalItemConsolidationAccountItems),
            items: newConsolidationJournalItems,
            linkId: linkRef.id,
            description,
            period,
            yearMonth,
            createdAt: new Date(),
          };
        })();

        const data = {
          newRelatedCompanyBalanceLink: {
            id: linkRef.id,
            selfCompanyId: leftRelatedCompany.id,
            opponentCompanyId: rightRelatedCompany.id,
            items: linkingItems,
            period,
            yearMonth,
            createdAt: new Date(),
          },
          newConsolidationJournal,
        };
        return {
          data,
          isSatisfied,
        };
      }).filter(_ => _.isSatisfied).map(_ => _.data);
      return newData;
    });
    await batch(db, data.flat(), (batch, _) => {
      batch.set(company.ref.collection('relatedCompanyBalanceLinks').doc(_.newRelatedCompanyBalanceLink.id), omit(_.newRelatedCompanyBalanceLink, 'id'))
      _.newConsolidationJournal && batch.set(company.ref.collection('consolidationJournals').doc(), _.newConsolidationJournal);
    }, 250);
    toast.success('自動マッチング実行しました');
  };
  const relatedCompanyBalancesById = keyBy(relatedCompanyBalanceItems, 'id');
  const relatedCompanyBalancesGroupedByRelatedCompanyId = groupBy(relatedCompanyBalanceItems, _ => _.subsidiaryId || company.id);
  const relatedCompanyBalancesGroupedByOpponentCompanyId = groupBy(relatedCompanyBalanceItems, 'opponentCompanyId');
  const linkedConsolidationJournals = useCollectionsFetch(relatedCompanyBalanceLinks.map(_ => company.ref.collection('consolidationJournals').where('linkId', '==', _.id).limit(1)), [relatedCompanyBalanceLinks, version]);
  const allConsolidationAccountItems = [...consolidationAccountItems, ...presetConsolidationAccountItems];
  const alerts = useMemo(_ => relatedCompanyBalanceItems && computeAlerts(relatedCompanyBalanceItems, relatedCompanyBalanceLinks, linkedConsolidationJournals), [itemGroups]);

  return props.translate(
    <div className="company-pkg-related-company-balances container" style={{ marginBottom: 180, }}>
      <Alerts alerts={alerts} />
      <div className="d-flex justify-content-end gap-1 mb-1">
        <ProgressButton process={onClickApplyLinkingRules}>
          自動マッチング実行
        </ProgressButton>
        <ModalButton title="自動マッチングルール" content={_ => <LinkingRules {...{ ...props, allConsolidationAccountItems, allConsolidationSegments, sectionsGroupedByRelatedCompanyId, }} />} modalProps={{ style: { minWidth: 1200 } }}>
          自動マッチングルール
        </ModalButton>
      </div>
      <div className="d-flex flex-column gap-5">
        {
          relatedCompanies.map((relatedCompany, index) => {
            const restCompanies = relatedCompanies.slice(index + 1);
            const restCompanyIds = restCompanies.map(_ => _.id);
            const restCompanyItems = restCompanies.map((restCompany) => {
              const relatedCompanyBalances = (relatedCompanyBalancesGroupedByRelatedCompanyId[relatedCompany.id] || []).filter(_ => _.opponentCompanyId === restCompany.id)
              const opponentRelatedCompanyBalances = (relatedCompanyBalancesGroupedByOpponentCompanyId[relatedCompany.id] || []).filter(_ => (_.subsidiaryId || company.id) === restCompany.id);
              return {
                restCompany,
                relatedCompanyBalances,
                opponentRelatedCompanyBalances,
              };
            })
            if(restCompanyItems.every(_ => _.relatedCompanyBalances.length === 0 && _.opponentRelatedCompanyBalances.length === 0)) return null;

            return (
              <div key={relatedCompany.id}>
                <div className="d-flex flex-column gap-3">
                  {
                    restCompanyItems.map(({ restCompany, relatedCompanyBalances, opponentRelatedCompanyBalances, }, i) => {
                      if(relatedCompanyBalances.length === 0 && opponentRelatedCompanyBalances.length === 0) return null;

                      return (
                        <LinkingBlock
                          key={i}
                          {...{
                            relatedCompanies,
                            parentCompany: company,
                            period,
                            yearMonth,
                            isLockedMonth,
                            selfCompany: relatedCompany,
                            opponentCompany: restCompany,
                            relatedCompanyBalanceLinks,
                            relatedCompanyBalances,
                            opponentRelatedCompanyBalances,
                            accountItemsById,
                            relatedCompanyBalancesById,
                            exchangeRate,
                            consolidationAccountItems,
                            allConsolidationSegments,
                            allConsolidationJournalTypes,
                            onAddedJournal: _ => updateVersion(),
                          }}
                        />
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
});

function LinkingBlock(props) {
  const { relatedCompanies, parentCompany, period, yearMonth, isLockedMonth, selfCompany, opponentCompany, relatedCompanyBalanceLinks, relatedCompanyBalances, opponentRelatedCompanyBalances, relatedCompanyBalancesById, accountItemsById, exchangeRate, consolidationAccountItems, allConsolidationSegments, onAddedJournal, allConsolidationJournalTypes, } = props;
  const links = orderBy(relatedCompanyBalanceLinks.filter(_ => _.selfCompanyId === selfCompany.id && _.opponentCompanyId === opponentCompany.id), _ => _.createdAt.toDate(), 'desc');
  const [linkingItems, { set: setLinkingItems, push: pushLinkingItem }] = useList([]);
  const linkedItems = links.flatMap(_ => _.items);
  const linkedRelatedCompanyBalanceIds = linkedItems.map(_ => _.relatedCompanyBalanceId);
  const onClickLink = async (companyType, relatedCompanyBalanceId) => {
    linkingItems.map(_ => _.relatedCompanyBalanceId).includes(relatedCompanyBalanceId) ? setLinkingItems(linkingItems.filter(_ => _.relatedCompanyBalanceId !== relatedCompanyBalanceId)) : pushLinkingItem({ companyType, relatedCompanyBalanceId, });
  };
  const fixLinking = async () => {
    await parentCompany.ref.collection('relatedCompanyBalanceLinks').add({
      selfCompanyId: selfCompany.id,
      opponentCompanyId: opponentCompany.id,
      items: linkingItems,
      period,
      yearMonth,
      createdAt: new Date(),
    })
    setLinkingItems([]);
  };
  if(relatedCompanyBalances.length === 0 && opponentRelatedCompanyBalances.length === 0) return null;

  return (
    <div className="border p-3 rounded-xl border-3">
      <div className="d-flex justify-content-between">
        <div>
          <h6 className="font-weight-bold text-primary">{selfCompany.display_name}</h6>
          <div className="d-flex flex-column gap-1">
            {
              relatedCompanyBalances.filter(_ => !linkedRelatedCompanyBalanceIds.includes(_.id)).map((relatedCompanyBalance) => {
                return (
                  <RelatedCompanyBalanceItemBlock key={relatedCompanyBalance.id} {...{ linkingItems, relatedCompanyBalance, accountItemsById, selfCompany, opponentCompany, exchangeRate, companyType: 'self', }} onClickLink={onClickLink.bind(null, 'self', relatedCompanyBalance.id)} />
                );
              })
            }
          </div>
        </div>
        <div>
          <h6 className="font-weight-bold text-primary">{opponentCompany.display_name}</h6>
          <div className="d-flex flex-column gap-1">
            {
              opponentRelatedCompanyBalances.filter(_ => !linkedRelatedCompanyBalanceIds.includes(_.id)).map((opponentRelatedCompanyBalance) => {
                return (
                  <RelatedCompanyBalanceItemBlock key={opponentRelatedCompanyBalance.id} {...{ linkingItems, relatedCompanyBalance: opponentRelatedCompanyBalance, accountItemsById, selfCompany, opponentCompany, exchangeRate, companyType: 'opponent', }} onClickLink={onClickLink.bind(null, 'opponent', opponentRelatedCompanyBalance.id)} />
                );
              })
            }
          </div>
        </div>
      </div>
      {
        !isEmpty(linkingItems) && (() => {
          const { self: selfLinkingItems = [], opponent: opponentLinkingItems = [] } = groupBy(linkingItems, 'companyType');
          const diff = sumBy([...selfLinkingItems, ...opponentLinkingItems], _ => relatedCompanyBalancesById[_.relatedCompanyBalanceId]?.jpyAmount * relatedCompanyBalancesById[_.relatedCompanyBalanceId]?.category?.directionValue);
          return (
            <div className="position-fixed d-flex justify-content-center" style={{ bottom: 20, left: 0, right: 0, }}>
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between gap-3">
                    <div>{numeral(selfLinkingItems.length).format()}件</div>
                    <div>{numeral(opponentLinkingItems.length).format()}件</div>
                  </div>
                  <div className={classnames('mt-1 d-flex justify-content-end', { 'text-danger': Math.abs(diff) > 0, })}>
                    差額: {numeral(diff).format()}
                  </div>
                  <div className="mt-3 d-flex justify-content-around gap-3">
                    <Button color="warning" onClick={setLinkingItems.bind(null, [])}>
                      マッチング取消
                    </Button>
                    <Button color="success" onClick={fixLinking} disabled={linkingItems.length < 1}>
                      マッチング決定
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })()
      }
      <div className="mt-3 d-flex flex-column gap-5">
        {
          links.map((link) => {
            return <LinkContent key={link.id} {...{ link, relatedCompanies, parentCompany, period, yearMonth, isLockedMonth, selfCompany, opponentCompany, relatedCompanyBalanceLinks, relatedCompanyBalances, opponentRelatedCompanyBalances, relatedCompanyBalancesById, accountItemsById, exchangeRate, consolidationAccountItems, allConsolidationSegments, onAddedJournal, allConsolidationJournalTypes, }} />
          })
        }
      </div>
    </div>
  );
}

function LinkContent (props) {
  const { link, relatedCompanies, parentCompany, period, yearMonth, selfCompany, opponentCompany, relatedCompanyBalanceLinks, relatedCompanyBalances, opponentRelatedCompanyBalances, relatedCompanyBalancesById, accountItemsById, exchangeRate, consolidationAccountItems, allConsolidationSegments, isLockedMonth, onAddedJournal, allConsolidationJournalTypes, } = props;
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const consolidationJournalsRef = parentCompany.ref.collection('consolidationJournals');
  const queryParams = useQueryParams();
  const { elapsed, elm, focusColor, } = useScrollFocusByQuery(_ => _.queryParams.linkId === link.id);
  const [consolidationJournal] = useCollectionSubscription(consolidationJournalsRef.where('linkId', '==', link.id).limit(1), [link]);
  const linkingItems = link.items.map(_ => ({ ..._, relatedCompanyBalance: relatedCompanyBalancesById[_.relatedCompanyBalanceId] })).filter(_ => _.relatedCompanyBalance);
  const linkingItemsGroupedByCompanyType = groupBy(linkingItems, 'companyType');
  const linkingItemsGroupedByDirection = groupBy(linkingItems, 'relatedCompanyBalance.category.direction');
  const diff = sumBy(linkingItemsGroupedByCompanyType.self, _ => _.relatedCompanyBalance.jpyAmount * _.relatedCompanyBalance.category?.directionValue) + sumBy(linkingItemsGroupedByCompanyType.opponent, _ => _.relatedCompanyBalance.jpyAmount * _.relatedCompanyBalance.category?.directionValue);
  const remoteCurrencyAmountDiff = sumBy(linkingItemsGroupedByCompanyType.self, _ => _.relatedCompanyBalance.remoteCurrencyAmount * _.relatedCompanyBalance.category?.directionValue) + sumBy(linkingItemsGroupedByCompanyType.opponent, _ => _.relatedCompanyBalance.remoteCurrencyAmount * _.relatedCompanyBalance.category?.directionValue);
  const allConsolidationAccountItems = [...consolidationAccountItems, ...presetConsolidationAccountItems];
  const newConsolidationJournalItems = zip(linkingItemsGroupedByDirection.credit || [], linkingItemsGroupedByDirection.debit || []) // NOTE: 貸借反対にする
    .map(([creditItem, debitItem]) => {
      return {
        debitCompanyId: get(creditItem, 'relatedCompanyBalance.relatedCompany.id'),
        debitItemId: get(creditItem, 'relatedCompanyBalance.accountItem.consolidationAccountItemId'),
        debitAmount: Math.round(get(creditItem, 'relatedCompanyBalance.exchangedAmount')),
        debitSegmentId: get(creditItem, 'relatedCompanyBalance.consolidationSegment.id'),
        creditCompanyId: get(debitItem, 'relatedCompanyBalance.relatedCompany.id'),
        creditItemId: get(debitItem, 'relatedCompanyBalance.accountItem.consolidationAccountItemId'),
        creditAmount: Math.round(get(debitItem, 'relatedCompanyBalance.exchangedAmount')),
        creditSegmentId: get(debitItem, 'relatedCompanyBalance.consolidationSegment.id'),
      };
    });
  const newConsolidationJournalItemConsolidationAccountItems = newConsolidationJournalItems.flatMap(_ => [_.debitItemId, _.creditItemId].map(_ => consolidationAccountItemsById[_]));
  const newConsolidationJournalType = computeType(newConsolidationJournalItemConsolidationAccountItems)
  const onClickCopy = () => {
    const { location } = window;
    const url = `${location.origin}${location.pathname}?${qs.stringify({ ...pick(queryParams, ['period', 'yearMonth']), linkId: link.id })}`;
    copy(url);
    toast.success('URLをクリップボードにコピーしました');
  };

  return (
    <div className="d-flex flex-column gap-1" ref={elm} style={{ backgroundColor: focusColor, }}>
      <div className="d-flex justify-content-center align-items-center gap-1">
        <div className="d-flex flex-column align-items-end gap-1" style={{ flexBasis: '50%' }}>
          {
            (linkingItemsGroupedByCompanyType.self || []).map((linkingItem, i) => {
              const { relatedCompanyBalance } = linkingItem;
              return (
                <RelatedCompanyBalanceItemBlock key={relatedCompanyBalance.id} isLinked {...{ relatedCompanyBalance, accountItemsById, selfCompany, opponentCompany, exchangeRate, companyType: 'self', }} />
              );
            })
          }
        </div>
        <div className="d-flex align-items-center gap-1" style={{ flexBasis: '50%' }}>
          <div className="d-flex flex-column align-items-start gap-1 flex-grow-1">
            {
              (linkingItemsGroupedByCompanyType.opponent || []).map((linkingItem, i) => {
                const { relatedCompanyBalance } = linkingItem;
                return (
                  <RelatedCompanyBalanceItemBlock key={relatedCompanyBalance.id} isLinked {...{ relatedCompanyBalance, accountItemsById, selfCompany, opponentCompany, exchangeRate, companyType: 'opponent', }} />
                );
              })
            }
          </div>
          <div className="text-right">
            <div className={classnames({ 'text-danger': Math.abs(diff) > 0 })}>
              {integerFormat(diff)}
            </div>
            <div className={classnames('small', { 'text-danger': Math.abs(remoteCurrencyAmountDiff) > 0 })}>
              {integerFormat(remoteCurrencyAmountDiff)}
            </div>
          </div>
          <DeleteButton confirmMessage="本当に解除しますか？" outline size="sm" label="解除" renderBody={_ => <span className="fas fa-unlink" />} itemRef={link.ref} disabled={consolidationJournal != null} />
          <Button outline size="sm" onClick={onClickCopy}>
            <span className="fas fa-clipboard" />
          </Button>
          <EditButton outline size="sm" itemRef={link.ref} icon={<span className="fas fa-pen-square" />} label={false} FormModal={ModelFormModal} formProps={{ title: 'メモ', fields: relatedCompanyBalanceLinkFields(), }} />
          {
            consolidationJournal != null ? (
              <Button size="sm" outline tag={Link} to={`/companies/${parentCompany.id}/consolidationJournals?${qs.stringify({ period, yearMonth, consolidationJournalId: consolidationJournal.id, })}`} target="_blank">
                仕訳
                <span className="fas fa-fw fa-external-link-alt ml-1" />
              </Button>
            ) : (
              <AddButton className="text-nowrap" size="sm" label="仕訳" itemRef={consolidationJournalsRef.doc()} processValues={_ => ({ ..._, period, yearMonth, linkId: link.id, })} FormModal={ConsolidationJournalFormModal} initialValues={{ type: newConsolidationJournalType, items: newConsolidationJournalItems, }} formProps={{ companies: relatedCompanies, consolidationAccountItems: allConsolidationAccountItems, consolidationSegments: allConsolidationSegments, allConsolidationJournalTypes, }} disabled={isLockedMonth} onFinish={onAddedJournal} />
            )
          }
        </div>
      </div>
      {
        !isEmpty(link.note) && (
          <div className="small">
            {link.note}
          </div>
        )
      }
    </div>
  );
}

function RelatedCompanyBalanceItemBlock (props) {
  const { isLinked = false, linkingItems = [], companyType, relatedCompanyBalance, accountItemsById, selfCompany, opponentCompany, exchangeRate, className, onClickLink, } = props;
  const isLinking = linkingItems.map(_ => _.relatedCompanyBalanceId).includes(relatedCompanyBalance.id);
  const { id, ref, opponentCompanyId, accountItemId, note, accountItem, consolidationSegment, jpyAmount, remoteCurrencyAmount, currency, } = relatedCompanyBalance;

  return (
    <div className={classnames('border rounded-lg p-2 d-flex align-items-center justify-content-between gap-2', { 'border-warning': !isLinked && !isLinking, 'border-info': isLinking, 'border-secondary': isLinked, })} style={{ width: 330 }}>
      <div>
        <div>{accountItem && accountItem.name}</div>
        <div className="badge badge-secondary">{consolidationSegment?.name}</div>
      </div>
      <div className="text-right">
        <div>
          {integerFormat(jpyAmount)}
        </div>
        <div className="text-muted small">
          {
            remoteCurrencyAmount != null && floatFormat(remoteCurrencyAmount)
          }
        </div>
      </div>
      {
        !isLinked && (
          <div>
            <Button outline size="sm" color="info" onClick={onClickLink} data-operation-type="write">
              <span className="fas fa-link" />
            </Button>
          </div>
        )
      }
    </div>
  );
}
