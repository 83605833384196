const { isEmpty, } = require('lodash');

const fieldsToImport = ({ consolidationAccountItems, } = {}) => {
  const consolidationAccountItemNames = consolidationAccountItems.map(_ => _.name);
  return {
    consolidationAccountItemName: {
      validations: {
        required: v => !isEmpty(v),
        exists: v => isEmpty(v) || consolidationAccountItemNames.includes(v),
      },
    },
    amount: {
      validations: {
        finite: v => isFinite(parseInt(v, 10)),
      },
    },
  };
};

exports.fieldsToImport = fieldsToImport;
