const { format: formatDate, addMonths, endOfMonth, } = require('date-fns');
const { pick, lowerFirst, mapKeys, range, isFunction, } = require('lodash');
const numeral = require('numeral');

const { keys } = Object;

const fieldDisplayValue = (values, key, { type, options: _options, }) => {
  const v = values[key];
  const options = isFunction(_options) ? _options(values) : _options;
  return (({
    select: _ => (options.find(_ => _.value === v) || {}).label,
    multiSelect: _ => (v || []).map(v => (options.find(_ => _.value === v) || {}).label).join(', '),
    date: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd'),
    datetime: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd HH:mm'),
    boolean: _ => ({ true: 'はい', false: 'いいえ' })[v || false],
  })[type] || (_ => v))();
};

const yearMonthDate = _ => new Date(parseInt(_.slice(0, 4), 10), parseInt(_.slice(4, 6), 10) - 1, 1);
const prevYearMonth = _ => formatDate(addMonths(yearMonthDate(_), -1), 'yyyyMM');
const floatFormat = (value) => {
  return numeral(value).format('0,0.00[0000]');
};
const numberFormat = (currency = 'jpy', value) => {
  return (currency === 'jpy' ? integerFormat : floatFormat)(value);
}

const integerFormat = (value) => {
  return numeral(value).format('0,0');
};
const integerChangeFormat = (value) => {
  return numeral(value).format('+0,0');
};

const periodOfFiscalYear = (fiscalYear) => {
  return fiscalYear && fiscalYear.start_date.replace(/-/g, '').slice(0, 6);
};

const fiscalYearOfPeriod = (period, fiscalYears = []) => {
  return fiscalYears.find(_ => periodOfFiscalYear(_) === period);
};

const yearMonthsOfPeriod = (period, fiscalYears = []) => {
  const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
  const [y, m, d] = fiscalYear?.start_date.split('-') || [];
  const startDate = new Date(y, m - 1, d);
  return range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
};

const uniqAccountName = (relatedCompany, accountItemName, code) => {
  return ['bugyo', 'pca'].includes(relatedCompany.externalType) ? code : accountItemName;
};

const uniqSectionName = (relatedCompany, name, code) => {
  return ['bugyo', 'pca'].includes(relatedCompany.externalType) ? code : name;
};

const disassembleJournalItems = (journal) => {
  return (journal.items || [])
    .map(_ => ({ ..._, journal, }))
    .flatMap(_ => ['debit', 'credit'].map(d => ({ direction: d, ...pick(_, ['journal', ...keys(_).filter(_ => _.startsWith(d))]), })))
    .map(_ => mapKeys(_, (v, k) => lowerFirst(k.replace(/^(debit|credit)/, ''))));
};

const serial = async (xs, f) => {
  return await xs.reduce(async (_, x) => {
    return [...(await _), await f(x)];
  }, Promise.resolve([]));
};

exports.fieldDisplayValue = fieldDisplayValue;
exports.yearMonthDate = yearMonthDate;
exports.prevYearMonth = prevYearMonth;
exports.floatFormat = floatFormat;
exports.integerFormat = integerFormat;
exports.numberFormat = numberFormat;
exports.integerChangeFormat = integerChangeFormat;
exports.periodOfFiscalYear = periodOfFiscalYear;
exports.fiscalYearOfPeriod = fiscalYearOfPeriod;
exports.yearMonthsOfPeriod = yearMonthsOfPeriod;
exports.uniqAccountName = uniqAccountName;
exports.uniqSectionName = uniqSectionName;
exports.disassembleJournalItems = disassembleJournalItems;
exports.serial = serial;
