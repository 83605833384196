import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { uniq, last, sumBy, groupBy, keyBy, pick, sortBy, omit, get, orderBy, isEmpty, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Container, Draggable } from 'react-smooth-dnd';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { integerFormat } from '../../util';
import { cfAccountItemCategories } from '../../shared/config';
import { presetConsolidationAccountItems, } from '../../shared/models/consolidationAccountItem';
import { fields } from '../../shared/models/cfAccountItem';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';

const { keys, entries } = Object;
const db = firebase.firestore();
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);

export default CompanyPage(function CompanyAccountItems (props) {
  const { company, } = props;
  const cfAccountItemsRef = company.ref.collection('cfAccountItems');
  const { items: cfAccountItems, isLoading } = useCollectionSubscription(cfAccountItemsRef, [company], { detail: true });
  const cfAccountItemsById = keyBy(cfAccountItems, 'id');
  const sortedCfAccountItems = orderBy(cfAccountItems, [_ => cfAccountItemCategoryKeys.indexOf(_.cfAccountItemCategoryKey), 'index'], ['asc', 'asc'])
  const groupedCfAccountItems = groupBy(sortedCfAccountItems, 'cfAccountItemCategoryKey');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = accountItems.filter(_ => _.subsidiaryId === null);
  const presetConsolidationAccountItemSettings = useCollectionSubscription(company.ref.collection('presetConsolidationAccountItemSettings'), [company], { initialItems: null });
  const presetConsolidationAccountItemSettingsById = keyBy(presetConsolidationAccountItemSettings, 'id');
  const allConsolidationAccountItems = [{ id: '税引前当期純利益', name: '税引前当期純利益' }, ...consolidationAccountItems, ...presetConsolidationAccountItems.map(_ => ({ ..._, ...presetConsolidationAccountItemSettingsById[_.id], }))];
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const rowsForExport = sortedCfAccountItems.map(_ => ({
    id: _.id,
    name: _.name,
    accountCode: _.accountCode, // NOTE: pickだとaccountCodeがない場合exportされない
    cfAccountItemCategoryKey: _.cfAccountItemCategoryKey,
  }));
  const processRow = (batch, row, i) => {
    const { id, name, accountCode, cfAccountItemCategoryKey, } = row;
    if(!name) return;

    const ref = cfAccountItemsRef.doc(...(id ? [id] : []));
    const exists = cfAccountItemsById[id] != null;
    const sameGroupItems = groupedCfAccountItems[cfAccountItemCategoryKey] || [];
    batch.set(ref, {
      name,
      accountCode,
      cfAccountItemCategoryKey,
      ...(
        !exists && {
          index: (isEmpty(sameGroupItems) ? 0 : last(sameGroupItems).index) + i + 1,
          createdAt: new Date(),
        }
      ),
    }, { merge: true });
  };
  const processRows = (rows) => {
    const cfAccountItemNames = cfAccountItems.map(_ => _.name);
    if(
      rows.map(_ => _.name).some(_ => cfAccountItemNames.includes(_)) ||
      uniq(rows.map(_ => _.name)).length !== rows.length
    ) {
      toast.error('名称が重複しています');
    } else {
      return rows;
    }
  };

  return props.translate(
    <div className="company-cf-change-types container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="CF科目を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>CF科目</h4>
        </div>
        <div className="mb-3 d-flex justify-content-end gap-1">
          <ImportButton disabled={isLoading} processRow={processRow} processRows={processRows} documentName="accountItem" importKey={`cfAccountItems`} fields={fields({ consolidationAccountItems: allConsolidationAccountItems })} />
          <ExportButton fileName="CF科目.csv" rows={rowsForExport} />
          <AddButton disabled={isLoading} itemRef={cfAccountItemsRef.doc()} FormModal={ModelFormModal} processValues={_ => ({ ..._, index: isEmpty(groupedCfAccountItems[_.cfAccountItemCategoryKey]) ? 0 : last(groupedCfAccountItems[_.cfAccountItemCategoryKey]).index + 1 })} formProps={{ title: 'CF科目 追加', fields: fields({ otherItems: cfAccountItems, consolidationAccountItems: allConsolidationAccountItems }), }} />
        </div>
        <div>
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr>
                <th></th>
                <th style={{ minWidth: 200 }}>名称</th>
                <th style={{ minWidth: 150 }}>科目コード</th>
                <th style={{ minWidth: 200 }}>区分</th>
                <th style={{ minWidth: 200 }}>対応する連結科目</th>
                <th></th>
              </tr>
            </thead>
            {
              entries(groupedCfAccountItems).map(([cfAccountItemCategoryKey, cfAccountItems]) => {
                return (
                  <RowsGroup key={cfAccountItemCategoryKey} {...{ company, cfAccountItems, allConsolidationAccountItems, consolidationAccountItemsById, }} />
                );
              })
            }
          </table>
        </div>
      </div>
    </div>
  );
});

function RowsGroup (props) {
  const { company, cfAccountItems, allConsolidationAccountItems, consolidationAccountItemsById, } = props;
  const cfAccountItemsRef = company.ref.collection('cfAccountItems');
  const [isDragging, toggleDragging] = useToggle(false);
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const removedItem = cfAccountItems[removedIndex];
    const otherItems = [...cfAccountItems.slice(0, removedIndex), ...cfAccountItems.slice(removedIndex + 1)];
    const prevItem = otherItems[addedIndex - 1];
    const nextItem = otherItems[addedIndex];
    const ref = cfAccountItemsRef.doc(removedItem.id);
    if(!prevItem) return ref.update({ index: (nextItem.index != null ? nextItem.index : addedIndex) - 1 });
    if(!nextItem) return ref.update({ index: (prevItem.index != null ? prevItem.index : addedIndex) + 1 });
    return ref.update({ index: ((prevItem.index != null ? prevItem.index : addedIndex) + (nextItem.index != null ? nextItem.index : (addedIndex + 1))) / 2 });
  };

  return (
    <Container
      dragHandleSelector=".drag-handle"
      onDrop={onDrop}
      onDragStart={_ => toggleDragging(true)}
      onDragEnd={_ => toggleDragging(false)}
      dropPlaceholder={{ style: { background: 'eee', } }}
      render={(ref) => (
        <tbody ref={ref}>
          {
            cfAccountItems.map((cfAccountItem) => {
              const { id, ref, name, accountCode, cfAccountItemCategoryKey, consolidationAccountItemIds, } = cfAccountItem;
              const beforeDelete = async () => {
                if(
                  allConsolidationAccountItems.some(_ => _.cfMappingItems?.some(_ => _.cfAccountItemId === id)) ||
                  (await getCollectionData(company.ref.collection('cfChanges').where('cfAccountItemIds', 'array-contains', id))).length > 0
                ) {
                  toast.error('使用されているため、削除できません');
                  return false;
                }
              };

              return (
                <Draggable
                  key={id}
                  render={() => (
                    <tr style={{ display: !isDragging && 'table-row' }}>
                      <td style={{ width: 30 }}>
                        <div className="drag-handle text-muted cursor-pointer">
                          <span className="fas fa-grip-vertical" />
                        </div>
                      </td>
                      <td style={{ minWidth: 200 }}>
                        {name}
                      </td>
                      <td>
                        {accountCode}
                      </td>
                      <td className="text-nowrap">
                        {get(cfAccountItemCategories, [cfAccountItemCategoryKey, 'label'])}
                      </td>
                      <td className="text-nowrap">
                        {
                          (consolidationAccountItemIds || []).map(_ => consolidationAccountItemsById[_]).map((consolidationAccountItem) => {
                            return (
                              <div key={consolidationAccountItem?.id}>
                                {consolidationAccountItem?.name}
                              </div>
                            );
                          })
                        }
                      </td>
                      <td className="text-nowrap text-right">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'CF科目 編集', fields: fields({ otherItems: cfAccountItems.filter(_ => _.id !== id), consolidationAccountItems: allConsolidationAccountItems }), }} />
                        <DeleteButton item={cfAccountItem} itemRef={ref} className="ml-2" beforeDelete={beforeDelete} />
                      </td>
                    </tr>
                  )}
                />
              );
            })
          }
        </tbody>
      )}
    />
  );
}
