import React, { Component, Fragment, useEffect, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { uniqBy, isEmpty, get, orderBy, sumBy, keyBy, sortBy, omit, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { batch } from './../shared/firebase';
import { accountItemCategoryNames, } from '../shared/config';
import { floatFormat } from './../util';
import firebase, { functions } from './../firebase';
import { generateSimpleRows, simpleRowsForExport, computeSimpleAlerts, } from '../shared/lib/individualPkg';
import { withConsolidationAccountItem, } from './../shared/models/trial';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import usePkgItems from './hooks/usePkgItems';
import ModelFormModal from './modals/ModelFormModal';
import ProgressButton from './ProgressButton';
import AutoLinkText from './AutoLinkText';
import AddButton from './AddButton';
import EditButton from './EditButton';
import ExportButton from './ExportButton';
import DeleteButton from './DeleteButton';
import AmountWithExchange from './AmountWithExchange';
import Alerts from './Alerts';

const { keys, entries } = Object;
const db = firebase.firestore();

export default function IndividualSimpleTableSection (props) {
  const { collectionName, section, title, fields, changeFieldNames, company, period, yearMonth, filteredYearMonths, relatedCompanies, accountItems, consolidationAccountItems, itemTrials, trials, subsidiaryId, filterTrials, relatedCompany, prevCr, cr, isLockedMonth, } = props;
  const itemsRef = company.ref.collection(collectionName);
  const { items } = usePkgItems(company, relatedCompany, subsidiaryId, accountItems, collectionName, [], 'closing', [], filteredYearMonths);
  const accountItemsByName = keyBy(accountItems, 'name');
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const trialBalances = trials.flatMap(_ => _.balances);
  const rows = generateSimpleRows(items || [], accountItems, prevCr, cr, trials);
  const alerts = computeSimpleAlerts(items || [], accountItems, prevCr, cr, trials);

  return (
    <div>
      <h5>{title}</h5>
      <Alerts alerts={alerts} />
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ExportButton fileName={`${section}_${title}.csv`} rows={simpleRowsForExport(items, accountItems, prevCr, cr, trials)} />
        <AddButton itemRef={itemsRef.doc()} processValues={_ => ({ ..._, subsidiaryId, period, yearMonth, })} FormModal={ModelFormModal} formProps={{ title, fields: fields({ accountItems, otherItems: rows, }) }} disabled={isLockedMonth} />
      </div>
      <div>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }}>個別勘定科目</th>
              <th style={{ minWidth: 150 }}>期首</th>
              <th style={{ minWidth: 150 }}>増減</th>
              <th style={{ minWidth: 150 }}>為替差額</th>
              <th style={{ minWidth: 150 }}>期末</th>
              <th style={{ minWidth: 150 }}>試算表残高</th>
              <th style={{ minWidth: 150 }}>備考</th>
              <th style={{ minWidth: 150 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              rows.map((row) => {
                const { id, ref, accountItem, category, rate, change, exchangedChange, exchangeDiff, trialAmount, opening = 0, closing = 0, exchangedAmounts, note, } = row;

                return (
                  <tr key={id}>
                    <td>
                      {accountItem && accountItem.name}
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[opening, opening * prevCr]} />
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[change, exchangedChange]} />
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[0, exchangeDiff]} />
                    </td>
                    <td className={classnames('text-right', { 'table-danger': floatFormat(closing) !== floatFormat(trialAmount) })}>
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[closing, exchangedAmounts.closing]} />
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[trialAmount, trialAmount * rate]} />
                    </td>
                    <td>
                      <AutoLinkText>
                        {note}
                      </AutoLinkText>
                    </td>
                    <td className="text-nowrap text-right">
                      <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '取得または売却にかかる債権債務・売却損益', fields: fields({ accountItems, otherItems: rows.filter(_ => _.id !== id), }), }} disabled={isLockedMonth} />
                      <DeleteButton item={row} itemRef={ref} className="ml-2" disabled={isLockedMonth} />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
