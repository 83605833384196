import React, { Fragment, Component, useMemo, } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, pick, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../firebase';
import { integerFormat } from '../util';
import { generateSimpleRowGroups, simpleRowsForExport, } from '../shared/lib/pkg';
import { getCategory, } from '../shared/models/accountItem';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useCompanySelector from './hooks/useCompanySelector';
import useQueryParams from './hooks/useQueryParams';
import usePkgItemGroups from './hooks/usePkgItemGroups';
import PkgPage from './hocs/PkgPage';
import ModelFormModal from './modals/ModelFormModal';
import CompanySyncButton from './CompanySyncButton';
import TrialsSyncButton from './TrialsSyncButton';
import AutoLinkText from './AutoLinkText';
import AddButton from './AddButton';
import EditButton from './EditButton';
import ExportButton from './ExportButton';
import DeleteButton from './DeleteButton';
import QuerySelector from './QuerySelector';

const { keys, entries, } = Object;

export default function PkgSimpleTableSection(props) {
  const { path, collectionName, section, title, company, exchangeRate, prevExchangeRate, period, yearMonth, relatedCompanies, relatedCompaniesById, accountItems, accountItemsById, consolidationAccountItems, consolidationAccountItemsById, allConsolidationJournalTypes, location, filteredYearMonths, } = props;
  const { itemGroups } = usePkgItemGroups(company, relatedCompanies, accountItems, collectionName, [], 'closing', [], filteredYearMonths);
  const rowGroups = useMemo(_ => itemGroups && generateSimpleRowGroups(relatedCompanies, itemGroups, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate), [itemGroups]);

  return (
    <div>
      <h5>{title}</h5>
      <div className="mb-3 d-flex justify-content-end gap-1">
        <ExportButton fileName={`全社_${section}_${title}.csv`} rows={simpleRowsForExport(relatedCompanies, itemGroups, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, prevExchangeRate, exchangeRate)} />
      </div>
      <div className="overflow-auto" style={{ maxHeight: 'min(800px, 80vh)', }}>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }} className="sticky">勘定科目</th>
              <th style={{ minWidth: 200 }}>会社</th>
              <th style={{ minWidth: 150 }}>期首</th>
              <th style={{ minWidth: 150 }}>増減</th>
              <th style={{ minWidth: 150 }}>為替差額</th>
              <th style={{ minWidth: 150 }}>期末</th>
              <th style={{ minWidth: 150 }}>備考</th>
            </tr>
          </thead>
          <tbody className="thead-light">
            {
              rowGroups?.map(({ consolidationAccountItem, relatedCompanyRowGroups, }, consolidationAccountItemRowGroupIndex) => {
                const Amount = _ => <div>{integerFormat(_.amount)}</div>;
                const rowsInRelatedCompanyRowGroup = relatedCompanyRowGroups.flatMap(_ => _.rows);

                return (
                  <Fragment key={consolidationAccountItem.id}>
                    {
                      relatedCompanyRowGroups.map(({ relatedCompany, prevCr, cr, rows }, relatedCompanyRowGroupIndex) => {
                        return (
                          <Fragment key={relatedCompany.id}>
                            {
                              rows.map((row, rowIndex) => {
                                const { id, rowType, rowLabel, accountItemId, opening, closing, rate, note, subsidiaryId, relatedCompany, consolidationAccountItem, exchangedChange, exchangeDiff, exchangedAmounts, } = row;
                                const Amount = _ => <Link to={`/companies/${company.id}/relatedCompanies/${get(relatedCompany, 'id')}/${path}${location.search}`}>{integerFormat(_.amount)}</Link>;

                                return (
                                  <tr key={id || rowType}>
                                    {
                                      relatedCompanyRowGroupIndex === 0 && rowIndex === 0 && (
                                        <th rowSpan={sumBy(relatedCompanyRowGroups, 'rows.length') + 1} className="sticky">
                                          {consolidationAccountItem.name}
                                        </th>
                                      )
                                    }
                                    {
                                      rowIndex === 0 && (
                                        <td rowSpan={rows.length} style={{ borderRight: 0, }}>
                                          {relatedCompany.display_name}
                                        </td>
                                      )
                                    }
                                    <td className="text-right">
                                      <Amount amount={opening * prevCr} />
                                    </td>
                                    <td className="text-right">
                                      <Amount amount={exchangedChange} />
                                    </td>
                                    <td className="text-right">
                                      <Amount amount={exchangeDiff} />
                                    </td>
                                    <td className="text-right">
                                      <Amount amount={exchangedAmounts.closing} />
                                    </td>
                                    <td>
                                      <AutoLinkText>
                                        {note}
                                      </AutoLinkText>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </Fragment>
                        );
                      })
                    }
                    <tr className="font-weight-bold">
                      <td className="text-right">
                        合計
                      </td>
                      <td className="text-right">
                        <Amount amount={sumBy(relatedCompanyRowGroups, _ => _.opening * _.prevCr)} />
                      </td>
                      <td className="text-right">
                        <Amount amount={sumBy(rowsInRelatedCompanyRowGroup, 'exchangedChange')} />
                      </td>
                      <td className="text-right">
                        <Amount amount={sumBy(rowsInRelatedCompanyRowGroup, 'exchangeDiff')} />
                      </td>
                      <td className="text-right">
                        <Amount amount={sumBy(rowsInRelatedCompanyRowGroup, _ => _.closing * _.rate)} />
                      </td>
                      <td>
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
