const { inRange, range, isEmpty, } = require('lodash');
const { format: formatDate, } = require('date-fns');

const { accountItemCategoriesByName, otherHrAccountItemNamePatterns, } = require('../config');
const { optionLabel, } = require('../lib/consolidationAccountItems');

const netAssetEndAccountCategoryNames = accountItemCategoriesByName['純資産'].descendants().map(_ => _.name);

const { entries } = Object;

const hrConsolidationAccountItems = (consolidationAccountItems) => {
  return consolidationAccountItems.filter((consolidationAccountItem) => {
    const { name, account_category } = consolidationAccountItem;
    return otherHrAccountItemNamePatterns.some(_ => name.match(_)) || netAssetEndAccountCategoryNames.includes(account_category);
  });
};

const batchCloneFields = ({ company, periodOptions, fiscalYearOfPeriod, }) => {
  return {
    period: {
      label: '事業年度',
      type: 'select',
      options: periodOptions,
      validations: {
        required: v => v != null,
      },
    },
    yearMonth: {
      label: '月度',
      type: 'select',
      options: (state) => {
        const fiscalYear = fiscalYearOfPeriod(state.period, company.fiscalYears) || {};
        const { start_date: startDate, end_date: endDate } = fiscalYear;
        const monthOptions = startDate ? range(formatDate(new Date(startDate), 'yyyyMM'), formatDate(new Date(endDate), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
        return monthOptions;
      },
      validations: {
        required: v => v != null,
      },
    },
  };
};

const itemFields = ({ company, consolidationAccountItems = [] }) => {
  return {
    accountItemId: {
      label: '連結科目',
      type: 'select',
      options: hrConsolidationAccountItems(consolidationAccountItems).map(_ => ({ label: optionLabel(_, company), value: _.id })),
    },
    amount: {
      label: '金額',
      type: 'integer',
    },
  };
};

exports.itemFields = itemFields;
exports.batchCloneFields = batchCloneFields;
exports.hrConsolidationAccountItems = hrConsolidationAccountItems;
