import React, { Fragment, Component, useEffect, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { isEmpty, get, mapValues, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { batch, getDocumentData, getCollectionData, } from '../../shared/firebase';
import { numberFormat, integerFormat, } from '../../util';
import { fiscalYearOfPeriod, } from '../../utils';
import { presetConsolidationAccountItems, } from '../../shared/models/consolidationAccountItem';
import { batchCloneFields, } from '../../shared/models/exchangedItem';
import { accountItemCategoryNames, accountItemCategories, currencies, } from '../../shared/config';
import { generateRowGroups, rowsForExport, computeAlerts, } from '../../shared/lib/individualAdjusted';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import useSingleAmounts from '../hooks/useSingleAmounts';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import ExchangedItemFormModal from '../modals/ExchangedItemFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ModalButton from '../ModalButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';
import Alerts from '../Alerts';

export default RelatedCompanyPage(function CompanyIndividualAdjusted (props) {
  const { relatedCompany, company, subsidiaryId, period, yearMonth, prevEndYearMonth, isParent, periodOptions, isLockedMonth, filteredYearMonths, prevYearMonths, } = props;
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const sortedConsolidationAccountItems = sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index');
  const allConsolidationAccountItems = [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems];
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const exchangedItemRef = company.ref.collection('exchangedItems').doc([relatedCompany.id, yearMonth].join('__'));
  const exchangedItem = useDocumentSubscription(exchangedItemRef, [relatedCompany.id, yearMonth]);
  const exchangeRate = useDocumentSubscription(company.ref.collection('exchangeRates').doc(yearMonth), [yearMonth]);
  const { items } = useSingleAmounts(company, relatedCompany, subsidiaryId, allConsolidationAccountItems, accountItems, filteredYearMonths, false, [exchangedItem]);
  const { items: prevYearItems } = useSingleAmounts(company, relatedCompany, subsidiaryId, allConsolidationAccountItems, accountItems, prevYearMonths);

  const isJpy = (relatedCompany.currency || 'jpy') === 'jpy';
  const rowGroups = generateRowGroups(items || []);
  const onSubmitBatchClone = async (values, { onClickClose }) => {
    if(!window.confirm('本当にコピーしますか？')) return;

    const { yearMonth: sourceYearMonth, } = values;
    const source = await getDocumentData(company.ref.collection('exchangedItems').doc([relatedCompany.id, sourceYearMonth].join('__')));
    exchangedItemRef.set({ ...omit(source, ['id', 'ref']), yearMonth, subsidiaryId, });
    toast.success('コピーしました');
    onClickClose();
  };
  const alerts = computeAlerts(relatedCompany, items, prevYearItems);
  useEffect(() => {
    if(!isParent && exchangedItem === null) {
      exchangedItemRef.set({ createdAt: new Date(), yearMonth, }, { merge: true });
    }
  }, [isParent, exchangedItem, subsidiaryId, yearMonth]);

  return props.translate(
    <div className="company-individual-adjusted">
      <Alerts alerts={alerts} />
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          {
            !isParent && !isJpy && (
              <table className="m-0 table table-bordered">
                <tbody>
                  <tr>
                    <td>{get(currencies, [relatedCompany.currency, 'label'], '')}</td>
                    <th className="table-secondary">{company.usesMonthlyAr ? '単月' : ''}AR</th>
                    <td>{get(exchangeRate, `${relatedCompany.currency}-${company.usesMonthlyAr ? 'mar' : 'ar'}`, 0)}</td>
                    <th className="table-secondary">CR</th>
                    <td>{get(exchangeRate, `${relatedCompany.currency}-cr`, 0)}</td>
                  </tr>
                </tbody>
              </table>
            )
          }
        </div>
        <div className="d-flex gap-1">
          <ExportButton fileName="個別修正・換算後.csv" rows={rowsForExport(items)} />
          {
            !isParent && !isJpy && (
              <Fragment>
                <ModalButton label="他の年月からコピー" Modal={ModelFormModal} modalProps={{ values: { period, yearMonth, }, fields: batchCloneFields({ periodOptions, company, fiscalYearOfPeriod, }), title: '他の年月からコピー', onSubmit: onSubmitBatchClone, submitLabel: 'コピー', }} disabled={isLockedMonth} />
                <EditButton processValues={_ => ({ ..._, subsidiaryId, yearMonth, })} itemRef={exchangedItemRef} FormModal={ExchangedItemFormModal} formProps={{ consolidationAccountItems: allConsolidationAccountItems, }} disabled={isLockedMonth} />
              </Fragment>
            )
          }
        </div>
      </div>
      {
        rowGroups.map(({ type, rowGroups }) => {
          return (
            <div key={type} className="mb-5">
              <table className="table table-bordered sticky-table">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ minWidth: 180 }}>連結科目</th>
                    <th>金額</th>
                    <th>借方</th>
                    <th>貸方</th>
                    <th>修正後</th>
                    <th>換算後</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rowGroups.map(({ category, rows, }) => {
                      return (
                        <Fragment key={category.name}>
                          {
                            rows.map(({ item, itemType, closingBalance, debitAmount, creditAmount, adjustedAmount, accountItemCategory, exchangedAmount, }, i) => {
                              const Component = itemType === 'category' ? 'span' : Link;
                              return (
                                <tr key={i} className={itemType === 'category' && 'font-weight-bold'}>
                                  <td style={{ textIndent: (category.indent() + (itemType === 'category' ? 0 : 1)) * 18 }}>
                                    {item.name}
                                  </td>
                                  <td className="text-right">
                                    {numberFormat(relatedCompany.currency, closingBalance)}
                                  </td>
                                  <td className="text-right">
                                    <Component to={`/companies/${company.id}/relatedCompanies/${relatedCompany.id}/individualAdjustments?${qs.stringify({ period, yearMonth, debitItemId: item.id, })}`}>
                                      {numberFormat(relatedCompany.currency, debitAmount)}
                                    </Component>
                                  </td>
                                  <td className="text-right">
                                    <Component to={`/companies/${company.id}/relatedCompanies/${relatedCompany.id}/individualAdjustments?${qs.stringify({ period, yearMonth, creditItemId: item.id, })}`}>
                                      {numberFormat(relatedCompany.currency, creditAmount)}
                                    </Component>
                                  </td>
                                  <td className="text-right">
                                    {numberFormat(relatedCompany.currency, adjustedAmount)}
                                  </td>
                                  <td className="text-right">
                                    {integerFormat(exchangedAmount)}
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          );
        })
      }
    </div>
  );
});
