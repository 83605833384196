import React, { Fragment, Component } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, pick, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { getCategory, } from '../../shared/models/accountItem';
import { fields as investmentFields, changeFieldNames as investmentChangeFieldNames, } from '../../shared/models/investment';
import { fields as investmentRelatedItemFields, } from '../../shared/models/investmentRelatedItem';
import { changeFieldNames as fixedDepositChangeFieldNames, fields as fixedDepositFields, } from '../../shared/models/fixedDeposit';
import { changeFieldNames as loanChangeFieldNames, fields as loanFields, } from '../../shared/models/loan';
import { fields as incomeInterestItemFields } from '../../shared/models/incomeInterestItem';
import { changeFieldNames as otherLiabilityChangeFieldNames, fields as otherLiabilityFields } from '../../shared/models/otherLiability';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import PkgChangesTableSection from '../PkgChangesTableSection';
import PkgSimpleTableSection from '../PkgSimpleTableSection';

const { keys, entries, } = Object;

export default PkgPage(function CompanyPkgInvestments (props) {
  const { company, exchangeRate, period, yearMonth, relatedCompanies, consolidationAccountItemsById, location, } = props;

  return props.translate(
    <div className="company-pkg-fixed-assets">
      <PkgChangesTableSection
        section="投資関連"
        title="投資その他増減"
        collectionName="investments"
        path="investments"
        fields={investmentFields}
        changeFieldNames={investmentChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgSimpleTableSection
        section="投資関連"
        title="取得または売却にかかる債権債務・売却損益"
        collectionName="investmentRelatedItems"
        path="investments"
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="投資関連"
        title="定期預金"
        collectionName="fixedDeposits"
        path="investments"
        fields={fixedDepositFields}
        changeFieldNames={fixedDepositChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="投資関連"
        title="外部貸付"
        collectionName="loans"
        path="investments"
        fields={loanFields}
        changeFieldNames={loanChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgSimpleTableSection
        section="投資関連"
        title="未収利息・前受利息"
        collectionName="incomeInterestItems"
        path="investments"
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="投資関連"
        title="その他負債"
        collectionName="otherLiabilities"
        path="investments"
        fields={otherLiabilityFields}
        changeFieldNames={otherLiabilityChangeFieldNames}
        {...props}
      />
    </div>
  );
});
