import React, { useState, Fragment, useEffect, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { last, first, findKey, pick, isEmpty, groupBy, get, range, inRange, keyBy, sortBy, omit, } from 'lodash';
import { Button, FormGroup, Input, Label, } from 'reactstrap';
import Select from 'react-select';
import { startOfMonth, endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';
import qs from 'qs';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';

import { serial, } from '../../shared/util';
import { numberFormat, dateStringToDate, } from '../../util';
import { startOfMonthByFiscalYears, periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import firebase, { functions } from '../../firebase';
import { batch } from '../../shared/firebase';
import { documentTypes, accountItemCategories, accountItemCategoriesByName } from '../../shared/config';
import { generateRows, rowsForExport, computeAlerts, } from '../../shared/lib/sectionTrials';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import { getCategory, } from '../../shared/models/accountItem';
import { balanceFields } from '../../shared/models/trial';
import { noneSection } from '../../shared/models/section';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDependentState from '../hooks/useDependentState';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ProgressButton from '../ProgressButton';
import ExportButton from '../ExportButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import QuerySelector from '../QuerySelector';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const db = firebase.firestore();
const syncBreakdownTrials = functions.httpsCallable('syncBreakdownTrials');

export default RelatedCompanyPage(function CompanySectionTrials (props) {
  const { period, yearMonth, company, relatedCompany, subsidiaryId, isLockedMonth, } = props;
  const [selectedStartPeriod, selectStartPeriod] = useDependentState(period, [period]);
  const [selectedStartYearMonth, selectStartYearMonth] = useDependentState(fiscalYearOfPeriod(period, relatedCompany.fiscalYears)?.start_date.replace('-', '').slice(0, 6), [period]);
  const [selectedEndPeriod, selectEndPeriod] = useDependentState(period, [period]);
  const [selectedEndYearMonth, selectEndYearMonth] = useDependentState(yearMonth, [yearMonth]);
  const sectionTrials = useCollectionSubscription(company.ref.collection('sectionTrials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const sectionTrialsByType = keyBy(sectionTrials, 'type');
  const sections = useCollectionSubscription(company.ref.collection('sections').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const accountItemsByName = keyBy(accountItems, 'name');
  const accountItemsByCategoryAndName = keyBy(accountItems, _ => [_.account_category, _.name].join('__'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'), [company]);
  const periodOptions = (relatedCompany.fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), }));
  const rows = generateRows(relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems);
  const startFiscalYear = fiscalYearOfPeriod(selectedStartPeriod, relatedCompany.fiscalYears) || {};
  const endFiscalYear = fiscalYearOfPeriod(selectedEndPeriod, relatedCompany.fiscalYears) || {};
  const usesIndustry = ['bugyo', 'mf', 'pca'].includes(relatedCompany.externalType) || !!endFiscalYear?.use_industry_template;
  const enabledDocumentTypes = keys(usesIndustry ? documentTypes : omit(documentTypes, 'cr'));
  const [currentDocumentTypes, setCurrentDocumentTypes] = useState(enabledDocumentTypes);
  const startMonthOptions = startFiscalYear.start_date ? range(formatDate(dateStringToDate(startFiscalYear.start_date), 'yyyyMM'), formatDate(dateStringToDate(startFiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  const endMonthOptions = endFiscalYear.start_date ? range(formatDate(dateStringToDate(endFiscalYear.start_date), 'yyyyMM'), formatDate(dateStringToDate(endFiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  const alerts = computeAlerts(relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems);
  const processRows = (rows) => {
    return Object.values(groupBy(rows.map(_ => ({
      ..._,
      account_item_name: _.account_item_name || null,
      closing_balance: numeral(_.closing_balance).value(),
    })), 'account_item_name'))
      .map((rows) => {
        const [{ account_item_name: itemName, }] = rows;
        const accountItem = accountItemsByName[itemName];
        const category = getCategory(accountItem);
        return {
          account_category_name: category?.name || null,
          account_item_name: accountItem?.name || null,
          accountItem,
          category,
          sections: rows.map((row) => {
            return {
              name: row.sectionName,
              closing_balance: numeral(row.closing_balance).value(),
            };
          }),
        };
      });
  };
  const importRows = async (rows) => {
    const closingDate = endOfMonth(parseDate(yearMonth + '01', 'yyyyMMdd', new Date()));
    const endDate = formatDate(closingDate, 'yyyy-MM-dd');
    await batch(db, rows.filter(_ => !isEmpty(_.account_item_name)), (batch, row, i) => {
      const { category: { type } } = row;
      const id = [subsidiaryId, type, yearMonth, (row.account_item_name || row.account_category_name).replace(/\//g, '_')].join('__');
      batch.set(company.ref.collection('sectionTrials').doc(id), {
        ...pick(row, ['account_category_name', 'account_item_name', 'sections']),
        type,
        endDate,
        yearMonth,
        subsidiaryId,
        createdAt: new Date(),
      });
    });
  };
  const onClickClear = async () => {
    if(!window.confirm('本当にクリアしますか？')) return;

    await batch(db, sectionTrials, (batch, trial) => batch.delete(trial.ref));
    toast.success('クリアしました');
  };
  const sync = async () => {
    const startDate = formatDate(startOfMonth(parseDate(selectedStartYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const endDate = formatDate(endOfMonth(parseDate(selectedEndYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const filteredFiscalYears = relatedCompany.fiscalYears.filter(_ => _.end_date >= startDate).filter(_ => _.start_date <= endDate);
    const dateRanges = filteredFiscalYears
      .map(_ => ({ dataAreaName: _.Name, startDate: last(sortBy([_.start_date, startDate], _ => _)), endDate: first(sortBy([_.end_date, endDate])), }));
    const _sync = async (type) => {
      try {
        await syncBreakdownTrials({ type, externalType: relatedCompany.externalType, companyId: company.id, companySourceId: relatedCompany.sourceId, ownerId: findKey(company.users, { role: 'owner' }), subsidiaryId, startDate, endDate, yearMonth, dateRanges, breakdownDisplayType: 'section', });
        toast.success(`${documentTypes[type].label}の試算表を同期しました`);
      } catch(e) {
        toast.error(`${documentTypes[type].label}の試算表の同期に失敗しました`);
        console.error(e);
      }
    };
    if(relatedCompany.externalType === 'pca') {
      await serial(currentDocumentTypes.filter(_ => enabledDocumentTypes.includes(_)), async (type) => {
        await _sync(type);
      });
    } else {
      await Promise.all(currentDocumentTypes.filter(_ => enabledDocumentTypes.includes(_)).map(async (type, i) => {
        if(new Date() > new Date(relatedCompany[relatedCompany.externalType]?.expires) && i !== 0) {
          await new Promise(_ => setTimeout(_, 10000));
        }
        await _sync(type);
      }));
    }
  };
  const onClickComputeBalanceKeys = async () => {
    if(!window.confirm('科目コードの紐づいていない行を自動で紐づけます。よろしいですか？')) return;

    await Promise.all(sectionTrials.map(async (sectionTrial) => {
      await sectionTrial.ref.update({
        key: sectionTrial.key || (
          accountItemsByCategoryAndName[[sectionTrial.account_category_name, sectionTrial.account_item_name].join('__')]?.shortcut_num
        ) || null,
      });
    }));
    toast.success('科目コードを紐づけました');
  };
  useEffect(() => {
    if(!startMonthOptions.map(_ => _.value).includes(selectedStartYearMonth)) {
      selectStartYearMonth(null);
    }
  }, [selectedStartPeriod]);
  useEffect(() => {
    if(!endMonthOptions.map(_ => _.value).includes(selectedEndYearMonth)) {
      selectEndYearMonth(null);
    }
  }, [selectedEndPeriod]);

  return props.translate(
    <div className="company-individual-trial">
      <Alerts alerts={alerts} />
      <div className="mb-1 d-flex justify-content-end align-items-end gap-1">
        {
          relatedCompany.externalType !== null ? (
            <Fragment>
              <div className="d-flex gap-1 align-items-end">
                <div className="d-flex gap-1 align-items-center">
                  <div>開始</div>
                  <div style={{ width: 160, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={periodOptions.find(_ => _.value === selectedStartPeriod)}
                      options={periodOptions}
                      onChange={_ => selectStartPeriod(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                  <div style={{ width: 90, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={startMonthOptions.find(_ => _.value === selectedStartYearMonth) || null}
                      options={startMonthOptions}
                      onChange={_ => selectStartYearMonth(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <div>終了</div>
                  <div style={{ width: 160, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={periodOptions.find(_ => _.value === selectedEndPeriod)}
                      options={periodOptions}
                      onChange={_ => selectEndPeriod(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                  <div style={{ width: 90, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={endMonthOptions.find(_ => _.value === selectedEndYearMonth) || null}
                      options={endMonthOptions}
                      onChange={_ => selectEndYearMonth(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div>
                {
                  entries(pick(documentTypes, enabledDocumentTypes)).map(([documentType, { label }]) => {
                    const checked = currentDocumentTypes.includes(documentType);
                    return (
                      <FormGroup check key={documentType}>
                        <Label check>
                          <Input type="checkbox" checked={checked} onChange={_ => _.target.checked ? setCurrentDocumentTypes([...currentDocumentTypes, documentType]) : setCurrentDocumentTypes(currentDocumentTypes.filter(_ => _ !== documentType))} />
                          {label}
                        </Label>
                      </FormGroup>
                    );
                  })
                }
              </div>
              <ProgressButton process={sync} disabled={!(selectedStartPeriod && selectedStartYearMonth && selectedEndPeriod && selectedEndYearMonth) || isLockedMonth}>
                {
                  ({ isProcessing }) => {
                    return (
                      <span>
                        {!isProcessing && <span className='mr-1 fas fa-sync cursor-pointer' />}
                        試算表を同期
                      </span>
                    );
                  }
                }
              </ProgressButton>
            </Fragment>
          ) : (
            <ImportButton processRows={processRows} beforeSave={importRows} documentName="trial" fields={pick(balanceFields({ accountItems }), ['account_category_name', 'account_item_name'])} disabled={isLockedMonth} importKey={`sectionTrials__${relatedCompany.id}__${yearMonth}`} />
          )
        }
        {
          ['bugyo', 'pca'].includes(relatedCompany.externalType) && sectionTrials?.some(_ => _.key == null) && (
            <ProgressButton color="info" process={onClickComputeBalanceKeys}>
              自動で科目コードを紐づける
            </ProgressButton>
          )
        }
        <ExportButton fileName={`部門別試算表_${yearMonth}.csv`} rows={rowsForExport(relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems)} />
        <ProgressButton color="danger" process={onClickClear} disabled={isEmpty(sectionTrials) || isLockedMonth}>
          <span className="fas fa-eraser mr-1" />
          試算表をクリア
        </ProgressButton>
      </div>
      <div className="mb-1 d-flex justify-content-end gap-3">
        {
          relatedCompany.externalType !== null && entries(documentTypes).map(([documentType, { label }]) => {
            const trial = sectionTrialsByType[documentType];
            return trial != null && (
              <div className="text-muted small d-flex justify-content-end">
                {label} 最終同期日時: {formatDate((trial.syncedAt || trial.createdAt).toDate(), 'yyyy/MM/d HH:mm:ss')} ({[trial.startDate, trial.endDate].map(_ => _?.slice(0, 7).replace('-', '/')).join(' - ')}試算表)
              </div>
            );
          })
        }
      </div>
      <div>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th>カテゴリー</th>
              <th>科目</th>
              <th>連結科目</th>
              <th>部門</th>
              <th>連結セグメント</th>
              <th>残高</th>
            </tr>
          </thead>
          <tbody>
            {
              rows.map((sectionTrial) => {
                const { id, accountItem, consolidationAccountItem, account_category_name: categoryName, sections = [], } = sectionTrial;
                const isCategory = accountItem == null;

                return (
                  <Fragment key={id}>
                    {
                      sections.map((section, i) => {
                        const { name, consolidationSegment, closing_balance: closingBalance, } = section;
                        return (
                          <tr key={name}>
                            {
                              i === 0 && (
                                <Fragment>
                                  <td rowSpan={sections.length}>
                                    {categoryName}
                                  </td>
                                  <td rowSpan={sections.length}>
                                    {accountItem?.name || categoryName}
                                  </td>
                                  <td rowSpan={sections.length}>
                                    {consolidationAccountItem?.name}
                                  </td>
                                </Fragment>
                              )
                            }
                            <td>
                              {name}
                            </td>
                            <td>
                              {consolidationSegment?.name}
                            </td>
                            <td className="text-right">
                              {numberFormat(relatedCompany.currency, closingBalance)}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
