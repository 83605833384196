const { keyBy, sortBy, sumBy, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategoryNames } = require('../config');
const { getCategory, } = require('../models/accountItem');

const computeAlerts = (balances, links, consolidationJournals) => {
  const consolidationJournalsByLinkId = keyBy(consolidationJournals, 'linkId');
  const linkedItemIds = links.flatMap(_ => _.items.map(_ => _.relatedCompanyBalanceId));
  const unlinkedItems = balances.filter(_ => !linkedItemIds.includes(_.id));
  const linksWithoutConsolidationJournal = links.filter(_ => consolidationJournalsByLinkId[_.id] == null);
  return [
    unlinkedItems.length > 0 && `マッチングできていないものがあります`,
    linksWithoutConsolidationJournal.length > 0 && `仕訳が作られていないものがあります`,
  ].filter(_ => _);
};

exports.computeAlerts = computeAlerts;
