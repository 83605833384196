const { chunk, pick } = require('lodash');

const docToData = (doc) => {
  return { ...doc.data(), ...pick(doc, ['id', 'ref']), };
};

const getDocumentData = async (ref) => {
  return ref && docToData(await ref.get());
};

const getCollectionData = async (ref) => {
  return ref && (await ref.get()).docs.map(docToData);
};

const batch = async (db, data, f, chunkSize = 500) => {
  return await chunk(data, chunkSize).reduce(async (x, data, i) => {
    const prevs = await x;

    const batch = db.batch();
    const refs = data.map((_, i2) => f(batch, _, i * chunkSize + i2));
    await batch.commit();
    return [...prevs, ...refs];
  }, Promise.resolve([]));
};

exports.docToData = docToData;
exports.getDocumentData = getDocumentData;
exports.getCollectionData = getCollectionData;
exports.batch = batch;
