import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { generateRowGroups, rowsForExport, computeAlerts, } from '../../shared/lib/bs';
import { presetConsolidationAccountItems, computeDisclosureAmounts, } from '../../shared/models/consolidationAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { accountItemCategories, accountItemCategoryNames, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useDisclosureAmounts from '../hooks/useDisclosureAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';
import WithLoading from '../WithLoading';
import Alerts from '../Alerts';

const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');

export default CompanyPage(function CompanyBs (props) {
  const { company, history, location, filteredYearMonths, } = props;
  const { period, yearMonth, } = useQueryParams();
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [subsidiaries]);
  const disclosureSetting = useDocumentSubscription(company.ref.collection('disclosureSettings').doc(yearMonth), [yearMonth]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const sortedConsolidationAccountItems = useMemo(_ => sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
  const allConsolidationAccountItems = useMemo(_ => [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems], [sortedConsolidationAccountItems]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const { items, } = useDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true, null, false, disclosureSetting);
  const rowGroups = useMemo(_ => items && generateRowGroups(items), [items]);
  const alerts = useMemo(_ => items && computeAlerts(items), [items]);

  return props.translate(
    <div className="company-account-item-mappings container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結財務諸表（連結BS、PL、SS、CI）を確認する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結BS</h4>
        </div>
        <WithLoading loading={!rowGroups}>
          <Alerts alerts={alerts} />
          <div className="d-flex row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="mb-2 d-flex align-items-end justify-content-end gap-1">
                <ExportButton fileName="連結BS.csv" rows={rowsForExport(items)} />
              </div>
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ minWidth: 180 }}>開示科目</th>
                    <th>
                      金額
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rowGroups?.map((rowGroup) => {
                      return (
                        <CategoryRows key={rowGroup.category.name} {...rowGroup} />
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </WithLoading>
      </div>
    </div>
  );
});

function CategoryRows(props) {
  const { category, rows, } = props;

  return (
    <Fragment key={category.name}>
      {
        rows.map(({ item, itemType, amount }, i) => {
          return (
            <tr key={i} className={itemType === 'category' && 'font-weight-bold'}>
              <td style={{ textIndent: (category.indent() + (itemType === 'category' ? 0 : 1)) * 18 }}>
                {item.group_name || item.name}
              </td>
              <td className="text-right">
                {integerFormat(amount)}
              </td>
            </tr>
          );
        })
      }
    </Fragment>
  );
}
