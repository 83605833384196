const { zip, keyBy, sortBy, groupBy, sumBy, get, sum, omit, } = require('lodash');
const numeral = require('numeral');

const { floatFormat } = require('../util');
const { cfAccountItemCategories, } = require('../config');

const { keys, entries, } = Object;
const { abs, round } = Math;

const generateRowGroups = (cfChange, cfAccountItems, cfChangeTypes, companiesAmountItems) => {
  const companiesAmountItemsByItemId = keyBy(companiesAmountItems, 'item.id');
  const cfAccountItemsGroupedByCategoryKey = groupBy(cfAccountItems, 'cfAccountItemCategoryKey');
  const cfChangeValuesGroupedByCfAccountItemId = groupBy(Object.values(cfChange?.values || {}).filter(_ => isFinite(_?.amount)), 'cfAccountItemId');
  return entries(cfAccountItemCategories).map(([cfAccountItemCategoryKey]) => {
    const cfAccountItemCategory = { key: cfAccountItemCategoryKey, ...cfAccountItemCategories[cfAccountItemCategoryKey] };
    const rows = get(cfAccountItemsGroupedByCategoryKey, cfAccountItemCategoryKey, []).map((cfAccountItem) => {
      const cfChangeValues = cfChangeValuesGroupedByCfAccountItemId[cfAccountItem.id] || [];
      const cfChangeColumns = cfChangeTypes.map((cfChangeType) => {
        return {
          cfChangeType,
          amount: sumBy(cfChangeValues.filter(_ => _.cfChangeTypeId === cfChangeType.id), 'amount'),
        };
      });
      const totalAmount = sumBy(cfChangeColumns, 'amount');
      const wsItems = (cfAccountItem.consolidationAccountItemIds || []).map(_ => companiesAmountItemsByItemId[_]);
      const wsAmount = sumBy(wsItems.filter(_ => _), (wsItem) => {
        const { accountItemCategory, conclusiveAmount } = wsItem;
        // NOTE: 絶対値比較になるので貸借相殺さえできればよい
        return conclusiveAmount * ({ debit: 1, credit: -1 })[accountItemCategory.direction];
      });
      const diffFromWs = Math.abs(totalAmount) - Math.abs(wsAmount);
      return {
        cfAccountItem,
        cfChangeColumns,
        totalAmount,
        wsItems,
        wsAmount,
        diffFromWs,
      };
    });
    return { cfAccountItemCategory, rows };
  });
};

const rowsForExport = (cfChange, sortedCfAccountItems, sortedCfChangeTypes, disclosureSetting, generalAmount) => () => {
  return [
    ...generateRowGroups(cfChange, sortedCfAccountItems, sortedCfChangeTypes).flatMap(({ cfAccountItemCategory, rows }) => {
      return rows.map(({ cfAccountItem, cfChangeColumns, totalAmount, }, i) => {
        const disclosesAsOther = disclosureSetting?.otherCfAccountItems?.includes(cfAccountItem.id);

        return {
          cfAccountItemName: cfAccountItem.name,
          accountCode: cfAccountItem.accountCode,
          ...(
            cfChangeColumns.reduce((x, cfChangeColumn) => {
              const { cfChangeType, amount, } = cfChangeColumn;
              return {
                ...x,
                [cfChangeType.name]: amount,
              };
            }, {})
          ),
          totalAmount,
          disclosesAsOther,
        };
      });
    }),
    {
      cfAccountItemName: '現金及び現金同等物の期首残高',
      accountCode: 'genkin_kishu',
      totalAmount: generalAmount?.openingCashBalance,
    },
  ];
};

const computeAlerts = (...args) => {
  const rowGroups = generateRowGroups(...args);
  return [
    ...rowGroups.flatMap(_ => _.rows).filter(_ => _.wsItems.filter(_ => _).length > 0 && Math.abs(_.diffFromWs) > 0).map((row) => {
      const { cfAccountItem, wsItems, diffFromWs, } = row;
      return `CF科目(${cfAccountItem.name})と連結科目(${wsItems.map(_ => _.item.name).join(',')})が不一致です。(差額:  ${numeral(Math.abs(diffFromWs)).format()})`;
    }),
  ].filter(_ => _);
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
