import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { omitBy, orderBy, get, sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import numeral from 'numeral';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { generateRowsGroupedByCategory, rowsForExport, sumRow, computeAlerts, } from '../../shared/lib/cf';
import { presetCfAccontItems, } from '../../shared/models/cfAccountItem';
import { cfAccountItemCategories, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import usePkgItemGroups from '../hooks/usePkgItemGroups';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';
import ContentEditable from '../ContentEditable';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const { abs } = Math;
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);

export default CompanyPage(function CompanyCf (props) {
  const { company, role, history, location, prevEndYearMonth, isLockedMonth, filteredYearMonths, } = props;
  const { period, yearMonth, } = useQueryParams();
  const disclosureSetting = useDocumentSubscription(company.ref.collection('disclosureSettings').doc(yearMonth), [yearMonth]);
  const generalAmount = useDocumentSubscription(company.ref.collection('amounts').doc(period), [company, period]);
  const cfChangeTypes = useCollectionSubscription(company.ref.collection('cfChangeTypes').orderBy('name'), [company]);
  const cfAccountItems = useCollectionSubscription(company.ref.collection('cfAccountItems').orderBy('name'), [company]);
  const allCfAccountItems = useMemo(_ => [...cfAccountItems, ...presetCfAccontItems], [cfAccountItems]);
  const sortedCfAccountItems = useMemo(_ => orderBy(allCfAccountItems, [_ => cfAccountItemCategoryKeys.indexOf(_.cfAccountItemCategoryKey), 'index'], ['asc', 'asc']), [allCfAccountItems]);
  const cfAccountItemsById = keyBy(sortedCfAccountItems, 'id');
  const cfChangeRef = company.ref.collection('cfChanges').doc(yearMonth);
  const cfChange = useDocumentSubscription(cfChangeRef, [yearMonth]);
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'), [company]);
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [subsidiaries]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'), [company]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const { itemGroups: cashGroups } = usePkgItemGroups(company, relatedCompanies, accountItems, 'cashes', [], 'closing', [], filteredYearMonths);
  const individualAdjustmentJournals = useCollectionSubscription(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth), [yearMonth], { initialItems: null });
  const consolidationJournals = useCollectionSubscription(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth), [yearMonth], { initialItems: null });
  
  const rowsGroupedByCategory = useMemo(_ => generateRowsGroupedByCategory(cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting), [cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting]);
  const onFixOpeningCashBalance = async (value) => {
    await company.ref.collection('amounts').doc(period).set({ openingCashBalance: numeral(value).value() });
  };

  const exchangeRate = useDocumentSubscription(company.ref.collection('exchangeRates').doc(yearMonth), [yearMonth]);
  const alerts = useMemo(_ =>
    cashGroups && individualAdjustmentJournals && consolidationJournals && computeAlerts(cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount, cashGroups, company, individualAdjustmentJournals, consolidationJournals, relatedCompanies, accountItems, consolidationAccountItems, exchangeRate),
    [cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount, cashGroups, company, individualAdjustmentJournals, consolidationJournals, relatedCompanies, accountItems, consolidationAccountItems, exchangeRate]
  );

  return props.translate(
    <div className="company-cf container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結CFを確認する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結CF</h4>
        </div>
        <Alerts alerts={alerts} />
        <div className="d-flex row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div className="mb-2 d-flex justify-content-end">
              <ExportButton fileName="連結CF.csv" rows={rowsForExport(cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount)} />
            </div>
            <table className="table table-bordered table-sm">
              <tbody className="thead-light">
                <tr>
                  <th>営業活動によるキャッシュ・フロー</th>
                  <th className="text-center">金額</th>
                </tr>
                {
                  get(rowsGroupedByCategory, ['sales'], []).map((row, i) => {
                    return <Row {...row} key={i} />;
                  })
                }
                <tr>
                  <th>小計</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'sales'))}</th>
                </tr>
                {
                  get(rowsGroupedByCategory, ['salesBottom'], []).map((row, i) => {
                    return <Row {...row} key={i} />;
                  })
                }
                <tr>
                  <th>営業活動によるキャッシュ・フロー</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'sales', 'salesBottom'))}</th>
                </tr>
                <tr>
                  <th>投資活動によるキャッシュ・フロー</th>
                  <th></th>
                </tr>
                {
                  get(rowsGroupedByCategory, ['investment'], []).map((row, i) => {
                    return <Row {...row} key={i} />;
                  })
                }
                <tr>
                  <th>投資活動によるキャッシュ・フロー</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'investment'))}</th>
                </tr>
                <tr>
                  <th>財務活動によるキャッシュ・フロー</th>
                  <th></th>
                </tr>
                {
                  get(rowsGroupedByCategory, ['finance'], []).map((row, i) => {
                    return <Row {...row} key={i} />;
                  })
                }
                <tr>
                  <th>財務活動によるキャッシュ・フロー</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'finance'))}</th>
                </tr>
                <tr>
                  <th>現金及び現金同等物に係る換算差額</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'cashExchangeDiff'))}</th>
                </tr>
                <tr>
                  <th>現金及び現金同等物の増加（減少）額</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'sales', 'salesBottom', 'investment', 'finance', 'cashExchangeDiff'))}</th>
                </tr>
                <tr>
                  <th>現金及び現金同等物の期首残高</th>
                  <th className="text-right">
                    <ContentEditable value={integerFormat(generalAmount?.openingCashBalance)} onFix={onFixOpeningCashBalance} disabled={isLockedMonth || ['reader'].includes(role)} />
                  </th>
                </tr>
                {
                  get(rowsGroupedByCategory, ['openingCash'], []).map((row, i) => {
                    const { cfAccountItem, totalAmount, } = row;
                    return (
                      <tr key={i}>
                        <th>{cfAccountItem?.name}</th>
                        <th className="text-right">{integerFormat(totalAmount)}</th>
                      </tr>
                    );
                  })
                }
                <tr>
                  <th>現金及び現金同等物の期末残高</th>
                  <th className="text-right">{integerFormat(sumRow(rowsGroupedByCategory, 'sales', 'salesBottom', 'investment', 'finance', 'cashExchangeDiff', 'openingCash') + numeral(generalAmount?.openingCashBalance).value())}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

function Row(props) {
  const { cfAccountItem, totalAmount, } = props;
  return (
    <tr>
      <td>
        {cfAccountItem.name}
      </td>
      <td className="text-right">
        {integerFormat(totalAmount)}
      </td>
    </tr>
  );
}
