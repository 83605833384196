import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react';

if(['production', 'staging'].includes(import.meta.env.VITE_ENV)) {
  Bugsnag.start({
    apiKey: '4e761910d9d92b187f19e978ffff60c2',
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: import.meta.env.VITE_ENV || 'development',
  });
}
