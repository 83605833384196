const { isEmpty, omit, } = require('lodash');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const { entries } = Object;

const changeFieldNames = [
  'increase',
  'reduction',
  'compensation',
  'execution',
  'other',
];

const fields = ({ accountItems = [], otherItems, } = {}) => {
  const unique = (v, s) => otherItems?.every(o => ['accountItemId'].some(_ => o[_] !== s[_]));
  return {
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
        unique,
      },
    },
    opening: {
      label: '期首',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    increase: {
      label: '増資',
      type: 'float',
    },
    reduction: {
      label: '減資',
      type: 'float',
    },
    compensation: {
      label: '欠損填補',
      type: 'float',
    },
    execution: {
      label: '権利行使',
      type: 'float',
    },
    other: {
      label: 'その他',
      type: 'float',
    },
  };
};

exports.fields = fields;
exports.changeFieldNames = changeFieldNames;
