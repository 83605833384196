const { keyBy, sortBy, sumBy, groupBy, } = require('lodash');

const { floatFormat } = require('../util');
const { accountItemCategories, } = require('../config');
const { getCategory, } = require('../models/accountItem');

const generateRowGroups = (consolidationAccountItems, accountItems, company, relatedCompanies, showsAll) => {
  const consolidationAccountItemsGroupedByCategory = groupBy(consolidationAccountItems, _ => _.account_category);
  const accountItemsGroupedByConsolidationAccountItemId = groupBy(accountItems, 'consolidationAccountItemId');
  return accountItemCategories.map((accountItemCategory) => {
    const rows = sortBy((consolidationAccountItemsGroupedByCategory[accountItemCategory.name] || []).map((consolidationAccountItem) => {
      const accountItems = accountItemsGroupedByConsolidationAccountItemId[consolidationAccountItem.id] || [];
      const columns = relatedCompanies.map((_company) => {
        const _accountItems = accountItems.filter(_ => _.subsidiaryId === (_company === company ? null : _company.id));
        return {
          company: _company,
          accountItems: _accountItems,
        };
      });
      return {
        ...consolidationAccountItem,
        columns,
      };
    }), 'index');
    let filteredRows = rows;
    if(showsAll) {
      filteredRows = filteredRows.filter(_ => _.columns.some(_ => _.accountItems.length > 0));
    }
    return {
      accountItemCategory,
      rows,
      filteredRows,
    };
  });
};

const rowsForExport = (...args) => () => {
  return generateRowGroups(...args).flatMap(({ accountItemCategory, rows }) => {
    return rows.map((row) => {
      const { group_name: groupName, name, shortcut_num: accountCode, columns, } = row;
      return {
        category: accountItemCategory.name,
        groupName,
        name,
        accountCode,
        ...(
          columns.reduce((x, { company, accountItems, }) => {
            return {
              ...x,
              [company.display_name]: accountItems.map(_ => _.name).join(','),
            };
          }, {})
        ),
      };
    });
  });
};

const optionLabel = (accountItem, company) => {
  return accountItem.name + (_ => _ ? ` [${_}]` : '')(company?.[`integration_account_id_${accountItem.id}`] || accountItem.shortcut_num);
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
exports.optionLabel = optionLabel;
