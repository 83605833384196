import React, { Fragment, Component } from 'react';
import { Link, } from 'react-router-dom';
import { useToggle, useAsync, } from 'react-use';
import { get, sumBy, pick, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { getCategory, } from '../../shared/models/accountItem';
import { changeFieldNames as debtChangeFieldNames, fields as debtFields, } from '../../shared/models/debt';
import { changeFieldNames as bondChangeFieldNames, fields as bondFields, } from '../../shared/models/bond';
import { changeFieldNames as capitalChangeFieldNames, fields as capitalFields, } from '../../shared/models/capital';
import { changeFieldNames as treasuryStockChangeFieldNames, fields as treasuryStockFields, } from '../../shared/models/treasuryStock';
import { changeFieldNames as stockOptionChangeFieldNames, fields as stockOptionFields, } from '../../shared/models/stockOption';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import PkgPage from '../hocs/PkgPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import PkgChangesTableSection from '../PkgChangesTableSection';
import PkgSimpleTableSection from '../PkgSimpleTableSection';

const { keys, entries, } = Object;

export default PkgPage(function CompanyPkgInvestments (props) {
  const { company, exchangeRate, period, yearMonth, relatedCompanies, accountItemsById, consolidationAccountItemsById, location, } = props;
  const pledgedAssets = useCollectionSubscription(company.ref.collection('pledgedAssets').where('yearMonth', '==', yearMonth), [company, yearMonth]);
  const pledgedAssetsGroupedBySubsidiaryId = groupBy(pledgedAssets, _ => _.subsidiaryId || company.id);

  return props.translate(
    <div className="company-pkg-fixed-assets">
      <PkgChangesTableSection
        section="財務関連"
        title="外部借入"
        collectionName="debts"
        path="finances"
        fields={debtFields}
        changeFieldNames={debtChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="財務関連"
        title="社債"
        collectionName="bonds"
        path="finances"
        fields={bondFields}
        changeFieldNames={bondChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgSimpleTableSection
        section="財務関連"
        title="未払利息・前払利息"
        collectionName="expenseInterestItems"
        path="finances"
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="財務関連"
        title="資本"
        collectionName="capitals"
        path="finances"
        fields={capitalFields}
        changeFieldNames={capitalChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="財務関連"
        title="自己株式増減"
        collectionName="treasuryStocks"
        path="finances"
        fields={treasuryStockFields}
        changeFieldNames={treasuryStockChangeFieldNames}
        {...props}
      />
      <hr className="my-5" />
      <PkgChangesTableSection
        section="財務関連"
        title="新株予約権"
        collectionName="stockOptions"
        path="finances"
        fields={stockOptionFields}
        changeFieldNames={stockOptionChangeFieldNames}
        {...props}
      />
    </div>
  );
});
