const { chunk, last, sumBy, mergeWith, unzip, zip, keyBy, sortBy, groupBy, } = require('lodash');
const numeral = require('numeral');

const { numberFormat, integerFormat, floatFormat } = require('../util');
const { accountItemCategories, accountItemCategoryNames } = require('../config');

const { keys, entries, } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');
const aggregationTypes = {
  individual: {
    label: '個別',
    amountName: 'closingBalance',
  },
  individualAdjustment: {
    label: '個別修正',
    amountName: 'adjustmentAmount',
  },
  individualAdjusted: {
    label: '換算後',
    amountName: 'exchangedAmount',
  },
  consolidation: {
    label: '連結仕訳',
    amountName: 'consolidationAmount',
  },
  consolidated: {
    label: '連結消去後',
    amountName: 'consolidatedAmount',
  },
};
const formatByAggregationType = _ => ['individual', 'individualAdjustment'].includes(_) ? numberFormat : (_, v) => integerFormat(v);
const metrics = {
  current: {
    label: _ => '当期',
    format: (v, a, r) => formatByAggregationType(a)(r?.currency, v),
    compute: (amount) => {
      return amount;
    },
  },
  prev: {
    label: _ => comparisons[_.comparison]?.label,
    format: (v, a, r) => formatByAggregationType(a)(r?.currency, v),
    compute: (amount, comparisonAmount) => {
      return comparisonAmount;
    },
  },
  change: {
    label: _ => '増減額',
    format: (v, a, r) => formatByAggregationType(a)(r?.currency, v),
    compute: (amount, comparisonAmount) => {
      return amount - comparisonAmount;
    },
  },
  changeRate: {
    label: _ => '増減率',
    format: _ => numeral(_).format('0,0.0%'),
    compute: (amount, comparisonAmount) => {
      return metrics.change.compute(amount, comparisonAmount) / comparisonAmount;
    },
  },
};

const generateQuarters = (documentType, comparison, allMonthColumns, compute) => {
  const allQuarters = chunk(allMonthColumns, 3).map((monthColumns, quarterIndex) => {
    const [{ isCurrent, isCurrentFirst, }] = monthColumns;
    const quarterColumn = {
      amount: _ => {
        return (documentType === 'bs' || isCurrentFirst) ? last(monthColumns)?.amount : (
          last(monthColumns)?.amount - (last(allQuarters[quarterIndex - 1]?.monthColumns)?.amount || 0)
        );
      },
      comparisonAmount: _ => {
        return ({
          prevYear: _ => {
            return (documentType === 'bs' || isCurrentFirst) ? last(monthColumns)?.comparisonAmount : (
              last(monthColumns)?.comparisonAmount - (last(allQuarters[quarterIndex - 1]?.monthColumns)?.comparisonAmount || 0)
            );
          },
          prevMonth: _ => allQuarters[quarterIndex - 1]?.quarterColumn.amount() || 0,
        })[comparison]?.();
      },
      value: _ => {
        return compute(quarterColumn.amount(), quarterColumn.comparisonAmount());
      },
    };
    return {
      isCurrent,
      monthColumns,
      quarterColumn,
    };
  });
  return allQuarters;
};

const generateRowGroups = (relatedCompanies, yearMonths, monthItems, prevYearMonthItems, documentType, comparison = 'prevYear') => {
  const accountItemCategories = accountItemCategoriesGroupedByType[documentType];
  const items = unzip([...prevYearMonthItems, ...monthItems]).map((allMonthItemColumns) => {
    const { itemType, accountItemCategory } = allMonthItemColumns[0];
    return {
      itemType,
      accountItemCategory,
      allMonthItemColumns,
    }
  });
  const { consolidationAccountItem: nonCategoryItems = [], category: categoryItems = [], } = groupBy(items, 'itemType');
  const nonCategoryItemsGroupedByCategory = groupBy(nonCategoryItems, 'accountItemCategory.name');
  const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
  const rowGroups = accountItemCategories.map((category) => {
    const directItems = nonCategoryItemsGroupedByCategory[category.name] || [];
    const categoryItem = categoryItemsByCategory[category.name];
    return {
      category,
      itemRowGroups: [...directItems, categoryItem].map(({ allMonthItemColumns }) => {
        const { key, item, } = allMonthItemColumns[0];
        const currentMonthItemColumns = allMonthItemColumns.slice(prevYearMonthItems.length);
        const relatedCompanyRowGroups = relatedCompanies.map((relatedCompany, relatedCompanyIndex) => {
          const aggregationTypeRowGroups = entries(aggregationTypes).map(([aggregationType]) => {
            const { amountName } = aggregationTypes[aggregationType];
            const metricRows = entries(metrics).map(([metric, { compute }]) => {
              const allMonthColumns = allMonthItemColumns.map((monthItemColumn, monthIndex) => {
                const isCurrent = monthIndex > prevYearMonthItems.length - 1;
                const isCurrentFirst = isCurrent && monthIndex === prevYearMonthItems.length;
                const comparisonMonthIndex = monthIndex - ({ prevYear: 12, prevMonth: 1})[comparison];
                const comparisonMonthItemColumn = allMonthItemColumns[comparisonMonthIndex];
                const amount = monthItemColumn.companyColumns[relatedCompanyIndex][amountName];
                const prevMonthAmount = allMonthItemColumns[monthIndex - 1]?.companyColumns[relatedCompanyIndex][amountName] || 0;
                const displayAmount = documentType === 'bs' ? amount : amount - (isCurrentFirst ? 0 : prevMonthAmount);
                const isComparisonCurrentFirst = isCurrent && comparisonMonthIndex === prevYearMonthItems.length;
                const comparisonAmount = comparisonMonthItemColumn?.companyColumns[relatedCompanyIndex][amountName] || 0;
                const comparisonPrevMonthAmount = allMonthItemColumns[comparisonMonthIndex - 1]?.companyColumns[relatedCompanyIndex][amountName] || 0;
                const comparisonDisplayAmount = documentType === 'bs' ? comparisonAmount : comparisonAmount - (isComparisonCurrentFirst ? 0 : comparisonPrevMonthAmount);
                return {
                  isCurrent,
                  isCurrentFirst,
                  amount,
                  prevMonthAmount,
                  displayAmount,
                  comparisonAmount,
                  comparisonPrevMonthAmount,
                  comparisonDisplayAmount,
                  value: _ => compute(displayAmount, comparisonDisplayAmount),
                };
              });
              const monthColumns = allMonthColumns.filter(_ => _.isCurrent);
              const allQuarters = generateQuarters(documentType, comparison, allMonthColumns, compute);
              const quarters = allQuarters.filter(_ => _.isCurrent);
              return {
                metric,
                monthColumns,
                allMonthColumns,
                quarters,
                isAllNone: monthColumns.every(_ => !(Math.abs(_.value()) > 0)),
              };
            });
            const sumAmount = sumBy(metricRows[0].monthColumns, _ => _.value());
            const comparisonSumAmount = sumBy(metricRows[1].monthColumns, _ => _.value())
            const summaryComputedMetricRows = metricRows.map((metricRow) => {
              const { metric } = metricRow;
              const shouldNaN = metric !== 'current' && comparison === 'prevMonth';
              const summaryColumns = [
                {
                  type: 'total',
                  value: _ => shouldNaN ? NaN : metrics[metric].compute(sumAmount, comparisonSumAmount),
                },
              ];
              return {
                ...metricRow,
                summaryColumns,
              };
            });
            return {
              aggregationType,
              metricRows: summaryComputedMetricRows,
              isAllNone: metricRows.every(_ => _.isAllNone),
            };
          });
          return {
            relatedCompany,
            aggregationTypeRowGroups,
            isAllNone: aggregationTypeRowGroups.every(_ => _.isAllNone),
          };
        });
        return {
          key,
          item,
          relatedCompanyRowGroups,
          isAllNone: relatedCompanyRowGroups.every(_ => _.isAllNone),
        };
      })
        .filter(_ => !_.isAllNone),
    };
  });
  return rowGroups;
};

const computeItemSummaryRowGroup = (documentType, comparison, yearMonths, prevYearMonths, relatedCompanyRowGroups) => {
  const allMonthConsolidatedAmounts = [...prevYearMonths, ...yearMonths].map((yearMonth, yearMonthIndex) => {
    return {
      ...['amount', 'prevMonthAmount', 'displayAmount', 'comparisonAmount', 'comparisonPrevMonthAmount', 'comparisonDisplayAmount'].reduce((x, y) => {
        return {
          ...x,
          [y]: sumBy(relatedCompanyRowGroups.map(_ => last(_.aggregationTypeRowGroups).metricRows[0].allMonthColumns[yearMonthIndex]), y),
        };
      }, {}),
    };
  });
  const itemSummaryRowGroup = {
    relatedCompany: null,
    aggregationTypeRowGroups: [
      (() => {
        const metricRows = entries(metrics).map(([metric, { compute }], metricIndex) => {
          const allMonthColumns = [...prevYearMonths, ...yearMonths].map((yearMonth, yearMonthIndex) => {
            const isCurrent = yearMonthIndex > prevYearMonths.length - 1;
            const isCurrentFirst = isCurrent && yearMonthIndex === prevYearMonths.length;
            const { amount, comparisonAmount, displayAmount, comparisonDisplayAmount, } = allMonthConsolidatedAmounts[yearMonthIndex];
            return {
              isCurrent,
              isCurrentFirst,
              amount,
              comparisonAmount,
              value: _ => compute(displayAmount, comparisonDisplayAmount),
            };
          });
          const monthColumns = allMonthColumns.filter(_ => _.isCurrent);
          const allQuarters = generateQuarters(documentType, comparison, allMonthColumns, metrics[metric].compute);
          const quarters = allQuarters.filter(_ => _.isCurrent);
          return {
            metric,
            monthColumns,
            quarters,
          };
        });
        const sumAmount = sumBy(metricRows[0].monthColumns, _ => _.value());
        const comparisonSumAmount = sumBy(metricRows[1].monthColumns, _ => _.value());
        const summaryComputedMetricRows = metricRows.map((metricRow) => {
          const { metric } = metricRow;
          const shouldNaN = metric !== 'current' && comparison === 'prevMonth';
          const summaryColumns = [
            {
              type: 'total',
              value: _ => shouldNaN ? NaN : metrics[metric].compute(sumAmount, comparisonSumAmount),
            },
          ];
          return {
            ...metricRow,
            summaryColumns,
          };
        });
        return {
          aggregationType: 'consolidated',
          metricRows: summaryComputedMetricRows,
        };
      })()
    ],
  };
  return itemSummaryRowGroup;
};

const displayColumnTypes = {
  month: { label: '月', },
  quarter: { label: '四半期', },
};

const comparisons = {
  prevYear: { label: '前期', },
  prevMonth: { label: '前月(前四半期)', },
};

exports.aggregationTypes = aggregationTypes;
exports.metrics = metrics;
exports.generateRowGroups = generateRowGroups;
exports.computeItemSummaryRowGroup = computeItemSummaryRowGroup;
exports.displayColumnTypes = displayColumnTypes;
exports.comparisons = comparisons;
