const { pick, groupBy, keyBy, sortBy, omit, } = require('lodash');

const { accountItemCategories } = require('../config');
const { getCategory, } = require('../models/accountItem');
const { uniqAccountName, } = require('../util');

const generateRows = (relatedCompany, trials, accountItems, consolidationAccountItems) => {
  const accountItemsByKey = keyBy(accountItems, _ => uniqAccountName(relatedCompany, _.name, _.shortcut_num));
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const balances = sortBy(trials, 'type').map(trial => trial.balances.map(_ => ({ ..._, trial }))).flat();
  const rows = balances.map((balance) => {
    const { account_item_name: accountItemName, account_category_name: accountCategoryName, key, } = balance;
    const accountItem = accountItemsByKey[uniqAccountName(relatedCompany, accountItemName, key)];
    const consolidationAccountItem = consolidationAccountItemsById[accountItem?.consolidationAccountItemId];
    return {
      ...balance,
      accountItem,
      consolidationAccountItem,
      category: getCategory(accountItem),
    };
  });
  const rowsGroupedByCategory = groupBy(rows, 'account_category_name');
  const sortedRows = accountItemCategories.flatMap((category) => {
    return sortBy(rowsGroupedByCategory[category.name], _ => _.accountItem?.index);
  });
  return sortedRows;
};

const rowsForExport = (relatedCompany, trials, accountItems, consolidationAccountItems) => () => {
  return generateRows(relatedCompany, trials, accountItems, consolidationAccountItems).map((row) => {
    return {
      ...pick(row, ['account_category_name', 'account_item_name', 'opening_balance', 'closing_balance', ]),
    };
  });
};

const computeAlerts = (...args) => {
  const rows = generateRows(...args);
  const noConsolidationAccountItemRows = rows.filter(_ => _.accountItem != null && _.consolidationAccountItem == null);
  return [
    noConsolidationAccountItemRows.length > 0 && `連結科目がマッピングされていない科目があります(${noConsolidationAccountItemRows.map(_ => _.accountItem?.name).join(', ')})`,
  ].filter(_ => _);
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
