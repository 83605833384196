const { get, mapValues, last, sumBy, groupBy, keyBy, sortBy, omit, isEmpty, } = require('lodash');

const { accountItemCategories } = require('../config');
const { fieldDisplayValue, integerFormat, integerChangeFormat, } = require('../util');
const { cfMappingItemFields, } = require('../models/consolidationAccountItem');
const { generalSegment, } = require('../models/consolidationSegment');
const { noneSection, } = require('../models/section');

const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRowGroups = (items, consolidationCalculationItems, allConsolidationSegments, showsAll) => {
  const categoryItems = items.filter(_ => _.itemType === 'category');
  const itemsGroupedByCategory = groupBy(items, 'accountItemCategory.name');
  const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
  const consolidationCalculationItemsByKey = keyBy(consolidationCalculationItems, 'key');
  return ['bs', 'pl', 'cr'].map((type) => {
    const accountItemCategories = accountItemCategoriesGroupedByType[type];
    return {
      type,
      rowGroups: accountItemCategories.map((category) => {
        const sign = ({ debit: 1, credit: -1 })[category.direction];
        const directItems = (itemsGroupedByCategory[category.name] || []).filter(_ => _.itemType === 'consolidationAccountItem');
        const categoryItem = categoryItemsByCategory[category.name];
        const rowGroups = [...directItems, categoryItem].map((item) => {
          const { companyItems, segmentColumns, key, } = item;
          const consolidationCalculationItem = consolidationCalculationItemsByKey[key];
          const companyRows = companyItems.map(({ company, segmentColumns }) => {
            return {
              rowType: 'company',
              metricLabel: '個別',
              companyName: company.display_name,
              segmentColumns: segmentColumns.map(_ => ({ ..._, amount: _.exchangedClosingBalance })),
            };
          });
          const individualAdjustmentJournalRow = {
            rowType: 'individualAdjustmentJournal',
            metricLabel: '個別修正',
            segmentColumns: allConsolidationSegments.map((segment, i) => {
              return {
                segment,
                amount: sumBy(companyItems, ({ segmentColumns }) => {
                  const segmentColumn = segmentColumns[i];
                  return segmentColumn.exchangedAdjustmentAmount;
                }),
              };
            })
          };
          const consolidationJournalRow = {
            rowType: 'consolidationJournal',
            metricLabel: '連結仕訳',
            segmentColumns: segmentColumns.map(({ segment, consolidationAmount, }) => {
              return {
                segment,
                amount: consolidationAmount,
              };
            })
          };
          const consolidatedRow = {
            rowType: 'consolidated',
            metricLabel: '連結消去後',
            segmentColumns: allConsolidationSegments.map((segment, i) => {
              return {
                segment,
                amount: sumBy([...companyRows, individualAdjustmentJournalRow, consolidationJournalRow], _ => _.segmentColumns[i].amount),
              };
            })
          };
          const intersegmentRow = {
            rowType: 'intersegment',
            metricLabel: 'セグメント間',
            segmentColumns: segmentColumns.map(({ segment, consolidationIntersegmentAmount, }) => {
              const amount = segment.id === 'general' ? sumBy(segmentColumns, 'consolidationIntersegmentAmount') : consolidationIntersegmentAmount * -1;
              return {
                segment,
                amount,
              };
            })
          };
          const totalRow = {
            rowType: 'total',
            metricLabel: '合計',
            segmentColumns: allConsolidationSegments.map((segment, i) => {
              return {
                segment,
                amount: sumBy([...companyRows, individualAdjustmentJournalRow, consolidationJournalRow, intersegmentRow], _ => _.segmentColumns[i].amount),
              };
            }),
          };
          return {
            item,
            rows: [
              ...companyRows,
              individualAdjustmentJournalRow,
              consolidationJournalRow,
              consolidatedRow,
              intersegmentRow,
              totalRow,
            ].map((row) => {
              const { rowType, segmentColumns } = row;
              const segmentWithoutGeneralColumns = segmentColumns.filter(_ => _.segment.id !== 'general').map(_ => ({ ..._, columnType: 'segment' }));
              const totalWithoutGeneralColumn = {
                columnType: 'totalWithoutGeneral',
                amount: sumBy(segmentWithoutGeneralColumns, 'amount'),
              };
              const generalColumn = {
                columnType: 'general',
                ...segmentColumns.find(_ => _.segment.id === 'general'),
              };
              const totalColumn = {
                columnType: 'total',
                amount: sumBy(segmentColumns, 'amount'),
              };
              const consolidationCalculationColumn = {
                columnType: 'consolidationCalculation',
                amount: rowType === 'total' ? consolidationCalculationItem?.conclusiveAmount : null,
              };
              const diffColumn = {
                columnType: 'diff',
                format: integerChangeFormat,
                amount: rowType === 'total' ? (sumBy(segmentColumns, 'amount') - consolidationCalculationItem?.conclusiveAmount || 0) : null,
              };
              return {
                ...row,
                columns: [
                  ...segmentWithoutGeneralColumns,
                  totalWithoutGeneralColumn,
                  generalColumn,
                  totalColumn,
                  consolidationCalculationColumn,
                  diffColumn,
                ].map((column, i) => {
                  const { columnType, } = column;
                  const label = columnType === 'segment' ? allConsolidationSegments[i]?.name : columnType;
                  return {
                    ...column,
                    label,
                  };
                }),
              };
            }),
          };
        }).filter(_ => showsAll || last(_.rows).columns.some(_ => Math.abs(_.amount) > 0));
        return {
          category,
          rowGroups,
        };
      }),
    };
  });
};

const rowsForExport = (...args) => () => {
  return generateRowGroups(...args).flatMap(({ type, rowGroups }) => {
    return rowGroups.flatMap(({ category, rowGroups, }) => {
      return rowGroups.flatMap(({ item: { item, itemType, }, rows, }, itemIndex) => {
        return rows.map((row) => {
          const { rowType, metricLabel, companyName, columns = [], } = row;
          return {
            category: itemType === 'category' ? item.name : '',
            consolidationAccountItemName: itemType === 'consolidationAccountItem' ? item.name : '',
            metricLabel,
            companyName,
            ...(
              columns.reduce((x, column, i) => {
                const { columnType, label, amount, } = column;
                return {
                  ...x,
                  [label]: amount,
                };
              }, {})
            ),
          };
        })
      });
    });
  });
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
