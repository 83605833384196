import React from 'react';
import { omit, isFunction, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';
import classnames from 'classnames';

import useLocale from './hooks/useLocale';

export default function AddButton ({ initialValues, validateOnSubmit = _ => true, itemRef, FormModal, formProps, processValues = _ => _, label = '追加', iconClassName = 'fas fa-fw fa-plus', onFinish, ...extraProps }) {
  const [showsModal, toggleModal] = useToggle(false);
  const { translate } = useLocale();
  const onSubmit = async (values) => {
    const ref = isFunction(itemRef) ? itemRef(values) : itemRef;
    const { id } = ref;
    if(!(await validateOnSubmit({ id, ...values }))) return;

    try {
      await ref.set({ ...omit(await processValues({ id, ...values }), ['id', 'ref']), createdAt: new Date(), });
      onFinish && await onFinish({ id, ...values });
      toast.success('追加しました');
      toggleModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color="primary" onClick={_ => toggleModal(true)} {...extraProps} data-operation-type="write">
      <span className={classnames(iconClassName, 'mr-1')} />
      {label}
      {showsModal && <FormModal isOpen values={initialValues} onClickClose={_ => toggleModal(false)} onSubmit={onSubmit} {...formProps} />}
    </Button>
  );
};
