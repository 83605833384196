import { useState, useEffect } from 'react';
import { useToggle, } from 'react-use';

export default function useCollectionSubscription (ref, dependencies = [], { initialItems = [], detail = false, } = {}) {
  const [items, setItems] = useState(initialItems);
  const [isLoading, toggleLoading] = useToggle(false);
  useEffect(() => {
    if(!ref) return setItems(initialItems);

    toggleLoading(true);
    setItems(initialItems);
    const unsubscribe = ref
      .onSnapshot(({ docs }) => {
        setItems(docs.map(_ => ({ ..._.data(), id: _.id, ref: _.ref, })));
        toggleLoading(false);
      }, _ => console.log(_, ref));
    return unsubscribe;
  }, dependencies);
  return detail ? { items, isLoading } : items;
};
