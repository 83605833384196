import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { isEmpty, pickBy, pick, get, keyBy, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';
import { addDays, } from 'date-fns';

import { accountItemCategories, } from '../../shared/config';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';

const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const netAssetEndAccountCategories = accountItemCategoriesByName['純資産'].descendants().filter(_ => _.indent() <= 3 && _.children().filter(_ => _.indent() <= 3).length === 0);
const { entries, } = Object;
const categoryFields = () => {
  return netAssetEndAccountCategories.reduce((x, category) => {
    const { name } = category;
    return {
      ...x,
      [name]: {
        label: name,
        type: 'float',
      },
    };
  }, {});
};

export default function SsItemFormModal (props) {
  const { isOpen, values, onClickClose, } = props;
  const fields = {
    ...entries(categoryFields()).reduce((x, [k, v]) => {
      return {
        ...x,
        [['policyChange', k].join('--')]: {
          ...v,
        },
      };
    }, {}),
  };
  const statedFields = useFormState(values, fields, isOpen);
  const isNew = !values || !values.id;
  const [changeItems, { set: setChangeItems, updateAt: updateChangeItemAt }] = useList(get(values, 'changeItems', []));
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    (changeItems.some(_ => !_.isValid));
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), changeItems: changeItems.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: '95vw', }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            SS編集
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="mt-4">
            <h5 className="mb-3">
              会計方針の変更による累積的影響額
            </h5>
            <div className="d-flex flex-wrap">
              {
                entries(statedFields).map(([fieldName, fieldSetting]) => (
                  <div key={fieldName} className="mr-2" style={{ width: 160 }}>
                    <Field
                      key={fieldName}
                      name={fieldName}
                      {...fieldSetting}
                    />
                  </div>
                ))
              }
            </div>
          </div>
          <div className="mt-5">
            <h5 className="mb-3">
              当期中の変動額
            </h5>
            <ListForm
              addButtonLabel="行追加"
              items={changeItems}
              renderItem={(changeItem, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ChangeItemForm
                      index={itemIndex}
                      values={changeItem}
                      onChange={_ => updateChangeItemAt(itemIndex, { ...changeItem, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setChangeItems}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ChangeItemForm (props) {
  const { values, onChange, } = props;
  const fields = {
    label: {
      label: '内容',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    ...categoryFields(),
  };
  const statedFields = useFormState(values, fields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex flex-wrap">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div key={fieldName} className="mr-2" style={{ width: 160 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
