const { useMemo } = require('react');
const { useToggle, useAsync, } = require('react-use');
const { mapValues, zip, last, sumBy, groupBy, sum, isEmpty, keyBy, } = require('lodash');
const { format: formatDate, addMonths, } = require('date-fns');
const lI = require('lodash-inflection');

const { getCollectionData, getDocumentData, } = require('../firebase');
const { getCategory, } = require('../models/accountItem');
const { changeTypes, } = require('../changeTypes');
const { disassembleJournalItems, } = require('../util');

const computeItems = async (company, relatedCompany, subsidiaryId, accountItems, collectionName, uniqKeyFields, closingFieldName, changeFieldNames, yearMonthDates) => {
  if(isEmpty(yearMonthDates)) return;

  const arName = company.usesMonthlyAr ? 'mar' : 'ar';
  const targetChangeTypes = Object.keys(changeTypes).filter(_ => _.split('__')[0] === lI.singularize(collectionName));
  const isJpy = (relatedCompany?.currency || 'jpy') === 'jpy';
  const accountItemsById = keyBy(accountItems, 'id');
  const yearMonths = (company.usesMonthlyAr ? yearMonthDates : [last(yearMonthDates)]).map(_ => formatDate(_, 'yyyyMM'));
  const prevMonth = company.usesMonthlyAr ? formatDate(addMonths(yearMonthDates[0], -1), 'yyyyMM') : null;
  const getData = async (yearMonth) => {
    const pkgItems = await getCollectionData(company.ref.collection(collectionName).where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth));
    const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('changeTypes', 'array-contains-any', isEmpty(targetChangeTypes) ? ['dummy'] : targetChangeTypes).where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth));
    const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
    const individualAdjustmentJournalItemsGroupedByItemId = groupBy(individualAdjustmentJournals.flatMap(disassembleJournalItems).filter(_ => _.changeType?.split('__')[0] === lI.singularize(collectionName)), 'itemId');
    return {
      exchangeRate,
      pkgItems: pkgItems.map(_ => ({ ..._, exchangeRate, uniqKey: [_.accountItemId, ...uniqKeyFields.map(k => _[k])].join(' '), })),
      individualAdjustmentJournalItemsGroupedByItemId,
    };
  };
  const monthGroups = await Promise.all(yearMonths.map(getData));
  const prevMonthGroup = company.usesMonthlyAr ? await getData(formatDate(addMonths(yearMonthDates[0], -1), 'yyyyMM')) : { pkgItems: [], individualAdjustmentJournalItemsGroupedByItemId: {}, };
  const groupedPkgItems = groupBy([prevMonthGroup, ...monthGroups].flatMap(_ => _.pkgItems), _ => _.uniqKey);
  const computedPkgItems = Object.values(groupedPkgItems).map((pkgItems) => {
    const [_prevMonthItem, ...monthItems] = [prevMonth, ...yearMonths]
      .map(yearMonth => pkgItems.find(_ => _.yearMonth === yearMonth))
      .map(_ => _ && ({ ..._, _change: (_?.closing || 0) - (_?.opening || 0), }));
    const category = getCategory(accountItemsById[pkgItems[0]?.accountItemId]) || {};
    const computedMonthItems = monthItems.map((monthItem, i) => {
      // NOTE: 前月がない場合、つまり期首の場合は、かつBSなら前期末の数値を使うようにしてたが、それをなくした方が正しそうなので、一旦なくす
      // const prevMonthItem = monthItems[i - 1] || (category.type === 'bs' ? _prevMonthItem : null);
      const prevMonthItem = monthItems[i - 1];
      const issuedAmounts = ['_change', closingFieldName, ...changeFieldNames].reduce((x, amountFieldName) => {
        const value = monthItem?.[amountFieldName] - (prevMonthItem?.[amountFieldName] || 0);
        return {
          ...x,
          [amountFieldName]: value,
        };
      }, {});
      return monthItem && {
        ...monthItem,
        issuedAmounts,
      };
    });
    const [lastMonthItem] = computedMonthItems.slice(-1);
    const exchangedAmounts = ['_change', closingFieldName, ...changeFieldNames].reduce((x, amountFieldName) => {
      if(isJpy) return { ...x, [amountFieldName]: lastMonthItem?.[amountFieldName] };

      const value = amountFieldName === closingFieldName && category.type === 'bs' ? (
        lastMonthItem?.[closingFieldName] * (lastMonthItem?.exchangeRate?.[`${relatedCompany.currency}-cr`] || 0)
      ) : sumBy(computedMonthItems, _ => (_?.issuedAmounts[amountFieldName] || 0) * (isJpy ? 1 : _?.exchangeRate?.[`${relatedCompany.currency}-${arName}`] || 0));
      return {
        ...x,
        [amountFieldName]: value,
      };
    }, {});
    return lastMonthItem && {
      ...lastMonthItem,
      computedMonthItems,
      exchangedAmounts,
    };
  }).filter(_ => _);
  const pkgIndividualAdjustments = accountItems
    .filter(a => monthGroups.some(_ => _.individualAdjustmentJournalItemsGroupedByItemId[a.id]))
    .map((accountItem) => {
      const category = getCategory(accountItem);
      const computedMonthItems = monthGroups.map((monthGroup, i) => {
        const prevMonthGroup = monthGroups[i - 1];
        const { exchangeRate, } = monthGroup;
        const [individualAdjustmentJournalItemsGroupedByChangeType, prevYearIndividualAdjustmentJournalItemsGroupedByChangeType] = [monthGroup, prevMonthGroup].map((monthGroup) => {
          const individualAdjustmentJournalItems = monthGroup?.individualAdjustmentJournalItemsGroupedByItemId[accountItem.id];
          return groupBy(individualAdjustmentJournalItems, _ => _.changeType.split('__')[1]);
        });
        const issuedAmounts = targetChangeTypes.map(_ => _.split('__')[1]).reduce((x, changeType) => {
          const individualAdjustmentJournalItems = individualAdjustmentJournalItemsGroupedByChangeType[changeType];
          const monthAmount = sumBy(individualAdjustmentJournalItems, _ => _.amount * (_.direction === category.direction ? 1 : -1));
          const prevMonthAmount = sumBy(prevYearIndividualAdjustmentJournalItemsGroupedByChangeType[changeType], _ => _.amount * (_.direction === category.direction ? 1 : -1));
          const issuedAmount = monthAmount - prevMonthAmount;
          const exchangedIssuedAmount = issuedAmount * (isJpy ? 1 : exchangeRate?.[`${relatedCompany.currency}-${arName}`] || 0);
          return {
            ...x,
            [changeType]: {
              issuedAmount,
              exchangedIssuedAmount,
            },
          };
        }, {});
        const totalIssuedAmount = sumBy(Object.values(issuedAmounts), 'issuedAmount');
        const totalExchangedIssuedAmount = sumBy(Object.values(issuedAmounts), 'exchangedIssuedAmount');
        return monthGroup && {
          ...monthGroup,
          issuedAmounts,
          totalIssuedAmount,
          totalExchangedIssuedAmount,
        };
      });
      const [lastMonthItem] = computedMonthItems.slice(-1);
      const exchangedAmounts = targetChangeTypes.map(_ => _.split('__')[1]).reduce((x, changeType) => {
        const value = sumBy(computedMonthItems, _ => (_?.issuedAmounts[changeType]?.exchangedIssuedAmount || 0));
        return {
          ...x,
          [changeType]: value,
        };
      }, {});
      const change = sumBy(computedMonthItems, 'totalIssuedAmount');
      const exchangedChange = sumBy(computedMonthItems, 'totalExchangedIssuedAmount');
      return lastMonthItem && {
        accountItem,
        ...lastMonthItem,
        exchangedAmounts,
        change,
        exchangedChange,
      };
    })
    .filter(_ => _);
  return {
    pkgItems: computedPkgItems,
    pkgIndividualAdjustments,
  };
};

exports.computeItems = computeItems;
