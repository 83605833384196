const { get, last } = require('lodash');

const fields = () => {
  return {
    他有価証券評価差額金: {
      label: '他有価証券評価差額金',
      type: 'integer',
    },
    繰延ヘッジ損益: {
      label: '繰延ヘッジ損益',
      type: 'integer',
    },
    土地再評価差額金: {
      label: '土地再評価差額金',
      type: 'integer',
    },
    為替換算調整勘定: {
      label: '為替換算調整勘定',
      type: 'integer',
    },
    退職給付に係る調整累計額: {
      label: '退職給付に係る調整累計額',
      type: 'integer',
    },
  };
};

exports.fields = fields;
