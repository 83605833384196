const { get, mapValues, sumBy, groupBy, keyBy, sortBy, omit, } = require('lodash');
const { accountItemCategories } = require('../config');

const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const generateRowGroups = (accountItems, consolidationAccountItems, onlyNotSelected) => {
  const accountItemsGroupedByCategory = groupBy(accountItems, _ => _.account_category);
  return [{ name: 'null', }, ...accountItemCategories].map((accountItemCategory) => {
    const rows = sortBy((accountItemsGroupedByCategory[accountItemCategory.name] || []), 'index');
    const sameCategoryConsolidationAccountItems = consolidationAccountItems.filter(_ => _.account_category === accountItemCategory.name);
    const consolidationAccountItemsById = keyBy(sameCategoryConsolidationAccountItems, 'id');
    // NOTE: filter
    let filteredRows = rows;
    if(onlyNotSelected) {
      filteredRows = filteredRows.filter(_ => consolidationAccountItemsById[_.consolidationAccountItemId] == null);
    }

    return {
      accountItemCategory,
      rows,
      filteredRows,
    };
  });
};

const rowsForExport = (accountItems, consolidationAccountItems, showsGroupName) => () => {
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  return generateRowGroups(accountItems, consolidationAccountItems).flatMap(_ => _.rows).map((accountItem) => {
    const { id, name, consolidationAccountItemId, account_category: categoryName, group_name, shortcut_num: accountCode, } = accountItem;
    return {
      name,
      accountCode,
      consolidationAccountItemName: consolidationAccountItemsById[consolidationAccountItemId]?.name,
      categoryName,
      ...(
        showsGroupName && {
          groupName: group_name,
        }
      ),
    };
  });
};

exports.generateRowGroups = generateRowGroups;
exports.rowsForExport = rowsForExport;
