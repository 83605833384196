const { isNumber, sum, uniqBy, last, get, uniq, flatten, omit, mapValues, zip, sortBy, groupBy, sumBy, keyBy, isEmpty, upperFirst, } = require('lodash');
const lI = require('lodash-inflection');

const { noneSection, } = require('./section');
const { uniqAccountName, } = require('../util');
const { presetConsolidationAccountItems, otherHrAccountItemNamePatterns, accountItemCategories, accountItemCategoriesByName, accountItemCategoryChildren, } = require('../config');
const { isTrialNetIncome0, } = require('./trial');
const { getRates, } = require('./exchangeRate');

const { keys, entries } = Object;

// NOTE: accountItemモデルのgetCategoryをrequireすると相互参照になりエラー
const getCategory = (accountItem) => {
  return accountItemCategoriesByName[get(accountItem, 'account_category')];
};

const cfMappingPresetConsolidationAccountItems = [
  ...presetConsolidationAccountItems,
  {
    id: '税引前当期純利益',
    consolidationAccountItemId: '税引前当期純利益',
    account_category: '税引前当期純利益',
    name: '税引前当期純利益',
    group_name: '税引前当期純利益',
    shortcut_num: 'zeibikimaerieki',
  },
];

const netAssetEndAccountCategories = accountItemCategoriesByName['純資産'].descendants().filter(_ => _.children().length === 0);

//const computeSingleAmounts = (_allConsolidationAccountItems, accountItems = [], individualAdjustmentJournals = [], trials = [], exchangeRate, currency = 'jpy', exchangedItem) => {
//  const allConsolidationAccountItems = _allConsolidationAccountItems.filter(_ => getCategory(_));
//  const isJpy = currency === 'jpy';
//  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
//  const _isTrialNetIncome0 = isTrialNetIncome0(trials.flatMap(_ => _.balances));
//  const balances = sortBy(trials, 'type')
//    .map((trial) => {
//      return trial.balances
//        // NOTE: カテゴリの行を除く。freeeから同期した場合はカテゴリ値があり、importの場合はカテゴリ値がない
//        .filter(_ => !isEmpty(_.account_item_name) || _.account_category_name === '当期純損益金額')
//        .map(_ => ({ ..._, trial }));
//    })
//    .flat();
//  const balancesByAccountItemName = keyBy(balances, 'account_item_name');
//  const balancesGroupedByCategory = groupBy(balances, 'account_category_name');
//  const accountItemsGroupedByConsolidationId = groupBy(accountItems, 'consolidationAccountItemId');
//  const individualAdjustmentJournalItems = individualAdjustmentJournals.map(individualAdjustmentJournal => individualAdjustmentJournal.items.map(_ => ({ ..._, individualAdjustmentJournal }))).flat()
//  const individualAdjustmentJournalItemsGroupedByDebitItemId = groupBy(individualAdjustmentJournalItems, _ => consolidationAccountItemsById[_.debitItemId]?.consolidationAccountItemId);
//  const individualAdjustmentJournalItemsGroupedByCreditItemId = groupBy(individualAdjustmentJournalItems, _ => consolidationAccountItemsById[_.creditItemId]?.consolidationAccountItemId);
//  const individualAdjustmentJournalItemsGroupedByDebitCategory = groupBy(individualAdjustmentJournalItems, _ => get(consolidationAccountItemsById, [_.debitItemId, 'account_category']));
//  const individualAdjustmentJournalItemsGroupedByCreditCategory = groupBy(individualAdjustmentJournalItems, _ => get(consolidationAccountItemsById, [_.creditItemId, 'account_category']));
//
//  const consolidationAccountItemItems = allConsolidationAccountItems.map((consolidationAccountItem) => {
//    const { id, account_category: accountCategoryName } = consolidationAccountItem;
//    const accountItemCategory = accountItemCategoriesByName[accountCategoryName];
//    const { direction } = accountItemCategory;
//    const accountItems = accountItemsGroupedByConsolidationId[id] || [];
//    const balances = accountItems.map(_ => balancesByAccountItemName[_.name]).filter(_ => _);
//    const closingBalance = sumBy(balances, 'closing_balance') || 0;
//    const debitAmount = sumBy(individualAdjustmentJournalItemsGroupedByDebitItemId[consolidationAccountItem.id] || [], 'debitAmount');
//    const creditAmount = sumBy(individualAdjustmentJournalItemsGroupedByCreditItemId[consolidationAccountItem.id] || [], 'creditAmount');
//    return {
//      key: consolidationAccountItem.id,
//      itemType: 'consolidationAccountItem',
//      item: consolidationAccountItem,
//      consolidationAccountItem,
//      accountItemCategory,
//      closingBalance,
//      debitAmount,
//      creditAmount,
//    };
//  });
//  const categoryItems = accountItemCategories.map((accountItemCategory) => {
//    const balances = uniq(accountItemCategory.selfAndDescendants().flatMap(_ => balancesGroupedByCategory[_.name] || [])).filter(_ => _.trial.type === accountItemCategory.type);
//    const closingBalance = sumBy(balances, 'closing_balance') || 0;
//    const debitAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => individualAdjustmentJournalItemsGroupedByDebitCategory[_.name] || [])), 'debitAmount');
//    const creditAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => individualAdjustmentJournalItemsGroupedByCreditCategory[_.name] || [])), 'creditAmount');
//    return {
//      key: accountItemCategory.key(),
//      itemType: 'category',
//      item: accountItemCategory,
//      accountItemCategory,
//      balances,
//      closingBalance,
//      debitAmount,
//      creditAmount,
//    };
//  });
//  const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
//  const categoryComputedItems = categoryItems.map((item) => {
//    return {
//      ...item,
//      closingBalance: item.accountItemCategory.compute(mapValues(categoryItemsByCategory, _ => _.closingBalance), false, _isTrialNetIncome0 ? 0 : null),
//      debitAmount: item.accountItemCategory.compute(mapValues(categoryItemsByCategory, _ => _.debitAmount), true),
//      creditAmount: item.accountItemCategory.compute(mapValues(categoryItemsByCategory, _ => _.creditAmount), true),
//    };
//  });
//  const exchangedItems = [...consolidationAccountItemItems, ...categoryComputedItems].map((item) => {
//    const { itemType, consolidationAccountItem, accountItemCategory, closingBalance, debitAmount, creditAmount, } = item;
//    const { direction } = accountItemCategory;
//    const adjustedAmount = closingBalance + (debitAmount - creditAmount) * ({ debit: 1, credit: -1 })[direction];
//    const ar = isJpy ? 1 : get(exchangeRate, [currency, 'ar'].join('-'), 0);
//    const cr = isJpy ? 1 : get(exchangeRate, [currency, 'cr'].join('-'), 0);
//    const rate = ({ pl: ar, cr: ar, bs: cr })[accountItemCategory.type];
//    const exchangedAmount = Math.round(
//      (!isJpy && exchangedItem != null && netAssetEndAccountCategories.includes(accountItemCategory)) ? (
//        ({
//          consolidationAccountItem: exchangedItem.items?.find(_ => _.accountItemId === consolidationAccountItem?.id)?.amount || 0,
//          category: sumBy(exchangedItem.items?.filter(({ accountItemId }) => {
//            const consolidationAccountItem = consolidationAccountItemsById[accountItemId];
//            return consolidationAccountItem?.account_category === accountItemCategory.name;
//          }), 'amount'),
//        })[itemType]
//      ) : adjustedAmount * rate
//    );
//    return {
//      ...item,
//      adjustedAmount,
//      exchangedAmount,
//      ar,
//      cr,
//      rate,
//    };
//  });
//  const { consolidationAccountItem: exchangedConsolidationAccountItemItems = [], category: exchangedCategoryItems = [] } = groupBy(exchangedItems, 'itemType');
//  const exchangedCategoryItemsByCategory = keyBy(exchangedCategoryItems, 'accountItemCategory.name');
//  const exchangedCategoryComputedItems = exchangedCategoryItems.map((item) => {
//    return {
//      ...item,
//      exchangedAmount: item.accountItemCategory.compute(mapValues(exchangedCategoryItemsByCategory, _ => _.exchangedAmount), false, (isJpy && _isTrialNetIncome0) ? exchangedCategoryItemsByCategory['当期純損益金額'].adjustedAmount : null),
//    };
//  });
//  const exchangedCategoryComputedItemsByCategory = keyBy(exchangedCategoryComputedItems, 'accountItemCategory.name');
//  // NOTE: カテゴリの換算後の数値は、各アイテムの換算後の数値をroundした合計
//  const exchangedConsolidationAccountItemItemsWithFcta = exchangedConsolidationAccountItemItems.map((item) => { // Foreign currency translation adjustment
//    if(item.item.name !== '為替換算調整勘定') {
//      return item;
//    }
//
//    return {
//      ...item,
//      exchangedAmount: (
//        exchangedCategoryComputedItemsByCategory['資産'].exchangedAmount
//        - exchangedCategoryComputedItemsByCategory['負債'].exchangedAmount
//        - exchangedCategoryComputedItemsByCategory['株主資本'].exchangedAmount
//        - exchangedCategoryComputedItemsByCategory['新株予約権'].exchangedAmount
//        - exchangedCategoryComputedItemsByCategory['他有価証券評価差額金'].exchangedAmount
//        - exchangedCategoryComputedItemsByCategory['繰延ヘッジ損益'].exchangedAmount
//        - exchangedCategoryComputedItemsByCategory['土地再評価差額金'].exchangedAmount
//      ),
//    };
//  });
//  const exchangedConsolidationAccountItemItemsWithFctaGroupedByCategory = groupBy(exchangedConsolidationAccountItemItemsWithFcta, 'accountItemCategory.name');
//  const summarizedCategoryItems = exchangedCategoryComputedItems.map((item) => {
//    return {
//      ...item,
//      exchangedAmount: sumBy(item.accountItemCategory.selfAndDescendants().flatMap(_ => exchangedConsolidationAccountItemItemsWithFctaGroupedByCategory[_.name] || []), 'exchangedAmount'),
//    };
//  });
//  const summarizedCategoryItemsByCategory = keyBy(summarizedCategoryItems, 'accountItemCategory.name');
//  const summarizedCategoryComputedItems = summarizedCategoryItems.map((item) => {
//    return {
//      ...item,
//      exchangedAmount: item.accountItemCategory.compute(mapValues(summarizedCategoryItemsByCategory, _ => _.exchangedAmount), false, (isJpy && _isTrialNetIncome0) ? summarizedCategoryItemsByCategory['当期純損益金額'].adjustedAmount : null),
//    };
//  });
//
//  return [...exchangedConsolidationAccountItemItemsWithFcta, ...summarizedCategoryComputedItems];
//};

const computeSingleAmounts = (company, relatedCompany, _allConsolidationAccountItems, accountItems = [], currency = 'jpy', monthGroups, prevMonthGroup) => {
  const allConsolidationAccountItems = _allConsolidationAccountItems.filter(_ => getCategory(_));
  const isJpy = currency === 'jpy';
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const [prevMonthItemsGroup, ...monthItemsGroups] = [prevMonthGroup, ...monthGroups].map((monthGroup) => {
    if(monthGroup == null) return null;

    const { yearMonth, trials, individualAdjustmentJournals, debitConsolidationJournalItems = [], creditConsolidationJournalItems = [], } = monthGroup;
    const { bs: bsNetIncomeBalance, pl: plNetIncomeBalance, } = keyBy(trials.flatMap(t => t.balances.map(_ => ({ ..._, trial: t, })).filter(_ => _.account_category_name === '当期純損益金額')), 'trial.type');
    const netIncomeGapOfBsAndPl = (bsNetIncomeBalance?.closing_balance || 0) - (plNetIncomeBalance?.closing_balance || 0);
    let addsNetIncomeGapOfBsAndPl = false;
    const balances = sortBy(trials, 'type')
      .map((trial) => {
        return trial.balances
          // NOTE: カテゴリの行を除く。freeeから同期した場合はカテゴリ値があり、importの場合はカテゴリ値がない
          .filter(_ => !isEmpty(_.account_item_name) || _.account_category_name === '当期純損益金額')
          .map(_ => ({ ..._, trial }));
      })
      .flat()
      .map((balance) => {
        let closing_balance = balance.closing_balance;
        if(!addsNetIncomeGapOfBsAndPl && balance.account_item_name?.startsWith('繰越利益')) {
          closing_balance = closing_balance + netIncomeGapOfBsAndPl;
          addsNetIncomeGapOfBsAndPl = true;
        }
        return {
          ...balance,
          closing_balance,
        };
      });
    const balancesByAccountItemName = keyBy(balances, _ => uniqAccountName(relatedCompany, _.account_item_name, _.key));
    const balancesGroupedByCategory = groupBy(balances, 'account_category_name');
    const accountItemsGroupedByConsolidationId = groupBy(accountItems, 'consolidationAccountItemId');
    const individualAdjustmentJournalItems = individualAdjustmentJournals.map(individualAdjustmentJournal => (individualAdjustmentJournal.items || []).map(_ => ({ ..._, individualAdjustmentJournal }))).flat()
    const individualAdjustmentJournalItemsGroupedByDebitItemId = groupBy(individualAdjustmentJournalItems, _ => consolidationAccountItemsById[_.debitItemId]?.consolidationAccountItemId);
    const individualAdjustmentJournalItemsGroupedByCreditItemId = groupBy(individualAdjustmentJournalItems, _ => consolidationAccountItemsById[_.creditItemId]?.consolidationAccountItemId);
    const individualAdjustmentJournalItemsGroupedByDebitCategory = groupBy(individualAdjustmentJournalItems, _ => get(consolidationAccountItemsById, [_.debitItemId, 'account_category']));
    const individualAdjustmentJournalItemsGroupedByCreditCategory = groupBy(individualAdjustmentJournalItems, _ => get(consolidationAccountItemsById, [_.creditItemId, 'account_category']));
    const consolidationJournalItemsGroupedByDebitItemId = groupBy(debitConsolidationJournalItems, _ => consolidationAccountItemsById[_.debitItemId]?.consolidationAccountItemId);
    const consolidationJournalItemsGroupedByCreditItemId = groupBy(creditConsolidationJournalItems, _ => consolidationAccountItemsById[_.creditItemId]?.consolidationAccountItemId);
    const consolidationJournalItemsGroupedByDebitCategory = groupBy(debitConsolidationJournalItems, _ => get(consolidationAccountItemsById, [_.debitItemId, 'account_category']));
    const consolidationJournalItemsGroupedByCreditCategory = groupBy(creditConsolidationJournalItems, _ => get(consolidationAccountItemsById, [_.creditItemId, 'account_category']));

    let consolidationAccountItemItems = allConsolidationAccountItems.map((consolidationAccountItem) => {
      const { id, account_category: accountCategoryName } = consolidationAccountItem;
      const accountItemCategory = accountItemCategoriesByName[accountCategoryName];
      const { direction } = accountItemCategory;
      const accountItems = accountItemsGroupedByConsolidationId[id] || [];
      const balances = accountItems.map(_ => balancesByAccountItemName[uniqAccountName(relatedCompany, _.name, _.shortcut_num)]).filter(_ => _);
      const openingBalance = sumBy(balances, 'opening_balance') || 0;
      const closingBalance = sumBy(balances, 'closing_balance') || 0;
      const debitAmount = sumBy(individualAdjustmentJournalItemsGroupedByDebitItemId[consolidationAccountItem.id] || [], 'debitAmount');
      const creditAmount = sumBy(individualAdjustmentJournalItemsGroupedByCreditItemId[consolidationAccountItem.id] || [], 'creditAmount');
      const adjustmentAmount = (debitAmount - creditAmount) * ({ debit: 1, credit: -1 })[direction];
      const adjustedAmount = closingBalance + adjustmentAmount;
      const debitConsolidationAmount = sumBy(consolidationJournalItemsGroupedByDebitItemId[consolidationAccountItem.id] || [], 'debitAmount');
      const creditConsolidationAmount = sumBy(consolidationJournalItemsGroupedByCreditItemId[consolidationAccountItem.id] || [], 'creditAmount');
      const consolidationAmount = (debitConsolidationAmount - creditConsolidationAmount) * ({ debit: 1, credit: -1 })[direction];
      return {
        key: consolidationAccountItem.id,
        code: consolidationAccountItem.shortcut_num || '',
        itemType: 'consolidationAccountItem',
        item: consolidationAccountItem,
        consolidationAccountItem,
        accountItemCategory,
        openingBalance,
        closingBalance,
        debitAmount,
        creditAmount,
        adjustmentAmount,
        adjustedAmount,
        debitConsolidationAmount,
        creditConsolidationAmount,
        consolidationAmount,
        balances,
      };
    });
    const categoryItems = accountItemCategories.map((accountItemCategory) => {
      const { direction } = accountItemCategory;
      const balances = uniq(accountItemCategory.selfAndDescendants().flatMap(_ => balancesGroupedByCategory[_.name] || [])).filter(_ => _.trial.type === accountItemCategory.type);
      const openingBalance = sumBy(balances, 'opening_balance') || 0;
      const closingBalance = sumBy(balances, 'closing_balance') || 0;
      const debitAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => individualAdjustmentJournalItemsGroupedByDebitCategory[_.name] || [])), 'debitAmount');
      const creditAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => individualAdjustmentJournalItemsGroupedByCreditCategory[_.name] || [])), 'creditAmount');
      const adjustmentAmount = (debitAmount - creditAmount) * ({ debit: 1, credit: -1 })[direction];
      const adjustedAmount = closingBalance + adjustmentAmount;
      const debitConsolidationAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => consolidationJournalItemsGroupedByDebitCategory[_.name] || [])), 'debitAmount');
      const creditConsolidationAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => consolidationJournalItemsGroupedByCreditCategory[_.name] || [])), 'creditAmount');
      const consolidationAmount = (debitConsolidationAmount - creditConsolidationAmount) * ({ debit: 1, credit: -1 })[direction];
      return {
        key: accountItemCategory.key(),
        code: accountItemCategory.roma || '',
        itemType: 'category',
        item: accountItemCategory,
        accountItemCategory,
        balances,
        openingBalance,
        closingBalance,
        debitAmount,
        creditAmount,
        adjustmentAmount,
        adjustedAmount,
        debitConsolidationAmount,
        creditConsolidationAmount,
        consolidationAmount,
      };
    });
    const computeCategoryItems = (categoryItems) => {
      const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
      const categoryAmountsByKey = ['openingBalance', 'closingBalance', 'debitAmount', 'creditAmount', 'adjustmentAmount', 'adjustedAmount', 'debitConsolidationAmount', 'creditConsolidationAmount', 'consolidationAmount'].reduce((x, _) => ({
        ...x, [_]: mapValues(categoryItemsByCategory, _)
      }), {});
      return categoryItems.map((item) => {
        return {
          ...item,
          openingBalance: item.accountItemCategory.compute(categoryAmountsByKey.openingBalance, false),
          closingBalance: item.accountItemCategory.compute(categoryAmountsByKey.closingBalance, false),
          debitAmount: item.accountItemCategory.compute(categoryAmountsByKey.debitAmount, true),
          creditAmount: item.accountItemCategory.compute(categoryAmountsByKey.creditAmount, true),
          adjustmentAmount: item.accountItemCategory.compute(categoryAmountsByKey.adjustmentAmount, false),
          adjustedAmount: item.accountItemCategory.compute(categoryAmountsByKey.adjustedAmount, false),
          debitConsolidationAmount: item.accountItemCategory.compute(categoryAmountsByKey.debitConsolidationAmount, true),
          creditConsolidationAmount: item.accountItemCategory.compute(categoryAmountsByKey.creditConsolidationAmount, true),
          consolidationAmount: item.accountItemCategory.compute(categoryAmountsByKey.consolidationAmount, false),
        };
      });
    }
    let categoryComputedItems = computeCategoryItems(categoryItems);
    const computeWithNetIncome = (netIncome, item) => {
      return {
        ...item,
        ...[
          'openingBalance',
          'closingBalance',
          'debitAmount',
          'creditAmount',
          'adjustmentAmount',
          'adjustedAmount',
          'debitConsolidationAmount',
          'creditConsolidationAmount',
          'consolidationAmount',
        ].reduce((x, y) => {
          return {
            ...x,
            [y]: item[y] - (netIncome?.[y] || 0),
          };
        }, {}),
      };
    };
    if(relatedCompany.externalType === 'mf') {
      const netIncome = categoryComputedItems.find(_ => _.item.name === '当期純損益金額');
      categoryComputedItems = computeCategoryItems(categoryItems.map(_ => _.item.name === '繰越利益剰余金' ? computeWithNetIncome(netIncome, _) : _));
      consolidationAccountItemItems  = consolidationAccountItemItems.map(_ => _.item.name === '繰越利益剰余金' ? computeWithNetIncome(netIncome, _) : _);
    }
    return [...consolidationAccountItemItems, ...categoryComputedItems].map(_ => ({ ..._, monthGroup }));
  });
  const issueComputedItems = zip(...[prevMonthItemsGroup || [], ...monthItemsGroups]).map(([_prevMonthItem, ...monthItems]) => {
    return monthItems.map((monthItem, i) => {
      const { type, } = monthItem.accountItemCategory;
      const prevMonthItem = monthItems[i - 1] || (type === 'bs' ? _prevMonthItem : null);
      const [issuedClosingBalance, issuedAdjustmentAmount, issuedAdjustedAmount] = ['closingBalance', 'adjustmentAmount', 'adjustedAmount'].map(_ => monthItem[_] - (prevMonthItem?.[_] || 0));
      return {
        ...monthItem,
        issuedClosingBalance,
        issuedAdjustmentAmount,
        issuedAdjustedAmount,
      };
    });
  });
  const exchangedItems = issueComputedItems.map((monthItems) => {
    const lastMonthItem = last(monthItems);
    const { itemType, consolidationAccountItem, accountItemCategory, consolidationAmount, } = lastMonthItem;
    const { type, direction } = accountItemCategory;
    const totalIssuedExchangedAmount = company.usesMonthlyAr ? sumBy(monthItems, _ => _.issuedAdjustedAmount * (_.monthGroup.exchangeRate?.[`${currency}-mar`] || 0)) : lastMonthItem.issuedAdjustedAmount * (lastMonthItem.monthGroup.exchangeRate?.[`${currency}-ar`] || 0);
    const exchangedAmount = sum((type === 'bs' ? [lastMonthItem] : monthItems).map((monthItem, i) => {
      const prevMonthItem = monthItems[i - 1];
      const { monthGroup: { exchangeRate, exchangedItem }, adjustedAmount, issuedAdjustedAmount, } = monthItem;
      const arToUse = isJpy ? 1 : get(exchangeRate, [currency, company.usesMonthlyAr ? 'mar' : 'ar'].join('-'), 0);
      const cr = isJpy ? 1 : get(exchangeRate, [currency, 'cr'].join('-'), 0);
      const rate = ({ pl: arToUse, cr: arToUse, bs: cr })[type];
      const usesExchangeItem = !isJpy && exchangedItem != null && (netAssetEndAccountCategories.includes(accountItemCategory) || otherHrAccountItemNamePatterns.some(_ => consolidationAccountItem?.name.match(_)));
      const exchangeItemAmount = usesExchangeItem ? ({
        consolidationAccountItem: exchangedItem.items?.find(_ => _.accountItemId === consolidationAccountItem?.id)?.amount || 0,
        category: sumBy(exchangedItem.items?.filter(({ accountItemId }) => {
          const consolidationAccountItem = consolidationAccountItemsById[accountItemId];
          return consolidationAccountItem?.account_category === accountItemCategory.name;
        }), 'amount'),
      })[itemType] : 0;
      return Math.round(usesExchangeItem ? exchangeItemAmount : (adjustedAmount - (prevMonthItem?.adjustedAmount || 0)) * rate);
    }));
    const consolidatedAmount = exchangedAmount + consolidationAmount;
    return {
      ...lastMonthItem,
      monthItems,
      totalIssuedExchangedAmount,
      exchangedAmount,
      consolidatedAmount,
    };
  });
  // NOTE: カテゴリの換算後の数値は、各アイテムの換算後の数値をroundした合計
  const { consolidationAccountItem: exchangedConsolidationAccountItemItems = [], category: exchangedCategoryItems = [] } = groupBy(exchangedItems, 'itemType');
  const exchangedConsolidationAccountItemItemsGroupedByCategory = groupBy(exchangedConsolidationAccountItemItems, 'accountItemCategory.name');
  const exchangedSummarizedItems = [
    ...exchangedConsolidationAccountItemItems,
    ...exchangedCategoryItems.map((categoryItem) => {
      const { accountItemCategory } = categoryItem;
      return {
        ...categoryItem,
        exchangedAmount: sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => exchangedConsolidationAccountItemItemsGroupedByCategory[_.name] || [])), 'exchangedAmount'),
      };
    }),
  ];
  const { consolidationAccountItem: exchangedSummarizedConsolidationAccountItemItems = [], category: exchangedSummarizedCategoryItems = [] } = groupBy(exchangedSummarizedItems, 'itemType');
  const exchangedSummarizedCategoryItemsByCategory = keyBy(exchangedSummarizedCategoryItems, 'accountItemCategory.name');
  const exchangedSummarizedCategoryComputedItems = exchangedSummarizedCategoryItems.map((item) => {
    return {
      ...item,
      exchangedAmount: item.accountItemCategory.compute(mapValues(exchangedSummarizedCategoryItemsByCategory, _ => _.exchangedAmount), false),
    };
  });
  const exchangedSummarizedCategoryComputedItemsByCategory = keyBy(exchangedSummarizedCategoryComputedItems, 'accountItemCategory.name');
  const fcta = (() => {
    const item = exchangedSummarizedConsolidationAccountItemItems.find(_ => _.item.name === '為替換算調整勘定');
    const exchangedAmount = (
      exchangedSummarizedCategoryComputedItemsByCategory['資産'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['負債'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['株主資本'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['新株予約権'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['他有価証券評価差額金'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['繰延ヘッジ損益'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['土地再評価差額金'].exchangedAmount
      - exchangedSummarizedCategoryComputedItemsByCategory['退職給付に係る調整累計額'].exchangedAmount
    );
    return {
      ...item,
      ...(currency !== 'jpy' && {
        exchangedAmount,
        consolidatedAmount: exchangedAmount + item?.consolidationAmount,
      }),
    };
  })();
  const exchangedSummarizedConsolidationAccountItemItemsWithFcta = exchangedSummarizedConsolidationAccountItemItems.map((item) => { // Foreign currency translation adjustment
    return item.item.name === '為替換算調整勘定' ? fcta : item;
  });
  const exchangedSummarizedCategoryComputedItemsByCategoryWithFcta = {
    ...exchangedSummarizedCategoryComputedItemsByCategory,
    為替換算調整勘定: {
      ...exchangedSummarizedCategoryComputedItemsByCategory['為替換算調整勘定'],
      exchangedAmount: fcta.exchangedAmount,
    },
  };
  const recomputedCategoryItems = exchangedSummarizedCategoryComputedItems.map((item) => {
    const exchangedAmount = item.accountItemCategory.compute(mapValues(exchangedSummarizedCategoryComputedItemsByCategoryWithFcta, _ => _.exchangedAmount), false);
    const consolidatedAmount = exchangedAmount + item.consolidationAmount;
    return {
      ...item,
      exchangedAmount,
      consolidatedAmount,
    };
  });

  return [...exchangedSummarizedConsolidationAccountItemItemsWithFcta, ...recomputedCategoryItems];
};

const computeSingleSegmentsAmounts = (company, relatedCompany, allConsolidationAccountItems, accountItems = [], currency = 'jpy', sections, allConsolidationSegments, monthGroups, prevMonthGroup) => {
  const isJpy = currency === 'jpy';
  const sectionsGroupedByConsolidationSegmentId = groupBy(sections, 'consolidationSegmentId');
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const accountItemsGroupedByConsolidationId = groupBy(accountItems, 'consolidationAccountItemId');

  const [prevMonthItemsGroup, ...monthItemsGroups] = [prevMonthGroup, ...monthGroups].map((monthGroup) => {
    if(monthGroup == null) return null;

    const { yearMonth, trials, sectionTrials, individualAdjustmentJournals, debitConsolidationJournalItems = [], creditConsolidationJournalItems = [], } = monthGroup;
    const { bs: bsNetIncomeBalance, pl: plNetIncomeBalance, } = keyBy(trials.flatMap(t => t.balances.map(_ => ({ ..._, trial: t, })).filter(_ => _.account_category_name === '当期純損益金額')), 'trial.type');
    const netIncomeGapOfBsAndPl = (bsNetIncomeBalance?.closing_balance || 0) - (plNetIncomeBalance?.closing_balance || 0);
    const balances = sortBy(sectionTrials, 'type').flatMap((sectionTrial) => {
      return (sectionTrial.sections || []).map(_ => ({ ..._, sectionTrial }));
    });
    const balancesGroupedByAccountItemName = groupBy(balances, _ => uniqAccountName(relatedCompany, _.sectionTrial.account_item_name, _.sectionTrial.key));
    const balancesGroupedByCategory = groupBy(balances, 'sectionTrial.account_category_name');

    const individualAdjustmentJournalItems = individualAdjustmentJournals.flatMap(individualAdjustmentJournal => (individualAdjustmentJournal.items || []).map(_ => ({ ..._, individualAdjustmentJournal })));
    const individualAdjustmentJournalItemsGroupedByDebitItemId = groupBy(individualAdjustmentJournalItems, _ => consolidationAccountItemsById[_.debitItemId]?.consolidationAccountItemId);
    const individualAdjustmentJournalItemsGroupedByCreditItemId = groupBy(individualAdjustmentJournalItems, _ => consolidationAccountItemsById[_.creditItemId]?.consolidationAccountItemId);
    const individualAdjustmentJournalItemsGroupedByDebitCategory = groupBy(individualAdjustmentJournalItems, _ => get(consolidationAccountItemsById, [_.debitItemId, 'account_category']));
    const individualAdjustmentJournalItemsGroupedByCreditCategory = groupBy(individualAdjustmentJournalItems, _ => get(consolidationAccountItemsById, [_.creditItemId, 'account_category']));

    let consolidationAccountItemItems = allConsolidationAccountItems.map((consolidationAccountItem) => {
      const { id, account_category: accountCategoryName } = consolidationAccountItem;
      const accountItemCategory = accountItemCategoriesByName[accountCategoryName];
      const { direction } = accountItemCategory;
      const accountItems = accountItemsGroupedByConsolidationId[id] || [];
      const debitIndividualAdjustmentJournalItems = individualAdjustmentJournalItemsGroupedByDebitItemId[consolidationAccountItem.id] || [];
      const creditIndividualAdjustmentJournalItems = individualAdjustmentJournalItemsGroupedByCreditItemId[consolidationAccountItem.id] || [];
      const balances = accountItems.flatMap(_ => balancesGroupedByAccountItemName[uniqAccountName(relatedCompany, _.name, _.shortcut_num)]).filter(_ => _);
      const segmentColumns = allConsolidationSegments.map((segment) => {
        const { id } = segment;
        const sections = sectionsGroupedByConsolidationSegmentId[id] || [];
        const sectionNames = sections.map(_ => _.name);
        const sectionCodes = sections.map(_ => _.code).filter(_ => _);
        const sectionIds = sections.map(_ => _.id);
        const segmentBalances = balances.filter(_ => sectionNames.includes(_.name) || sectionCodes.includes(_.id));

        // NOTE: 繰越利益剰余金に当期純損益金額を調整するところだが、各部門ごとにやってしまうと調整が重複してしまう。(614) ただし、繰越利益剰余金についてすべて対応してしまうと繰越利益剰余金行がないのに調整してしまい、それもおかしくなる。
        const closingBalance = (_ => segmentBalances.length > 0 && consolidationAccountItem.name.startsWith('繰越利益') ? _ + netIncomeGapOfBsAndPl : _)(sumBy(segmentBalances, 'closing_balance') || 0);

        const debitAmount = sumBy(debitIndividualAdjustmentJournalItems.filter(_ => sectionIds.includes(_.debitSectionId)), 'debitAmount') || 0;
        const creditAmount = sumBy(creditIndividualAdjustmentJournalItems.filter(_ => sectionIds.includes(_.creditSectionId)), 'creditAmount') || 0;
        const adjustmentAmount = (debitAmount - creditAmount) * ({ debit: 1, credit: -1 })[direction];
        const adjustedAmount = closingBalance + adjustmentAmount;
        return {
          segment,
          closingBalance,
          debitAmount,
          creditAmount,
          adjustmentAmount,
          adjustedAmount,
        };
      });
      return {
        key: consolidationAccountItem.id,
        itemType: 'consolidationAccountItem',
        item: consolidationAccountItem,
        consolidationAccountItem,
        accountItemCategory,
        segmentColumns,
      };
    });
    const categoryItems = accountItemCategories.map((accountItemCategory) => {
      const { direction } = accountItemCategory;
      const balances = uniq(accountItemCategory.selfAndDescendants().flatMap(_ => balancesGroupedByCategory[_.name] || [])).filter(_ => _.sectionTrial.type === accountItemCategory.type);
      const segmentColumns = allConsolidationSegments.map((segment) => {
        const { id } = segment;
        const sections = sectionsGroupedByConsolidationSegmentId[id] || [];
        const sectionNames = sections.map(_ => _.name);
        const sectionIds = sections.map(_ => _.id);
        const segmentBalances = balances.filter(_ => sectionNames.includes(_.name));

        // NOTE: 繰越利益剰余金に当期純損益金額を調整するところだが、各部門ごとにやってしまうと調整が重複してしまう。(614) ただし、繰越利益剰余金についてすべて対応してしまうと繰越利益剰余金行がないのに調整してしまい、それもおかしくなる。
        const closingBalance = (_ => segmentBalances.length > 0 && accountItemCategory.name.startsWith('繰越利益') ? _ + netIncomeGapOfBsAndPl : _)(sumBy(segmentBalances, 'closing_balance') || 0);

        const debitAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => (individualAdjustmentJournalItemsGroupedByDebitCategory[_.name] || []).filter(_ => sectionIds.includes(_.debitSectionId)))), 'debitAmount');
        const creditAmount = sumBy(uniq(accountItemCategory.selfAndDescendants().flatMap(_ => (individualAdjustmentJournalItemsGroupedByCreditCategory[_.name] || []).filter(_ => sectionIds.includes(_.creditSectionId)))), 'creditAmount');
        const adjustmentAmount = (debitAmount - creditAmount) * ({ debit: 1, credit: -1 })[direction];
        const adjustedAmount = closingBalance + adjustmentAmount;
        return {
          segment,
          closingBalance,
          debitAmount,
          creditAmount,
          adjustmentAmount,
          adjustedAmount,
        };
      });
      return {
        key: accountItemCategory.key(),
        itemType: 'category',
        item: accountItemCategory,
        accountItemCategory,
        segmentColumns,
      };
    });
    const computeCategoryItems = (categoryItems) => {
      const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
      return categoryItems.map((item) => {
        const segmentColumns = item.segmentColumns.map((segmentColumn, i) => {
          const segmentColumnsByCategory = mapValues(categoryItemsByCategory, _ => _.segmentColumns[i]);
          return {
            ...segmentColumn,
            closingBalance: item.accountItemCategory.compute(mapValues(segmentColumnsByCategory, _ => _.closingBalance), false),
            debitAmount: item.accountItemCategory.compute(mapValues(segmentColumnsByCategory, _ => _.debitAmount), true),
            creditAmount: item.accountItemCategory.compute(mapValues(segmentColumnsByCategory, _ => _.creditAmount), true),
          };
        });
        return {
          ...item,
          segmentColumns,
        };
      });
    };
    let categoryComputedItems = computeCategoryItems(categoryItems);
    const computeWithNetIncome = (netIncome, item) => {
      return {
        ...item,
        segmentColumns: item.segmentColumns.map((segmentColumn, i) => {
          return {
            ...segmentColumn,
            ...[
              'closingBalance',
              'debitAmount',
              'creditAmount',
              'adjustmentAmount',
              'adjustedAmount',
            ].reduce((x, y) => {
              return {
                ...x,
                [y]: segmentColumn[y] - (netIncome?.segmentColumns[i][y] || 0),
              };
            }, {}),
          };
        }),
      };
    };
    if(relatedCompany.externalType === 'mf') {
      const netIncome = categoryComputedItems.find(_ => _.item.name === '当期純損益金額');
      categoryComputedItems = computeCategoryItems(categoryComputedItems.map(_ => _.item.name === '繰越利益剰余金' ? computeWithNetIncome(netIncome, _) : _));
      consolidationAccountItemItems  = consolidationAccountItemItems.map(_ => _.item.name === '繰越利益剰余金' ? computeWithNetIncome(netIncome, _) : _);
    }
    return [...consolidationAccountItemItems, ...categoryComputedItems].map(_ => ({ ..._, monthGroup }));
  });
  const issueComputedItems = zip(...[prevMonthItemsGroup || [], ...monthItemsGroups]).map(([_prevMonthItem, ...monthItems]) => {
    return monthItems.map((monthItem, i) => {
      const { type, } = monthItem.accountItemCategory;
      const prevMonthItem = monthItems[i - 1] || (type === 'bs' ? _prevMonthItem : null);
      const segmentColumns = monthItem.segmentColumns.map((segmentColumn, segmentColumnIndex) => {
        const [issuedClosingBalance, issuedAdjustmentAmount, issuedAdjustedAmount] = ['closingBalance', 'adjustmentAmount', 'adjustedAmount'].map(_ => segmentColumn[_] - (prevMonthItem?.segmentColumns[segmentColumnIndex][_] || 0));
        return {
          ...segmentColumn,
          issuedClosingBalance,
          issuedAdjustmentAmount,
          issuedAdjustedAmount,
        };
      });
      return {
        ...monthItem,
        segmentColumns,
      };
    });
  })
  const exchangedItems = issueComputedItems.map((monthItems) => {
    const lastMonthItem = last(monthItems);
    const { itemType, consolidationAccountItem, accountItemCategory, } = lastMonthItem;
    const { type, direction } = accountItemCategory;
    const segmentColumns = lastMonthItem.segmentColumns.map((segmentColumn, segmentColumnIndex) => {
      const { closingBalance, debitAmount, creditAmount } = segmentColumn;
      const [exchangedClosingBalance, exchangedAdjustmentAmount, exchangedAdjustedAmount] = ['closingBalance', 'adjustmentAmount', 'adjustedAmount'].map((amountName) => {
        if(type === 'bs') {
          const cr = isJpy ? 1 : lastMonthItem.monthGroup.exchangeRate[`${currency}-cr`];
          return Math.round(segmentColumn[amountName] * cr);
        }

        const exchangedAmount = sum(monthItems.map((monthItem, i) => {
          const { monthGroup: { exchangeRate, }, } = monthItem;
          const arToUse = isJpy ? 1 : get(exchangeRate, [currency, company.usesMonthlyAr ? 'mar' : 'ar'].join('-'), 0);
          const amount = monthItem.segmentColumns[segmentColumnIndex][`issued${upperFirst(amountName)}`];
          return Math.round(amount * arToUse);
        }));
        return exchangedAmount;
      });
      return {
        ...segmentColumn,
        exchangedClosingBalance,
        exchangedAdjustmentAmount,
        exchangedAdjustedAmount,
      };
    })
    return {
      ...lastMonthItem,
      monthItems,
      segmentColumns,
    };
  });
  const exchangedCategoryItemsByCategory = keyBy(exchangedItems.filter(_ => _.itemType === 'category'), 'accountItemCategory.name');
  const categoryComputedExchangedItems = exchangedItems.map((item) => {
    if(item.itemType === 'consolidationAccountItem') return item;

    const segmentColumns = item.segmentColumns.map((segmentColumn, i) => {
      const segmentColumnsByCategory = mapValues(exchangedCategoryItemsByCategory, _ => _.segmentColumns[i]);
      return {
        ...segmentColumn,
        exchangedClosingBalance: item.accountItemCategory.compute(mapValues(segmentColumnsByCategory, _ => _.exchangedClosingBalance), false),
        exchangedAdjustmentAmount: item.accountItemCategory.compute(mapValues(segmentColumnsByCategory, _ => _.exchangedAdjustmentAmount), false),
        exchangedAdjustedAmount: item.accountItemCategory.compute(mapValues(segmentColumnsByCategory, _ => _.exchangedAdjustedAmount), false),
      };
    });
    return {
      ...item,
      segmentColumns,
    };
  });
  return categoryComputedExchangedItems;
};

const computeCompaniesAmounts = (company, relatedCompanies, allConsolidationJournalTypes, _allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup, periodAmounts, usesPrevPeriodAmounts = false) => {
  const allConsolidationAccountItems = _allConsolidationAccountItems.filter(_ => getCategory(_));
  const periodAmountsByConsolidationAccountItemId = keyBy(periodAmounts, 'consolidationAccountItemId');
  const accountItemsGroupedByCompanyId = groupBy(accountItems, _ => _.subsidiaryId || company.id);
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const [prevMonthGroupedMonthGroup, ...groupedMonthGroups] = [prevMonthGroup, ...monthGroups].map((monthGroup) => {
    const { consolidationJournals, trials, individualAdjustmentJournals, exchangedItems } = monthGroup || {};
    const individualAdjustmentJournalsGroupedByCompanyId = groupBy(individualAdjustmentJournals, _ => _.subsidiaryId || company.id);
    const trialsGroupedByCompanyId = groupBy(trials, _ => _.subsidiaryId || company.id);
    const exchangedItemsByCompanyId = keyBy(exchangedItems, _ => _.subsidiaryId || company.id);
    const consolidationJournalItems = (consolidationJournals || []).map(consolidationJournal => (consolidationJournal?.items || []).map(_ => ({ ..._, consolidationJournal }))).flat()
    const consolidationJournalItemsGroupedByType = groupBy(consolidationJournalItems, 'consolidationJournal.type');
    const consolidationJournalItemsGroupedByDebitCompanyId = groupBy(consolidationJournalItems, 'debitCompanyId');
    const consolidationJournalItemsGroupedByCreditCompanyId = groupBy(consolidationJournalItems, 'creditCompanyId');
    return {
      ...monthGroup,
      individualAdjustmentJournalsGroupedByCompanyId,
      trialsGroupedByCompanyId,
      exchangedItemsByCompanyId,
      consolidationJournalItems,
      consolidationJournalItemsGroupedByType,
      consolidationJournalItemsGroupedByDebitCompanyId,
      consolidationJournalItemsGroupedByCreditCompanyId,
    };
  });
  const [lastMonthGroup] = groupedMonthGroups.slice(-1);
  const lastMonthGroupConsolidationJournalItemsGroupedByType = lastMonthGroup.consolidationJournalItemsGroupedByType || {};

  const summarizedItems = zip([...allConsolidationAccountItems.map(_ => ({ ..._, itemType: 'consolidationAccountItem' })), ...accountItemCategories.map(_ => ({ ..._, itemType: 'category' }))], ...relatedCompanies.map((relatedCompany) => {
    const shouldConsolidate = (relatedCompany.joinYearMonth || '000000') <= lastMonthGroup.yearMonth;
    const accountItems = accountItemsGroupedByCompanyId[relatedCompany.id];

    const [prevMonthComapnyMonthGroup, ...companyMonthGroups] = [prevMonthGroupedMonthGroup, ...groupedMonthGroups].map((monthGroup) => {
      return {
        ...monthGroup,
        individualAdjustmentJournals: monthGroup.individualAdjustmentJournalsGroupedByCompanyId[relatedCompany.id] || [],
        trials: monthGroup.trialsGroupedByCompanyId[relatedCompany.id] || [],
        exchangedItem: monthGroup.exchangedItemsByCompanyId[relatedCompany.id] || [],
        debitConsolidationJournalItems: monthGroup.consolidationJournalItemsGroupedByDebitCompanyId[relatedCompany.id] || [],
        creditConsolidationJournalItems: monthGroup.consolidationJournalItemsGroupedByCreditCompanyId[relatedCompany.id] || [],
      };
    });

    const singleAmounts = computeSingleAmounts(company, relatedCompany, allConsolidationAccountItems, accountItems, relatedCompany.currency, companyMonthGroups, prevMonthComapnyMonthGroup).map(_ => ({ ..._, company: relatedCompany, }));
    return shouldConsolidate ? singleAmounts : singleAmounts.map(_ => mapValues(_, v => isNumber(v) ? 0 : v));
  })).map(([item, ...companyColumns]) => {
    const simpleSum = sumBy(companyColumns, 'exchangedAmount');
    const { itemType } = item;
    const categoryName = ({ consolidationAccountItem: item.account_category, category: item.name })[itemType];
    const accountItemCategory = accountItemCategoriesByName[categoryName];
    const { direction } = accountItemCategory;
    const consolidationColumns = allConsolidationJournalTypes.map(({ id }) => {
      const consolidationJournalItems = lastMonthGroupConsolidationJournalItemsGroupedByType[id] || [];
      const debitItems = ({
        consolidationAccountItem: () => consolidationJournalItems.filter(_ => _.debitItemId === item.id),
        category : () => uniq(item.selfAndDescendants().flatMap(category => consolidationJournalItems.filter(_ => consolidationAccountItemsById[_.debitItemId]?.account_category === category.name))),
      })[itemType]();
      const creditItems = ({
        consolidationAccountItem: () => consolidationJournalItems.filter(_ => _.creditItemId === item.id),
        category : () => uniq(item.selfAndDescendants().flatMap(category => consolidationJournalItems.filter(_ => consolidationAccountItemsById[_.creditItemId]?.account_category === category.name))),
      })[itemType]();
      return {
        type: id,
        amount: (sumBy(debitItems, 'debitAmount') - sumBy(creditItems, 'creditAmount')) * ({ debit: 1, credit: -1 })[direction],
      };
    });
    const periodAmount = periodAmountsByConsolidationAccountItemId[item.id];

    return {
      key: item.id || item.key(),
      code: item.shortcut_num || item.roma || '',
      item,
      itemType,
      consolidationAccountItem: item,
      accountItemCategory,
      companyColumns,
      simpleSum,
      consolidationColumns,
      periodAmount,
    };
  });
  const { consolidationAccountItem: consolidationAccountItemSummarizedItems, category: categorySummarizedItems } = groupBy(summarizedItems, 'itemType');
  const consolidationAccountItemSummarizedItemsGroupedByCategory = groupBy(consolidationAccountItemSummarizedItems, 'accountItemCategory.name');
  const categorySummarizedItemsByCategory = keyBy(categorySummarizedItems, 'accountItemCategory.name');

  const consolidationColumnsComputedCategorySummarizedItems = categorySummarizedItems.map((categoryItem) => {
    const { accountItemCategory } = categoryItem;
    const consolidationColumns = categoryItem.consolidationColumns.map((consolidationColumn, i) => {
      return {
        ...consolidationColumn,
        amount: accountItemCategory.compute(mapValues(categorySummarizedItemsByCategory, _ => _.consolidationColumns[i].amount)),
      };
    });
    return {
      ...categoryItem,
      consolidationColumns,
    };
  });

  const concludedConsolidationAccountItems = consolidationAccountItemSummarizedItems.map((item) => {
    const { periodAmount, simpleSum, consolidationColumns } = item;
    return {
      ...item,
      conclusiveAmount: usesPrevPeriodAmounts ? (periodAmount?.amount || 0) : simpleSum + sumBy(consolidationColumns, 'amount'),
    };
  });
  const concludedConsolidationAccountItemsGroupedByCategory = groupBy(concludedConsolidationAccountItems, 'accountItemCategory.name');
  const concludedCategoryItems = consolidationColumnsComputedCategorySummarizedItems.map((item) => {
    const { simpleSum, consolidationColumns } = item;
    return {
      ...item,
      conclusiveAmount: sumBy(item.item.selfAndDescendants().flatMap(_ => concludedConsolidationAccountItemsGroupedByCategory[_.name]), 'conclusiveAmount'),
      horizontalSummarizedAmount: simpleSum + sumBy(consolidationColumns, 'amount'),
    };
  });
  const concludedCategoryItemsByCategory = keyBy(concludedCategoryItems, 'accountItemCategory.name');
  const computedCategoryItems = concludedCategoryItems.map((categoryItem) => { // Foreign currency translation adjustment
    const { accountItemCategory } = categoryItem;
    return {
      ...categoryItem,
      conclusiveAmount: accountItemCategory.compute(mapValues(concludedCategoryItemsByCategory, 'conclusiveAmount'), false, concludedCategoryItemsByCategory['当期純損益金額'][usesPrevPeriodAmounts ? 'conclusiveAmount' : 'horizontalSummarizedAmount']),
    };
  });
  return [...concludedConsolidationAccountItems, ...computedCategoryItems];
};

const computeSegmentsAmounts = (company, relatedCompanies, _allConsolidationAccountItems, accountItems, sections, allConsolidationSegments, monthGroups, prevMonthGroup) => {
  const allConsolidationAccountItems = _allConsolidationAccountItems.filter(_ => getCategory(_));
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const accountItemsGroupedByCompanyId = groupBy(accountItems, _ => _.subsidiaryId || company.id);
  const sectionsGroupedByCompanyId = groupBy(sections, _ => _.subsidiaryId || company.id);

  const [prevMonthGroupedMonthGroup, ...groupedMonthGroups] = [prevMonthGroup, ...monthGroups].map((monthGroup) => {
    const { trials, sectionTrials, individualAdjustmentJournals, exchangedItems } = monthGroup || {};
    const individualAdjustmentJournalsGroupedByCompanyId = groupBy(individualAdjustmentJournals, _ => _.subsidiaryId || company.id);
    const trialsGroupedByCompanyId = groupBy(trials, _ => _.subsidiaryId || company.id);
    const sectionTrialsGroupedByCompanyId = groupBy(sectionTrials, _ => _.subsidiaryId || company.id);
    const exchangedItemsByCompanyId = keyBy(exchangedItems, _ => _.subsidiaryId || company.id);
    return {
      ...monthGroup,
      individualAdjustmentJournalsGroupedByCompanyId,
      trialsGroupedByCompanyId,
      sectionTrialsGroupedByCompanyId,
      exchangedItemsByCompanyId,
    };
  });

  const [lastMonthGroup] = monthGroups.slice(-1);
  const consolidationJournalItems = lastMonthGroup.consolidationJournals.map(consolidationJournal => (consolidationJournal?.items || []).map(_ => ({ ..._, consolidationJournal }))).flat()
  const consolidationJournalItemsGroupedByDebitItemId = groupBy(consolidationJournalItems, 'debitItemId');
  const consolidationJournalItemsGroupedByCreditItemId = groupBy(consolidationJournalItems, 'creditItemId');
  const consolidationJournalItemsGroupedByDebitCategory = groupBy(consolidationJournalItems, _ => get(consolidationAccountItemsById, [_.debitItemId, 'account_category']));
  const consolidationJournalItemsGroupedByCreditCategory = groupBy(consolidationJournalItems, _ => get(consolidationAccountItemsById, [_.creditItemId, 'account_category']));

  const summarizedItems = zip([...allConsolidationAccountItems.map(_ => ({ ..._, itemType: 'consolidationAccountItem' })), ...accountItemCategories.map(_ => ({ ..._, itemType: 'category' }))], ...relatedCompanies.map((relatedCompany) => {
    const shouldConsolidate = (relatedCompany.joinYearMonth || '000000') <= lastMonthGroup.yearMonth;
    const accountItems = accountItemsGroupedByCompanyId[relatedCompany.id];
    const sections = [...(sectionsGroupedByCompanyId[relatedCompany.id] || []), noneSection(relatedCompany, company)];

    const [prevMonthComapnyMonthGroup, ...companyMonthGroups] = [prevMonthGroupedMonthGroup, ...groupedMonthGroups].map((monthGroup) => {
      return {
        ...monthGroup,
        individualAdjustmentJournals: monthGroup.individualAdjustmentJournalsGroupedByCompanyId[relatedCompany.id] || [],
        trials: monthGroup.trialsGroupedByCompanyId[relatedCompany.id] || [],
        sectionTrials: monthGroup.sectionTrialsGroupedByCompanyId[relatedCompany.id] || [],
        exchangedItem: monthGroup.exchangedItemsByCompanyId[relatedCompany.id] || [],
      };
    });

    const singleAmounts = computeSingleSegmentsAmounts(company, relatedCompany, allConsolidationAccountItems, accountItems, relatedCompany.currency, sections, allConsolidationSegments, companyMonthGroups, prevMonthComapnyMonthGroup).map(_ => ({ ..._, company: relatedCompany, }));
    return shouldConsolidate ? singleAmounts : singleAmounts.map(_ => ({ ..._, segmentColumns: _.segmentColumns.map(_ => mapValues(_, v => isNumber(v) ? 0 : v)) }));
  })).map(([item, ...companyItems]) => {
    const { itemType } = item;
    const categoryName = ({ consolidationAccountItem: item.account_category, category: item.name })[itemType];
    const accountItemCategory = accountItemCategoriesByName[categoryName];
    const { direction } = accountItemCategory;
    const debitItems = ({
      consolidationAccountItem: () => consolidationJournalItemsGroupedByDebitItemId[item.id] || [],
      category : () => uniq(item.selfAndDescendants().flatMap(category => consolidationJournalItemsGroupedByDebitCategory[category.name] || [])),
    })[itemType]();
    const creditItems = ({
      consolidationAccountItem: () => consolidationJournalItemsGroupedByCreditItemId[item.id] || [],
      category : () => uniq(item.selfAndDescendants().flatMap(category => consolidationJournalItemsGroupedByCreditCategory[category.name] || [])),
    })[itemType]();
    const segmentColumns = allConsolidationSegments.map((segment) => {
      const debitAmount = sumBy(debitItems.filter(_ => _.debitSegmentId === segment.id), 'debitAmount');
      const debitIntersegmentAmount = sumBy(debitItems.filter(_ => _.debitSegmentId === segment.id).filter(_ => _.debitIsIntersegment), 'debitAmount');
      const creditAmount = sumBy(creditItems.filter(_ => _.creditSegmentId === segment.id), 'creditAmount');
      const creditIntersegmentAmount = sumBy(creditItems.filter(_ => _.creditSegmentId === segment.id).filter(_ => _.creditIsIntersegment), 'creditAmount');
      const consolidationAmount = (debitAmount - creditAmount) * ({ debit: 1, credit: -1 })[direction];
      const consolidationIntersegmentAmount = (debitIntersegmentAmount - creditIntersegmentAmount) * ({ debit: 1, credit: -1 })[direction];

      return {
        segment,
        consolidationAmount,
        consolidationIntersegmentAmount,
      };
    });

    return {
      key: item.id || item.key(),
      item,
      itemType,
      consolidationAccountItem: item,
      accountItemCategory,
      companyItems,
      segmentColumns,
    };
  });
  const { consolidationAccountItem: consolidationAccountItemSummarizedItems, category: categorySummarizedItems } = groupBy(summarizedItems, 'itemType');
  const consolidationAccountItemSummarizedItemsGroupedByCategory = groupBy(consolidationAccountItemSummarizedItems, 'accountItemCategory.name');
  const categorySummarizedItemsByCategory = keyBy(categorySummarizedItems, 'accountItemCategory.name');

  const computedCategorySummarizedItems = categorySummarizedItems.map((categoryItem) => {
    const { accountItemCategory } = categoryItem;
    const segmentColumns = categoryItem.segmentColumns.map((segmentColumn, i) => {
      return {
        ...segmentColumn,
        consolidationAmount: accountItemCategory.compute(mapValues(categorySummarizedItemsByCategory, _ => _.segmentColumns[i].consolidationAmount)),
        consolidationIntersegmentAmount: accountItemCategory.compute(mapValues(categorySummarizedItemsByCategory, _ => _.segmentColumns[i].consolidationIntersegmentAmount)),
      };
    });
    return {
      ...categoryItem,
      segmentColumns,
    };
  });

  return [...consolidationAccountItemSummarizedItems, ...computedCategorySummarizedItems];
};

const disclosureAccountItems = (allConsolidationAccountItems) => {
  return uniqBy(allConsolidationAccountItems, _ => [_.account_category, _.group_name].join('__'));
};

const computeDisclosureAmounts = (company, relatedCompanies, allConsolidationJournalTypes, _allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup, periodAmounts, usesPrevPeriodAmounts = false, disclosureSetting) => {
  const allConsolidationAccountItems = _allConsolidationAccountItems.filter(_ => getCategory(_));
  const items = computeCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup, periodAmounts, usesPrevPeriodAmounts);
  const categoryItems = items.filter(_ => _.itemType === 'category');
  const itemsGroupedByDisclosureName = groupBy(items.filter(_ => _.itemType === 'consolidationAccountItem'), _ => [_.item.account_category, _.item.group_name].join('__'));
  const itemsGroupedByCategory = groupBy(categoryItems, 'item.name');
  const computedItems = [...disclosureAccountItems(allConsolidationAccountItems).map(_ => ({ ..._, itemType: 'disclosureAccountItem' })), ...accountItemCategories.map(_ => ({ ..._, itemType: 'category' }))].map((item) => {
    const { account_category: categoryName, itemType, group_name: disclosureName, } = item;
    const accountItemCategory = ({ disclosureAccountItem: accountItemCategoriesByName[item.account_category], category: item })[itemType];
    const companyItems = ({
      disclosureAccountItem: () => {
        const items = itemsGroupedByDisclosureName[[categoryName, disclosureName].join('__')];
        return items;
      },
      category: () => {
        const items = itemsGroupedByCategory[item.name];
        return items;
      },
    })[itemType]();
    const amount = sumBy(companyItems, 'conclusiveAmount');
    return {
      item,
      itemType,
      accountItemCategory,
      disclosureAccountItem: item,
      amount,
      companyItems,
    };
  });
  return (() => {
    const categoryItems = computedItems.filter(_ => _.itemType === 'category');
    const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
    const itemsGroupedByCategoryDisclosureName = groupBy(computedItems, _ => _.accountItemCategory.disclosureName());
    return accountItemCategories.map((category) => {
      const items = (itemsGroupedByCategoryDisclosureName[category.disclosureName()] || []).filter(_ => _.itemType !== 'category');
      const asOtherItems = items.filter(_ => disclosureSetting?.otherDiscloseItems?.includes(_.item.group_name));
      const directItems = [
        ...items.filter(_ => !asOtherItems.includes(_)),
        ...(
          asOtherItems.length > 0 ? [
            {
              item: { name: 'その他' },
              itemType: 'consolidationAccountItem',
              amount: sumBy(asOtherItems, 'amount'),
            }
          ] : []
        ),
      ];
      const categoryItem = categoryItemsByCategory[category.name];
      return {
        accountItemCategory: category,
        directItems,
        categoryItem,
      };
    });
  })();
};

const formFields = ({ otherConsolidationAccountItems = [] } = {}) => {
  return {
    name: {
      type: 'string',
      label: '連結科目名',
      validations: {
        required: v => !isEmpty(v),
        exclusive: v => ![...otherConsolidationAccountItems, ...presetConsolidationAccountItems].map(_ => _.name).includes(v),
      },
    },
    shortcut_num: {
      label: '科目コード',
      type: 'string',
    },
    account_category: {
      type: 'select',
      label: 'カテゴリ',
      options: accountItemCategoryChildren.filter(_ => !_.computable).map(_ => ({ label: [...[...(_.parents() || [])].reverse(), _.name].join(' > '), value: _.name })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const cfMappingItemFields = ({ cfChangeTypes = [], cfAccountItems = [], } = {}) => {
  return {
    pkgCategory: {
      type: 'select',
      label: 'PKGカテゴリ',
      options: entries(pkgCategories()).map(([k, v]) => ({ label: v.label, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    columnKey: {
      type: 'select',
      label: '列キー',
      options: _ => entries(pkgCategories()[_.pkgCategory]?.children || {}).map(([k, v]) => ({ label: v.label, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    cfChangeTypeId: {
      type: 'select',
      label: 'CF増減種別',
      options: cfChangeTypes.map(_ => ({ label: _.name, value: _.id, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    cfAccountItemId: {
      type: 'select',
      label: 'CF科目',
      options: cfAccountItems.map(_ => ({ label: _.name, value: _.id, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const pkgCategories = () => {
  return {
    cfWs: {
      label: '連結CFWS',
      children: {
        conclusiveAmount: {
          label: '当期末',
        },
        totalExchangeDiff: {
          label: '換算差額(B)',
        },
        diffWithoutExchange: {
          label: '換算差額等を除いた増減(A+B)',
        },
      },
      computeAmount: (consolidationAccountItem, cfAccountItem, columnKey, { cfWsRows, }) => {
        const row = cfWsRows.find(_ => _.item?.id === consolidationAccountItem.id);
        const sign = ({
          conclusiveAmount: ((row?.category.type === 'pl' && cfAccountItem?.cfAccountItemCategoryKey === 'sales' && row?.itemName !== '税引前当期純利益') ? 1 : -1),
          totalExchangeDiff: -1,
          diffWithoutExchange: -1,
        })[columnKey] || 1;
        return (row?.[columnKey] || 0) * row?.sign * sign;
      },
    },
    ...(
      [
        ['fixedAsset', '固定資産'],
        ['investment', '投資その他'],
        ['fixedDeposit', '定期預金'],
        ['loan', '外部貸付'],
        ['otherLiability', 'その他負債'],
        ['debt', '外部借入'],
        ['bond', '社債'],
        ['capital', '資本'],
        ['treasuryStock', '自己株式'],
        ['stockOption', '新株予約権'],
      ].reduce((x, [modelName, label]) => {
        const collectionName = lI.pluralize(modelName);
        const { fields, changeFieldNames } = require(`./${modelName}`);
        return {
          ...x,
          [collectionName]: {
            label,
            children: {
              ...omit(fields(), ['accountItemId', 'opening']),
            },
            computeAmount: (consolidationAccountItem, cfAccountItem, columnKey, data) => {
              const category = getCategory(consolidationAccountItem);
              const rows = data[`${modelName}Rows`].filter(_ => _.consolidationAccountItem.id === consolidationAccountItem.id);
              return sumBy(rows, (row) => {
                const amount = columnKey === 'opening' ? (
                  row.opening * row.prevCr
                ) : (row.exchangedAmounts[columnKey] || 0);
                const sign = ({ debit: -1, credit: 1 })[category.direction];
                return Math.round(amount * sign);
              });
            },
          },
        };
      }, {})
    ),
    ...(
      [
        ['fixedAssetRelatedItem', '固定資産に係る債権債務・売却損益'],
        ['investmentRelatedItem', '投資に係る債権債務・売却損益'],
        ['incomeInterestItem', '未収利息・前受利息'],
        ['expenseInterestItem', '未払利息・前払利息'],
      ].reduce((x, [modelName, label]) => {
        const collectionName = lI.pluralize(modelName);
        return {
          ...x,
          [collectionName]: {
            label,
            children: {
              change: { label: '増減', },
            },
            computeAmount: (consolidationAccountItem, cfAccountItem, columnKey, _) => {
              const rows = _[`${modelName}Rows`].filter(_ => _.consolidationAccountItem.id === consolidationAccountItem.id);
              return sumBy(rows, 'exchangedChange');
            },
          },
        };
      }, {})
    ),
  };
};

const fields = () => {
  return {
    name: {
      type: 'string',
      label: '科目名',
    },
    category: {
      type: 'select',
      label: 'カテゴリ',
      options: accountItemCategoryChildren.filter(_ => !_.computable).map(_ => ({ label: [...[...(_.parents() || [])].reverse(), _.name].join(' > '), value: _.name })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

exports.fields = fields;
exports.formFields = formFields;
exports.cfMappingItemFields = cfMappingItemFields;
exports.computeSingleAmounts = computeSingleAmounts;
exports.computeCompaniesAmounts = computeCompaniesAmounts;
exports.computeDisclosureAmounts = computeDisclosureAmounts;
exports.computeSegmentsAmounts = computeSegmentsAmounts;
exports.presetConsolidationAccountItems = presetConsolidationAccountItems;
exports.cfMappingPresetConsolidationAccountItems = cfMappingPresetConsolidationAccountItems;
exports.pkgCategories = pkgCategories;
