import React, { Component, useEffect, Fragment, useState, } from 'react';
import lI from 'lodash-inflection';
import { useToggle, useAsync, } from 'react-use';
import 'lodash.multicombinations';
import { isEmpty, orderBy, uniq, multicombinations, sum, last, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import fileDownload from 'js-file-download';
import Encoding from 'encoding-japanese';
import { unparse as unparseCsv, } from 'papaparse';
import JSZip from 'jszip';
import { format as formatDate, addMonths, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { changeTypes, } from '../../shared/changeTypes';
import { integerFormat } from '../../util';
import { prevYearMonth, disassembleJournalItems, serial, } from '../../shared/util';
import { getDocumentData, getCollectionData, } from '../../shared/firebase';
import { computeItems as computePkgItems, } from '../../shared/lib/pkgItems';
import * as AccountItemMappings from '../../shared/lib/accountItemMappings';
import * as SectionMappings from '../../shared/lib/sectionMappings';
import * as IndividualTrials from '../../shared/lib/individualTrials';
import * as SectionTrials from '../../shared/lib/sectionTrials';
import * as IndividualAdjustments from '../../shared/lib/individualAdjustments';
import * as IndividualAdjusted from '../../shared/lib/individualAdjusted';
import * as RelatedCompanyBalances from '../../shared/lib/relatedCompanyBalances';
import * as Cashes from '../../shared/lib/cashes';
import * as IndividualPkg from '../../shared/lib/individualPkg';
import * as MiscellaneousAccounts from '../../shared/lib/miscellaneousAccounts';
import * as ExchangeRates from '../../shared/lib/exchangeRates';
import * as PrevPeriodAmounts from '../../shared/lib/prevPeriodAmounts';
import * as ConsolidationAccountItems from '../../shared/lib/consolidationAccountItems';
import * as PkgCashes from '../../shared/lib/pkgCashes';
import * as Pkg from '../../shared/lib/pkg';
import * as PkgRelatedCompanyBalances from '../../shared/lib/pkgRelatedCompanyBalances';
import * as PkgMiscellaneousAccounts from '../../shared/lib/pkgMiscellaneousAccounts';
import * as ConsolidationJournals from '../../shared/lib/consolidationJournals';
import * as ConsolidationCalculation from '../../shared/lib/consolidationCalculation';
import * as Bs from '../../shared/lib/bs';
import * as Pl from '../../shared/lib/pl';
import * as Ss from '../../shared/lib/ss';
import * as Ci from '../../shared/lib/ci';
import * as CfMappings from '../../shared/lib/cfMappings';
import * as CfWs from '../../shared/lib/cfWs';
import * as CfWs2 from '../../shared/lib/cfWs2';
import * as Cf from '../../shared/lib/cf';
import * as ConsolidationSegments from '../../shared/lib/consolidationSegments';
import * as SegmentsInfo from '../../shared/lib/segmentsInfo';
import { presetConsolidationAccountItems, computeCompaniesAmounts, computeDisclosureAmounts, computeSegmentsAmounts, } from '../../shared/models/consolidationAccountItem';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import * as FixedAsset from '../../shared/models/fixedAsset';
import * as Investment from '../../shared/models/investment';
import * as FixedDeposit from '../../shared/models/fixedDeposit';
import * as Loan from '../../shared/models/loan';
import * as OtherLiability from '../../shared/models/otherLiability';
import * as Debt from '../../shared/models/debt';
import * as Bond from '../../shared/models/bond';
import * as Capital from '../../shared/models/capital';
import * as TreasuryStock from '../../shared/models/treasuryStock';
import * as StockOption from '../../shared/models/stockOption';
import * as MiscellaneousAccount from '../../shared/models/miscellaneousAccount';
import * as Allowance from '../../shared/models/allowance';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import { presetCfAccontItems, } from '../../shared/models/cfAccountItem';
import { accountItemCategories, accountItemCategoryNames, cfAccountItemCategories, } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import Alerts from '../Alerts';
import { encode } from '../../util';

const { keys, entries } = Object;
const { abs } = Math;
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');

export default CompanyPage(function CompanyBs (props) {
  const { company, history, location, prevEndYearMonth, filteredYearMonths, prevYearMonths, } = props;
  const [alerts, setAlerts] = useState([]);
  const { period, yearMonth, } = useQueryParams();
  const prevMonth = prevYearMonth(yearMonth);
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = [company, ...subsidiaries];
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const onClickExecute = async () => {
    const zip = new JSZip();
    const periodSetting = await getDocumentData(company.ref.collection('periodSettings').doc(period));
    const sections = await getCollectionData(company.ref.collection('sections'));
    const consolidationSegments = await getCollectionData(company.ref.collection('consolidationSegments'));
    const sortedConsolidationSegments = sortBy(consolidationSegments, 'index');
    const allConsolidationSegments = [...sortedConsolidationSegments, generalSegment];
    const accountItems = await getCollectionData(company.ref.collection('accountItems'));
    const accountItemsGroupedByRelatedCompanyId = groupBy(accountItems, _ => _.subsidiaryId ?? company.id);
    const accountItemsById = keyBy(accountItems, 'id');
    const consolidationAccountItems = await getCollectionData(company.ref.collection('accountItems').where('subsidiaryId', '==', null));
    const sortedConsolidationAccountItems = sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index');
    const allConsolidationAccountItems = [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems];
    const allConsolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
    const consolidationJournalTypes = await getCollectionData(company.ref.collection('consolidationJournalTypes'));
    const sortedConsolidationJournalTypes = sortBy(consolidationJournalTypes, 'index');
    const allConsolidationJournalTypes = [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes];
    const consolidationJournalTypesById = keyBy(allConsolidationJournalTypes, 'id');
    const prevPeriodAmounts = await getCollectionData(company.ref.collection('prevPeriodAmounts').where('period', '==', period));
    const relatedCompanyBalanceLinks = await getCollectionData(company.ref.collection('relatedCompanyBalanceLinks').where('yearMonth', '==', yearMonth));
    const linkedConsolidationJournals = (await Promise.all(relatedCompanyBalanceLinks.map(_ => getCollectionData(company.ref.collection('consolidationJournals').where('linkId', '==', _.id).limit(1))))).flat();
    const miscellaneousAccounts = await getCollectionData(company.ref.collection('miscellaneousAccounts').where('yearMonth', '==', yearMonth));

    const [pkgItemGroups, prevYearPkgItemGroups] = await serial([filteredYearMonths, prevYearMonths], async (yearMonths) => {
      return (await Promise.all([
        ['relatedCompanyBalance', 'relatedCompanyBalances', ['opponentCompanyId', 'sectionId'], 'amount', []],
        ['cash', 'cashes', [], 'amount', []],
        ...[
          'fixedAsset',
          'investment',
          'fixedDeposit',
          'loan',
          'otherLiability',
          'debt',
          'bond',
          'capital',
          'treasuryStock',
          'stockOption',
          'allowance',
          'fixedAssetRelatedItem',
          'investmentRelatedItem',
          'incomeInterestItem',
          'expenseInterestItem',
        ].map(_ => [_, lI.pluralize(_), [], 'closing', require(`../../shared/models/${_}`).changeFieldNames || []]),
      ].map(async ([modelName, collectionName, uniqKeyFields, closingFieldName, changeFieldNames]) => {
        const targetChangeTypes = Object.keys(changeTypes).filter(_ => _.split('__')[0] === modelName);
        return [collectionName, (await serial(relatedCompanies, async (relatedCompany) => {
          const subsidiaryId = relatedCompany.id === company.id ? null : relatedCompany.id;
          const { pkgItems, pkgIndividualAdjustments } = await computePkgItems(company, relatedCompany, subsidiaryId, accountItems, collectionName, uniqKeyFields, closingFieldName, changeFieldNames, yearMonths);
          const pkgItemsGroupedByConsolidationAccountId = groupBy(pkgItems, _ => accountItemsById[_.accountItemId]?.consolidationAccountItemId);
          const pkgIndividualAdjustmentsGroupedByConsolidationAccountId = groupBy(pkgIndividualAdjustments, _ => _.accountItem.consolidationAccountItemId);
          const [consolidationJournals, prevYearConsolidationJournals] = await Promise.all([last(yearMonths), addMonths(yearMonths[0], -1)].map(_ => getCollectionData(company.ref.collection('consolidationJournals').where('changeTypes', 'array-contains-any', isEmpty(targetChangeTypes) ? ['dummy'] : targetChangeTypes).where('yearMonth', '==', formatDate(_, 'yyyyMM')))));
          const [consolidationJournalItemsGroupedByItemId, prevYearConsolidationJournalItemsGroupedByItemId] = [consolidationJournals, prevYearConsolidationJournals].map(journals => (
            groupBy(journals.flatMap(disassembleJournalItems).filter(_ => _.changeType?.split('__')[0] === lI.singularize(collectionName) && _.companyId === relatedCompany.id), 'itemId')
          ));
          return {
            relatedCompany,
            pkgItems,
            pkgIndividualAdjustments,
            pkgItemsGroupedByConsolidationAccountId,
            pkgIndividualAdjustmentsGroupedByConsolidationAccountId,
            consolidationJournalItemsGroupedByItemId,
            prevYearConsolidationJournalItemsGroupedByItemId,
          };
        })).reduce((x, y) => ({ ...x, [y.relatedCompany.id]: y }), {})];
      }))).reduce((x, y) => ({ ...x, [y[0]]: y[1] }), {});
    });
    const computeMonthGroups = async (yearMonthDates) => {
      const yearMonths = (company.usesMonthlyAr ? yearMonthDates : [last(yearMonthDates)]).map(_ => formatDate(_, 'yyyyMM'));
      const getData = async (yearMonth) => {
        const trials = await getCollectionData(company.ref.collection('trials').where('yearMonth', '==', yearMonth));
        const sectionTrials = await getCollectionData(company.ref.collection('sectionTrials').where('yearMonth', '==', yearMonth));
        const individualAdjustmentJournals = await getCollectionData(company.ref.collection('individualAdjustmentJournals').where('yearMonth', '==', yearMonth));
        const consolidationJournals = await getCollectionData(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth));
        const exchangedItems = await getCollectionData(company.ref.collection('exchangedItems').where('yearMonth', '==', yearMonth));
        const exchangeRate = await getDocumentData(company.ref.collection('exchangeRates').doc(yearMonth));
        return {
          yearMonth,
          trials,
          trialsGroupedByCompanyId: groupBy(trials, _ => _.subsidiaryId || company.id),
          sectionTrials,
          sectionTrialsGroupedByCompanyId: groupBy(sectionTrials, _ => _.subsidiaryId || company.id),
          individualAdjustmentJournals,
          individualAdjustmentJournalsGroupedByCompanyId: groupBy(individualAdjustmentJournals, _ => _.subsidiaryId || company.id),
          consolidationJournals,
          exchangedItems,
          exchangeRate,
        };
      };
      const monthGroups = await Promise.all(yearMonths.map(getData));
      const prevMonthGroup = company.usesMonthlyAr ? await getData(formatDate(addMonths(yearMonthDates[0], -1), 'yyyyMM')) : null;
      return { monthGroups, prevMonthGroup, };
    };
    const { monthGroups, prevMonthGroup, } = await computeMonthGroups(filteredYearMonths);
    const companiesAmountItems = computeCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup);
    const displayConsolidationJournalTypes = uniq(companiesAmountItems.flatMap(_ => _.consolidationColumns.filter(_ => abs(_.amount) > 0).map(_ => _.type)));

    const prevMonthYearMonths = filteredYearMonths.slice(0, filteredYearMonths.length - 1);
    const { monthGroups: prevMonthMonthGroups, prevMonthGroup: prevMonthPrevMonthGroup, } = await computeMonthGroups(prevMonthYearMonths.length > 0 ? prevMonthYearMonths : prevYearMonths);
    const prevMonthCompaniesAmountItems = computeCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevMonthMonthGroups, prevMonthPrevMonthGroup);

    const { monthGroups: prevYearMonthGroups, prevMonthGroup: prevYearPrevMonthGroup, } = await computeMonthGroups(prevYearMonths);
    const prevYearCompaniesAmountItems = computeCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevYearMonthGroups, prevYearPrevMonthGroup);

    const lastPrevYearMonthGroup = last(prevYearMonthGroups);
    const lastMonthGroup = last(monthGroups);

    const retainedEarningItems = computeCompaniesAmounts(
      company,
      relatedCompanies,
      allConsolidationJournalTypes,
      allConsolidationAccountItems,
      accountItems,
      // NOTE: 当期の仕訳のisOpeningを使う
      (prevYearMonthGroups || []).map((_, i) => ({ ..._, consolidationJournals: ConsolidationCalculation.filterRetainedEarningConsolidationJournals(allConsolidationAccountItemsById, last(monthGroups)?.consolidationJournals || []), })),
      (prevYearPrevMonthGroup != null ? { ...prevYearPrevMonthGroup, consolidationJournals: ConsolidationCalculation.filterRetainedEarningConsolidationJournals(allConsolidationAccountItemsById, prevMonthGroup?.consolidationJournals || []), } : null),
    )

    const disclosureSetting = await getDocumentData(company.ref.collection('disclosureSettings').doc(yearMonth));
    const prevYearDisclosureSetting = await getDocumentData(prevEndYearMonth && company.ref.collection('disclosureSettings').doc(prevEndYearMonth));
    const generalAmount = await getDocumentData(company.ref.collection('amounts').doc(period));
    const ssItem = await getDocumentData(company.ref.collection('ssItems').doc(yearMonth));
    const equity = await getDocumentData(company.ref.collection('equities').doc(yearMonth));
    const prevYearEquity = await getDocumentData(prevEndYearMonth && company.ref.collection('equities').doc(prevEndYearMonth));

    const disclosureAmountItems = computeDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, monthGroups, prevMonthGroup, null, false, disclosureSetting);
    const prevYearDisclosureAmountItems = computeDisclosureAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevYearMonthGroups, prevYearPrevMonthGroup, prevPeriodAmounts, periodSetting?.usesPrevPeriodAmounts, prevYearDisclosureSetting);

    const cfChangeTypes = await getCollectionData(company.ref.collection('cfChangeTypes'));
    const sortedCfChangeTypes = sortBy(cfChangeTypes, 'index');
    const cfChange = await getDocumentData(company.ref.collection('cfChanges').doc(yearMonth));
    const cfAccountItems = await getCollectionData(company.ref.collection('cfAccountItems').orderBy('name'));
    const allCfAccountItems = [...cfAccountItems, ...presetCfAccontItems];
    const sortedCfAccountItems = orderBy(allCfAccountItems, [_ => cfAccountItemCategoryKeys.indexOf(_.cfAccountItemCategoryKey), 'index'], ['asc', 'asc']);

    const segmentsAmountItems = computeSegmentsAmounts(company, relatedCompanies, allConsolidationAccountItems, accountItems, sections, allConsolidationSegments, monthGroups, prevMonthGroup);

    const joinedRelatedCompanies = relatedCompanies.filter(_ => _.joinPeriod === period);
    const exceptedRelatedCompanies = relatedCompanies.filter(_ => _.exceptPeriod === period);
    const joinItems = await CfWs.computeJoinOrExceptRelatedCompaniesAmounts(company, joinedRelatedCompanies, 'joinYearMonth', 'isStartJoin', allConsolidationAccountItems, accountItems);
    const exceptItems = await CfWs.computeJoinOrExceptRelatedCompaniesAmounts(company, exceptedRelatedCompanies, 'exceptYearMonth', 'isStartExcept', allConsolidationAccountItems, accountItems);

    const relatedCompanyAlertsAndExports = relatedCompanies.map((relatedCompany, relatedCompanyIndex) => {
      const { currency = 'jpy' } = relatedCompany;
      const accountItems = accountItemsGroupedByRelatedCompanyId[relatedCompany.id];
      const prevCr = currency === 'jpy' ? 1 : lastPrevYearMonthGroup.exchangeRate[`${currency}-cr`];
      const cr = currency === 'jpy' ? 1 : lastMonthGroup.exchangeRate[`${currency}-cr`];
      const trials = lastMonthGroup.trialsGroupedByCompanyId[relatedCompany.id] || [];
      const sectionTrials = lastMonthGroup.sectionTrialsGroupedByCompanyId[relatedCompany.id] || [];
      const individualAdjustmentJournals = lastMonthGroup.individualAdjustmentJournalsGroupedByCompanyId[relatedCompany.id] || [];
      const singleAmountItems = companiesAmountItems.map(_ => _.companyColumns[relatedCompanyIndex]);
      const prevYearSingleAmountItems = prevYearCompaniesAmountItems.map(_ => _.companyColumns[relatedCompanyIndex]);
      const alerts = [
        ...IndividualTrials.computeAlerts(relatedCompany, trials, accountItems, allConsolidationAccountItems).map(_ => relatedCompany.display_name + ': [試算表] ' + _),
        ...SectionTrials.computeAlerts(relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems).map(_ => relatedCompany.display_name + ': [部門別試算表] ' + _),
        ...IndividualAdjusted.computeAlerts(relatedCompany, singleAmountItems, prevYearSingleAmountItems).map(_ => relatedCompany.display_name + ': [個別修正・換算後] ' + _),
        ...Cashes.computeAlerts(pkgItemGroups.cashes[relatedCompany.id].pkgItems, accountItems, trials).map(_ => relatedCompany.display_name + ': ' + '[現金及び現金同等物] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.fixedAssets[relatedCompany.id].pkgItems, accountItems, FixedAsset.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[固定資産 増減] ' + _),
        ...IndividualPkg.computeSimpleAlerts(pkgItemGroups.fixedAssetRelatedItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[固定資産 取得または売却にかかる債権債務・売却損益] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.investments[relatedCompany.id].pkgItems, accountItems, Investment.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[投資関連 投資その他増減] ' + _),
        ...IndividualPkg.computeSimpleAlerts(pkgItemGroups.investmentRelatedItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[投資関連 取得または売却にかかる債権債務・売却損益] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.fixedDeposits[relatedCompany.id].pkgItems, accountItems, FixedDeposit.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[投資関連 定期預金] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.loans[relatedCompany.id].pkgItems, accountItems, Loan.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[投資関連 外部貸付] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.otherLiabilities[relatedCompany.id].pkgItems, accountItems, OtherLiability.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[投資関連 その他負債] ' + _),
        ...IndividualPkg.computeSimpleAlerts(pkgItemGroups.incomeInterestItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[投資関連 未収利息・前受利息] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.debts[relatedCompany.id].pkgItems, accountItems, Debt.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[財務関連 外部借入] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.bonds[relatedCompany.id].pkgItems, accountItems, Bond.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[財務関連 社債] ' + _),
        ...IndividualPkg.computeSimpleAlerts(pkgItemGroups.expenseInterestItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[財務関連 未払利息・前払利息] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.capitals[relatedCompany.id].pkgItems, accountItems, Capital.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[財務関連 資本] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.treasuryStocks[relatedCompany.id].pkgItems, accountItems, TreasuryStock.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[財務関連 自己株式] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.stockOptions[relatedCompany.id].pkgItems, accountItems, StockOption.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[財務関連 新株予約権] ' + _),
        ...MiscellaneousAccounts.computeAlerts(miscellaneousAccounts.filter(_ => _.companyId === relatedCompany.id), accountItems, trials).map(_ => relatedCompany.display_name + ': ' + '[雑勘定] ' + _),
        ...IndividualPkg.computeAlerts(relatedCompany, pkgItemGroups.allowances[relatedCompany.id].pkgItems, accountItems, Allowance.changeFieldNames, prevCr, cr, trials).map(_ => relatedCompany.display_name + ': ' + '[引当金] ' + _),
      ];
      const exports = [
        [`勘定科目`, AccountItemMappings.rowsForExport(accountItems, consolidationAccountItems, relatedCompany.id === company.id)()],
        [`部門`, SectionMappings.rowsForExport(sections, consolidationSegments)()],
        [`試算表`, IndividualTrials.rowsForExport(relatedCompany, trials, accountItems, consolidationAccountItems)()],
        [`部門別試算表`, SectionTrials.rowsForExport(relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems)()],
        [`個別修正`, IndividualAdjustments.rowsForExport(company, individualAdjustmentJournals, sections, accountItems, consolidationAccountItems)()],
        [`個別修正・換算後`, IndividualAdjusted.rowsForExport(singleAmountItems)()],
        [`関係会社債権債務・取引高`, RelatedCompanyBalances.rowsForExport(company, pkgItemGroups.relatedCompanyBalances[relatedCompany.id].pkgItems, relatedCompanyBalanceLinks, lastMonthGroup.consolidationJournals, relatedCompanies, accountItems, sections)()],
        [`現金及び現金同等物`, Cashes.rowsForExport(pkgItemGroups.cashes[relatedCompany.id].pkgItems, accountItems, trials)()],
        [`固定資産_増減`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.fixedAssets[relatedCompany.id].pkgItems, accountItems, FixedAsset.changeFieldNames, prevCr, cr, trials)()],
        [`固定資産_取得または売却にかかる債権債務・売却損益`, IndividualPkg.simpleRowsForExport(pkgItemGroups.fixedAssetRelatedItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials)()],
        [`投資関連_投資その他増減`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.investments[relatedCompany.id].pkgItems, accountItems, Investment.changeFieldNames, prevCr, cr, trials)()],
        [`投資関連_取得または売却にかかる債権債務・売却損益`, IndividualPkg.simpleRowsForExport(pkgItemGroups.investmentRelatedItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials)()],
        [`投資関連_定期預金`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.fixedDeposits[relatedCompany.id].pkgItems, accountItems, FixedDeposit.changeFieldNames, prevCr, cr, trials)()],
        [`投資関連_外部貸付`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.loans[relatedCompany.id].pkgItems, accountItems, Loan.changeFieldNames, prevCr, cr, trials)()],
        [`投資関連_その他負債`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.otherLiabilities[relatedCompany.id].pkgItems, accountItems, OtherLiability.changeFieldNames, prevCr, cr, trials)()],
        [`投資関連_未収利息・前受利息`, IndividualPkg.simpleRowsForExport(pkgItemGroups.incomeInterestItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials)()],
        [`財務関連_外部借入`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.debts[relatedCompany.id].pkgItems, accountItems, Debt.changeFieldNames, prevCr, cr, trials)()],
        [`財務関連_社債`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.bonds[relatedCompany.id].pkgItems, accountItems, Bond.changeFieldNames, prevCr, cr, trials)()],
        [`財務関連_未払利息・前払利息`, IndividualPkg.simpleRowsForExport(pkgItemGroups.expenseInterestItems[relatedCompany.id].pkgItems, accountItems, prevCr, cr, trials)()],
        [`財務関連_資本`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.capitals[relatedCompany.id].pkgItems, accountItems, Capital.changeFieldNames, prevCr, cr, trials)()],
        [`財務関連_自己株式`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.treasuryStocks[relatedCompany.id].pkgItems, accountItems, TreasuryStock.changeFieldNames, prevCr, cr, trials)()],
        [`財務関連_新株予約権`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.stockOptions[relatedCompany.id].pkgItems, accountItems, StockOption.changeFieldNames, prevCr, cr, trials)()],
        [`雑勘定`, MiscellaneousAccounts.rowsForExport(miscellaneousAccounts.filter(_ => _.companyId === relatedCompany.id), accountItems, trials)()],
        [`引当金`, IndividualPkg.rowsForExport(relatedCompany, pkgItemGroups.allowances[relatedCompany.id].pkgItems, accountItems, Allowance.changeFieldNames, prevCr, cr, trials)()],
      ];
      return {
        relatedCompany,
        alerts,
        exports,
      };
    });
    const alerts = [
      ...relatedCompanyAlertsAndExports.flatMap(_ => _.alerts),
      ...PkgRelatedCompanyBalances.computeAlerts(Object.values(pkgItemGroups.relatedCompanyBalances).flatMap(_ => _.pkgItems), relatedCompanyBalanceLinks, linkedConsolidationJournals).map(_ => '全社連結PKG: [債権債務取引高] ' + _),
      ...[
        [`固定資産_増減`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.fixedAssets), Object.values(prevYearPkgItemGroups.fixedAssets), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, FixedAsset.changeFieldNames, FixedAsset.fields)],
        [`投資関連_投資その他増減`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.investments), Object.values(prevYearPkgItemGroups.investments), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Investment.changeFieldNames, Investment.fields)],
        [`投資関連_定期預金`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.fixedDeposits), Object.values(prevYearPkgItemGroups.fixedDeposits), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, FixedDeposit.changeFieldNames, FixedDeposit.fields)],
        [`投資関連_外部貸付`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.loans), Object.values(prevYearPkgItemGroups.loans), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Loan.changeFieldNames, Loan.fields)],
        [`投資関連_その他負債`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.otherLiabilities), Object.values(prevYearPkgItemGroups.otherLiabilities), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, OtherLiability.changeFieldNames, OtherLiability.fields)],
        [`財務関連_外部借入`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.debts), Object.values(prevYearPkgItemGroups.debts), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Debt.changeFieldNames, Debt.fields)],
        [`財務関連_社債`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.bonds), Object.values(prevYearPkgItemGroups.bonds), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Bond.changeFieldNames, Bond.fields)],
        [`財務関連_資本`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.capitals), Object.values(prevYearPkgItemGroups.capitals), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Capital.changeFieldNames, Capital.fields)],
        [`財務関連_自己株式`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.treasuryStocks), Object.values(prevYearPkgItemGroups.treasuryStocks), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, TreasuryStock.changeFieldNames, TreasuryStock.fields)],
        [`財務関連_新株予約権`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.stockOptions), Object.values(prevYearPkgItemGroups.stockOptions), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, StockOption.changeFieldNames, StockOption.fields)],
        [`引当金`, Pkg.computeAlerts(relatedCompanies, Object.values(pkgItemGroups.allowances), Object.values(prevYearPkgItemGroups.allowances), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Allowance.changeFieldNames, Allowance.fields)],
      ].flatMap(([name, alerts]) => alerts.map(_ => `全社連結PKG: [${name}] `  + _)),
      ...ConsolidationJournals.computeAlerts(company, lastMonthGroup.consolidationJournals).map(_ => '開始・連結仕訳: ' + _),
      ...ConsolidationCalculation.computeAlerts(companiesAmountItems, retainedEarningItems, prevYearCompaniesAmountItems, company).map(_ => '連結精算表: ' + _),
      ...Bs.computeAlerts(disclosureAmountItems).map(_ => '連結BS: ' + _),
      ...Ss.computeAlerts(disclosureAmountItems, prevYearDisclosureAmountItems, ssItem).map(_ => '連結SS: ' + _),
      ...CfWs.computeAlerts(companiesAmountItems, sortedCfChangeTypes, cfChange).map(_ => '連結CFWS: ' + _),
      ...CfWs2.computeAlerts(cfChange, sortedCfAccountItems, sortedCfChangeTypes, companiesAmountItems).map(_ => '連結CFWS2: ' + _),
      ...Cf.computeAlerts(cfChange, sortedCfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount, Object.values(pkgItemGroups.cashes), company, lastMonthGroup.individualAdjustmentJournals, lastMonthGroup.consolidationJournals, relatedCompanies, accountItems, consolidationAccountItems, lastMonthGroup.exchangeRate).map(_ => '連結CF: ' + _),
    ];

    setAlerts(alerts);
    relatedCompanyAlertsAndExports.map(({ relatedCompany, exports, }) => {
      const folder = zip.folder(relatedCompany.display_name);
      exports.map(([name, rows], i) => folder.file(`${i + 1}_${name}.csv`, encode(unparseCsv(rows))));
    });
    const pkgFolder = zip.folder('全社連結PKG');
    [
      [`現金及び現金同等物`, PkgCashes.rowsForExport(Object.values(pkgItemGroups.cashes), lastMonthGroup.individualAdjustmentJournals, lastMonthGroup.consolidationJournals, company, accountItems, consolidationAccountItems, lastMonthGroup.exchangeRate)()],
      [`固定資産_増減`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.fixedAssets), Object.values(prevYearPkgItemGroups.fixedAssets), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, FixedAsset.changeFieldNames, FixedAsset.fields)()],
      [`固定資産_取得または売却にかかる債権債務・売却損益`, Pkg.simpleRowsForExport(relatedCompanies, Object.values(pkgItemGroups.fixedAssetRelatedItems), company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate)()],
      [`投資関連_投資その他増減`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.investments), Object.values(prevYearPkgItemGroups.investments), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Investment.changeFieldNames, Investment.fields)()],
      [`投資関連_取得または売却にかかる債権債務・売却損益`, Pkg.simpleRowsForExport(relatedCompanies, Object.values(pkgItemGroups.investmentRelatedItems), company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate)()],
      [`投資関連_定期預金`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.fixedDeposits), Object.values(prevYearPkgItemGroups.fixedDeposits), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, FixedDeposit.changeFieldNames, FixedDeposit.fields)()],
      [`投資関連_外部貸付`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.loans), Object.values(prevYearPkgItemGroups.loans), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Loan.changeFieldNames, Loan.fields)()],
      [`投資関連_その他負債`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.otherLiabilities), Object.values(prevYearPkgItemGroups.otherLiabilities), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, OtherLiability.changeFieldNames, OtherLiability.fields)()],
      [`投資関連_未収利息・前受利息`, Pkg.simpleRowsForExport(relatedCompanies, Object.values(pkgItemGroups.incomeInterestItems), company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate)()],
      [`財務関連_外部借入`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.debts), Object.values(prevYearPkgItemGroups.debts), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Debt.changeFieldNames, Debt.fields)()],
      [`財務関連_社債`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.bonds), Object.values(prevYearPkgItemGroups.bonds), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Bond.changeFieldNames, Bond.fields)()],
      [`財務関連_未払利息・前払利息`, Pkg.simpleRowsForExport(relatedCompanies, Object.values(pkgItemGroups.expenseInterestItems), company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate)()],
      [`財務関連_資本`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.capitals), Object.values(prevYearPkgItemGroups.capitals), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Capital.changeFieldNames, Capital.fields)()],
      [`財務関連_自己株式`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.treasuryStocks), Object.values(prevYearPkgItemGroups.treasuryStocks), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, TreasuryStock.changeFieldNames, TreasuryStock.fields)()],
      [`財務関連_新株予約権`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.stockOptions), Object.values(prevYearPkgItemGroups.stockOptions), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, StockOption.changeFieldNames, StockOption.fields)()],
      [`雑勘定`, PkgMiscellaneousAccounts.rowsForExport(miscellaneousAccounts, company, relatedCompanies, accountItems, consolidationAccountItems, lastMonthGroup.exchangeRate)()],
      [`引当金`, Pkg.rowsForExport(relatedCompanies, Object.values(pkgItemGroups.allowances), Object.values(prevYearPkgItemGroups.allowances), companiesAmountItems, company, allConsolidationJournalTypes, accountItemsById, consolidationAccountItems, lastPrevYearMonthGroup.exchangeRate, lastMonthGroup.exchangeRate, Allowance.changeFieldNames, Allowance.fields)()],
    ].map(([name, rows], i) => pkgFolder.file(`${i + 1}_${name}.csv`, encode(unparseCsv(rows))));
    [
      [`為替レート`, ExchangeRates.rowsForExport(lastMonthGroup.exchangeRate)()],
      [`前期数値`, PrevPeriodAmounts.rowsForExport(company, prevPeriodAmounts, allConsolidationAccountItems)()],
      [`連結科目`, ConsolidationAccountItems.rowsForExport(consolidationAccountItems, accountItems, company, relatedCompanies, true)()],
      [`連結仕訳`, ConsolidationJournals.rowsForExport(company, lastMonthGroup.consolidationJournals, relatedCompanies, multicombinations, allConsolidationSegments, allConsolidationAccountItems, consolidationJournalTypesById)()],
      [`連結精算表`, ConsolidationCalculation.rowsForExport(company, companiesAmountItems, prevMonthCompaniesAmountItems, retainedEarningItems, displayConsolidationJournalTypes, consolidationJournalTypesById)()],
      [`連結BS`, Bs.rowsForExport(disclosureAmountItems)()],
      [`連結PL`, Pl.rowsForExport(disclosureAmountItems)()],
      [`連結SS`, Ss.rowsForExport(disclosureAmountItems, prevYearDisclosureAmountItems, ssItem)()],
      [`連結CI`, Ci.rowsForExport(disclosureAmountItems, prevYearDisclosureAmountItems, equity, prevYearEquity)()],
      [`CFマッピング`, CfMappings.rowsForExport(allConsolidationAccountItems, cfChangeTypes, allCfAccountItems)()],
      [`連結CFWS`, CfWs.rowsForExport(company, relatedCompanies, period, companiesAmountItems, prevYearCompaniesAmountItems, joinItems, exceptItems, sortedCfChangeTypes, cfChange, sortedCfAccountItems, true, yearMonth)()],
      [`連結CFWS2`, CfWs2.rowsForExport(cfChange, sortedCfAccountItems, sortedCfChangeTypes, disclosureSetting)()],
      [`連結CF`, Cf.rowsForExport(cfChange, cfChangeTypes, sortedCfAccountItems, disclosureSetting, generalAmount)()],
      [`連結セグメント`, ConsolidationSegments.rowsForExport(sortedConsolidationSegments, sections, company, relatedCompanies)()],
      [`連結セグメント情報`, SegmentsInfo.rowsForExport(segmentsAmountItems, companiesAmountItems, allConsolidationSegments)()],
    ].map(([name, rows], i) => zip.file(`${i + 1}_${name}.csv`, encode(unparseCsv(rows))));
    const zipFile = await zip.generateAsync({ type: 'blob' })
    fileDownload(zipFile, `${yearMonth}.zip`);
  };

  return props.translate(
    <div className="company-account-item-mappings container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-center mb-3">
          <h4>全体チェック</h4>
        </div>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <ProgressButton process={onClickExecute} data-operation-type="read">
            全体チェック &amp; エクスポート実行
          </ProgressButton>
        </div>
        <Alerts alerts={alerts} />
      </div>
    </div>
  );
});
