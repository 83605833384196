import React, { Component, useEffect, Fragment, useMemo, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { last, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, uniq, isEmpty, } from 'lodash';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import classnames from 'classnames';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { integerFormat, integerChangeFormat, } from '../../util';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { presetConsolidationAccountItems, computeCompaniesAmounts, } from '../../shared/models/consolidationAccountItem';
import { isTrialNetIncome0, } from '../../shared/models/trial';
import { accountItemCategories, accountItemCategoryNames, } from '../../shared/config';
import { prevYearMonth, } from '../../shared/util';
import { filterRetainedEarningConsolidationJournals, generateRowGroups, rowsForExport, computeAlerts, } from '../../shared/lib/consolidationCalculation';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useCompaniesAmounts from '../hooks/useCompaniesAmounts';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import ExportButton from '../ExportButton';
import WithLoading from '../WithLoading';
import Field from '../Field';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const { abs } = Math;
const COLUMN_WIDTH = 120;

export default CompanyPage(function CompanyConsolidationCalculation (props) {
  const { company, history, location, period, yearMonth, prevYearMonths, filteredYearMonths, prevEndYearMonth, isLockedMonth, } = props;
  const prevMonthYearMonths = useMemo(_ => filteredYearMonths.slice(0, filteredYearMonths.length - 1), [filteredYearMonths]);
  const queryParams = useQueryParams();
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [company, subsidiaries]);
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const sortedConsolidationAccountItems = useMemo(_ => sortBy(consolidationAccountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index'), [consolidationAccountItems]);
  const allConsolidationAccountItems = useMemo(_ => [...sortedConsolidationAccountItems, ...presetConsolidationAccountItems], [sortedConsolidationAccountItems]);
  const allConsolidationAccountItemsById = useMemo(_ => keyBy(allConsolidationAccountItems, 'id'), [allConsolidationAccountItems]);
  const disclosureSettingRef = company.ref.collection('disclosureSettings').doc(yearMonth);
  const disclosureSetting = useDocumentSubscription(disclosureSettingRef, [yearMonth]);
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = useMemo(_ => sortBy(consolidationJournalTypes, 'index'), [consolidationJournalTypes]);
  const allConsolidationJournalTypes = useMemo(_ => [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes], [sortedConsolidationJournalTypes]);
  const consolidationJournalTypesById = useMemo(_ => keyBy(allConsolidationJournalTypes, 'id'), [allConsolidationJournalTypes]);

  const { items: prevMonthItems, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevMonthYearMonths, true);
  const { items, monthGroups, prevMonthGroup, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, filteredYearMonths, true);
  const { items: prevYearItems, monthGroups: prevYearMonthGroups, prevMonthGroup: prevYearPrevMonthGroup, } = useCompaniesAmounts(company, relatedCompanies, allConsolidationJournalTypes, allConsolidationAccountItems, accountItems, prevYearMonths, true);
  const retainedEarningItems = useMemo(_ => (
    [monthGroups, prevYearMonthGroups].every(_ => !isEmpty(_)) ? (
      computeCompaniesAmounts(
        company,
        relatedCompanies,
        allConsolidationJournalTypes,
        allConsolidationAccountItems,
        accountItems,
        // NOTE: 当期の仕訳のisOpeningを使う
        (prevYearMonthGroups || []).map((_, i) => ({ ..._, consolidationJournals: filterRetainedEarningConsolidationJournals(allConsolidationAccountItemsById, last(monthGroups)?.consolidationJournals || []), })),
        (prevYearPrevMonthGroup != null ? { ...prevYearPrevMonthGroup, consolidationJournals: filterRetainedEarningConsolidationJournals(allConsolidationAccountItemsById, prevMonthGroup?.consolidationJournals || []), } : null),
      )
    ) : null
  ), [monthGroups, prevMonthGroup, prevYearMonthGroups, prevYearPrevMonthGroup]);

  const displayConsolidationJournalTypes = uniq((items || []).flatMap(_ => _.consolidationColumns.filter(_ => abs(_.amount) > 0).map(_ => _.type)));
  const [rowGroups, retainedEarningRowGroups] = useMemo(_ => [items, prevMonthItems || prevYearItems, retainedEarningItems].every(_ => _) && generateRowGroups(items || [], prevMonthItems || prevYearItems, retainedEarningItems || []), [items, prevMonthItems, prevYearItems, retainedEarningItems]) || [];
  const alerts = computeAlerts(items || [], retainedEarningItems || [], prevYearItems || [], company);
  useEffect(() => {
    if(disclosureSetting === null) {
      disclosureSettingRef.set({ createdAt: new Date(), }, { merge: true });
    }
  }, [disclosureSetting]);

  return props.translate(
    <div className="company-account-item-mappings container-fluid">
      <div className="bg-white mt-4">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="連結精算表を確認する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>連結精算表</h4>
        </div>
        <WithLoading loading={!(rowGroups && retainedEarningRowGroups)}>
          <div className="position-relative">
            <Alerts alerts={alerts} />
            <div className="mb-3 d-flex justify-content-start align-items-center gap-3">
              <ExportButton fileName={queryParams.filename || '連結精算表.csv'} rows={rowsForExport(company, items, prevMonthItems || prevYearItems, retainedEarningItems, displayConsolidationJournalTypes, consolidationJournalTypesById)} encoding={queryParams.encoding} />
            </div>
            {
              rowGroups && retainedEarningRowGroups && (
                <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
                  <table className="table table-bordered sticky-table m-0">
                    <thead className="thead-light text-center">
                      <tr>
                        <th style={{ minWidth: 230 }} rowSpan={2} className="sticky">連結科目</th>
                        {
                          relatedCompanies.map((company) => {
                            return (
                              <th key={company.id} style={{ minWidth: COLUMN_WIDTH, maxWidth: COLUMN_WIDTH, }} rowSpan={2}>
                                {company.display_name || company.name}
                              </th>
                            );
                          })
                        }
                        <th style={{ minWidth: COLUMN_WIDTH }} rowSpan={2}>
                          単純合算
                        </th>
                        {
                          displayConsolidationJournalTypes.length > 0 && (
                            <th style={{ minWidth: COLUMN_WIDTH * displayConsolidationJournalTypes.length }} colSpan={displayConsolidationJournalTypes.length}>
                              開始・連結仕訳
                            </th>
                          )
                        }
                        <th style={{ minWidth: COLUMN_WIDTH }} rowSpan={2}>
                          連結FS
                        </th>
                        <th style={{ minWidth: COLUMN_WIDTH }} rowSpan={2}>
                          比率
                        </th>
                        <th style={{ minWidth: COLUMN_WIDTH * 2, }} colSpan={2}>
                          開示
                        </th>
                        <th style={{ minWidth: COLUMN_WIDTH }} rowSpan={2}>
                          対前月差額
                        </th>
                      </tr>
                      <tr>
                        {
                          allConsolidationJournalTypes.filter(_ => displayConsolidationJournalTypes.includes(_.id)).map((consolidationJournalType) => {
                            const { id, name } = consolidationJournalType;
                            return (
                              <th key={id} style={{ minWidth: COLUMN_WIDTH }}>
                                {name}
                              </th>
                            );
                          })
                        }
                        <th style={{ minWidth: COLUMN_WIDTH }}>
                          開示科目
                        </th>
                        <th style={{ minWidth: COLUMN_WIDTH }}>
                          その他
                        </th>
                      </tr>
                    </thead>
                    {
                      (rowGroups || []).map(({ type, rowGroups }, i) => {
                        return (
                          <Fragment key={i}>
                            <thead className="text-center">
                              <tr>
                                <th className="bg-light">
                                  {type.toUpperCase()}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="thead-light">
                              {
                                rowGroups.map(({ category, rows, }) => {
                                  return (
                                    <Fragment key={category.name}>
                                      {
                                        rows.map(({ item, itemType, companyColumns, simpleSum, consolidationColumns, conclusiveAmount, ratio, prevMonthChange, }, i) => {
                                          const disclosesAsOther = itemType === 'consolidationAccountItem' && disclosureSetting?.otherDiscloseItems?.includes(item.group_name);
                                          return (
                                            <tr key={i} className={itemType === 'category' && 'font-weight-bold'}>
                                              <th style={{ textIndent: (category.indent() + (itemType === 'category' ? 0 : 1)) * 18 }} className={classnames('sticky', { 'font-weight-normal': itemType !== 'category' })}>
                                                {item.name}
                                              </th>
                                              {
                                                companyColumns.map((companyColumn, i) => {
                                                  const { exchangedAmount } = companyColumn;
                                                  return (
                                                    <td key={i} className="text-right">
                                                      {integerFormat(exchangedAmount)}
                                                    </td>
                                                  );
                                                })
                                              }
                                              <td className="text-right">
                                                {integerFormat(simpleSum)}
                                              </td>
                                              {
                                                consolidationColumns.filter(_ => displayConsolidationJournalTypes.includes(_.type)).map((consolidationColumn) => {
                                                  const { type, amount } = consolidationColumn;
                                                  const Component = itemType === 'category' ? 'span' : Link;
                                                  return (
                                                    <td className="text-right" key={type}>
                                                      <Component to={`/companies/${company.id}/consolidationJournals/?${qs.stringify({ period, yearMonth, types: [type], consolidationAccountItemIds: [item.id], onlyFilteredRows: 1, })}`}>
                                                        {integerFormat(amount)}
                                                      </Component>
                                                    </td>
                                                  )
                                                })
                                              }
                                              <td className="text-right">
                                                {integerFormat(conclusiveAmount)}
                                              </td>
                                              <td className="text-right">
                                                {isFinite(ratio) ? numeral(ratio).format('0.00%') : '-'}
                                              </td>
                                              <td className="text-right">
                                                {item.group_name}
                                              </td>
                                              <td>
                                                {
                                                  itemType === 'consolidationAccountItem' && (
                                                    <FormGroup className="m-0">
                                                      <FormGroup check>
                                                        <Label check>
                                                          <Input type="checkbox" checked={disclosesAsOther === true} onChange={_ => disclosureSetting?.ref.update({ otherDiscloseItems: firebase.firestore.FieldValue[_.target.checked ? 'arrayUnion' : 'arrayRemove'](item.group_name) })} disabled={isLockedMonth} />
                                                          その他
                                                        </Label>
                                                      </FormGroup>
                                                    </FormGroup>
                                                  )
                                                }
                                              </td>
                                              <td className="text-right">
                                                {integerChangeFormat(prevMonthChange)}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </Fragment>
                                  );
                                })
                              }
                            </tbody>
                          </Fragment>
                        );
                      })
                    }
                    <thead className="text-center">
                      <tr>
                        <th className="bg-light">
                          利益剰余金計算書
                        </th>
                      </tr>
                    </thead>
                    <tbody className="thead-light">
                      {
                        (retainedEarningRowGroups || []).map(({ category, rows, }) => {
                          return (
                            <Fragment key={category.name}>
                              {
                                rows.map(([item, prevYearItem], i) => {
                                  const { itemType, consolidationColumns, conclusiveAmount, ratio, } = item;
                                  return (
                                    <tr key={i} className={itemType === 'category' && 'font-weight-bold'}>
                                      <th style={{ textIndent: (category.indent() + (itemType === 'category' ? 0 : 1)) * 18 }} className={classnames('sticky', { 'font-weight-normal': itemType !== 'category' })}>
                                        {item.item.name}
                                      </th>
                                      {
                                        item.companyColumns.map((companyColumn, i) => {
                                          const prevYearCompanyColumn = prevYearItem.companyColumns[i];
                                          return (
                                            <td key={i} className="text-right text-nowrap">
                                              <div>
                                                {integerFormat(prevYearCompanyColumn.exchangedAmount)}
                                              </div>
                                              <div>
                                                {integerChangeFormat(companyColumn.exchangedAmount - prevYearCompanyColumn.exchangedAmount)}
                                              </div>
                                              <div>
                                                {integerFormat(companyColumn.exchangedAmount)}
                                              </div>
                                            </td>
                                          );
                                        })
                                      }
                                      <td className="text-right text-nowrap">
                                        <div>
                                          {integerFormat(prevYearItem.simpleSum)}
                                        </div>
                                        <div>
                                          {integerChangeFormat(item.simpleSum - prevYearItem.simpleSum)}
                                        </div>
                                        <div>
                                          {integerFormat(item.simpleSum)}
                                        </div>
                                      </td>
                                      {
                                        item.consolidationColumns.filter(_ => displayConsolidationJournalTypes.includes(_.type)).map((consolidationColumn, i) => {
                                          const { type, } = consolidationColumn;
                                          const prevYearConsolidationColumn = prevYearItem.consolidationColumns.filter(_ => displayConsolidationJournalTypes.includes(_.type))[i];
                                          return (
                                            <td className="text-right text-nowrap" key={type}>
                                              <div>
                                                {integerFormat(prevYearConsolidationColumn.amount)}
                                              </div>
                                              <div>
                                                {integerChangeFormat(consolidationColumn.amount - prevYearConsolidationColumn.amount)}
                                              </div>
                                              <div>
                                                {integerFormat(consolidationColumn.amount)}
                                              </div>
                                            </td>
                                          )
                                        })
                                      }
                                      <td className="text-right text-nowrap">
                                        <div>
                                          {integerFormat(prevYearItem.conclusiveAmount)}
                                        </div>
                                        <div>
                                          {integerChangeFormat(item.conclusiveAmount - prevYearItem.conclusiveAmount)}
                                        </div>
                                        <div>
                                          {integerFormat(item.conclusiveAmount)}
                                        </div>
                                      </td>
                                      <td className="text-right">
                                        -
                                      </td>
                                      <td className="text-right">
                                        -
                                      </td>
                                      <td className="text-right">
                                        -
                                      </td>
                                      <td className="text-right">
                                        -
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </Fragment>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              )
            }
          </div>
        </WithLoading>
      </div>
    </div>
  );
});
