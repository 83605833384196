import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { omit, keyBy } from 'lodash';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import { adminFields, plans, } from '../../shared/models/company';
import { fields, } from '../../shared/models/locale';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import AddButton from '../AddButton';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default AdminPage(function AdminLocales(props) {
  const locales = useCollectionSubscription(db.collection('locales'), [], { initialItems: null });
  useEffect(() => {
    if(locales?.length === 0) ['ja', 'en'].map(_ => db.collection('locales').doc(_).set({ name: '', content: '' }));
  }, [locales]);

  return (
    <div className="admin-locales">
      <div className="container-fluid py-5">
        <div className="d-flex justify-content-center mb-2">
          <h3>言語管理</h3>
        </div>
        <div className="mb-3 d-flex justify-content-end gap-1">
          <AddButton itemRef={_ => db.collection('locales').doc(_.code)} FormModal={ModelFormModal} processValues={_ => omit(_, ['code'])} formProps={{ title: '言語追加', fields, }} />
        </div>
        <table className="table mt-5 table-bordered">
          <thead className="thead-light text-nowrap">
            <tr>
              <th style={{ minWidth: 100 }}>言語名</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              locales?.map(({ ref, id, name, content, }) => {
                return (
                  <tr key={id} id={id}>
                    <td>
                      {name}
                    </td>
                    <td>
                      <div className="text-nowrap d-flex justify-content-end gap-1">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '言語 編集', fields: omit(fields(), ['code']), }} />
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
