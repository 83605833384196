import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { uniq, pick, isEmpty, last, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { integerFormat } from '../../util';
import { fields } from '../../shared/models/cfChangeType';
import { presetConsolidationAccountItems, } from '../../shared/models/consolidationAccountItem';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';

const db = firebase.firestore();

export default CompanyPage(function CompanyCfChangeTypes (props) {
  const { company, } = props;
  const [isDragging, toggleDragging] = useToggle(false);
  const cfChangeTypesRef = company.ref.collection('cfChangeTypes');
  const { items: cfChangeTypes, isLoading, } = useCollectionSubscription(cfChangeTypesRef, [company], { detail: true });
  const cfChangeTypesById = keyBy(cfChangeTypes, 'id');
  const sortedCfChangeTypes = sortBy(cfChangeTypes, 'index');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const consolidationAccountItems = accountItems.filter(_ => _.subsidiaryId === null);
  const presetConsolidationAccountItemSettings = useCollectionSubscription(company.ref.collection('presetConsolidationAccountItemSettings'), [company], { initialItems: null });
  const presetConsolidationAccountItemSettingsById = keyBy(presetConsolidationAccountItemSettings, 'id');
  const allConsolidationAccountItems = [...consolidationAccountItems, ...presetConsolidationAccountItems.map(_ => ({ ..._, ...presetConsolidationAccountItemSettingsById[_.id], }))];
  const rowsForExport = sortedCfChangeTypes.map(_ => pick(_, ['id', 'name']));
  const processRow = (batch, row, i) => {
    const { id, name, } = row;
    const ref = cfChangeTypesRef.doc(...(id ? [id] : []));
    const exists = cfChangeTypesById[id] != null;
    batch.set(ref, {
      name,
      ...(
        !exists && {
          index: (isEmpty(cfChangeTypes) ? 0 : last(cfChangeTypes).index) + i + 1,
          createdAt: new Date(),
        }
      ),
    }, { merge: true });
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(sortedCfChangeTypes, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('cfChangeTypes').doc(id), { index });
    });
  };
  const processRows = (rows) => {
    const cfChangeTypeNames = cfChangeTypes.map(_ => _.name);
    if(
      rows.map(_ => _.name).some(_ => cfChangeTypeNames.includes(_)) ||
      uniq(rows.map(_ => _.name)).length !== rows.length
    ) {
      toast.error('名称が重複しています');
    } else {
      return rows;
    }
  };

  return props.translate(
    <div className="company-cf-change-types container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="CF増減種別を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>CF増減種別</h4>
        </div>
        <div className="mb-3 d-flex justify-content-end gap-1">
          <ImportButton disabled={isLoading} processRow={processRow} processRows={processRows} documentName="cfChangeType" importKey="cfChangeTypes" />
          <ExportButton fileName="CF増減種別.csv" rows={rowsForExport} />
          <AddButton disabled={isLoading} itemRef={cfChangeTypesRef.doc()} FormModal={ModelFormModal} processValues={_ => ({ ..._, index: (last(sortedCfChangeTypes)?.index || 0) + 1 })} formProps={{ title: 'CF増減種別 追加', fields: fields({ otherItems: cfChangeTypes }), }} />
        </div>
        <div>
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr>
                <th></th>
                <th style={{ minWidth: 200 }}>名称</th>
                <th></th>
              </tr>
            </thead>
            <Container
              dragHandleSelector=".drag-handle"
              onDrop={onDrop}
              onDragStart={_ => toggleDragging(true)}
              onDragEnd={_ => toggleDragging(false)}
              dropPlaceholder={{ style: { background: 'eee', } }}
              render={(ref) => (
                <tbody ref={ref}>
                  {
                    sortedCfChangeTypes.map((cfChangeType) => {
                      const { id, ref, name, disuses = false, } = cfChangeType;
                      const beforeDelete = async () => {
                        if(
                          allConsolidationAccountItems.some(_ => _.cfMappingItems?.some(_ => _.cfChangeTypeId === id)) ||
                          (await getCollectionData(company.ref.collection('cfChanges').where('cfChangeTypeIds', 'array-contains', id))).length > 0
                        ) {
                          toast.error('使用されているため、削除できません');
                          return false;
                        }
                      };

                      return (
                        <Draggable
                          key={id}
                          render={() => (
                            <tr style={{ display: !isDragging && 'table-row' }}>
                              <td style={{ width: 30 }}>
                                <div className="drag-handle text-muted cursor-pointer">
                                  <span className="fas fa-grip-vertical" />
                                </div>
                              </td>
                              <td style={{ minWidth: 200 }}>
                                {name}
                                {disuses && <div className="ml-1 badge badge-secondary">使用しない</div>}
                              </td>
                              <td className="text-nowrap text-right">
                                <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'CF増減種別 編集', fields: fields({ otherItems: cfChangeTypes.filter(_ => _.id !== id) }), }} />
                                <DeleteButton item={cfChangeType} itemRef={ref} className="ml-2" beforeDelete={beforeDelete} />
                              </td>
                            </tr>
                          )}
                        />
                      );
                    })
                  }
                </tbody>
              )}
            />
          </table>
        </div>
      </div>
    </div>
  );
});
